import CommandProcessor from './../../../../cmd-process';

export function setValueOnServer(sheet, row, column, value) {
  return CommandProcessor.setValueOnServer(sheet, row, column, value);
}

export function prepareCommand(GC, spreadSheet, command, actionType) {
  return CommandProcessor.prepareCommand(GC, spreadSheet, command, actionType);
}

export function setCountCommand(ss, rowCount, colCount, callback) {
  return CommandProcessor.setCountCommand(ss, rowCount, colCount, callback);
}
