import * as fetch from '../../../../_shared/fetch';
import { coreAPIHostName, dataFlowServiceHostName, nodeAPIServiceHostName } from '../../../../../configs/params';
import CustomLogger from '../../../../_shared/Logger/CustomLogger';
import { GENERATE_XLSX } from '../../../../_shared/jobs/jobTypes';
import { startJob } from '../../../../_shared/jobs/apis';
import { getPollingJobResult } from '../apis';
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from '../../../../_shared/storage';

export const generateXlsx = async (id, dataFlowRunId, timezoneOffset, payload) => {
  await fetch.post(
    `${dataFlowServiceHostName}/api/v1/data-flows/${id}/data-flow-run/${dataFlowRunId}/generate-xlsx?timezoneOffset=${timezoneOffset}`,
    {
      body: JSON.stringify(payload),
    }
  );
};

export async function generateXLSXFile(id, dataFlowRunId, csvToXlsxMetadata = null) {
  const timezoneOffset = new Date().getTimezoneOffset();
  const generateXlsxJobKey = 'generateXlsxJobKey';
  let jobId;
  let payload;
  try {
    jobId = getDataFromLocalStorage(generateXlsxJobKey);
    if (!jobId) {
      const jobResponse = await startJob({
        entityId: dataFlowRunId,
        jobType: GENERATE_XLSX,
        payload: null,
        batchId: id,
      });
      jobId = jobResponse?.jobId;
      setDataToLocalStorage(generateXlsxJobKey);
      CustomLogger.pushLog(CustomLogger.operations.DOWNLOAD_OUTPUT, {
        workflow_id: id,
        job_id: jobId,
        message: `Starting the polling mechanism to retrieve the response from generate-xlsx endpoint`,
      });

      payload = {
        jobId,
        ...(csvToXlsxMetadata && { metadata: csvToXlsxMetadata }),
      };
    }
    const response = await getPollingJobResult(id, jobId, () =>
      generateXlsx(id, dataFlowRunId, timezoneOffset, payload)
    );
    removeDataFromLocalStorage(generateXlsxJobKey);
    return response;
  } catch (err) {
    removeDataFromLocalStorage(generateXlsxJobKey);
    CustomLogger.pushLog(CustomLogger.operations.DOWNLOAD_OUTPUT, {
      workflow_id: id,
      message: `Something happen while running pulling mechanism for the generate-xlsx endpoint`,
      error: JSON.stringify(err),
    });
  }
}

export async function generatePresignedUrls(outputInfo) {
  const response = await fetch.get(
    `${nodeAPIServiceHostName}/node/presignedurl?outputInfo=${encodeURIComponent(JSON.stringify(outputInfo))}`
  );
  if (!response.ok) {
    throw response.data;
  }

  return response.json();
}

export async function dataFlowOutputExport(dataFlowRunId, outputsToBeExported) {
  const response = await fetch.put(
    `${dataFlowServiceHostName}/api/v1/data-flows/data-flow-run/${dataFlowRunId}/output-information`,
    { body: JSON.stringify(outputsToBeExported) }
  );
  if (!response.ok) {
    throw new Error('Error while sending to Fixed Assets');
  }
  return response.json();
}

export async function exportToTPClassic(payload) {
  const response = await fetch.post(`${coreAPIHostName}/export-xlsx-to-tax-provision`, {
    body: JSON.stringify(payload),
  });
  if (response.status !== 200) {
    throw new Error('Error while exporting to TP Classic');
  }
}
