import GC from '../../../../SpreadSheets';
import { useRef, useState } from 'react';
import {
  getWorkpaperDataReferences,
  resolveFormulaValues,
  updateReferences,
  deleteReferences,
  createWorkpaperDataReferences,
} from './apis';
import {
  customFormulaMatchReturnValue,
  generateRequestPayload,
  generateOutputRequest,
  renderResolvedValues,
  updateCustomFormulaQueue,
  createInMemoryDataReferences,
  updateInMemoryDataReferences,
} from './dataReferenceHelper';
import { dataReferenceOverridePaint } from '../Spreadsheet/_spreadsheets/commands/customPaintOverride';
import useDataReferenceCellTracker from '../DataReference/useDataReferenceCellTracker';
import useReferenceScheduler from './useReferenceScheduler';
import { CELL_REVIEW, DATA_LINK } from '../../../_shared/DataReference/ReferenceType';

import { getDataFromLocalStorage } from '../../../_shared/storage';
import {
  CELL_REVIEW_ADDED,
  CELL_REVIEW_REMOVED,
  CELL_REVIEW_COMMAND_DELETE,
} from '../HistoryTracker/useHistoryTracker';
import { customFunctionNames } from '../Spreadsheet/_spreadsheets/formulas';
import { isDataLinkFormula } from '../datalinkHelper';
export default function useDataReferenceManager({
  dataReferences,
  dataReferenceValues,
  tooltipManagerRef,
  spreadRef,
  sendMessage,
  dataReferenceHistoryTracker = null,
  dataReferenceWorkpaperId,
  dataReferenceWorkpaperVersionId,
  customPaintOverriden,
}) {
  const customFormulaQueue = useRef([]);
  const dataLinkFormulaQueue = useRef([]);
  const cellReviewQueue = useRef([]);
  const cellPositionUpdateQueue = useRef([]);
  const deleteQueue = useRef([]);
  const isUndoDeleteCommand = useRef(false);
  const deleteCellReviewCommand = useRef([]);
  const lastCellReviewActionTimestamp = useRef(0);
  const inNavigationModeRef = useRef(false);

  const { trackPosition, trackPastedReference, cellValueChangedHandler, handleDataLinkCellDependencyChange } =
    useDataReferenceCellTracker(
      spreadRef,
      dataReferenceWorkpaperId,
      dataReferences,
      cellPositionUpdateQueue,
      deleteQueue,
      lastCellReviewActionTimestamp,
      deleteCellReviewCommand,
      dataReferenceHistoryTracker,
      processCellPositionUpdateQueue,
      processDeleteQueue,
      enqueueCellReviews,
      processCellReviewQueue,
      sendMessage
    );

  const [isProcessing, setIsProcessing] = useState(false);
  const processingInterval = 500;
  dataReferenceHistoryTracker.current.dataReferenceWorkpaperId = dataReferenceWorkpaperId;
  useReferenceScheduler(customFormulaQueue, processCustomFormulaQueue, processingInterval);

  // ====================
  // Enqueue Process
  // ====================

  function enqueueCellReviews(references, isUndoDeleteCommandAction = false) {
    isUndoDeleteCommand.current = isUndoDeleteCommandAction;
    const spreadsheet = spreadRef.current.getActiveSheet();
    if (references.length > 0) {
      cellReviewQueue.current = [...cellReviewQueue.current, ...references];
      createInMemoryDataReferences(spreadsheet, dataReferences, cellReviewQueue, isUndoDeleteCommandAction);
      lastCellReviewActionTimestamp.current = Date.now();
    }

    if (references.length > 0) {
      createInMemoryDataReferences(spreadsheet, dataReferences, customFormulaQueue, isUndoDeleteCommandAction);
    }
  }

  function enqueueCustomFormula(reference) {
    const activeSheet = spreadRef.current.getActiveSheet();
    const { type, row, column, sheetName, parameters } = reference;

    const calcReferenceExist = customFormulaQueue.current.some(
      x =>
        x.row === row &&
        x.column === column &&
        x.sheetName === sheetName &&
        x.type === type &&
        JSON.stringify(x.parameters) === JSON.stringify(parameters)
    );

    if (!calcReferenceExist) {
      customFormulaQueue.current = [...customFormulaQueue.current, reference];
      createInMemoryDataReferences(activeSheet, dataReferences, customFormulaQueue);
    }
  }

  async function enqueueDataLinks(references) {
    const spreadsheet = spreadRef.current.getActiveSheet();
    const existingReferences = new Set(
      dataLinkFormulaQueue.current.map(ref => `${ref.sheetName}-${ref.row}-${ref.column}`)
    );
    const filteredReferences = references.filter(
      ref => !existingReferences.has(`${ref.sheetName}-${ref.row}-${ref.column}`)
    );
    dataLinkFormulaQueue.current = [...dataLinkFormulaQueue.current, ...filteredReferences];
    if (dataLinkFormulaQueue.current) {
      createInMemoryDataReferences(spreadsheet, dataReferences, dataLinkFormulaQueue);
      await processDataLinksFormulaQueue();
    }
  }

  // ====================
  // References return value
  // ====================

  function customFormulaReturnValue(reference) {
    return customFormulaMatchReturnValue(dataReferences, dataReferenceValues, reference);
  }

  // ====================
  // References values resolution & rendering
  // ====================

  async function repaintActiveSheet(references) {
    const activeSheet = spreadRef.current.getActiveSheet();
    const currentReferences = references?.length ? references : dataReferences.current;
    if (activeSheet) {
      const needsRepaint = currentReferences.some(x => x.type === CELL_REVIEW && x.sheetName === activeSheet.name());

      if (needsRepaint) {
        repaint(activeSheet);
      }
    }
  }

  async function resolveEnqueuedFormulasValues(sourceQueue) {
    try {
      let resolvedValuesResponseData = [];
      const outputRequest = await generateOutputRequest(sourceQueue);
      if (outputRequest.length > 0) {
        const resolvedValuesResponse = await resolveFormulaValues(dataReferenceWorkpaperId.current, outputRequest);
        if (resolvedValuesResponse.ok) {
          resolvedValuesResponseData = await resolvedValuesResponse.json();
          if (resolvedValuesResponseData?.length > 0) {
            resolvedValuesResponseData.forEach(resolvedValue => {
              const newParsedKey = JSON.parse(resolvedValue.key);
              if (newParsedKey) {
                const index = dataReferenceValues.current.findIndex(entry => {
                  const existingValue = JSON.parse(entry.key);
                  return (
                    existingValue &&
                    existingValue.row === newParsedKey.row &&
                    existingValue.column === newParsedKey.column &&
                    existingValue.sheetName === newParsedKey.sheetName &&
                    existingValue.type === newParsedKey.type
                  );
                });

                if (index !== -1) {
                  dataReferenceValues.current[index].value = resolvedValue.value;
                  dataReferenceValues.current[index].extraData = resolvedValue.extraData;
                } else {
                  dataReferenceValues.current.push(resolvedValue);
                }
              }
            });
          }
        } else {
          dataReferenceValues.current = outputRequest.map(request => {
            if (request.type === DATA_LINK) {
              const value = customFormulaReturnValue(request);
              return {
                ...request,
                value: value,
              };
            }
            return {
              ...request,
              value: '',
            };
          });
        }
      }
    } catch (error) {
      if (dataReferenceValues.current.length <= 0) {
        dataReferenceValues.current = sourceQueue.current.map(request => {
          if (request.type === DATA_LINK) {
            const value = customFormulaReturnValue(request);
            return {
              ...request,
              value,
            };
          }
          return {
            ...request,
            value: '',
          };
        });
      }
    }
  }

  async function cleanUpDataReferences(workpaperId) {
    const dataReferences = await getWorkpaperDataReferences({ workpaperId });
    if (dataReferences?.length) {
      const referencesToDelete = dataReferences.filter(r => {
        if (r.type === CELL_REVIEW) return false;

        const { sheetName, column, row } = r;

        const referencesOnCoordinate = dataReferences.filter(
          ref => ref.sheetName === sheetName && ref.row === row && ref.column === column && ref.type !== CELL_REVIEW
        );

        const sheet = spreadRef.current.getSheetFromName(sheetName);
        if (sheet) {
          const currentFormula = sheet?.getFormula(row, column);
          if (currentFormula) {
            if (referencesOnCoordinate.length === 1) {
              if (
                !customFunctionNames.some(functionName => currentFormula.includes(functionName)) &&
                !isDataLinkFormula(currentFormula)
              )
                return true;
            }
          }
        }

        return false;
      });

      if (referencesToDelete?.length) {
        await deleteReferences(
          workpaperId,
          referencesToDelete.map(r => r.id)
        );
      }
    }
  }
  async function loadWorkpaperDataReferences(workpaperId, needsRepaint = false) {
    const { creatingWorkpaper } = JSON.parse(getDataFromLocalStorage(workpaperId) || '{}');

    if (!creatingWorkpaper) {
      const workbookDataReferences = await getWorkpaperDataReferences({
        workpaperId,
        filters: new Map([['includeDataLinksSources', true]]),
      });

      if (workbookDataReferences?.length) {
        let references = [...workbookDataReferences];
        if (dataReferenceWorkpaperVersionId?.current) {
          references = references.filter(x => x.type !== CELL_REVIEW);
        }
        dataReferences.current = [...new Map(references.map(reference => [reference.id, reference])).values()];
      }

      if (!customPaintOverriden.current) {
        dataReferenceOverridePaint(tooltipManagerRef.current, dataReferences, inNavigationModeRef);
        customPaintOverriden.current = true;
      }

      if (dataReferenceWorkpaperVersionId.current) {
        repaint(spreadRef.current);
      } else if (needsRepaint) {
        await repaintActiveSheet();
      }
    }
  }

  function repaint(spread) {
    spread.suspendPaint();
    spread.resumePaint();
  }

  // ====================
  // References cell position tracker
  // ====================
  async function trackDataReferenceCellPositions({ command, sheet }) {
    trackPosition(command, sheet);
  }

  const getDataReferencesToDelete = () =>
    deleteQueue.current.map(id => dataReferences.current.find(dr => dr.id === id)).filter(x => x) || [];

  const handleCellReviewDeleteAction = (addToCellReviewHistory, spread, references) => {
    const deletedCellReviews = references
      ? references.current
      : dataReferences.current.filter(({ id }) => deleteQueue.current.includes(id));
    const dataReferencesToDelete = getDataReferencesToDelete();

    addToCellReviewHistory(
      {
        cmd: references ? CELL_REVIEW_COMMAND_DELETE : CELL_REVIEW_REMOVED,
        data: deletedCellReviews,
      },
      spread
    );
    if (!references) {
      dataReferences.current = dataReferences.current.filter(({ id }) => !deleteQueue.current.includes(id));
    }

    dataReferencesToDelete.forEach(({ row, column, sheetName }) => {
      const sheet = spreadRef.current.getSheetFromName(sheetName);
      unsetCellReviewPadding(sheet, row, column);
    });
  };

  // ====================
  // Base queue processors
  // ====================

  async function processCustomFormulaQueue() {
    await processFormulaQueue(customFormulaQueue, true, true);
  }

  async function processDataLinksFormulaQueue() {
    await processFormulaQueue(dataLinkFormulaQueue, true, false);
  }

  async function processFormulaQueue(sourceQueue, shouldResolveValues = false, shouldRenderResolveValues = false) {
    if (!sourceQueue.current?.length) {
      return;
    }
    setIsProcessing(true);
    const referencesToAdd = { current: [...sourceQueue.current] };
    if (shouldResolveValues) {
      await resolveEnqueuedFormulasValues(sourceQueue);
      await updateInMemoryDataReferences(dataReferences, sourceQueue, dataReferenceValues);
    }
    if (shouldRenderResolveValues) {
      await renderResolvedValues(spreadRef, dataReferenceValues);
    }
    const metadata = await generateRequestPayload(referencesToAdd);
    const createDataReferences = await createWorkpaperDataReferences(dataReferenceWorkpaperId.current, metadata);
    if (createDataReferences) {
      dataReferences.current = createDataReferences;
    }
    if (shouldResolveValues) {
      await syncDataReferencesQueues(sourceQueue);
    } else {
      sourceQueue.current = sourceQueue.current.filter(x => !referencesToAdd.current.find(y => y.id === x.id));
    }
    setIsProcessing(false);
    window.formulaRecalcInProgress = false;
  }

  function setCellReviewPadding(sheet, row, column) {
    let style = sheet.getStyle(row, column);
    if (!style) {
      style = new GC.Spread.Sheets.Style();
    }

    let [topPadding, downPadding, rightPadding, leftPadding] = (style.cellPadding || '0 0 0 0').split(' ');

    if (Number(leftPadding) < 20) {
      style.cellPadding = [topPadding, downPadding, rightPadding, '20'].join(' ');
      sheet.setStyle(row, column, style, GC.Spread.Sheets.SheetArea.viewport);
    }
  }

  function unsetCellReviewPadding(sheet, row, column) {
    let style = sheet.getStyle(row, column);
    if (!style) {
      style = new GC.Spread.Sheets.Style();
    }

    let [topPadding, downPadding, rightPadding, leftPadding] = (style.cellPadding || '0 0 0 0').split(' ');

    if (Number(leftPadding) === 20) {
      style.cellPadding = [topPadding, downPadding, rightPadding, '0'].join(' ');
      sheet.setStyle(row, column, style, GC.Spread.Sheets.SheetArea.viewport);
    }
  }

  async function processCellReviewQueue(spread, sheet, addToHistory, repaintOnLoad = true) {
    let alreadySyncData = false;
    if (!cellReviewQueue.current || cellReviewQueue.current.length === 0) {
      return { alreadySyncData: true };
    }
    setIsProcessing(true);
    const metadata = [];
    cellReviewQueue.current.forEach(({ id, value, row, column, sheetName, parameters, type }) => {
      metadata.push({
        parameters: typeof parameters === 'string' ? parameters : JSON.stringify(parameters),
        oldValue: value,
        newValue: value,
        row,
        column,
        sheetName,
        referenceType: type,
        referenceId: id,
      });
    });

    cellReviewQueue.current.forEach(({ row, column }) => setCellReviewPadding(sheet, row, column));

    const createDataReferences = await createWorkpaperDataReferences(dataReferenceWorkpaperId.current, metadata);
    if (createDataReferences) {
      dataReferences.current = createDataReferences;
      if (repaintOnLoad) await repaintActiveSheet();
      alreadySyncData = true;
    }
    setIsProcessing(false);
    window.formulaRecalcInProgress = false;
    addToHistory &&
      addToHistory(
        {
          cmd: CELL_REVIEW_ADDED,
          data: cellReviewQueue.current,
        },
        spread,
        sheet
      );
    cellReviewQueue.current = [];
    isUndoDeleteCommand.current = false;

    return { alreadySyncData };
  }

  function groupReferencesById(grouped, ref) {
    if (!grouped[ref.workpaperId]) grouped[ref.workpaperId] = [];
    grouped[ref.workpaperId].push(ref);
    return grouped;
  }

  async function processCellPositionUpdateQueue() {
    if (!cellPositionUpdateQueue.current || cellPositionUpdateQueue.current.length === 0) {
      return;
    }

    const references = [...cellPositionUpdateQueue.current];
    const groupedById = references.reduce(groupReferencesById, {});

    const updatePromises = [];
    for (const id in groupedById) {
      updatePromises.push(updateReferences(id, groupedById[id]));
    }

    const responses = await Promise.all(updatePromises);

    if (responses.every(r => r.ok)) {
      const sheet = spreadRef.current.getActiveSheet();
      const { alreadySyncData } = await processCellReviewQueue(spreadRef.current, sheet, null);
      await syncDataReferencesQueues(customFormulaQueue, alreadySyncData, false);
      if (alreadySyncData) await repaintActiveSheet();
      const updatedPositionsReferences = cellPositionUpdateQueue.current.filter(item => !references.includes(item));

      cellPositionUpdateQueue.current = updatedPositionsReferences;
    }
  }

  async function processDeleteQueue(
    workpaperIdParam = dataReferenceWorkpaperId.current,
    callback = null,
    isCellReview = false,
    spread = null,
    addToCellReviewHistory = null
  ) {
    if (deleteQueue.current.length && !isUndoDeleteCommand.current) {
      if (isCellReview && spread && addToCellReviewHistory) {
        handleCellReviewDeleteAction(addToCellReviewHistory, spread, null);
      }

      if (
        dataReferenceHistoryTracker &&
        deleteCellReviewCommand.current.length &&
        dataReferenceHistoryTracker?.current?.addToHistory
      ) {
        handleCellReviewDeleteAction(
          dataReferenceHistoryTracker.current.addToHistory,
          spreadRef.current,
          deleteCellReviewCommand
        );
      }

      getDataReferencesToDelete().forEach(({ row, column, sheetName }) => {
        const sheet = spreadRef.current.getSheetFromName(sheetName);
        unsetCellReviewPadding(sheet, row, column);
      });

      dataReferences.current = dataReferences.current.filter(ref => !deleteQueue.current.includes(ref.id));
      const response = await deleteReferences(workpaperIdParam, deleteQueue.current);
      if (response.ok) {
        await repaintActiveSheet(deleteCellReviewCommand.current);
        await syncDataDeleteAction(workpaperIdParam, callback, true);
      }
    } else {
      await syncDataDeleteAction(workpaperIdParam, callback);
    }
  }

  // ====================
  // Base queue synchronizers
  // ====================
  async function syncDataDeleteAction(workpaperIdParam, callback = null, alreadySyncData = false) {
    if (!alreadySyncData) await loadWorkpaperDataReferences(workpaperIdParam, !alreadySyncData);
    deleteQueue.current = [];
    deleteCellReviewCommand.current = [];
    isUndoDeleteCommand.current = false;
    callback && callback();
  }

  async function syncDataReferencesQueues(sourceQueue, alreadySyncData = true, updateFormulaQueues = true) {
    if (!alreadySyncData) await loadWorkpaperDataReferences(dataReferenceWorkpaperId.current, !alreadySyncData);
    if (updateFormulaQueues) {
      await updateCustomFormulaQueue(sourceQueue, dataReferenceValues);
    }
  }

  return {
    cellValueChangedHandler,
    handleDataLinkCellDependencyChange,
    dataReferences,
    dataReferenceValues,
    deleteQueue,
    customFormulaQueue,
    enqueueCustomFormula,
    enqueueDataLinks,
    enqueueCellReviews,
    loadWorkpaperDataReferences,
    renderResolvedValues,
    trackDataReferenceCellPositions,
    trackPastedReference,
    updateCustomFormulaQueue,
    customFormulaReturnValue,
    processCellReviewQueue,
    processDeleteQueue,
    resolveEnqueuedFormulasValues,
    cleanUpDataReferences,
    inNavigationModeRef,
    isProcessing,
  };
}
