import React, { useContext, useEffect, useState, useRef } from 'react';
import { BTForm, BTInput } from '@btas/jasper';
import ElementInspector from './shared/ElementInspector';
import { TEXT, NUMERIC, DATE } from './shared/fieldTypes';
import { useDebouncedEffect } from './shared/useDebouncedEffect';
import useFieldNameValidation from './FormulaElementInspector/useFieldNameValidation';
import useFormulaValidation from './FormulaElementInspector/useFormulaValidation';
import FormulaTextArea from './shared/FormulaTextArea';
import UserPreferencesContext from '../../_shared/UserPreferencesContext';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { getOriginalNames } from '../shared/utils/FieldHashUtils';
import { WKP_AI_FORMULA_GENERATION } from '../../../constants/featureFlags';
import ExpressionGeneratorDisclaimer from './ExpressionGeneratorModal/ExpressionGeneratorDisclaimer';
import ExpressionGeneratorModal from './ExpressionGeneratorModal/ExpressionGeneratorModal';
import AILabModal from './ExpressionGeneratorModal/AILabModal';
import './FormulaElementInspector/styles.scss';
import AiGenerated from '../../_shared/AiGenerated';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
import { FormulaColumnOverride } from './FormulaElementInspector/FormulaColumnOverride';
import { FORMULA_OVERRIDE_ENABLED, FORMULA_OVERRIDE_KEY } from './FormulaElementInspector/formulaOverride';

const FormulaElementInspector = ({ elementData, elementType, updateData, elementId, inputElements, isDirty }) => {
  const { userPreferences, setUserPreferences } = useContext(UserPreferencesContext);
  const { hasAiEnabled: aiEnabled } = userPreferences;
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [readOnlyDisclaimer, setReadOnlyDisclaimer] = useState(false);
  const [showExpressionGeneratorModal, setShowExpressionGeneratorModal] = useState(false);
  const prevPromptRef = useRef('');

  const { outputFieldName } = elementData;
  const inputFields = inputElements.in?.elementData?.fields || [];
  const fields = getOriginalNames(inputFields);
  const { validateFieldName, outputFieldNameError } = useFieldNameValidation();
  const { validateFormula, fieldType, formulaError } = useFormulaValidation(inputFields);
  const canEditWorkflow = useCanEditWorkflow();

  useDebouncedEffect(
    () => {
      const displayFieldNameErrorMessage = !elementData.formula ? false : true;
      const displayFormulaErrorMessage = !elementData.outputFieldName ? false : true;
      validateFieldName(elementData.outputFieldName, inputFields, displayFieldNameErrorMessage);
      validateFormula(elementData.formula, inputFields, [TEXT, NUMERIC, DATE], displayFormulaErrorMessage);
    },
    350,
    [validateFieldName, elementData.outputFieldName, inputFields, validateFormula, elementData.formula]
  );

  useEffect(() => {
    if (fieldType && fieldType !== elementData.outputFieldType) {
      updateData({ outputFieldType: fieldType });
    }
  }, [updateData, fieldType, elementData.outputFieldType]);

  const handleOutputFieldNameChange = ({ target: { value } }) => {
    updateData({ outputFieldName: value });
  };

  const handleFormulaChange = value => {
    if (elementData?.AIGenerated && elementData?.formula !== value) {
      isAIGenerated(false);
    }
    updateData({ formula: value });
  };

  const isAIGenerated = value => {
    updateData({ AIGenerated: value });
  };

  const expressionGenerationHandler = () => {
    !aiEnabled ? setShowDisclaimer(true) : setShowExpressionGeneratorModal(true);
  };

  const acceptDisclaimer = () => {
    setUserPreferences({
      ...userPreferences,
      hasAiEnabled: true,
    });
    setShowDisclaimer(false);
    setShowExpressionGeneratorModal(true);
  };
  const toggleModal = () => {
    setShowDisclaimer(state => !state);
  };

  let formulaOverride = elementData?.[FORMULA_OVERRIDE_KEY];

  return (
    <>
      {showDisclaimer && (
        <ExpressionGeneratorDisclaimer
          acceptDisclaimer={acceptDisclaimer}
          readOnly={readOnlyDisclaimer}
          setReadOnlyDisclaimer={setReadOnlyDisclaimer}
          toggleModal={toggleModal}
        />
      )}
      <ElementInspector {...{ elementData, elementId, elementType, updateData, isDirty }}>
        <div className="wkp-formula-element-inspector">
          <BTForm onSubmit={e => e.preventDefault()}>
            <BTForm.FormGroup>
              <FormulaColumnOverride
                canEditWorkflow={canEditWorkflow}
                formulaOverride={formulaOverride}
                newOutputFieldName={elementData.outputFieldName}
                overrideFields={elementData.fields}
                updateData={updateData}
              ></FormulaColumnOverride>
            </BTForm.FormGroup>
            <BTForm.FormGroup>
              <div>
                {!formulaOverride?.[FORMULA_OVERRIDE_ENABLED] && (
                  <BTForm.FormGroup
                    required
                    errorText={outputFieldNameError}
                    hasError={!!outputFieldNameError}
                    label="Provide a name for the new column"
                  >
                    <BTInput
                      disabled={!canEditWorkflow}
                      value={outputFieldName}
                      onChange={handleOutputFieldNameChange}
                    />
                  </BTForm.FormGroup>
                )}
              </div>
            </BTForm.FormGroup>

            {showExpressionGeneratorModal ? (
              <ExpressionGeneratorModal
                blockType={'formula'}
                disabled={!canEditWorkflow}
                fields={fields}
                handleFormulaChange={handleFormulaChange}
                isAIGenerated={isAIGenerated}
                prevPromptRef={prevPromptRef}
                setReadOnlyDisclaimer={setReadOnlyDisclaimer}
                setShowDisclaimer={setShowDisclaimer}
                setShowExpressionGeneratorModal={setShowExpressionGeneratorModal}
              />
            ) : (
              <>
                <BTForm.FormGroup errorText={formulaError} hasError={!!formulaError} label="Provide the formula">
                  <div className="text-formula-area">
                    <FormulaTextArea
                      disabled={!canEditWorkflow}
                      error={formulaError}
                      fields={fields}
                      maxLength="10000"
                      value={elementData.formula}
                      onChange={handleFormulaChange}
                    />
                    {elementData?.AIGenerated && (
                      <span>
                        <AiGenerated uniqueId={'formula-ai-generated'} />
                      </span>
                    )}
                  </div>
                </BTForm.FormGroup>
                {isFeatureFlagEnabled(WKP_AI_FORMULA_GENERATION) && canEditWorkflow && (
                  <div style={{ marginBottom: '20px' }}>
                    <AILabModal
                      blockType={'formula'}
                      expressionGenerationHandler={expressionGenerationHandler}
                      isAIGenerated={elementData?.AIGenerated}
                    />
                  </div>
                )}
                <BTForm.FormGroup>
                  <p>
                    Reference columns by wrapping their name in square brackets.
                    <br />
                    Examples:
                  </p>
                  <code className="wkp-formula-example">[Amount]*[Percent deductible]</code>
                  <code className="wkp-formula-example">IF(([Amount]-[Credit])&lt;0,0,[Amount]-[Credit])</code>
                  <code className="wkp-formula-example">[Amount]*0.1</code>
                  <b>
                    <a
                      href="https://workpapers.bloombergtax.com/help/index.html?article=expressionSyntax"
                      target="blank"
                    >
                      View full list of functions and expression syntax
                    </a>
                  </b>
                </BTForm.FormGroup>
              </>
            )}
          </BTForm>
        </div>
      </ElementInspector>
    </>
  );
};

export default FormulaElementInspector;
