import { AUTHENTICATOR_ENDPOINT, dataFlowServiceHostName, PROVISION_ENDPOINT } from '../../configs/params';
import {
  removeTokenInfo,
  removeProductInfo,
  setTokenInfo,
  setProductInfo,
  setAiSubscription,
  removeAiSubscription,
  setUserInfo,
  removeUserInfo,
} from './auth';
import { isFeatureFlagEnabled } from '../../utils/featureFlags';
import { WKP_CM_INTEGRATION } from '../../constants/featureFlags';
import { setDataToLocalStorage, removeDataFromLocalStorage } from './storage';
import { isEmpty } from '../../utils/isEmpty';

export function redirectToLogin(returnUrl) {
  let target;
  if (isFeatureFlagEnabled(WKP_CM_INTEGRATION)) {
    target = encodeURIComponent(
      `${document.baseURI}auth/login?returnUrl=${document.baseURI}client-selection?${returnUrl ?? document.location.toString()}`
    );
  } else {
    target = encodeURIComponent(`${document.baseURI}auth/login?returnUrl=${returnUrl ?? document.location.toString()}`);
  }

  const redirectUrl = `${document.baseURI}login?target=${target}`;
  window.location.replace(redirectUrl);
}

export async function getOneTimeToken(authToken) {
  const res = await fetch(`${AUTHENTICATOR_ENDPOINT}`, {
    body: JSON.stringify({
      authorization_Token: authToken,
    }),
    credentials: 'include',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${'token'}` },
    method: 'POST',
  });

  if (res.ok && res.status === 201) {
    const data = await res.json();
    setTokenInfo(data);
    const tokenExpirationTime = getTokenExpirationTime(data);
    setDataToLocalStorage('tokenExpirationTime', tokenExpirationTime);
    return { data };
  }

  if (res.status === 401) {
    return { notAuthorized: true };
  }

  throw new Error('An error occurred while trying to authenticate with the ECDS service');
}

export async function scaleProvision() {
  try {
    await fetch(`${PROVISION_ENDPOINT}`, {
      credentials: 'include',
      method: 'GET',
      headers: { Authorization: `Bearer ${'token'}` },
    });
  } catch (error) {}
}

export async function refreshExpiredToken() {
  const res = await fetch(`${AUTHENTICATOR_ENDPOINT}`, {
    credentials: 'include',
    method: 'PUT',
    headers: { Authorization: `Bearer ${'token'}` },
  });

  if (res.ok && res.status === 201) {
    const data = await res.json();
    setTokenInfo(data);
    const tokenExpirationTime = getTokenExpirationTime(data);
    setDataToLocalStorage('tokenExpirationTime', tokenExpirationTime);
    return data;
  } else {
    if (res.status === 401 || res.status === 400) {
      removeTokenInfo();
      removeDataFromLocalStorage('tokenExpirationTime');
      redirectToLogin();
    } else {
      throw Error('An error occurred while trying to refresh authorization token in ECDS services');
    }
  }
}

const getTokenExpirationTime = ({ expirationDate: expirationDateTime, serverDate: serverDateTime }) => {
  // Adjust the server-side date to local browser time
  const localDate = new Date().getTime();
  const serverDate = new Date(serverDateTime).getTime();
  const timeDifference = localDate - serverDate;

  const adjustedExpirationTime = expirationDateTime * 1000 + timeDifference;
  return adjustedExpirationTime;
};

export async function getUserInfoV2() {
  const res = await fetch(`${dataFlowServiceHostName}/api/v1/data-flows/user/info`, {
    credentials: 'include',
    headers: { Authorization: `Bearer ${'token'}` },
    method: 'GET',
  });

  if (res.ok && res.status === 200) {
    const data = await res.json();
    const { products, preferences } = data;
    setUserInfo({ designatedUser: data.designatedUser, sharedUser: data.sharedUser });
    if (!isEmpty(products)) {
      setProductInfo(data);
    }
    setAiSubscription(preferences);
    return data;
  } else {
    if (res.status === 401 || res.status === 400) {
      removeUserInfo();
      removeProductInfo();
      removeAiSubscription();
    } else {
      throw Error('An error occurred while trying to get Product Codes User information in ECDS services');
    }
  }
}

export function getAuthUrlParameters() {
  const queryParameters = new URLSearchParams(window.location.search);
  const authToken = queryParameters.get('Authorization_Token');
  const returnUrls = queryParameters.get('returnUrl');
  let returnUrl;
  if (returnUrls) {
    const containsReturnUrl = returnUrls.includes('?');
    const splittedUrls = containsReturnUrl ? returnUrls.split('?') : [returnUrls];
    const cleanReturnUrl = splittedUrls.length > 1 ? splittedUrls[splittedUrls.length - 1] : splittedUrls[0];
    returnUrl = cleanReturnUrl.includes('returnUrl=') ? cleanReturnUrl.replace('returnUrl=', '') : cleanReturnUrl;
    /**
     * TODO: required refactoring as there are other cases
     * when query string contains parameters, not valid url
     */
    returnUrl = cleanReturnUrl.includes('redirectUrl=') ? cleanReturnUrl.replace('redirectUrl=', '') : cleanReturnUrl;
  }
  return { returnUrl, authToken };
}
