import { BTButton, BTModal } from '@btas/jasper';
import { eventTrackTagNames } from '../../EventTrackingContext/constants';

const ClientChangeConfirmationModal = ({ show, onClose, onProceed }) => {
  return (
    <BTModal show={show} size="modal-lg" title="Change Client" onCloseClick={onClose}>
      <BTModal.Body>
        By changing clients, the session for the previously selected client will close. Any unsaved work will be lost.
        This change will impact all Bloomberg Tax Workpapers browser tabs. Are you sure?
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onClose}>Cancel</BTButton>
        <BTButton
          btStyle="primary"
          data-track-tag={eventTrackTagNames.clientSelect}
          id="client-change-confirmation-modal-proceed-btn"
          onClick={onProceed}
        >
          Proceed
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default ClientChangeConfirmationModal;
