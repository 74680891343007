import React, { useEffect, useState } from 'react';
import AiSparklesIcon from './TaxResearchAi/assets/ai_sparkles_white.svg';
import './TaxResearchAi/styles.scss';
import TaxResearchModal from './TaxResearchAi/TaxResearchModal';
import { getAiSummary } from './TaxResearchAi/api';
import AiSubscriptionModal from './TaxResearchAi/AiSubscriptionModal';
import { getAiSubscription } from '../auth';
import { useTrackInteractiveClick } from '../EventTrackingContext';
import { eventTrackTagNames } from '../EventTrackingContext/constants';
import { itemTypes } from '../EventTrackingContext/utils';
const TaxResearchAi = () => {
  const trackEvent = useTrackInteractiveClick();

  const [showModal, setShowModal] = useState(false);
  const [isSubscribed, setIsSubScribed] = useState(getAiSubscription());

  useEffect(() => {
    const storageListener = () => {
      const newData = getAiSubscription();
      setIsSubScribed(newData);
    };
    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, []);

  // search input state
  const [error, setError] = useState();
  const [inputValue, setInputValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [aiSummary, setAiSummary] = useState();

  // Click events
  const handleSubmit = async e => {
    trackEvent('Q&A - Submit search', itemTypes.BUTTON);
    e.preventDefault();

    setError(false);
    if (!inputValue) return clearAll();

    try {
      setIsSaving(true);
      const data = await getAiSummary(inputValue);

      setAiSummary(data);
      if (!data.summary) {
        setError('info');
      }
    } catch (error) {
      setAiSummary();
      setError('warning');
    } finally {
      setIsSaving(false);
    }
  };

  const clearAll = () => {
    setInputValue('');
    setAiSummary();
  };

  const toggleModal = event => {
    if (event) {
      if (isSubscribed) {
        trackEvent('Bloomberg Tax Answers Link', itemTypes.LI);
      } else if (event.target?.innerHTML === 'Cancel') {
        trackEvent('Bloomberg Tax Answers - Inactive (upgrade) Cancel', itemTypes.LI);
      } else {
        trackEvent('Bloomberg Tax Answers - Inactive (upgrade)', itemTypes.BUTTON);
      }
    }
    setShowModal(state => !state);
  };

  return (
    <>
      <li className={`${showModal && 'modal-active'} navbar-tax-research`} onClick={toggleModal}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="research-ai-btn" id={isSubscribed ? 'ask-research-active' : 'ask-research-inactive'}>
          <img alt="Tax Research Icon" className="navbar-button-icon" src={AiSparklesIcon} />
          <span className="navbar-button-text">Bloomberg Tax Answers</span>
          {!isSubscribed && <span className="upgrade-badge">Upgrade</span>}
        </a>

        {showModal && isSubscribed && (
          <TaxResearchModal
            aiSummary={aiSummary}
            error={error}
            handleSubmit={handleSubmit}
            inputValue={inputValue}
            isSaving={isSaving}
            setInputValue={setInputValue}
            toggleModal={toggleModal}
          />
        )}
      </li>

      {/* Subscribe to Tax Research Modal */}
      {!isSubscribed && showModal && (
        <AiSubscriptionModal data-track-tag={eventTrackTagNames.interactive} toggleModal={toggleModal} />
      )}
    </>
  );
};

export default TaxResearchAi;
