import {
  spreadsheetServiceHostName,
  nodeAPIServiceHostName,
  workpapersSpreadsheetModelHostName,
} from '../../../configs/params';
import * as api from '../../_shared/fetch';
import { isGlobalTemplateAccount } from '../auth';

export async function getNextValidWorkpaperName(workpaperId) {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workbook/${workpaperId}/next-valid-name`);
  const { name } = await response.json();
  return name;
}

export async function saveAsWorkpaper(workpaperId, jobId, name, taxPeriod, targetClientId) {
  const baseUrl = `${workpapersSpreadsheetModelHostName}/file/copy/${workpaperId}`;
  const response = await api.post(baseUrl, {
    body: JSON.stringify({ workpaperId, jobId, name, taxPeriod, ...(!!targetClientId && { targetClientId }) }),
  });
  const { workpaperId: newId } = await response.json();
  return newId;
}

export async function validateMetadata(workpaperId, name, taxPeriod, targetClientId = null) {
  const response = await api.post(`${spreadsheetServiceHostName}/spreadsheet/metadata/${workpaperId}/validate`, {
    body: JSON.stringify({
      name,
      taxPeriod,
      targetClientId,
    }),
  });
  const { isValid } = await response.json();
  return isValid;
}

export async function saveAsTags(fromResourceId, toResourceId, resourceType) {
  try {
    if (isGlobalTemplateAccount()) {
      const response = await api.post(`${nodeAPIServiceHostName}/node/tags/resource/copy`, {
        body: JSON.stringify({ fromResourceId, toResourceId, resourceType }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw data;
      }
      return data;
    }
  } catch (err) {
    throw ('Not a global template account ', err);
  }
}
