import { useEffect } from 'react';
import { BTForm, BTInput, BTComboBox, BTIcon } from '@btas/jasper';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_CM_INTEGRATION } from '../../../constants/featureFlags';
import { useGetClients } from '../../../hooks/useGetClients';

export default function SaveAs({
  name,
  taxPeriod,
  taxPeriodComboData,
  errorMessage,
  handleName,
  selectedClient,
  handleTaxPeriod,
  currentClientId,
  handleClient,
  isDifferentClient,
}) {
  const { clients, isLoading: clientsLoading, error: clientsError } = useGetClients();

  useEffect(() => {
    if (!clientsLoading && clients.length > 0) {
      const defaultClient = clients.find(client => client.id === currentClientId);
      if (defaultClient && (!selectedClient || currentClientId !== defaultClient.id)) {
        handleClient({ label: defaultClient.name, value: defaultClient.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsLoading, clients, currentClientId]);

  return (
    <>
      <BTForm>
        {isFeatureFlagEnabled(WKP_CM_INTEGRATION) && (
          <BTForm.FormGroup
            required
            aria-label="clientForm"
            errorText={clientsError ? 'Failed to load clients' : undefined}
            hasError={!!clientsError}
            label="Client"
          >
            <BTComboBox
              canCreateNew={false}
              id="client"
              isLoading={clientsLoading}
              isSearchable={true}
              maxMenuHeight={100}
              noResultsMessage="No clients found."
              options={clients.map(client => ({ label: client.name, value: client.id }))}
              value={selectedClient}
              onChange={option => handleClient(option)}
            />
          </BTForm.FormGroup>
        )}

        <BTForm.FormGroup errorText={errorMessage} hasError={!!errorMessage} htmlFor="name" label="Name">
          <BTInput id="name" value={name} onChange={({ target: { value } }) => handleName(value)} />
        </BTForm.FormGroup>

        <BTForm.FormGroup htmlFor="taxPeriod" label="Tax Period">
          <BTComboBox
            canCreateNew
            isClearable
            popOutMenu
            createText="Add tax period"
            id="taxPeriod"
            isSearchable={true}
            maxMenuHeight={150}
            noResultsMessage="No tax periods created. Start typing to add a tax period."
            options={taxPeriodComboData}
            placeholder="Select a tax period, or start typing to add new"
            value={taxPeriod && { label: taxPeriod, value: taxPeriod }}
            onChange={option => {
              const selectedValue = option && option.value;
              handleTaxPeriod(selectedValue);
            }}
          />
        </BTForm.FormGroup>

        {isDifferentClient && (
          <>
            <br />
            <div
              className="notification"
              style={{
                backgroundColor: '#FFD700',
                fontSize: '16px',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
                padding: '12px 16px',
                color: 'black',
              }}
            >
              <BTIcon icon="exclamation-triangle" style={{ marginRight: '8px', color: 'black' }} />
              <span>Copying this item to a different client will cause all links to be removed.</span>
            </div>
          </>
        )}
      </BTForm>
    </>
  );
}
