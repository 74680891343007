import { useState } from 'react';
import { BTAccordion, BTAlert, BTButton, BTForm, BTIcon, BTInput, BTSpinner, H2, H3, P } from '@btas/jasper';
import AiGenerated from '../../AiGenerated';

const TaxResearchModal = ({ toggleModal, inputValue, setInputValue, handleSubmit, isSaving, aiSummary, error }) => {
  const handleClick = e => {
    e.stopPropagation();
  };

  const handleChange = ({ target }) => {
    setInputValue(target.value);
  };

  return (
    <div className="research-dropdown" onClick={handleClick}>
      <div className="research-body">
        <div className="title-box">
          <div className="title-and-badge">
            <H2 className="title">Bloomberg Tax Answers</H2>
            <div>
              <span className="beta-badge">Beta</span>
            </div>
          </div>
          <div
            className="dropdown-button"
            id="dropdown-close"
            style={{
              fontSize: '24px',
              cursor: 'pointer',
            }}
            onClick={() => toggleModal()}
          >
            <BTIcon icon="close" />
          </div>
        </div>
        <BTForm className="search-box" onSubmit={handleSubmit}>
          <BTForm.FormGroup required htmlFor="search">
            <BTInput
              aria-label="Input"
              id="search"
              maxLength="2000"
              name="search"
              placeholder="Ask a question in Bloomberg Tax Research"
              value={inputValue}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />
          </BTForm.FormGroup>
          <BTButton className="search-icon" disabled={isSaving || !inputValue.length} onClick={handleSubmit}>
            <BTIcon icon="magnify" />
          </BTButton>
        </BTForm>
        {isSaving ? (
          <div className="spinner-container">
            <BTSpinner />
          </div>
        ) : (
          aiSummary?.summary && (
            <div className="scroll-container">
              <div className="research-summary">
                <AiGenerated uniqueId={'qna-ai-generated'} />
                <TruncatedSummary summary={aiSummary?.summary} />
                <div className="summary-disclaimer">
                  <p>
                    Bloomberg Tax Answers is experimental in nature, and users are strongly advised to corroborate the
                    information obtained, exercise professional judgment, and seek out additional sources of information
                    as deemed necessary.
                  </p>
                </div>
              </div>

              <div className="sources">
                <H3 className="title-md">Sources</H3>
                <ReferenceList sources={aiSummary.answers} />
              </div>
            </div>
          )
        )}

        {error && <ErrorMessage type={error} />}
      </div>
    </div>
  );
};

export default TaxResearchModal;

const TruncatedSummary = ({ summary }) => {
  const [isExpanded, setIsExpanded] = useState(summary?.length < 600);
  const truncatedText = isExpanded ? summary : summary.slice(0, 600);
  return (
    <div className="summary-content">
      <P>
        {truncatedText}
        {!isExpanded && '...'}
      </P>

      {summary?.length > 600 && (
        <button className="btn-text" onClick={() => setIsExpanded(state => !state)}>
          {!isExpanded ? 'Show Full Summary' : 'View Less'}
        </button>
      )}
    </div>
  );
};

const ReferenceList = ({ sources }) => {
  const [showAll, setShowAll] = useState(false);
  const displayCount = showAll ? sources.length : 3;

  const toggleDisplay = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <BTAccordion>
        {sources?.slice(0, displayCount).map(source => (
          <BTAccordion.Panel header={source.docTitle}>
            {source.text}
            <a
              className="btn-text"
              href={`https://www.bloomberglaw.com/product/tax/document/${source.docId}`}
              rel="noreferrer"
              target="_blank"
            >
              View full document
            </a>
          </BTAccordion.Panel>
        ))}
      </BTAccordion>
      {sources.length > 3 && (
        <button className="btn-text" onClick={toggleDisplay}>
          View {showAll ? 'Less' : 'More'} Sources
        </button>
      )}
    </>
  );
};

const ErrorMessage = ({ type }) => {
  return (
    <div className="error-container">
      {type === 'info' ? (
        <BTAlert btStyle="info">AI Summary - There is no AI summary available for this query</BTAlert>
      ) : (
        <BTAlert btStyle="danger">
          The application has encountered a problem. If you continue to receive this error message, contact Customer
          Support by phone (1-800-424-2938) or email (appsupport@bloombergtax.com)
        </BTAlert>
      )}
    </div>
  );
};
