import { useEffect, useState } from 'react';
import { spreadsheetServiceHostName } from '../configs/params';
import { WKP_CM_INTEGRATION } from '../constants/featureFlags';
import { isFeatureFlagEnabled } from '../utils/featureFlags';
import * as fetch from '../components/_shared/fetch';
import { useClient } from '../contexts/ClientContext';
import { createUserToken } from '../components/home/ClientSelection/apis';
import { getDataFromLocalStorage } from '../components/_shared/storage';

export function useGetResourceClient(resourceType, resourceId) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const clientContext = useClient();

  useEffect(() => {
    const fetchResourceClient = async () => {
      setIsLoading(true);
      try {
        const { clientId, setClientId } = clientContext;
        const currentUrl = window.location.href;
        const { creatingWorkpaper } = JSON.parse(getDataFromLocalStorage(resourceId) || '{}');

        if (resourceType === 'spreadsheet' && creatingWorkpaper) {
          // ignore when creating new workpaper
          return;
        }

        const result = await fetch.get(
          `${spreadsheetServiceHostName}/spreadsheet/resource/client?resourceType=${resourceType}&resourceId=${resourceId}`
        );

        if (!clientId && ![200, 404].includes(result.status)) {
          // Throw error when user not selected any client
          // and directly landed on artifact page after login
          // and received
          throw new Error('Invalid response');
        }

        if (result.status === 404) {
          // If that resource not belongs to that user clients
          window.location.replace(`${document.baseURI}unauthorized-client-access`);
          return;
        }

        if (result.status === 200) {
          const resourceClientInfo = await result.json();
          const resourceClientId = resourceClientInfo?.clientId;

          if (!resourceClientId) {
            // no action when no resource id received
            // app will behave with existing logic
            return;
          }

          if (!clientId && resourceClientId) {
            // redirect to artifact url and set new client as default
            // when there are not any client set
            await createUserToken(resourceClientId);
            setClientId(resourceClientId);
            return;
          }

          if (clientId && clientId !== resourceClientId) {
            // redirect to client change if current client is not same as received client
            window.location.replace(
              `${document.baseURI}client-change-confirmation?client=${resourceClientId}&redirectUrl=${currentUrl}`
            );
            return;
          }
        }
      } catch (error) {
        setError(error);
        !clientContext?.clientId &&
          window.location.replace(`${document.baseURI}client-selection?redirectUrl=${window.location.href}&error=true`);
      } finally {
        setIsLoading(false);
      }
    };
    isFeatureFlagEnabled(WKP_CM_INTEGRATION) && fetchResourceClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, resourceId]);

  return { isLoading, error };
}
