import GC from '../../../../../SpreadSheets';
import { executeWithSuspendedPaint, formulaHelpers, gcExpressionTypes } from './spreadHelpers';
import { intTaxRateFormulaName, intTaxRatesFormula } from './formulas/intTaxRatesFormula';
import { sourceDataFormulaName, sourceDataFormula } from './formulas/sourceDataFormula';
import { stateTaxRateFormulaName, stateTaxRateFormula } from './formulas/stateTaxRateFormula';
import { taxPeriodFormulaName, TaxPeriodFormula } from './formulas/taxPeriodFormula';
import { stateApportionFormula, stateApportionFormulaName } from './formulas/stateApportionFormula';
import { operatorMap } from './utils';
import {
  DATA_LINK,
  INT_TAX_RATE,
  SOURCE_DATA_CONNECTION,
  STATE_APPORTION,
  STATE_TAX_RATE,
} from '../../../../_shared/DataReference/ReferenceType';
import { v4 as uuidv4 } from 'uuid';
import { getFiltersFromDynamicArguments } from './sourceDataFormulaHelper';
import CustomLogger from '../../../../_shared/Logger/CustomLogger';

export const customFunctionNames = ['SOURCE_DATA', 'INTTAXRATE', 'STATETAXRATE', 'STATEAPPORTION'];

export const StateTaxRateDataFormulaRegEx =
  /(STATETAXRATE\()"(?<jurisdiction>.*)",(?:| )"(?<periodStartDate>.*)",(?:| )(?:,(?<taxableIncome>.*))?\)/;

// RegEx for Dynamic formula (BPMP-2780): SOURCE_DATA(dataFlowId, taxPeriod, output, outputField, [field_name1, field_value1, ...])
// The first 4 params are fixed then the rest are optionals and dynamics (max 256)
export const SourceDataFormulaRegex =
  /(SOURCE_DATA\()"(?<outputId>[\w-]{36,36})",(?:| )(?<outputField>"[^"]*?"|[^",]*)(?:,(?<filters>.+))?\)/;

export const datalinkFormulaRegex =
  /^[+-]*'((?<taxPeriod>[^/]+)\/)?\[(?<workpaperName>[^\]]+)\](?<sheetName>[^']+)'!(?<a1Coordinate>[$]?[A-Z]+[$]?[0-9]+)$/;
export const loadingString = 'Loading...';

// ====================
// Custom formula definition
// ====================

function gcBaseCustomFunction(isContextSensitive) {
  const gcBaseCustomFunctionSubClass = isContextSensitive
    ? GC.Spread.CalcEngine.Functions.Function
    : GC.Spread.CalcEngine.Functions.AsyncFunction;

  const customFunctionClass = class extends gcBaseCustomFunctionSubClass {
    constructor({ name, minArgs, maxArgs, descriptionData, evaluationFunction, data }) {
      super();

      this.name = name;
      this.minArgs = minArgs;
      this.maxArgs = maxArgs;
      this.descriptionData = descriptionData;
      this.evaluationFunction = evaluationFunction;
      this.data = data;
    }

    //set context flag to get GC context object for affected cell
    isContextSensitive() {
      return isContextSensitive;
    }

    defaultValue() {
      return this.data.defaultValue;
    }
  };

  if (isContextSensitive) {
    // add evaluate method to class
    customFunctionClass.prototype.evaluate = function (...args) {
      return this.evaluationFunction(args, this.data);
    };
  } else {
    customFunctionClass.prototype.evaluateAsync = function (...args) {
      return this.evaluationFunction(args, this.data);
    };
  }

  customFunctionClass.prototype.description = function () {
    return this.descriptionData;
  };

  return customFunctionClass;
}

/**
 * Register global custom functions.
 */
export function registerGlobalCustomFunctions(
  taxPeriod,
  workpaperId,
  enqueueCommands,
  spread,
  enqueueCustomFormula,
  customFormulaReturnValue
) {
  const gcSyncFunction = gcBaseCustomFunction(true);

  GC.Spread.CalcEngine.Functions.defineGlobalCustomFunction(
    taxPeriodFormulaName,
    new TaxPeriodFormula(taxPeriod, workpaperId, enqueueCommands, spread)
  );

  GC.Spread.CalcEngine.Functions.defineGlobalCustomFunction(
    stateTaxRateFormulaName,
    stateTaxRateFormula(gcSyncFunction, {
      defaultValue: loadingString,
      enqueueCustomFormula,
      workpaperId,
      spread,
      customFormulaReturnValue,
    })
  );

  GC.Spread.CalcEngine.Functions.defineGlobalCustomFunction(
    sourceDataFormulaName,
    sourceDataFormula(gcSyncFunction, {
      defaultValue: loadingString,
      enqueueCustomFormula,
      workpaperId,
      spread,
      customFormulaReturnValue,
    })
  );

  GC.Spread.CalcEngine.Functions.defineGlobalCustomFunction(
    intTaxRateFormulaName,
    intTaxRatesFormula(gcSyncFunction, {
      defaultValue: loadingString,
      enqueueCustomFormula,
      workpaperId,
      spread,
      customFormulaReturnValue,
    })
  );

  GC.Spread.CalcEngine.Functions.defineGlobalCustomFunction(
    stateApportionFormulaName,
    stateApportionFormula(gcSyncFunction, {
      defaultValue: loadingString,
      enqueueCustomFormula,
      workpaperId,
      spread,
      customFormulaReturnValue,
    })
  );
}

const buildTextArgument = value => ({ value, type: 'text' });

/**
 * Set a cell to use source data
 * @param {object} args { spread, sheet, row, col, output, outputField, account, entity, filters }
 */
export async function setCellDataSourceFormula({
  col,
  filters,
  output,
  outputField,
  row,
  sheet,
  spread,
  sdcFormulaWrapper,
}) {
  const stringParam = param => (param ? (param.formula ?? `"${param}"`) : '""');

  function setFormula(...args) {
    let params = args
      .map(arg => {
        const { value: v, type } = arg;
        const param = type === 'formula' ? arg : v;
        const value = type === 'numeric' ? v : stringParam(param);
        return value ? value : '""';
      })
      .join(',');

    executeWithSuspendedPaint(spread, () => {
      const sdcFormula = `${sourceDataFormulaName}(${params})`;
      const wrappedFormula = sdcFormulaWrapper ? wrapSourceDataFormula(sdcFormulaWrapper, sdcFormula) : sdcFormula;

      const commandManager = spread.commandManager();
      const sheetId = sheet._id;
      const sheetName = sheet.name();

      commandManager.execute({
        cmd: 'editCell',
        sheetName,
        row,
        col,
        sheetId,
        newValue: `=${wrappedFormula}`,
      });
    });
  }

  const applyArguments = [buildTextArgument(output), outputField];

  for (const [filterName, filter] of Object.entries(filters)) {
    const { value, type, nameReference } = filter;
    const nameArgument = {};
    if (nameReference) {
      nameArgument.formula = nameReference;
    } else {
      nameArgument.value = filterName;
    }
    nameArgument.type = nameReference ? 'formula' : 'text';

    applyArguments.push(nameArgument);

    const formula = value.formula;
    const valueArgument = { type: formula ? 'formula' : type };
    if (formula) {
      valueArgument.formula = formula;
    } else {
      valueArgument.value = value;
    }
    applyArguments.push(valueArgument);
  }
  setFormula(...applyArguments);

  return;
}

/**
 * Wraps the SDC formula using the whole expression obtained at the begenning of the process
 *
 * @param {GCExpression} sdcFormulaWrapper Whole expression that wraps the old sdcFormula
 * @param {string} sdcFormula Source data connection formula
 * @returns Wrapped SDC formula as string
 */
function wrapSourceDataFormula(sdcFormulaWrapper, sdcFormula) {
  const { type } = sdcFormulaWrapper;
  let result;
  switch (type) {
    case gcExpressionTypes.function:
      const { functionName, arguments: fnArgs } = sdcFormulaWrapper;
      if (functionName === sourceDataFormulaName) {
        result = sdcFormula;
      } else {
        const wrappedArgs = fnArgs.map(a => wrapSourceDataFormula(a, sdcFormula)).join(',');
        result = `${functionName}(${wrappedArgs})`;
      }
      break;
    case gcExpressionTypes.operator:
      const { operatorType, value: value1, value2 } = sdcFormulaWrapper;
      const operator = operatorMap[operatorType];
      const v1 = wrapSourceDataFormula(value1, sdcFormula);

      if (!value2) {
        result = `${operator}${v1}`;
        break;
      }
      const v2 = wrapSourceDataFormula(value2, sdcFormula);
      result = `${v1}${operator}${v2}`;
      break;
    case gcExpressionTypes.parentheses:
      result = `(${wrapSourceDataFormula(sdcFormulaWrapper.value, sdcFormula)})`;
      break;
    default:
      const { value } = formulaHelpers.getValueFromExpression(sdcFormulaWrapper);
      result = value;
      break;
  }
  return result;
}

export function isSourceDataFormulaMatch(formula) {
  return formula?.match(SourceDataFormulaRegex);
}

export function isNumeric(value) {
  if (!value) return false;
  if (typeof value != 'string') return false;
  return !isNaN(value) && !isNaN(parseFloat(value));
}

function parseToTokens(ss, sheet, formula) {
  var calcService = ss.getCalcService();
  let source = getSheetSource(calcService, sheet);
  let parseContext = calcService.getParserContext(source);
  let parser = new GC.Spread.CalcEngine.Parser();
  let charArray = formula.split('');
  let tokens = parser.parseToTokens(parseContext, charArray, true);
  return tokens;
}
function getSheetSource(calcService, sheet) {
  let source = calcService.getAllSourceModels().find(m => m.getSource().getName() === sheet.name());
  return source;
}

export function findFnInFormula(ss, sheet, formula, fnName) {
  // eslint-disable-next-line no-useless-escape --- It's not useless we actually want to match the parentheses
  let extractedFormula = '';
  const tokens = parseToTokens(ss, sheet, formula);
  let formulaBuild = '';
  for (let i = 0; i < tokens.length; i++) {
    let t = tokens[i];
    const tokenValue = t.stringValue().toString();
    if (t.type() === 1 && t.stringValue().toUpperCase() === fnName.toUpperCase()) {
      formulaBuild = formulaBuild.concat(tokenValue) + '(';
      continue;
    }

    if (formulaBuild) {
      if (t.stringValue() === ')') {
        formulaBuild = formulaBuild.concat(tokenValue);
        extractedFormula = formulaBuild;
        formulaBuild = '';
        continue;
      }
      formulaBuild = formulaBuild.concat(tokenValue);
    }
  }

  const formulaRegex = new RegExp(`${fnName}\\(([^)]+)\\)`, 'g');
  const parameterRegex = /([^,]+)|(\s?$)/g;

  return extractedFormula.replace(formulaRegex, (_match, parameters) => {
    const quotedParameters = parameters.replace(parameterRegex, '"$1"');
    return `${fnName}(${quotedParameters})`;
  });
}

export function cellFormulaCount(formula) {
  let regex = new RegExp(`(${customFunctionNames.join('|')})`, 'g');
  let matches = formula.match(regex);
  return matches && matches.length > 1;
}

export function dataLinkAdditionalFormulaCheck(formula) {
  let datalinkRegex = /[+-]*\(?'(?:([\d]{4})\/)?\[([^\]]+)]([^']+)'!\$([A-Z]+\$\d+)\)?/;

  const datalinkMatch = formula.match(datalinkRegex);

  if (datalinkMatch) {
    const datalinkStart = formula.indexOf(datalinkMatch[0]);
    const beforeDatalink = formula.slice(0, datalinkStart).trim();
    const afterDatalink = formula.slice(datalinkStart + datalinkMatch[0].length).trim();

    const signsNotToInclude = ['+', '-', '*', '/', '=', ')', '^', '|', '&', ',', '%', '>', '<'];
    const matchAlphabets = /^[a-zA-Z]*$/;

    //also include the customFunctionNames and in-built functions
    if (beforeDatalink) {
      const lastChar = beforeDatalink.slice(-1);
      if (
        signsNotToInclude.includes(lastChar) ||
        beforeDatalink.includes(customFunctionNames) ||
        matchAlphabets.test(beforeDatalink)
      ) {
        return beforeDatalink.slice(0, -1).trim().length >= 0;
      }
    }

    if (afterDatalink) {
      if (
        signsNotToInclude.some(sign => afterDatalink.startsWith(sign)) ||
        afterDatalink.includes(customFunctionNames)
      ) {
        return afterDatalink.slice(1).trim().length >= 0;
      }
    }
  }

  return false;
}

export function replaceFormulaInExpression(spreadsheet, { row, col, formula }, newFormula) {
  const pattern = /SOURCE_DATA\("([^"]+)","([^"]+)","([^"]+)","([^"]+)"\)/g;
  const updatedFormula = formula.replace(pattern, newFormula);

  spreadsheet.getActiveSheet().setFormula(row, col, updatedFormula.toString());
  return updatedFormula;
}

export function restoreCell(spread, command) {
  const { row, col, sheetName } = command;
  const value = command[`changes${sheetName}`][0][1];
  const { oldFormula } = command[`changes${sheetName}`]?.formulas[row][col];
  const sheet = spread.getActiveSheet();

  sheet.setFormula(row, col, oldFormula ?? '');
  return sheet.setValue(row, col, value);
}

export function enqueueFormula(data, context, formulaType, args) {
  try {
    window.formulaRecalcInProgress = true;
    const { row, column, source } = context;
    const sheet = source.getSheet();
    const requestParameters = getRequestParameters(formulaType, context, args);
    const value = data.customFormulaReturnValue(requestParameters);
    requestParameters.workpaperId = data.workpaperId;
    requestParameters['id'] = uuidv4();
    requestParameters['processId'] = uuidv4();
    requestParameters.value = value;
    data.enqueueCustomFormula(requestParameters);
    return formatCustomFormulaReturnValue(value, data, sheet, row, column, formulaType);
  } catch (error) {
    CustomLogger &&
      CustomLogger.pushLog(CustomLogger.operations.FORMULA_RECALCULATION, {
        error: JSON.stringify(error),
        formulaData: JSON.stringify(data),
      });
    return GC.Spread.CalcEngine.Errors.NotAvailable;
  }
}

function getRequestParameters(formulaType, context, args) {
  const { row, column, source } = context;
  const sheetName = source.getSheet()?.name();
  const requestParameters = {
    row,
    column,
    type: formulaType,
    value: undefined,
    sheetName,
  };

  switch (formulaType) {
    case STATE_TAX_RATE:
      const [, jurisdiction, periodStartDate, taxableIncome] = args;
      requestParameters.jurisdiction = jurisdiction;
      requestParameters.periodStartDate = periodStartDate;
      requestParameters.taxableIncome = taxableIncome;
      requestParameters.parameters = {
        Jurisdiction: jurisdiction,
        PeriodStartDate: periodStartDate,
        TaxableIncome: taxableIncome,
      };
      break;

    case STATE_APPORTION:
      const [, state, date, factor] = args;
      requestParameters.state = state;
      requestParameters.date = date;
      requestParameters.factor = factor;
      requestParameters.parameters = { State: state, Date: date, Type: factor };
      break;

    case INT_TAX_RATE:
      const [, country, intTaxRateDate, income] = args;
      requestParameters.country = country;
      requestParameters.periodStartDate = intTaxRateDate;
      requestParameters.taxableIncome = income;
      requestParameters.parameters = {
        Country: country,
        PeriodStartDate: intTaxRateDate,
        TaxableIncome: income,
      };
      break;

    case SOURCE_DATA_CONNECTION:
      const [, output, outputField, ...rawFilters] = args;
      const filters = getFiltersFromDynamicArguments(rawFilters);
      requestParameters.output = output;
      requestParameters.filters = filters;
      requestParameters.outputField = outputField;
      requestParameters.parameters = {
        OutputId: output,
        Filters: filters ? JSON.stringify(filters) : undefined,
        OutputField: outputField,
      };
      break;

    default:
      throw new Error(`${formulaType} formulas are not handled yet!`);
  }

  return requestParameters;
}

export function formatCustomFormulaReturnValue(value, data, sheet, row, column, type) {
  const isSdcFormula = type === SOURCE_DATA_CONNECTION;
  if (value === null || value === undefined) {
    return data.defaultValue;
  } else if (value === '') {
    return GC.Spread.CalcEngine.Errors.NotAvailable;
  } else if (!isSdcFormula && isNumeric(value)) {
    const currentFormat = sheet.getFormatter(row, column);
    const isInteger = Number.isInteger(Number(value) * 100);
    const formattedNumber = isInteger ? Number(value) : parseFloat(Number(value).toFixed(4));
    const format = isInteger ? '0%' : '0.00%';
    if (currentFormat !== format) sheet.setFormatter(row, column, format);
    return formattedNumber;
  } else if (isSdcFormula && isNumeric(value)) {
    return Number(value);
  } else {
    return value;
  }
}

export function generateFormulaFromDatabase(dataReference) {
  const generateFormulaFromDbParameters = {
    [STATE_TAX_RATE]: generateStateTaxRateFormula,
    [SOURCE_DATA_CONNECTION]: generateSourceDataConnectionFormula,
    [DATA_LINK]: generateDataLinkFormula,
    [INT_TAX_RATE]: generateIntTaxRateFormula,
    [STATE_APPORTION]: generateStateApportionmentFormula,
  };

  const referenceType = dataReference.referenceType ?? dataReference.type;
  const parameters =
    typeof dataReference.parameters === 'string' ? JSON.parse(dataReference.parameters) : dataReference.parameters;
  return generateFormulaFromDbParameters[referenceType](parameters);
}

function generateStateTaxRateFormula(parameters) {
  const { Jurisdiction: jurisdiction, PeriodStartDate: periodStartDate, TaxableIncome: taxableIncome } = parameters;
  return `=${stateTaxRateFormulaName}("${jurisdiction}","${periodStartDate}"${taxableIncome ? `,${taxableIncome}` : ''})`;
}

function generateDataLinkFormula(parameters) {
  const { taxPeriod, workpaperName, sheetName, a1Coordinate } = parameters;
  const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';

  return `='${effectiveTaxPeriod}[${workpaperName}]${sheetName}'!${a1Coordinate}`;
}

function generateSourceDataConnectionFormula(parameters) {
  const { OutputId: outputId, Filters: filters, OutputField: outputField } = parameters;
  let params = `"${outputId}","${outputField}"`;

  const parsedFilters = typeof filters === 'string' ? JSON.parse(filters) : filters;

  if (parsedFilters.length) {
    const stringifiedFilters = parsedFilters
      .map(({ field, value }) => {
        const processedValue = typeof value === 'string' ? `"${value}"` : value;
        return `"${field}",${processedValue}`;
      })
      .join(',');
    params += `,${stringifiedFilters}`;
  }
  return `=${sourceDataFormulaName}(${params})`;
}

function generateIntTaxRateFormula(parameters) {
  const { Country: country, PeriodStartDate: periodStartDate, TaxableIncome: taxableIncome } = parameters;
  return `=${intTaxRateFormulaName}("${country}","${periodStartDate}"${taxableIncome ? `,${taxableIncome}` : ''})`;
}

function generateStateApportionmentFormula(parameters) {
  const { State: state, Date: date, Type: type } = parameters;
  return `=${stateApportionFormulaName}("${state}","${date}","${type}")`;
}
