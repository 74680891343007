import { DATA_LINK } from '../../_shared/DataReference/ReferenceType';
import { datalinkFormulaRegex, isNumeric } from './Spreadsheet/_spreadsheets/formulas';

export function formatLinkValue(value) {
  if (typeof value === 'string' && !isNaN(value) && value.trim() !== '') {
    return Number(value);
  }
  return value;
}

function toRowAndColumn(a1Notation) {
  const charCodeA = 65;
  const alphabetLength = 26;
  const cleanedA1 = a1Notation.toUpperCase().replaceAll('$', '');

  const match = cleanedA1.match(/^([A-Z]+)(\d+)$/);

  if (!match) {
    return;
  }

  const columnLabel = match[1];
  const rowLabel = match[2];

  let column = 0;
  for (let i = 0; i < columnLabel.length; i++) {
    column = column * alphabetLength + (columnLabel.charCodeAt(i) - charCodeA + 1);
  }
  column -= 1;

  const row = parseInt(rowLabel) - 1;

  return { row, column };
}

/**
 * Extracts values from a formula.
 *
 * @param {string} formula - The formula to extract values from.
 * @returns {Array} Extracted values.
 *
 * @warning DOES NOT WORK WITH '=' SIGN IN THE FORMULA!
 */
export function extractFormulaValues(formula) {
  try {
    const match = formula.match(datalinkFormulaRegex);

    if (!match) {
      return;
    }

    const { taxPeriod, workpaperName, sheetName, a1Coordinate } = match.groups;
    const { row, column } = toRowAndColumn(a1Coordinate);

    return {
      taxPeriod: taxPeriod || null,
      name: workpaperName,
      sheetName,
      a1Coordinate,
      row,
      column,
    };
  } catch {}
  return;
}

export function toA1Notation(row, column, isRelative = false) {
  const charCodeA = 65;
  const alphabetLength = 26;
  let columnLabel = '';

  for (let c = column; c >= 0; c = Math.floor(c / 26) - 1) {
    const transposition = c % alphabetLength;
    columnLabel = String.fromCharCode(charCodeA + transposition) + columnLabel;
  }

  const rowLabel = row + 1;
  return isRelative ? `${columnLabel}${rowLabel}` : `$${columnLabel}$${rowLabel}`;
}

export function isDataLinkFormula(formula) {
  return datalinkFormulaRegex.test(formula);
}

export function filterDataLinkReferences(createDataLinkReferences, formulas) {
  if (!formulas?.length) return createDataLinkReferences;

  return createDataLinkReferences
    .map(ref => {
      const matchingFormula = formulas.find(({ key }) => {
        const [sheetName, row, column] = key.split('-');
        return sheetName === ref.sheetName && Number(row) === ref.row && Number(column) === ref.column;
      });

      if (matchingFormula?.type === DATA_LINK) {
        ref.parameters = JSON.parse(matchingFormula.parameters);
      }

      return matchingFormula ? null : ref;
    })
    .filter(Boolean);
}

export function generateFormula(metadata, currentFormula, currentFormulaExtract, sheetName) {
  const effectiveTaxPeriod = metadata?.taxPeriod ? `${metadata?.taxPeriod}/` : '';
  const sign = currentFormula?.startsWith('-') ? '-' : '';
  const formula = `=${sign}'${effectiveTaxPeriod}[${metadata?.name}]${sheetName}'!${currentFormulaExtract.a1Coordinate}`;

  return { formula, isFormulaMatch: formula === `=${currentFormula}`, effectiveTaxPeriod };
}

export function groupWorkbooks(
  groupedWorkbooks,
  workpaperName,
  sheetName,
  row,
  column,
  reference,
  partialWorkbook,
  effectiveTaxPeriod
) {
  if (!groupedWorkbooks.has(workpaperName)) {
    groupedWorkbooks.set(workpaperName, { partialWorkbooks: {}, effectiveTaxPeriod });
  }

  const groupedWorkbooksBySheet = groupedWorkbooks.get(workpaperName).partialWorkbooks;
  if (!groupedWorkbooksBySheet[sheetName]) {
    groupedWorkbooksBySheet[sheetName] = {};
  }
  if (!groupedWorkbooksBySheet[sheetName][row]) {
    groupedWorkbooksBySheet[sheetName][row] = {};
  }
  const value = isNumeric(reference.value) ? Number(reference.value) : reference.value;
  groupedWorkbooksBySheet[sheetName][row][column] = value;
  if (partialWorkbook) {
    if (!groupedWorkbooksBySheet[sheetName].partialWorkbook) {
      groupedWorkbooksBySheet[sheetName].partialWorkbook = {};
    }

    groupedWorkbooksBySheet[sheetName].partialWorkbook[row] = partialWorkbook;
  }
}
