import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { getTokenInfo, logout } from '../../_shared/auth';
import { setDataToLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage } from '../../_shared/storage';
import { getOneTimeToken, redirectToLogin, refreshExpiredToken } from '../../_shared/jwtAuth';
import { getUserInfoV2, getAuthUrlParameters, scaleProvision } from '../../_shared/jwtAuth';
import { EnviromentUrl, numberOfTokenRefreshesToRedirectOnLogin } from '../../../configs/params';
import { displayApplicationBrowserConsoleLogs } from '../../_shared/consoleLogger';
import { isTokenExpired, ONE_SECOND_MS, setMasterTab } from '../_shared/authUtils';
import { refreshToken, retryFunction } from '../_shared/authUtils';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { AUTH_LOCK } from '../_shared/authUtils';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_CM_INTEGRATION } from '../../../constants/featureFlags';
import LDClient from '../../../components/_shared/launchDarklyClient';
import { refreshUserToken } from '../../_shared/Frame/ClientPickerMenu/apis';
import { UserLogoutSyncService } from '../../../services/UserLogoutSync.service';

let tabTokenRefreshCounter = 0;
const tabId = Date.now().toString();
setMasterTab(tabId);
CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `Tab Id: ${tabId})` });
window.addEventListener('beforeunload', function (event) {
  if (getDataFromLocalStorage('masterTabId') === tabId) {
    removeDataFromLocalStorage('masterTabId');
  }
  CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
    message: 'Removing masterTabId from local storage',
  });
});

export const artifactUrlPattern = ['/data-flows/:id/editor', '/editor/:id', '/editor/:id/history/:versionId'];

const matchUrl = (url, pattern) => {
  const match = matchPath(url, {
    path: pattern,
    exact: true,
    strict: false,
  });
  return match;
};

const useAuth = () => {
  displayApplicationBrowserConsoleLogs();
  const { pathname } = useLocation();
  const [user, setUser] = useState();
  const ldClient = LDClient.getInstance();
  const [isError, setIsError] = useState(false);
  const TOKEN_REFRESH_COUNT = 'tokenRefreshCount';
  const resetRefreshCounterEvents = ['click', 'keypress', 'onkeydown'];
  const clientId = getDataFromLocalStorage('client-id');

  const resetTokenRefreshCount = () => {
    setDataToLocalStorage(TOKEN_REFRESH_COUNT, 0);
    tabTokenRefreshCounter = 0;
  };

  async function checkAndRefreshLogic() {
    const DEFAULT_REFRESH_IN_SECONDS = ONE_SECOND_MS * 5;
    try {
      setMasterTab(tabId);
      setIsError(false);
      if (getDataFromLocalStorage('masterTabId') === tabId) {
        if (getDataFromLocalStorage(TOKEN_REFRESH_COUNT)) {
          const tokenRefreshCount = parseInt(getDataFromLocalStorage(TOKEN_REFRESH_COUNT));
          if (tokenRefreshCount >= parseInt(numberOfTokenRefreshesToRedirectOnLogin)) {
            CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
              message: 'Refreshing token reached maximum refresh count.',
            });
            logout();
            redirectToLogin();
            return;
          }
        }
        const tokenExpirationTime = getDataFromLocalStorage('tokenExpirationTime');
        if (!tokenExpirationTime || isTokenExpired()) {
          CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
            message: `Token with expiration time ${new Date(parseInt(tokenExpirationTime))} is expired!`,
          });
          await checkAndRefreshToken();
          scaleProvision();
          // refresh userContext after ECDS token refresh
          if (isFeatureFlagEnabled(WKP_CM_INTEGRATION) && clientId) {
            await refreshUserToken(clientId);
          }
        }

        const tokenInfo = getTokenInfo();
        if (!tokenInfo) {
          redirectToLogin();
          return;
        }

        const currentTime = new Date().getTime();
        const timeUntilRefresh =
          tokenExpirationTime > 0 ? tokenExpirationTime - currentTime : DEFAULT_REFRESH_IN_SECONDS;
        const refreshTokenTime = timeUntilRefresh > 0 ? timeUntilRefresh : DEFAULT_REFRESH_IN_SECONDS;
        setTimeout(checkAndRefreshLogic, refreshTokenTime);
      } else {
        setTimeout(checkAndRefreshLogic, DEFAULT_REFRESH_IN_SECONDS);
      }
    } catch (error) {
      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
        message: `Error refreshing token. Resetting token refresh logic: ${JSON.stringify({ error })}.`,
      });
      setTimeout(checkAndRefreshLogic, DEFAULT_REFRESH_IN_SECONDS);
    }
  }

  const setUserAndTabToken = function (data) {
    setUser(data);
    if (!isTokenExpired()) {
      tabTokenRefreshCounter = parseInt(getDataFromLocalStorage(TOKEN_REFRESH_COUNT));
      tabTokenRefreshCounter++;
      setDataToLocalStorage(TOKEN_REFRESH_COUNT, tabTokenRefreshCounter);
    }
  };

  async function checkAndRefreshToken() {
    navigator.locks.request(AUTH_LOCK, async lock => {
      if (getDataFromLocalStorage('masterTabId') === tabId) {
        CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
          message: `This is Master Tab and token refresh is starting with lock info ${JSON.stringify(lock)}.`,
        });
        await refreshToken(setUserAndTabToken, refreshExpiredToken);
      }
    });
  }
  function forwardReturnUrl(returnUrl) {
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `EnvironmentUrl:${EnviromentUrl} ` });
    let target;
    if (returnUrl.includes(EnviromentUrl.toString())) {
      const uriDecodedReturnUrl = decodeURIComponent(returnUrl);
      const parsedReturnUrl = new URL(uriDecodedReturnUrl);
      const isArtifactUrl = !!artifactUrlPattern.find(pattern => matchUrl(parsedReturnUrl.pathname, pattern));

      if (isFeatureFlagEnabled(WKP_CM_INTEGRATION) && !isArtifactUrl) {
        target = `${document.baseURI}client-selection?redirectUrl=${uriDecodedReturnUrl}`;
      } else {
        target = uriDecodedReturnUrl;
      }
      window.location.replace(target);

      return;
    } else {
      logout();
      redirectToLogin();
      return;
    }
  }
  useEffect(() => {
    (async function () {
      const { returnUrl, authToken } = getAuthUrlParameters();
      const decodedPathName = decodeURIComponent(pathname);
      const isLoginPage = decodedPathName.includes('auth/login');
      if (isLoginPage) {
        if (!returnUrl) {
          setIsError(true);
          redirectToLogin();
          return;
        }
        if (!authToken) {
          forwardReturnUrl(returnUrl);
        }
        try {
          const result = await retryFunction(getOneTimeToken, 3, authToken);
          if (result.notAuthorized) {
            redirectToLogin(returnUrl);
            return;
          } else if (result.data) {
            CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
              message: 'One time token data exists. Proccess complete',
            });
            if (!ldClient.isInitDone) {
              await ldClient.init();
            }
            const flags = ldClient.getFlags();
            if (flags?.length === 0) {
              await ldClient.setFlags();
            }
            forwardReturnUrl(returnUrl);
          }
          setIsError(true);
        } catch (error) {
          setIsError(true);
        }
      }
      setDataToLocalStorage('masterTabId', tabId);
      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `This is Master Tab now: ${tabId}` });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);
  useEffect(() => {
    (async function () {
      setDataToLocalStorage(TOKEN_REFRESH_COUNT, 0);
      const tokenInfo = getTokenInfo();
      if (tokenInfo) {
        CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: 'Token exist on page load' });
        setUser(tokenInfo);
        await checkAndRefreshLogic();
        resetRefreshCounterEvents.forEach(evt => window.addEventListener(evt, resetTokenRefreshCount));
        if (!isTokenExpired()) {
          await getUserInfoV2().catch(error => console.log('Error trying to get Product Codes from User Info'));
        }
      }
      const isLoginPage = pathname.includes('auth/login') || pathname.includes('/login');
      if (!isLoginPage && !tokenInfo) {
        CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: 'Token does not exist on home page' });
        await checkAndRefreshLogic();
        resetRefreshCounterEvents.forEach(evt => window.addEventListener(evt, resetTokenRefreshCount));
      }
    })();

    /**
     * Call checkAndRefreshLogic method for token check
     * when 'token-info' from localstorage removed (in case of logout) to redirect user to
     * login page on multiple open tabs.
     */
    const userLogoutSyncServiceInstance = new UserLogoutSyncService();
    userLogoutSyncServiceInstance.subscribe(checkAndRefreshLogic);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    user,
    isError,
  };
};
export { useAuth };
