import { BTButton, BTForm, BTInput, BTModal } from '@btas/jasper';
import React, { useContext, useEffect, useState } from 'react';
import SheetList from '../../../_shared/SheetList';
import isValidCellFormat from './utils';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { STEP_START } from '../InputElementInspector/useImportFile';
import { DATAFLOW_FILE_METADATA_JOB_TYPE } from '../../../_shared/jobs/jobTypes';
import { startJob } from '../../../_shared/jobs/apis';
import { getTempWorkingFileLocation, getWorkbookInformation } from './apis';
import { INTEGRATION_REGEX, INTEGRATIONS_TYPE, SFTP_REGEX } from '../InputElementInspector/constants';
import { FilePreview } from './FilePreview';
import './styles.scss';

const FileDataDialog = ({ elementData, dataFlowActions, dataFlowState, setGeneralError }) => {
  const { setWKPFileImportProperties, resetSaveMenuDirty, setSaveStateDirty } = dataFlowActions;
  const { workingElement, wkpFileImportProperties, taxPeriod: dataFlowTaxPeriod, id: dataFlowId } = dataFlowState;
  const [sheetData, setSheetData] = useState({ sheetName: '', dataStartAt: '', numHeaders: '' });
  const [processingDirectory, setProcessingDirectory] = useState(null);
  const [validationError, setValidationError] = useState({});
  const { fileImport } = useContext(DataFlowEditorContext);
  const { importFileState, setImportFileState, updateImportFileState } = fileImport;

  useEffect(() => {
    async function fetchData() {
      let sheetOptions = [];
      let sourceData;

      if (elementData?.uploadedFile) {
        const { name: fileName, location, bucket } = elementData?.uploadedFile;
        sourceData = {
          fileName,
          path: location,
          bucket,
        };

        try {
          // create job for polling
          let { jobId } = await startJob({
            entityId: workingElement.id,
            jobType: DATAFLOW_FILE_METADATA_JOB_TYPE,
            batchId: dataFlowId,
            payload: {},
          });

          const integrationType = SFTP_REGEX.test(bucket)
            ? INTEGRATIONS_TYPE.SFTP
            : INTEGRATION_REGEX.test(bucket)
              ? INTEGRATIONS_TYPE.FIXED_ASSETS
              : null;

          setWKPFileImportProperties({ ...wkpFileImportProperties, isLoadingDataDialog: true });
          const jobResult = await getTempWorkingFileLocation(jobId, sourceData, dataFlowId, integrationType);
          setProcessingDirectory(jobResult.processingPath);

          if (!wkpFileImportProperties?.isCsvFile) {
            const sheetsResult = await getWorkbookInformation({ fileName, path: jobResult.processingPath }, dataFlowId);

            if (sheetsResult?.error) {
              setGeneralError(sheetsResult.error);
              setWKPFileImportProperties({
                popupDataDialog: false,
                isLoadingDataDialog: false,
                isSavingDataDialog: false,
                sheets: [],
              });
              return;
            }
            sheetOptions = sheetsResult.sheetNames.map(sheet => {
              return { value: sheet.name, label: sheet.name };
            });
            const defaultSheet = sheetOptions.length > 0 ? sheetOptions[0].value : '';
            setSheetData({ sheetName: defaultSheet, dataStartAt: '', numHeaders: '' });
          }
          setWKPFileImportProperties({
            ...wkpFileImportProperties,
            isLoadingDataDialog: false,
            ...(!wkpFileImportProperties?.isCsvFile ? { sheets: sheetOptions } : null),
          });
        } catch (error) {
          setGeneralError(error.message);
          setWKPFileImportProperties({
            popupDataDialog: false,
            isLoadingDataDialog: false,
            isSavingDataDialog: false,
            sheets: [],
          });
        }
      }
    }
    if (wkpFileImportProperties?.popupDataDialog) {
      setGeneralError(null);
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wkpFileImportProperties?.popupDataDialog]);

  useEffect(() => {
    if (wkpFileImportProperties?.updateSourceFile) {
      const { sheetName, headerRows: numHeaders, headersStartAt: dataStartAt } = wkpFileImportProperties.savedValues;
      setSheetData({ sheetName, dataStartAt, numHeaders });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wkpFileImportProperties?.updateSourceFile]);

  useEffect(() => {
    if (importFileState[workingElement.id]?.error) {
      setGeneralError(importFileState[workingElement.id]?.error);

      const newImportFileState = importFileState;
      delete newImportFileState[workingElement.id];
      setImportFileState(newImportFileState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFileState[workingElement.id]?.error]);

  const onClose = () => {
    setValidationError({});
    setWKPFileImportProperties({
      popupDataDialog: false,
      isLoadingDataDialog: false,
      isSavingDataDialog: false,
    });
  };

  const onSheetComboChange = e => {
    const { value } = e;
    setSheetData(prev => ({ ...prev, sheetName: value }));
  };

  const onSheetValueChange = e => {
    const { name, value } = e.target;
    setSheetData(prev => ({ ...prev, [name]: value.toString().toUpperCase() }));
  };

  const isValidHeaderInput = str => {
    return onlyNumberVals(str);
  };

  const onlyNumberVals = str => {
    const pattern = /^[0-9]+$/;
    return str.match(pattern);
  };

  const onFileSubmit = async () => {
    setValidationError({});
    setGeneralError(null);

    if (!sheetData?.sheetName && wkpFileImportProperties?.sheets?.length > 1 && !wkpFileImportProperties.isCsvFile) {
      setValidationError({
        sheetName: 'Sheet is required',
      });
      return;
    }

    if (sheetData.dataStartAt && !isValidCellFormat(sheetData.dataStartAt)) {
      setValidationError({
        dataStartAt:
          'Invalid cell reference. Valid cell references start with one or more letters and are followed by one or more numbers.',
      });
      return;
    }

    if (sheetData.numHeaders && !isValidHeaderInput(sheetData.numHeaders)) {
      setValidationError({
        numHeaders: 'Number of column header rows is invalid.',
      });
      return;
    }

    updateImportFileState(workingElement.id, {
      sheetData: sheetData,
      currentStep: STEP_START,
      processingPath: processingDirectory,
      updateSourceFile: wkpFileImportProperties.updateSourceFile,
      sourceFileId: wkpFileImportProperties.sourceFileId,
      systemCode: 'wkp',
      taxPeriod: wkpFileImportProperties?.fileDialogTaxPeriod,
    });
    //Set the state dirty after upload
    resetSaveMenuDirty();
    setSaveStateDirty(true);

    onClose();
  };

  return (
    <BTModal
      show={wkpFileImportProperties?.popupDataDialog}
      size="modal-lg"
      title="Select Data Within File"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
          <div style={{ flexGrow: 1, flexShrink: 0 }}>
            <BTForm>
              <BTForm.FormGroup label="File name">
                <BTInput disabled name="fileName" value={wkpFileImportProperties?.fileName} />
              </BTForm.FormGroup>
              <BTForm.FormGroup label="Tax period">
                <BTInput
                  disabled
                  name="taxPeriod"
                  value={wkpFileImportProperties?.fileDialogTaxPeriod || dataFlowTaxPeriod}
                />
              </BTForm.FormGroup>
              {!wkpFileImportProperties?.isCsvFile && (
                <SheetList
                  hasError={!!validationError.sheetName}
                  isRequired={true}
                  loading={wkpFileImportProperties?.isLoadingDataDialog}
                  sheetData={sheetData}
                  sheets={wkpFileImportProperties?.sheets}
                  onChange={onSheetComboChange}
                />
              )}
              <BTForm.FormGroup
                errorText={validationError.dataStartAt}
                hasError={!!validationError.dataStartAt}
                htmlFor="dataStartAt"
                label="Column headers start at"
              >
                <BTInput
                  disabled={wkpFileImportProperties?.isLoadingDataDialog}
                  id="dataStartAt"
                  name="dataStartAt"
                  placeholder="A1"
                  value={sheetData.dataStartAt}
                  onChange={onSheetValueChange}
                />
              </BTForm.FormGroup>
              <BTForm.FormGroup
                errorText={validationError.numHeaders}
                hasError={!!validationError.numHeaders}
                htmlFor="numHeaders"
                label="Number of column header rows"
              >
                <BTInput
                  disabled={wkpFileImportProperties?.isLoadingDataDialog}
                  id="numHeaders"
                  name="numHeaders"
                  placeholder="1"
                  value={sheetData.numHeaders}
                  onChange={onSheetValueChange}
                />
              </BTForm.FormGroup>
            </BTForm>
          </div>
          <FilePreview
            file={wkpFileImportProperties?.uploadFile}
            id="sjs-root"
            isCsv={wkpFileImportProperties?.isCsvFile}
            isVisible={wkpFileImportProperties?.popupDataDialog}
            sheetName={sheetData.sheetName}
          />
        </div>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onClose}>Cancel</BTButton>
        <BTButton
          btStyle="primary"
          disabled={wkpFileImportProperties?.isLoadingDataDialog || wkpFileImportProperties?.isSavingDataDialog}
          hasSpinner={wkpFileImportProperties?.isSavingDataDialog}
          id="df-save-file-settings"
          onClick={onFileSubmit}
        >
          Save
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default FileDataDialog;
