import * as fetch from '../../../../_shared/fetch';
import { generalErrorMessage } from '../../../../_shared/messages';
import {
  spreadsheetServiceHostName,
  taxRatesServiceUrl,
  stateTaxRatesServiceUrl,
  dataFlowServiceHostName,
} from '../../../../../configs/params';
import { getDBFormattedDate } from '../../../../_shared/dateFormatting';
import { transformIntoString } from './stateTaxRateFormulaHelper';
import { httpRequestWithRetry } from './utils';

export async function getDataFlowOutputSingleData({ dataFlow, output, outputField, criteria }) {
  let response;

  const queryParameters = getQueryParameters(criteria, outputField);

  response = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlow}/outputs/${output}/data${queryParameters}`
  );

  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data.value;
}

function getQueryParameters(criteria, outputField) {
  const params = [];

  if (Object.values(JSON.parse(criteria)).length) {
    params.push(`criteria=${encodeURIComponent(JSON.stringify(criteria))}`);
  }
  if (outputField) {
    params.push(`output-field=${outputField}`);
  }

  return params.length ? `?${params.join('&')}` : '';
}

export async function getInternationalTaxRateData({ country, periodStartDate: date, taxableIncome }) {
  let queryParams = '';

  if (taxableIncome) {
    queryParams += `income=${taxableIncome}`;
  }

  if (date) {
    let startDate;
    if (date instanceof Date) {
      const year = date.toLocaleString('default', { year: 'numeric' });
      const mm = date.toLocaleString('default', { month: '2-digit' });
      const dd = date.toLocaleString('default', { day: '2-digit' });
      startDate = `${dd}/${mm}/${year}`;
    } else {
      startDate = date;
    }

    queryParams += `${taxableIncome ? '&' : ''}startDate=${startDate}`;
  }

  if (queryParams) {
    queryParams = `?${queryParams}`;
  }

  const url = `${taxRatesServiceUrl}/${country}${queryParams}`;
  const response = await fetch.get(url);
  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data;
}

export async function getApportionmentData({ state, date, type }) {
  const dateString = transformIntoString(date);
  const startDate = getDBFormattedDate(dateString);
  const url = `${spreadsheetServiceHostName}/tax/state-apportionment/${startDate}/${type}?state=${state}`;
  const response = await fetch.get(url);
  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data;
}

async function fetchStateTaxRate({ jurisdiction, periodStartDate, taxableIncome }) {
  const data = {
    jurisdiction,
    startDate: getDBFormattedDate(periodStartDate),
    taxableIncome: sanitizeTaxableIncome(taxableIncome),
  };
  return fetch.post(stateTaxRatesServiceUrl, { data });
}

export async function getStateTaxRateData(stateTaxrateParams) {
  try {
    const response = await fetchStateTaxRate(stateTaxrateParams);
    const data = await response.json();

    if (!response.ok) {
      throw data.error;
    }

    return data;
  } catch (error) {
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: generalErrorMessage }];
    throw mappedError;
  }
}

function sanitizeTaxableIncome(taxableIncome) {
  if (taxableIncome === null || taxableIncome === undefined || taxableIncome < 0) return null;

  if (typeof taxableIncome === 'string') {
    taxableIncome = taxableIncome.replace(/['"]+/g, '');
  }
  const parsedTaxableIncome = parseFloat(taxableIncome);
  return !isNaN(parsedTaxableIncome) ? parsedTaxableIncome : null;
}

export async function getKeyData({ workpaperId, sheetName = null }) {
  const response = await httpRequestWithRetry(() =>
    fetch.get(`${spreadsheetServiceHostName}/spreadsheet/keydata/${workpaperId}/${sheetName ?? ''}`)
  );

  const data = await response.json();

  if (response.status === 404) return [];

  return data;
}

export async function createKeyData({ workpaperId, keyData = { key: '', value: '', sheetName: '' } }) {
  const response = await httpRequestWithRetry(() =>
    fetch.post(`${spreadsheetServiceHostName}/spreadsheet/keydata/${workpaperId}`, {
      body: JSON.stringify(keyData),
    })
  );

  return response.ok;
}
