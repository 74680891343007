import { BTAlert } from '@btas/jasper';
import { useEffect, useState } from 'react';
import GC from '../../../../SpreadSheets';
import { markDataReferencesAsReviewed } from '../DataReference/apis';

const modalBtnsLayerStye = {
  display: 'flex',
  justifyContent: 'space-between',
};

// eslint-disable-next-line no-script-url
const href = 'javascript:void(0);';

function sortDataReferences(dataReferences, spread) {
  const sortedDatareferences = [];

  const dataReferencesPerSheet = spread.sheets
    .map(sheet => sheet.name())
    .map(sheetName => dataReferences.filter(dr => dr.sheetName === sheetName));
  for (const dataReferencesInSheet of dataReferencesPerSheet) {
    sortedDatareferences.push(
      dataReferencesInSheet.sort((dr1, dr2) => (dr1.row === dr2.row ? dr1.column - dr2.column : dr1.row - dr2.row))
    );
  }

  return sortedDatareferences.flat(1);
}

function findDatareference(dataReferences, { row, column, sheetName }) {
  for (let i = 0; i < dataReferences.length; i++) {
    const dr = dataReferences[i];
    if (dr.row === row && dr.column === column && dr.sheetName === sheetName) {
      return [i, dr];
    }
  }
  return [-1, null];
}

function IncomingLinksUpdateNavigationModal({ updatedDataReferences, spread, children }) {
  const [currentDatareferenceIndex, setCurrentDatareferenceIndex] = useState(0);
  const [dataReferences, setDataReferences] = useState(updatedDataReferences);
  const [currentDatareference, setCurrentDatareference] = useState(null);
  const [wizardCellSelected, setWizardCellSelected] = useState(true);

  const moveReference = step => () => {
    const shift = step < 0 ? dataReferences.length + step : step;
    const i = Math.abs((currentDatareferenceIndex + shift) % dataReferences.length);
    const dr = dataReferences[i];
    setWizardCellSelected(true);
    setCurrentDatareferenceIndex(i);
    setCurrentDatareference(dr);
  };

  useEffect(() => {
    const sortedDrs = sortDataReferences(updatedDataReferences, spread);

    setDataReferences(sortedDrs);
    setCurrentDatareference(sortedDrs[currentDatareferenceIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedDataReferences, spread]);

  useEffect(() => {
    function moveModalToReferenceSelected() {
      const sheet = spread.getActiveSheet();
      const row = sheet.getActiveRowIndex();
      const column = sheet.getActiveColumnIndex();
      const sheetName = sheet.name();

      const [dri, datareference] = findDatareference(dataReferences, { row, column, sheetName });

      if (datareference) {
        setCurrentDatareferenceIndex(dri);
        setCurrentDatareference(datareference);
      }
    }

    spread.bind(GC.Spread.Sheets.Events.CellClick, moveModalToReferenceSelected);
    spread.bind(GC.Spread.Sheets.Events.SelectionChanged, moveModalToReferenceSelected);

    return () => {
      spread.unbind(GC.Spread.Sheets.Events.CellClick, moveModalToReferenceSelected);
      spread.unbind(GC.Spread.Sheets.Events.SelectionChanged, moveModalToReferenceSelected);
    };
  }, [dataReferences, spread]);

  useEffect(() => {
    const setActiveCell = ({ row, column, sheetName }) => {
      if (!spread.getSheetFromName(sheetName)?.visible()) {
        return;
      }

      let sheet = spread.getActiveSheet();

      if (sheet.name() !== sheetName) {
        spread.setActiveSheet(sheetName);
        sheet = spread.getActiveSheet();
      }

      if (sheet.getActiveRowIndex() === row && sheet.getActiveColumnIndex() === column) {
        return;
      }

      const [rowOffset, columnOffset] = [10, 5];

      sheet.showCell(Math.max(row - rowOffset, 0), Math.max(column - columnOffset, 0));
      sheet.setActiveCell(row, column);
    };
    if (currentDatareference && wizardCellSelected) {
      setActiveCell(currentDatareference);
      setWizardCellSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDatareference]);

  let prevValue = currentDatareference?.valueBeforeRefresh;
  if (typeof prevValue !== 'number' && !prevValue) {
    prevValue = 'N/A';
  }

  return (
    <>
      <p className="info">
        Change {currentDatareferenceIndex + 1}/{dataReferences.length}: Previous value {prevValue}
      </p>
      <div style={modalBtnsLayerStye}>
        <div>
          {dataReferences.length > 1 ? (
            <>
              <a href={href} style={{ marginRight: '1rem' }} onClick={moveReference(-1)}>
                Previous change
              </a>
              <a href={href} onClick={moveReference(1)}>
                Next change
              </a>
            </>
          ) : null}
        </div>
        {children}
      </div>
    </>
  );
}

function IncomingLinksUpdateInitialModal({ dataReferences, onFirstChange, children }) {
  return (
    <>
      <p className="info">
        {dataReferences.length} cells with incoming links have changed one or more data sources were updated
      </p>
      <div style={modalBtnsLayerStye}>
        <a href={href} onClick={onFirstChange}>
          First change
        </a>
        {children}
      </div>
    </>
  );
}

export default function IncomingLinksUpdateModal({
  workpaperId,
  updatedDataReferences,
  inNavigationModeRef,
  spreadRef,
  onClose,
}) {
  const [isVisible, setIsVisible] = useState(true);
  const [inNavigationMode, setInNavigationMode] = useState(false);

  const changeCornerFoldColor = changesToReview => {
    inNavigationModeRef.current = changesToReview;
    spreadRef.current.resumePaint(); // This forces to change the caret color
  };

  const close =
    ({ dismiss }) =>
    async () => {
      setIsVisible(false);
      if (dismiss) {
        changeCornerFoldColor(false);
        await markDataReferencesAsReviewed(workpaperId, updatedDataReferences);
      }
      onClose();
    };

  const openNavigationMode = () => {
    setInNavigationMode(true);

    inNavigationModeRef.current = true;
    spreadRef.current.resumePaint();
  };

  useEffect(() => {
    changeCornerFoldColor(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CloseBtns = () => (
    <div>
      <a href={href} style={{ marginRight: '1rem' }} onClick={close({ dismiss: false })}>
        Snooze
      </a>
      <a href={href} onClick={close({ dismiss: true })}>
        Dismiss
      </a>
    </div>
  );

  return (
    <BTAlert appear fixed btStyle="info" visible={isVisible}>
      <div style={{ minWidth: '52rem' }}>
        {inNavigationMode ? (
          <IncomingLinksUpdateNavigationModal spread={spreadRef.current} updatedDataReferences={updatedDataReferences}>
            <CloseBtns />
          </IncomingLinksUpdateNavigationModal>
        ) : (
          <IncomingLinksUpdateInitialModal dataReferences={updatedDataReferences} onFirstChange={openNavigationMode}>
            <CloseBtns />
          </IncomingLinksUpdateInitialModal>
        )}
      </div>
    </BTAlert>
  );
}
