import GC from '../../../../../SpreadSheets';

import { isSourceDataFormulaMatch } from './formulas';

const visibleValue = 'ClickViewport';
const notVisibleValue = 'none';

export const setGoToSourceCommandsBehavior = (sheet, setCommandsVisibleContext) => {
  const activeCell = sheet.getCell(sheet.getActiveRowIndex(), sheet.getActiveColumnIndex());
  const formula = activeCell.formula();

  if (formula) {
    const sourceDataFormulaMatch = isSourceDataFormulaMatch(formula);
    if (sourceDataFormulaMatch) {
      setCommandsVisibleContext({
        'wkp.goToSourceDataFlow': visibleValue,
      });
    }
  } else {
    setCommandsVisibleContext({
      'wkp.goToSourceDataFlow': notVisibleValue,
    });
  }
};

export function attachCommandsBehavior({ spread, setCommandsVisibleContext }) {
  spread.bind('SelectionChanged', (_, { sheet }) => {
    setGoToSourceCommandsBehavior(sheet, setCommandsVisibleContext);
  });
}

/**
 * Overrides the context menu filter action in order to
 * set the filter dropdown buttons at the top of the
 * selected region
 */
export function overrideContextMenuFilterCommand() {
  const oldFilterCommand = GC.Spread.Sheets.Commands.contextmenuFilterForSheet.execute;

  GC.Spread.Sheets.Commands.contextmenuFilterForSheet.execute = function (context, options, _isUndo) {
    const sheet = context.getSheetFromName(options.sheetName);
    const selection = options.cmdOption.selection;
    const { rowCount: expandedRowCount } = sheet.expandSelection(sheet, selection);
    const { row, col, rowCount, colCount } = selection;
    const newRowCount = rowCount === 1 ? expandedRowCount : rowCount;
    options.cmdOption.selection = new GC.Spread.Sheets.Range(row + 1, col, newRowCount, colCount);

    oldFilterCommand.apply(this, arguments);
  };
}

/**
 * Overrides the ribbon filter action in order to
 * set the filter dropdown buttons at the top of the
 * selected region
 * @param {*} spread
 */
export function overrideRibbonFilterCommand(spread) {
  const filterCommand = spread.commandManager()['Designer.setRowFilter'];
  if (!filterCommand) return;

  const oldFilterCommand = filterCommand.execute;

  filterCommand.execute = function (context, options, _isUndo) {
    const sheet = context.getSheetFromName(options.sheetName);
    sheet.clearSelection();

    const selections = options.selections;
    for (const selection of selections) {
      const { rowCount: expandedRowCount } = sheet.expandSelection(sheet, selection);
      const { row, col, rowCount, colCount } = selection;
      const newRowCount = rowCount === 1 ? expandedRowCount : rowCount;
      sheet.addSelection(row + 1, col, newRowCount, colCount);
    }

    oldFilterCommand.apply(this, arguments);

    // restore selection
    sheet.clearSelection();

    for (const selection of selections) {
      const { row, col, rowCount, colCount } = selection;
      sheet.addSelection(row, col, rowCount, colCount);
    }
  };
}
