import { useEffect, useState } from 'react';
import { getDataFlow, cleanUpAllJobs } from './apis';
import { removePendingData } from './DataFlowEditorHeaderBar/apis';

export function useDataFlow(id, isResourceApiLoading) {
  const [dataFlow, setDataFlow] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setError(false);
      try {
        // clean up all jobs
        await cleanUpAllJobs();
        // verify pending data deletion before dataflow loads. This happens when dataflow is closed or location is changed without saving changes
        await removePendingData(id);

        const data = await getDataFlow(id);
        if (!data.error) {
          setDataFlow(data);
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };
    !isResourceApiLoading && fetch();
  }, [id, isResourceApiLoading]);

  return [dataFlow, { isLoading, isError }];
}
