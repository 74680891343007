import { CELL_REVIEW, DATA_LINK } from '../../../_shared/DataReference/ReferenceType';
import { CommandType } from '../../../_shared/commandType';
import { formatCellValue } from './dataReferenceHelper';
import { useCellTrackerPaste } from './useCellTrackerPaste';
import { useCellTrackerRow } from './useCellTrackerRow';
import { useCellTrackerDelete } from './useCellTrackerDelete';
import { useCellTrackerColumn } from './useCellTrackerColumn';
import { useCellTrackerUpdate } from './useCellTrackerUpdate';
import { getWorkpaperDataReferences, updateReferences } from './apis';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { DATA_LINKS } from '../../../../constants/featureFlags';

export default function useDataReferenceCellTracker(
  spreadRef,
  workpaperIdRef,
  dataReferences,
  cellPositionUpdateQueue,
  deleteQueue,
  lastCellReviewActionTimestamp,
  deleteCellReviewCommand = null,
  dataReferenceHistoryTracker,
  processCellPositionUpdateQueue,
  processDeleteQueue,
  enqueueCellReviews,
  processCellReviewQueue,
  sendMessage
) {
  const { addToHistory } = dataReferenceHistoryTracker?.current;

  const formulas = ['SOURCE_DATA', 'INTTAXRATE', 'STATETAXRATE', 'STATEAPPORTION'];

  /**
   * =====================
   *      Child Hooks
   * =====================
   */
  const { handleRowOperations } = useCellTrackerRow();

  const { handleColumnOperations } = useCellTrackerColumn();

  const { deleteDataReferences } = useCellTrackerDelete({
    dataReferences,
    spreadRef,
    workpaperIdRef,
    deleteQueue,
    processDeleteQueue,
    formulas,
  });

  const {
    updateSheetReferencesFromCells,
    updateReferencesFromCells,
    updateDataReferenceCellPositions,
    updateDataReferences,
  } = useCellTrackerUpdate({
    dataReferences,
    workpaperIdRef,
    cellPositionUpdateQueue,
    processCellPositionUpdateQueue,
    lastCellReviewActionTimestamp,
  });

  const { trackPastedReference, trackCellReviewPastedReferences } = useCellTrackerPaste({
    dataReferences,
    enqueueCellReviews,
    deleteQueue,
    processCellReviewQueue,
    processDeleteQueue,
    updateDataReferences,
    updateDataReferenceCellPositions,
    deleteCellReviewCommand,
    workpaperIdRef,
    addToHistory,
    spreadRef,
  });

  /**
   * =====================
   *      Hook output
   * =====================
   */
  return {
    trackPosition,
    trackPastedReference,
    cellValueChangedHandler,
    dataReferences,
    cellPositionUpdateQueue,
    handleDataLinkCellDependencyChange,
    deleteQueue,
  };

  /**
   * =====================
   *      trackPosition
   * =====================
   */
  function trackPosition(command, sheet) {
    if (dataReferences.current) {
      deleteDataReferences(command, deleteCellReviewCommand);

      const sheetReferences = dataReferences.current.filter(r => {
        return r.sheetName === command?.sheetName && r.workpaperId === workpaperIdRef.current;
      });

      if (sheetReferences && sheetReferences.length > 0) {
        let referencesToUpdate = sheetReferences;
        const isUndo = command.actionType === 1;
        const { selections, isRow } = command;

        if (
          selections &&
          selections.length > 0 &&
          command.cmd !== CommandType.DragDrop &&
          command.cmd !== CommandType.DragFill &&
          command.cmd !== CommandType.ClearCellValue
        ) {
          // Handles row/column insert/delete operations
          referencesToUpdate = handleRowAndColumnInsertDelete(
            command,
            referencesToUpdate,
            isRow,
            isUndo,
            dataReferences
          );
        } else if (command.cmd === CommandType.DragDrop) {
          // Handles drag-drop logic
          referencesToUpdate = handleDragDrop(command, sheet, sheetReferences);
        } else if (command.cmd === CommandType.DragFill) {
          // Handles drag-fill logic
          referencesToUpdate = handleDragFill(command, sheet, sheetReferences);
        } else if (command.cmd === CommandType.ClearCellValue) {
          // Handles clearing cell values
          referencesToUpdate = handleClearCellValue(command, sheetReferences);
        } else {
          // default path if none of the above
          referencesToUpdate = [];
        }

        if (Object.values(CommandType).indexOf(command.cmd) > -1 && command.cmd !== CommandType.CellPasting) {
          updateDataReferences(referencesToUpdate);
        }
      }
    }

    updateSheetReferencesFromCells(sheet, command.sheetNames);
  }

  /**
   * =====================
   *      Handlers
   * =====================
   */

  function handleRowAndColumnInsertDelete(command, referencesToUpdate, isRow, isUndo, dataReferences) {
    const { cmd, selections } = command;

    for (let i = 0; i < selections.length; i++) {
      let { row, col, rowCount, colCount } = selections[i];
      row = row < 0 ? 0 : row;
      col = col < 0 ? 0 : col;
      switch (cmd) {
        case CommandType.InsertEntireRow:
        case CommandType.InsertPartialRow:
        case CommandType.InsertCellsDown:
        case CommandType.DeleteEntireRow:
        case CommandType.DeleteCellsUp:
        case CommandType.InsertRowOrColumn && isRow:
        case CommandType.DeleteEntireRowColumn && isRow:
          referencesToUpdate = handleRowOperations(
            cmd,
            isUndo,
            rowCount,
            row,
            col,
            referencesToUpdate,
            updateDataReferenceCellPositions
          );
          break;
        case CommandType.InsertEntireColumn:
        case CommandType.InsertCellsRight:
        case CommandType.DeleteEntireColumn:
        case CommandType.DeleteCellsLeft:
        case CommandType.InsertRowOrColumn && !isRow:
        case CommandType.DeleteEntireRowColumn && !isRow:
          referencesToUpdate = handleColumnOperations(
            cmd,
            isUndo,
            colCount,
            row,
            col,
            referencesToUpdate,
            updateDataReferenceCellPositions
          );

          break;
        default:
          referencesToUpdate = [];
          break;
      }
    }

    return referencesToUpdate;
  }

  function handleDragDrop(command, sheet, sheetReferences) {
    const valueReferences = [];

    const isUndo = command.actionType === 1;
    const isUndoMultiplier = isUndo ? -1 : 1;

    const rowColumnReferences = [];
    const rowCount = command.toRow - command.fromRow;
    const columnCount = command.toColumn - command.fromColumn;

    let selectedRanges = sheet.getSelections();

    for (let i = 0; i < selectedRanges.length; i++) {
      const selectedRange = selectedRanges[i];

      for (let r = 0; r < selectedRange.rowCount; r++) {
        for (let c = 0; c < selectedRange.colCount; c++) {
          const selectedRangeRow = (isUndo ? command.toRow : command.fromRow) + r;
          const selectedRangeCol = (isUndo ? command.toColumn : command.fromColumn) + c;

          const references = sheetReferences.filter(reference => {
            return reference.column === selectedRangeCol && reference.row === selectedRangeRow;
          });

          trackCellReviewPastedReferences(
            valueReferences,
            command.toRow + r,
            command.toColumn + c,
            sheet.name(),
            sheet
          );

          if (references?.length) {
            if (!isUndo || !valueReferences.filter(x => references.filter(y => y.id === x.id)).length) {
              rowColumnReferences.push(...references);
            }
          }
        }
      }
    }

    if (valueReferences.length) {
      updateDataReferences(valueReferences);
    }

    const newRowCount = rowCount * isUndoMultiplier;
    const newColCount = columnCount * isUndoMultiplier;

    return updateDataReferenceCellPositions(rowColumnReferences, 'rowcolumn', null, null, null, null, {
      rowCount: newRowCount,
      columnCount: newColCount,
    });
  }

  function handleDragFill(command, sheet, sheetReferences) {
    let referencesToUpdate = [];

    const valueReferences = [];
    const selectedRanges = sheet.getSelections();

    const isUndo = command.actionType === 1;

    let { row, col, rowCount, colCount } = selectedRanges[0];

    for (let r = 0; r < (isUndo ? command.fillRange.rowCount : rowCount); r++) {
      for (let c = 0; c < (isUndo ? command.fillRange.colCount : colCount); c++) {
        const selectedRangeRow = (isUndo ? command.fillRange.row : row) + r;
        const selectedRangeCol = (isUndo ? command.fillRange.col : col) + c;

        const references = sheetReferences.filter(
          reference =>
            reference.column === selectedRangeCol &&
            reference.row === selectedRangeRow &&
            reference.type === CELL_REVIEW
        );

        if (references?.length) {
          references.forEach(reference => {
            const value = formatCellValue(sheet.getCell(reference.row, reference.column).text());

            if (reference.value !== value && !valueReferences.find(x => x.id === reference.id)) {
              valueReferences.push({ ...reference, value: value });
            }
          });
        }
      }
    }

    if (valueReferences.length) {
      referencesToUpdate = [...referencesToUpdate, ...valueReferences];
    }

    return referencesToUpdate;
  }

  function handleClearCellValue(command, sheetReferences) {
    let referencesToUpdate = [];
    const valueReferences = [];
    const referenceIdsToDelete = [];

    for (let i = 0; i < command.ranges.length; i++) {
      const selectedRange = command.ranges[i];

      let { row, col, rowCount, colCount } = selectedRange;

      row = row < 0 ? 0 : row;
      col = col < 0 ? 0 : col;

      for (let r = 0; r < rowCount; r++) {
        for (let c = 0; c < colCount; c++) {
          const selectedRangeRow = row + r;
          const selectedRangeCol = col + c;

          const idsToDelete = updateReferencesFromCells(sheetReferences, selectedRangeRow, selectedRangeCol);

          if (idsToDelete?.length) {
            referenceIdsToDelete.push(...idsToDelete);
          }
        }
      }
    }

    referencesToUpdate = [...referencesToUpdate, ...valueReferences];

    if (referenceIdsToDelete.length > 0) {
      deleteQueue.current = deleteQueue.current.concat(referenceIdsToDelete);

      processDeleteQueue(
        workpaperIdRef.current,
        undefined,
        deleteCellReviewCommand?.current?.length,
        spreadRef?.current
      );
    }

    return referencesToUpdate;
  }

  /**
   * =====================
   *   Helper Functions
   * =====================
   */

  async function handleDataLinkCellDependencyChange(commands) {
    const isDataLinksEnabled = isFeatureFlagEnabled(DATA_LINKS);
    if (isDataLinksEnabled) {
      const currentWorkpaperId = workpaperIdRef.current;

      const references = await getWorkpaperDataReferences({
        workpaperId: currentWorkpaperId,
        filters: new Map([
          ['includeDataLinksSources', true],
          ['referenceType', DATA_LINK],
        ]),
      });

      if (!references?.length) return;

      const uniqueSheetNames = new Set();
      for (const commandRequest of commands) {
        try {
          const command = JSON.parse(commandRequest.commandText);
          const sheetNames = command.sheetNames?.length
            ? command.sheetNames
            : [spreadRef.current?.getActiveSheet()?.name()];
          sheetNames.forEach(name => name && uniqueSheetNames.add(name));
        } catch (e) {
          console.warn('Invalid command JSON:', e);
        }
      }

      if (!uniqueSheetNames.size) return;

      const valueReferencesByWorkpaper = new Map();

      for (const reference of references) {
        if (reference.type !== DATA_LINK) continue;

        let parsedParams;
        try {
          parsedParams = JSON.parse(reference.parameters);
        } catch (error) {
          console.error('Invalid reference parameters:', error);
          continue;
        }

        const { workpaperId, row, column, sheetName } = parsedParams;

        if (workpaperId !== currentWorkpaperId || !uniqueSheetNames.has(sheetName)) {
          continue;
        }

        const sheet = spreadRef.current?.getSheetFromName(sheetName);
        if (!sheet) continue;

        const cellValue = sheet.getValue(row, column);
        if (!valueReferencesByWorkpaper.has(reference.workpaperId)) {
          valueReferencesByWorkpaper.set(reference.workpaperId, []);
        }
        valueReferencesByWorkpaper.get(reference.workpaperId).push({
          ...reference,
          value: cellValue ?? '',
        });
      }

      for (const [workpaperId, references] of valueReferencesByWorkpaper.entries()) {
        if (references.length) {
          updateReferences(workpaperId, references);
        }
      }
    }
  }

  function cellValueChangedHandler(eventInfo) {
    if (eventInfo) {
      const sheet = eventInfo.sheet;

      const formula = sheet.getFormula(eventInfo.row, eventInfo.col);
      let hasCustomFormula = false;

      const valueReferences = [];

      if (formula) {
        hasCustomFormula = formulas.some(frml => formula.includes(frml));
      }

      if (!eventInfo.newValue || !hasCustomFormula) {
        const sheetReferences = dataReferences?.current?.filter(r => {
          return r.workpaperId === workpaperIdRef.current && r.sheetName === sheet.name();
        });

        const referenceIdsToDelete = updateReferencesFromCells(sheetReferences, eventInfo.row, eventInfo.col);

        if (valueReferences.length) {
          updateDataReferences(valueReferences);
        }

        if (referenceIdsToDelete?.length) {
          const referenceIds = deleteQueue.current.concat(referenceIdsToDelete);

          dataReferences.current = dataReferences.current.filter(x => !referenceIds.some(y => y === x.id));

          deleteQueue.current = referenceIds;

          processDeleteQueue(
            workpaperIdRef.current,
            undefined,
            deleteCellReviewCommand?.current?.length,
            spreadRef?.current
          );
        }
      }

      if (eventInfo.newValue && eventInfo.newValue !== eventInfo.oldValue) {
        const affectedReferencesToUpdate = dataReferences.current.filter(
          x =>
            x.type === CELL_REVIEW &&
            x.row === eventInfo.row &&
            x.column === eventInfo.col &&
            x.sheetName === sheet.name()
        );

        if (affectedReferencesToUpdate?.length) {
          const updatedAffectedReferences = affectedReferencesToUpdate.map(reference => {
            return {
              ...reference,
              value: eventInfo.newValue,
              newValue: eventInfo.newValue,
            };
          });

          cellPositionUpdateQueue.current = [...cellPositionUpdateQueue.current, ...updatedAffectedReferences];

          processCellPositionUpdateQueue();
        }
      }
    }
  }
}
