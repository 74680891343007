import { BTButton } from '@btas/jasper';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Frame from '../_shared/Frame';
import './NoClientAccessPage/styles.scss';
import { useClient } from '../../contexts/ClientContext.js';

export default function GetClientsErrorPage() {
  const { setClientId } = useClient();
  const history = useHistory();

  return (
    <Frame showAppBar={false}>
      <div className="no-client-access-container">
        <div>
          <h1>Something went wrong</h1>
          <>
            <div className="clients-error-message-text">
              <span>
                The application has encountered a problem. If you continue to receive this error message, contact
                Customer
                <br /> Support by phone (<strong>1-800-424-2938</strong>) or email&nbsp;
                <b>
                  <a href="mailto:appsupport@bloombergtax.com" rel="noreferrer" target="_blank">
                    (appsupport@bloombergtax.com)
                  </a>
                </b>
                &nbsp;.
              </span>
            </div>

            <div className="clients-btn-container">
              <BTButton
                btStyle="primary"
                className="client-access-manager-btn"
                onClick={async () => {
                  await setClientId(null);
                  history.replace('/client-selection');
                }}
              >
                RETURN TO WORKPAPERS
              </BTButton>
            </div>
          </>
        </div>
      </div>
    </Frame>
  );
}
