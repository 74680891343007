import { applyTimestamp } from './sjs-cmd-process';
import GC from './SpreadSheets';

GC.Spread.Sheets.CalcEngine.CalcOperatorAdjustor.SaveFormulaChanges = true;
class CommandProcessor {
  static setValueOnServer(sheet, row, column, value) {
    const command = { cmd: 'editCell', sheetName: sheet.name(), row: row, col: column, newValue: value };
    applyTimestamp(command);
    return command;
  }

  static getSheetList(spreadSheet) {
    let sheetList = [];
    for (let i = 0; i < spreadSheet.sheets.length; i++) {
      sheetList.push(spreadSheet.sheets[i].name());
    }
    return sheetList;
  }

  static nullOrUndefined(value) {
    return value === undefined || value === null;
  }

  static registerRowColCountCmd(commandManager) {
    const self = this;
    commandManager.register('Designer.sheetSettings', {
      canUndo: false,
      execute: function (spread, cmdArgs) {
        //Suspend layout
        var sheet = spread.getActiveSheet();
        if (!sheet) {
          return;
        }
        sheet.suspendPaint();
        sheet.suspendCalcService();
        var optionResult = cmdArgs.options;
        try {
          sheet.setColumnCount(optionResult.colCount);
          sheet.setRowCount(optionResult.rowCount);
          sheet.frozenRowCount(optionResult.frozenRowCount);
          sheet.frozenColumnCount(optionResult.frozenColumnCount);
          sheet.frozenTrailingRowCount(optionResult.frozenTrailingRowCount, optionResult.frozenTrailingRowStickToEdge);
          sheet.frozenTrailingColumnCount(
            optionResult.frozenTrailingColumnCount,
            optionResult.frozenTrailingColumnStickToEdge
          );
          sheet.selectionPolicy(optionResult.selectionPolicy);
          sheet.options.isProtected = optionResult.isProtected;
          if (!sheet.options.isProtected) {
            sheet.options.protectionOptions = {};
          }
          sheet.options.allowCellOverflow = optionResult.allowCellOverflow;
          if (!self.nullOrUndefined(optionResult.gridlineColor)) {
            sheet.options.gridline = {
              showHorizontalGridline: optionResult.showHorizontalGridline,
              showVerticalGridline: optionResult.showVerticalGridline,
              color: optionResult.gridlineColor,
            };
          }
          sheet.setRowCount(optionResult.colHeaderRowCount, 1);
          sheet.options.colHeaderAutoText = optionResult.colHeaderAutoText;
          sheet.options.colHeaderAutoTextIndex = optionResult.colHeaderAutoTextIndex;
          sheet.defaults.colHeaderRowHeight = Math.max(optionResult.colHeaderDefRowHeight || 0, 0 /* minHeight */);
          sheet.options.colHeaderVisible = optionResult.colHeaderVisible;
          sheet.setColumnCount(optionResult.rowHeaderColCount, 2 /* rowHeader */);
          sheet.options.rowHeaderAutoText = optionResult.rowHeaderAutoText;
          sheet.options.rowHeaderAutoTextIndex = optionResult.rowHeaderAutoTextIndex;
          sheet.defaults.rowHeaderColWidth = Math.max(optionResult.rowHeaderDefColWidth || 0, 0 /* minWidth */);
          sheet.options.rowHeaderVisible = optionResult.rowHeaderVisible;
          if (!self.nullOrUndefined(optionResult.sheetTabColor)) {
            sheet.options.sheetTabColor = optionResult.sheetTabColor;
          }
        } finally {
          sheet.resumeCalcService(false);
          sheet.resumePaint();
        }
      },
    });
  }

  static getSheetSetting(spread, sheetName) {
    var option = {};
    var sheet = spread.getSheetFromName(sheetName);
    if (!sheet) {
      return option;
    }
    option.colCount = sheet.getColumnCount();
    option.rowCount = sheet.getRowCount();
    option.frozenColumnCount = sheet.frozenColumnCount();
    option.frozenRowCount = sheet.frozenRowCount();
    option.frozenTrailingColumnCount = sheet.frozenTrailingColumnCount();
    option.frozenTrailingRowCount = sheet.frozenTrailingRowCount();
    option.frozenTrailingColumnStickToEdge = sheet.getFrozenTrailingState();
    option.frozenTrailingRowStickToEdge = sheet.getFrozenTrailingState(true /* isRow */);
    option.selectionPolicy = sheet.selectionPolicy();
    option.isProtected = sheet.options.isProtected;
    option.allowCellOverflow = sheet.options.allowCellOverflow;
    option.showHorizontalGridline = sheet.options.gridline.showHorizontalGridline || false;
    option.showVerticalGridline = sheet.options.gridline.showVerticalGridline || false;
    option.gridlineColor = sheet.options.gridline.color || '';
    option.colHeaderRowCount = sheet.getRowCount(GC.Spread.Sheets.SheetArea.colHeader);
    option.colHeaderAutoText = sheet.options.colHeaderAutoText;
    option.colHeaderAutoTextIndex = sheet.options.colHeaderAutoTextIndex;
    option.colHeaderDefRowHeight =
      sheet.defaults.colHeaderRowHeight !== undefined ? sheet.defaults.colHeaderRowHeight : 20;
    option.colHeaderVisible = sheet.options.colHeaderVisible;
    option.rowHeaderColCount = sheet.getColumnCount(GC.Spread.Sheets.SheetArea.rowHeader);
    option.rowHeaderAutoText = sheet.options.rowHeaderAutoText;
    option.rowHeaderAutoTextIndex = sheet.options.rowHeaderAutoTextIndex;
    option.rowHeaderDefColWidth =
      sheet.defaults.rowHeaderColWidth !== undefined ? sheet.defaults.rowHeaderColWidth : 40;
    option.rowHeaderVisible = sheet.options.rowHeaderVisible;
    option.sheetTabColor = sheet.options.sheetTabColor;
    option.showZeros = true;
    return option;
  }

  static async setCountCommand(ss, rowCount, colCount, callback) {
    const commandManager = ss.commandManager();
    this.registerRowColCountCmd(commandManager);
    const activeSheet = ss.getActiveSheet();
    const options = this.getSheetSetting(ss, activeSheet.name());
    const sheetName = activeSheet.name();

    const currentRowCount = activeSheet.getRowCount();
    const currentColCount = activeSheet.getColumnCount();

    options.rowCount = currentRowCount + rowCount;
    options.colCount = currentColCount + colCount;
    options.isProtected = false;

    let command = {
      sheetName,
      cmd: 'Designer.sheetSettings',
      options: options,
    };
    commandManager.execute(command);
    callback && (await callback());

    return { rowCount: options.rowCount, colCount: options.colCount };
  }
}

export default CommandProcessor;
