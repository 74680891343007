import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getKeyData, createKeyData } from './apis';
import { getDataFromLocalStorage } from '../../../../_shared/storage';

export function useKeyData({ workpaperId }) {
  const queryKey = ['keydata', workpaperId];
  const queryClient = useQueryClient();

  const keyDataQuery = useQuery({
    queryKey,
    queryFn: () => {
      const { creatingWorkpaper } = JSON.parse(getDataFromLocalStorage(workpaperId) || '{}');

      if (creatingWorkpaper) return [];
      return getKeyData({ workpaperId });
    },
  });

  async function getAllKeyValues() {
    const data = await queryClient.fetchQuery(queryKey, () => getKeyData({ workpaperId }));

    return data;
  }

  const { mutateAsync: createKeyValue } = useMutation({
    mutationFn: data => createKeyData(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  return { getAllKeyValues, keyDataQuery, createKeyValue };
}
