import React, { useEffect, useState } from 'react';
import { getLock, setLock, deleteLock } from '../../editor/EditorPage/apis';
import {
  workpaperNameAndTaxPeriodExist,
  getDataFlowTaxPeriods,
  getMetadata,
  getWorkbookMetadata,
  rollforwardWorkpaper,
} from './RollforwardModal/apis';
import { useClient } from '../../../contexts/ClientContext';
import TaxPeriodSelection from './RollforwardModal/TaxPeriodSelection';
import Summary from './RollforwardModal/Summary';
import { startJob } from '../../_shared/jobs/apis';
import { WORKPAPER_ROLLFORWARD_JOB_TYPE } from '../../_shared/jobs/jobTypes';
import { getUser } from '../../../components/_shared/auth';
import { getWorkflowStatus } from '../../editor/EditorPage/Spreadsheet/SideBar/WorkflowPanel/Status/api';
import { workpaper_status } from '../../editor/EditorPage/Spreadsheet/shared/constants';

const TAX_PERIOD_SELECTION_STEP = 1;
const SUMMARY_STEP = 2;
const workpaperLockError = 'workpaperLock';
const workpaperLockErrorMessage = userFullName =>
  `Selected workpaper is locked for editing by ${userFullName}. Rollforward cannot be completed until workpaper lock is released.`;
const taxPeriodError = 'taxPeriod';
const taxPeriodErrorMessage = 'Please enter the rollforward period before continuing.';
const combinationAlreadyExistMessage = 'Note: a workpaper with this name and period already exists';

const RollforwardModal = ({ cleanGridSelection, loadJobs, workpaperId, workpaperName, onClose }) => {
  const [lockedByModal, setLockedByModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [info, setInfo] = useState(undefined);
  const [taxPeriod, setTaxPeriod] = useState();
  const [taxPeriodOptions, setTaxPeriodOptions] = useState([]);
  const [selectedTaxPeriod, setSelectedTaxPeriod] = useState(null);
  const [step, setStep] = useState(TAX_PERIOD_SELECTION_STEP);
  let clientId;
  const client = useClient();
  if (client) {
    clientId = client?.clientId;
  }
  useEffect(() => {
    const setCurrentTaxPeriod = async () => {
      const metadata = await getWorkbookMetadata(workpaperId);
      setTaxPeriod(metadata.taxPeriod ? metadata.taxPeriod : 'unassigned');
    };

    const loadTaxPeriodOptions = async () => {
      const dataFlowTaxPeriods = await getDataFlowTaxPeriods();
      const taxPeriods = new Set(dataFlowTaxPeriods);

      const workbookMetadata = await getMetadata();

      for (const workbookTaxPeriod of workbookMetadata.taxPeriods) {
        taxPeriods.add(workbookTaxPeriod);
      }

      const taxPeriodOptions = [];
      for (const taxPeriod of taxPeriods) {
        taxPeriodOptions.push({ value: taxPeriod, label: taxPeriod });
      }

      setTaxPeriodOptions(taxPeriodOptions);
    };
    setCurrentTaxPeriod();
    loadTaxPeriodOptions();
  }, [workpaperId]);

  useEffect(() => {
    if (!lockedByModal) {
      const tryToLock = async () => {
        const lockInfo = await getLock(workpaperId);
        const workpaperStatus = await getWorkflowStatus(workpaperId);
        if (lockInfo === null || workpaperStatus.status === workpaper_status.Final) {
          await setLock(workpaperId);
          setLockedByModal(true);
        } else {
          const errorMessage = workpaperLockErrorMessage(lockInfo.userFullName);
          const newErrors = { ...errors, [workpaperLockError]: { message: errorMessage } };

          setErrors(newErrors);
        }
      };
      tryToLock();
    }

    return async () => {
      if (lockedByModal) {
        await deleteLock(workpaperId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedByModal]);

  useEffect(() => {
    const validateIfCombinationExist = async () => {
      if (!(selectedTaxPeriod && selectedTaxPeriod.value)) return;

      const { combinationExist } = await workpaperNameAndTaxPeriodExist(workpaperName, selectedTaxPeriod?.value);
      if (combinationExist) {
        setInfo({ message: combinationAlreadyExistMessage });
      } else if (info) {
        setInfo(undefined);
      }
    };
    validateIfCombinationExist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaxPeriod?.value]);

  const nextClickHandler = () => {
    if (!selectedTaxPeriod) {
      const newErrors = { ...errors, [taxPeriodError]: taxPeriodErrorMessage };
      setErrors(newErrors);
      return;
    }

    if (!errors[workpaperLockError]) {
      setStep(SUMMARY_STEP);
    }
  };

  const finishClickHandler = async () => {
    const { userId, firstName, companyId } = getUser();
    const userInfo = {
      companyId,
      userId,
      firstName,
      clientId,
    };
    const { jobId } = await startJob({
      entityId: workpaperId,
      jobType: WORKPAPER_ROLLFORWARD_JOB_TYPE,
      payload: {
        fileName: workpaperName,
      },
    });

    await loadJobs();
    await rollforwardWorkpaper(workpaperId, selectedTaxPeriod.value, jobId, taxPeriod, userInfo);
    await loadJobs();
    onClose();
    await cleanGridSelection();
  };

  const previousClickHandler = () => {
    setStep(TAX_PERIOD_SELECTION_STEP);
  };

  const taxPeriodHandler = taxPeriodOption => {
    const newErrors = { ...errors };
    delete newErrors[taxPeriodError];
    setErrors(newErrors);
    setSelectedTaxPeriod(taxPeriodOption);
  };

  return step === TAX_PERIOD_SELECTION_STEP ? (
    <TaxPeriodSelection
      generalError={errors[workpaperLockError]}
      infoMessage={info}
      newTaxPeriod={selectedTaxPeriod}
      taxPeriod={taxPeriod}
      taxPeriodError={errors[taxPeriodError]}
      taxPeriodOptions={taxPeriodOptions}
      onClose={onClose}
      onNextClick={nextClickHandler}
      onTaxPeriodChange={taxPeriodHandler}
    />
  ) : (
    <Summary
      currentTaxPeriod={taxPeriod}
      newTaxPeriod={selectedTaxPeriod?.value}
      workpaperId={workpaperId}
      onClose={onClose}
      onFinishClick={finishClickHandler}
      onPreviousClick={previousClickHandler}
    />
  );
};

export default RollforwardModal;
