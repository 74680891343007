import * as fetch from '../../../../_shared/fetch';
import { dataFlowServiceHostName, dataManagerHostName } from '../../../../../configs/params';

export async function copyDataFlow(
  configuration,
  name,
  taxPeriod,
  includeSourceFiles,
  copyFromDataFlowId,
  targetClientId
) {
  let response;
  if (targetClientId) {
    response = await fetch.post(`${dataManagerHostName}/resources/copy`, {
      body: JSON.stringify({
        configuration,
        name,
        taxPeriod,
        copyFromDataFlowId,
        targetClientId,
      }),
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    const result = await response.json();
    // Check if the response contains a dataflow object or an error
    return result.dataFlow ? result.dataFlow : result;
  }
  response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows/`, {
    body: JSON.stringify({ configuration, name, taxPeriod, includeSourceFiles, copyFromDataFlowId }),
  });

  return response.json();
}
