import React, { useEffect, useRef } from 'react';

import { EnviromentName, ecdsLoginPageUrl } from '../../configs/params';
import wkpLogo from '../_shared/Frame/FakeBanner/wkp_logo.svg';
import './LoginPage/normalize.scss';
import './LoginPage/index.scss';
import './LoginPage/crescent.scss';
import './LoginPage/_button.scss';
import './LoginPage/styles.scss';

export default function LoginPage() {
  const footerRef = useRef(null);

  useEffect(() => {
    const textNode = document.createTextNode(
      `© ${new Date().getFullYear()} Bloomberg Industry Group, Inc. All Rights Reserved.`
    );
    if (footerRef.current) {
      footerRef.current.replaceChildren(textNode);
    }
  }, []);

  const handleSubmit = e => {
    window.location.replace(ecdsLoginPageUrl);
  };

  return (
    <div className="moonfish" data-color-scheme="light" data-theme="crescent">
      <section className="bbthat-wrapper">
        <div className="bbthat" id="bbthat"></div>
      </section>
      <div className="main">
        <section className="tax-section">
          <div className="tax-logo">
            <div className="logo">
              <img alt="Workpapers logo" height="50" src={wkpLogo} width="320" />
            </div>
            <h1 className="tax-header">
              There's a better
              <br />
              way to work(paper)
            </h1>
            <div className="tax-text">
              <p>
                “Using Bloomberg Tax Workpapers, I know where the data is coming from and I know that it's correct. That
                Gives me assurance that the output is correct and I can explain it.”
              </p>
              <p className="p-text">Associate tax Director at Hyland Software</p>
              <div className="tax-text-links">
                <a
                  className="m-button primary tax-m-button"
                  href="https://pro.bloombergtax.com/products/workpapers/#request-demo?trackingcode=WKMS25114306"
                  id="request-demo-btn"
                >
                  Request Demo
                </a>
                <a
                  className="m-button secondary"
                  href="https://pro.bloombergtax.com/products/workpapers/#overview"
                  id="learn-more-btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="tax-footer">
            <div ref={footerRef} className="c-footer" id="c-footer"></div>
          </div>
        </section>
        <section className="auth-section">
          <div className="tax-promotion">
            <div className="promotion-call">
              <div className="promotion-text">
                <p>
                  Transform your workflows with 21st century tax tools. Discover the new Bloomberg Tax suite.
                  <a className="promotion-link" href="https://pro.bloombergtax.com/products/workpapers/#overview">
                    &nbsp;Learn More
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="tax-auth">
            <div className="auth">
              <div className="auth-component">
                {EnviromentName === 'LOCAL' ? (
                  <button className="m-button" onClick={handleSubmit}>
                    Sign in
                  </button>
                ) : (
                  <indg-login
                    vertical
                    header-text="Sign in to Bloomberg Tax"
                    hide-academic-registration-link="true"
                    sso-priority="false"
                    theme="crescent"
                  ></indg-login>
                )}
              </div>
            </div>
          </div>
          <div className="auth-footer">
            <div className="tax-footer-nav">
              <nav>
                <a href="https://www.bloombergindustry.com/terms-and-conditions/">Terms of Service</a>
                <a href="https://www.bloombergindustry.com/privacy-policy">Privacy Policy</a>
                <a href="https://www.bloombergindustry.com/copyright-and-usage-guidelines-copyright/">Copyright</a>
                <a href="https://www.bloombergindustry.com/about-us/">About Us</a>
                <a href="https://www.bloombergindustry.com/contact-us/">Contact Us</a>
              </nav>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
