import * as api from '../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../configs/params';
import { getActiveBatchId, setActiveBatchId } from './jobHelpers';
import { httpRequestWithRetry } from '../../editor/EditorPage/Spreadsheet/_spreadsheets/utils';

export async function startJob({ entityId, jobType, batchId, payload }) {
  batchId = batchId || getActiveBatchId() || setActiveBatchId();

  const jobResult = await httpRequestWithRetry(() =>
    api.post(`${spreadsheetServiceHostName}/spreadsheet/jobs/start`, {
      body: JSON.stringify({
        entityId,
        jobType,
        batchId,
        payload: JSON.stringify(payload),
      }),
    })
  );

  return jobResult.json();
}

export async function getJobsStatus({ jobIds, entityIds, batchIds, jobTypes, jobStatus }) {
  let arrQuery = '';
  arrQuery += parseArrayParams('jobIds', jobIds) || '';
  arrQuery += parseArrayParams('entityIds', entityIds) || '';
  arrQuery += parseArrayParams('batchIds', batchIds) || '';
  arrQuery += parseArrayParams('jobTypes', jobTypes) || '';
  arrQuery += parseArrayParams('jobStatus', jobStatus) || '';
  if (arrQuery === '') {
    return;
  }
  const url = `${spreadsheetServiceHostName}/spreadsheet/jobs/status${arrQuery !== '' ? `?${arrQuery}` : ''}`;
  const jobResult = await httpRequestWithRetry(() => api.get(url));

  return jobResult.json();
}

function parseArrayParams(key, values) {
  if (!values || values.length < 1) return;

  return `&${key}=${values.join(`&${key}=`)}`;
}

export async function cleanUpJobs() {
  const result = await httpRequestWithRetry(() =>
    api.remove(`${spreadsheetServiceHostName}/spreadsheet/jobs/clean-up`)
  );
  return result.json();
}

export async function updateJob(jobId, jobStatus, entityId) {
  const body = JSON.stringify({
    jobId,
    status: jobStatus,
    payload: null,
    entityId,
  });
  const response = await httpRequestWithRetry(() =>
    api.put(`${spreadsheetServiceHostName}/spreadsheet/jobs/status`, {
      body: body,
    })
  );

  if (!response.ok) {
    throw Error('Error updating userjob');
  }

  return response.json();
}
