import { CommandType } from '../../../_shared/commandType';
import { CELL_REVIEW, DATA_LINK } from '../../../_shared/DataReference/ReferenceType';

/**
 * This hook focuses on "undo"-related functions:
 *  - isDeleteCommand
 *  - getSelection
 *  - deleteDataReferences
 */
export function useCellTrackerDelete({
  dataReferences,
  spreadRef,
  workpaperIdRef,
  deleteQueue,
  processDeleteQueue,
  formulas,
}) {
  function isDeleteCommand(command) {
    const { cmd, actionType } = command;

    if (actionType === 0) {
      return [
        CommandType.DragDrop,
        CommandType.DeleteEntireRow,
        CommandType.DeleteCellsUp,
        CommandType.DeleteEntireColumn,
        CommandType.DeleteCellsLeft,
      ].includes(cmd);
    } else if (actionType === 1) {
      return [CommandType.CellPasting, CommandType.DragFill].includes(cmd);
    }
  }

  function getSelection(command) {
    const { cmd, selections } = command;

    if (cmd === CommandType.DragFill) {
      return [command.fillRange];
    }

    return selections;
  }

  function deleteDataReferences(command, deleteCellReviewCommand) {
    if (!isDeleteCommand(command)) return;

    const { sheetName, actionType } = command;
    const isUndo = actionType === 1;
    const sheetReferences = dataReferences.current.filter(r => r.sheetName === sheetName && r.type !== DATA_LINK);
    let referenceIds = [];

    if (sheetReferences?.length) {
      const selections = getSelection(command);

      if (selections && selections.length > 0) {
        for (let i = 0; i < selections.length; i++) {
          let { row, col, rowCount, colCount } = selections[i];
          row = row < 0 ? 0 : row;
          col = col < 0 ? 0 : col;
          for (let r = 0; r < rowCount; r++) {
            for (let c = 0; c < colCount; c++) {
              const selectedRangeRow = row + r;
              const selectedRangeCol = col + c;

              if (isUndo) {
                if (spreadRef?.current) {
                  const formula = spreadRef.current
                    .getSheetFromName(sheetName)
                    .getFormula(selectedRangeRow, selectedRangeCol);

                  if (formula && formulas.some(frml => formula.includes(frml))) {
                    continue;
                  }
                }
              }

              const referencesToDelete = sheetReferences.filter(
                ({ row, column }) => row === selectedRangeRow && column === selectedRangeCol
              );

              if (referencesToDelete?.length) {
                const cellReviewRowReferences = referencesToDelete.filter(x => x.type === CELL_REVIEW);

                if (cellReviewRowReferences?.length) {
                  deleteCellReviewCommand.current.push(...cellReviewRowReferences);
                }

                referenceIds.push(...referencesToDelete.map(x => x.id));
              }
            }
          }
        }

        if (referenceIds.length) {
          deleteQueue.current = [...deleteQueue.current, ...referenceIds];
          dataReferences.current = dataReferences.current.filter(x => !referenceIds.some(y => y === x.id));

          processDeleteQueue(
            workpaperIdRef.current,
            undefined,
            deleteCellReviewCommand?.current?.length,
            spreadRef?.current
          );
        }
      }
    }
  }

  return {
    isDeleteCommand,
    getSelection,
    deleteDataReferences,
  };
}
