import { useEffect } from 'react';
import { useClient } from '../contexts/ClientContext';
import CustomLogger from '../components/_shared/Logger/CustomLogger';
import { getTokenInfo } from '../components/_shared/auth';

export function useCheckClientAvailability(clients, onClientNotFound) {
  const { clientId } = useClient();

  useEffect(() => {
    async function checkClientAvailability() {
      if (clientId && clients.length > 0) {
        const isClientAvailable = clients.some(client => client.id === clientId);
        if (!isClientAvailable) {
          const { userId, companyId } = getTokenInfo();
          CustomLogger.pushLog(CustomLogger.operations.CLIENTS_MANAGEMENT.CLIENT_NOT_AVAILABLE, {
            message: `Client ${clientId} is not available for user ${userId}`,
            company_id: companyId,
          });
          await onClientNotFound?.();
        }
      }
    }
    checkClientAvailability();
  }, [clients, clientId, onClientNotFound]);
}
