import * as api from '../../../../_shared/fetch';
import { coreAPIHostName, nodeAPIServiceHostName, spreadsheetServiceHostName } from '../../../../../configs/params';
import { ResourceType, IntegrationType } from './constants';

export async function sendToTaxProvision(workpapaerId, sheetName) {
  const response = await api.get(
    `${spreadsheetServiceHostName}/spreadsheet/tax-provision/${workpapaerId}/${sheetName}`
  );
  if (!response.ok) {
    throw new Error('Error while sending to tax provision');
  }
  return await response.json();
}

export async function getPresignedUrl(objectKey, location, upload) {
  const outputInfo = [
    {
      outputId: objectKey,
      location,
      upload,
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      resourceType: ResourceType.Spreadsheet,
      integrationType: IntegrationType.TaxProvision,
    },
  ];
  const response = await api.get(
    `${nodeAPIServiceHostName}/node/presignedurl?outputInfo=${encodeURIComponent(JSON.stringify(outputInfo))}`
  );
  if (!response.ok) {
    throw response.data;
  }

  return response.json();
}

export async function uploadWorkpaper(sourceFile, url) {
  const uploadResult = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    body: sourceFile,
  });

  if (!uploadResult.ok) {
    throw new Error('Failed to upload source file. Please check your internet connection and try again.');
  }

  return true;
}

export async function copyFiles(payload) {
  const response = await api.post(`${nodeAPIServiceHostName}/node/files/copy`, { body: JSON.stringify(payload) });
  if (!response.ok) {
    throw response.data;
  }
  return response.json();
}

export async function exportToTPClassic(payload) {
  const response = await api.post(`${coreAPIHostName}/export-xlsx-to-tax-provision`, { body: JSON.stringify(payload) });
  if (!response.ok) {
    throw response.data;
  }
}
