import * as api from '../../../../_shared/fetch';
import { workpapersSpreadsheetModelHostName } from '../../../../../configs/params';
import { DATA_LINK } from '../../../../_shared/DataReference/ReferenceType';

export async function fetchDataLinks(workpaperIds) {
  const response = await api.post(`${workpapersSpreadsheetModelHostName}/datareferences/data-links`, {
    body: JSON.stringify({ workpaperIds }),
  });
  return await response.json();
}

export async function syncDependentDatalinks(sourceWorkpaperId) {
  const url = `${workpapersSpreadsheetModelHostName}/datareferences/data-links/sync/${sourceWorkpaperId}`;
  const response = await api.post(url, { body: JSON.stringify({ referenceType: DATA_LINK }) });

  if (response.status !== 201) {
    throw new Error();
  }
}
