import { useState, useEffect, useRef } from 'react';
import { ClientSyncService } from '../services/ClientSync.service';
import useBroadcast from '../components/editor/EditorPage/useBroadcast';
import { BroadcastChannels, EventTypes } from '../components/editor/EditorPage/useBroadcast/constants';
import { refreshUserToken } from '../components/_shared/Frame/ClientPickerMenu/apis';
import { createUserToken } from '../components/home/ClientSelection/apis';

export function useClientSync() {
  const syncServiceRef = useRef();
  const [clientId, setClientIdState] = useState(() => {
    if (!syncServiceRef.current) {
      syncServiceRef.current = new ClientSyncService();
    }
    return syncServiceRef.current.getStoredClient();
  });

  const { createChannel, sendMessage, subscribeToChannel } = useBroadcast();

  useEffect(() => {
    createChannel(BroadcastChannels.ClientSync);

    const syncService = syncServiceRef.current;
    const unsubscribeStorage = syncService.subscribe(setClientIdState);

    const unsubscribeBroadcast = subscribeToChannel(BroadcastChannels.ClientSync, EventTypes.ClientChanged, event => {
      setClientIdState(event.data.clientId);
    });

    return () => {
      unsubscribeStorage();
      if (unsubscribeBroadcast) {
        unsubscribeBroadcast();
      }
    };
  }, [createChannel, subscribeToChannel]);

  const setClientId = async (newClientId, options = {}) => {
    const { updateToken = true, isInitialSelection = false } = options;

    try {
      if (newClientId === null) {
        syncServiceRef.current.removeClient();
      } else {
        syncServiceRef.current.setClient(newClientId);

        if (updateToken) {
          if (isInitialSelection) {
            await createUserToken(newClientId);
          } else {
            await refreshUserToken(newClientId);
          }
        }
      }

      setClientIdState(newClientId);

      sendMessage(BroadcastChannels.ClientSync, {
        type: EventTypes.ClientChanged,
        clientId: newClientId,
      });
    } catch (error) {
      console.error('Error setting clientId', error);
      throw error;
    }
  };

  return { clientId, setClientId };
}
