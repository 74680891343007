/* eslint-disable no-loop-func */
import { CELL_REVIEW } from '../../../_shared/DataReference/ReferenceType';
import { formatCellValue } from './dataReferenceHelper';
import { v4 as uuidv4 } from 'uuid';

/**
 * This hook handles paste-like operations, including:
 *  - trackPastedReference
 *  - trackCellReviewPastedReferences
 */
export function useCellTrackerPaste({
  dataReferences,
  enqueueCellReviews,
  deleteQueue,
  processCellReviewQueue,
  processDeleteQueue,
  updateDataReferences,
  updateDataReferenceCellPositions,
  deleteCellReviewCommand,
  workpaperIdRef,
  addToHistory,
  spreadRef,
}) {
  function trackPastedReference(eventInfo) {
    if (eventInfo) {
      const cutPastedReferences = [];
      const cutPastedCellReviewReferences = [];
      const referencesToDelete = [];
      const valueReferences = [];
      let targetSheetParent;

      if (eventInfo?.fromRange && eventInfo?.cellRange) {
        const fromRange = eventInfo.fromRange;
        const pastedRange = eventInfo.cellRange;
        let pastedRangeRowCount = pastedRange.rowCount;
        let pastedRangeColCount = pastedRange.colCount;
        let pastedRangeRow = pastedRange.row;
        let pastedRangeCol = pastedRange.col;
        const fromSheet = eventInfo.fromSheet.name();
        const targetSheet = eventInfo.sheet.name();
        targetSheetParent = eventInfo.sheet;

        for (let r = 0; r < pastedRangeRowCount; r++) {
          for (let c = 0; c < pastedRangeColCount; c++) {
            const selectedFromRangeRow = fromRange.row + r;
            const selectedFromRangeCol = fromRange.col + c;
            const selectedToRangeRow = pastedRange.row + r;
            const selectedToRangeCol = pastedRange.col + c;

            const selectedReferences = dataReferences.current.filter(
              reference =>
                reference.column === selectedFromRangeCol &&
                reference.row === selectedFromRangeRow &&
                reference.sheetName === fromSheet
            );

            if (eventInfo?.isCutting) {
              if (selectedReferences?.length) {
                if (fromSheet === targetSheet) {
                  cutPastedReferences.push(...selectedReferences);
                } else {
                  const cellReviewsReferences = selectedReferences.filter(x => x.type === CELL_REVIEW);
                  if (cellReviewsReferences?.length) {
                    const newCellReviewsReferences = cellReviewsReferences.map(reference => {
                      return {
                        ...reference,
                        sheetName: targetSheet,
                        id: uuidv4(),
                        row: selectedToRangeRow,
                        column: selectedToRangeCol,
                      };
                    });

                    cutPastedCellReviewReferences.push(...newCellReviewsReferences);
                  }

                  referencesToDelete.push(...selectedReferences.map(x => x.id));
                }
              }
            }

            trackCellReviewPastedReferences(
              valueReferences,
              selectedToRangeRow,
              selectedToRangeCol,
              fromSheet,
              targetSheetParent
            );
          }
        }

        if (valueReferences.length) {
          updateDataReferences(valueReferences);
        }

        if (cutPastedReferences.length > 0) {
          const rowCount = pastedRangeRow - fromRange.row;
          const columnCount = pastedRangeCol - fromRange.col;
          const referencesToUpdate = updateDataReferenceCellPositions(
            cutPastedReferences,
            'rowcolumn',
            null,
            null,
            null,
            null,
            {
              rowCount,
              columnCount,
            }
          );
          updateDataReferences(referencesToUpdate);
        }
        if (cutPastedCellReviewReferences.length) {
          enqueueCellReviews(cutPastedCellReviewReferences);
          processCellReviewQueue(targetSheetParent.parent, targetSheetParent, addToHistory, false);
        }
        if (referencesToDelete.length > 0) {
          const referenceIds = deleteQueue.current.concat(referencesToDelete);
          dataReferences.current = dataReferences.current.filter(x => !referenceIds.some(y => y === x.id));
          deleteQueue.current = referenceIds;
          processDeleteQueue(
            workpaperIdRef.current,
            undefined,
            deleteCellReviewCommand?.current?.length,
            spreadRef?.current
          );
        }
      }
    }
  }

  function trackCellReviewPastedReferences(valueReferences, row, col, fromSheet, sheet) {
    const cellReviewsReferences = dataReferences.current.filter(
      reference =>
        reference.column === col &&
        reference.row === row &&
        reference.sheetName === fromSheet &&
        reference.type === CELL_REVIEW
    );
    if (cellReviewsReferences?.length) {
      cellReviewsReferences.forEach(reference => {
        const value = formatCellValue(sheet.getCell(reference.row, reference.column).text());
        if (reference.value !== value && !valueReferences.find(x => x.id === reference.id)) {
          valueReferences.push({ ...reference, value: value });
        }
      });
    }
  }

  return {
    trackCellReviewPastedReferences,
    trackPastedReference,
  };
}
