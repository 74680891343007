import { SOURCE_DATA_CONNECTION } from '../../../../_shared/DataReference/ReferenceType';
import { getDataFlowByOutputId } from '../SideBar/ConnectionPanel/apis';
import { getDataFlowOutputs } from './apis';

const cache = {};
const CACHE_TIMEOUT = 30000; // 30 seconds

function getCacheKey(args) {
  return JSON.stringify(args);
}

export class SourceDataTooltipHelper {
  constructor(fnArguments) {
    this.args = fnArguments;
    this.type = SOURCE_DATA_CONNECTION;
  }

  async fetchData() {
    const cacheKey = getCacheKey(this.args);
    const cachedEntry = cache[cacheKey];

    // Check for valid cached data
    if (cachedEntry && Date.now() - cachedEntry.timestamp < CACHE_TIMEOUT) {
      const { data } = cachedEntry;
      return { ...data };
    }

    let error;
    const outputId = this.args[0];
    const dataFlowInfo = await getDataFlowByOutputId(outputId);

    if (dataFlowInfo?.connectionError) {
      if (dataFlowInfo?.nullOutputId) {
        return { errorMessage: 'No Data Connect workflow output block matches the ID provided.' };
      } else if (!dataFlowInfo?.published) {
        return {
          errorMessage: `Publish the Data Connect workflow '${dataFlowInfo.dataFlowName}' for tax period '${dataFlowInfo.taxPeriod}' to see the data connection properties.`,
        };
      }
    }

    const {
      deleted,
      dataFlow: { name, outputs, taxPeriod },
    } = await getDataFlowOutputs({ dataFlowId: dataFlowInfo.dataFlowId });

    if (deleted || !name || !outputs) {
      return { errorMessage: 'The Data Connect for the source data connection is no longer available.' };
    }

    const outputField = this.args[1];
    const parsedFilters = this.args.slice(2).reduce((acc, curr, index, arr) => {
      if (index % 2 === 0 && arr[index + 1] !== undefined) {
        acc.push({ field: curr, value: arr[index + 1] });
      }
      return acc;
    }, []);

    const result = {
      data: {
        sdcInfo: {
          outputs,
          outputId,
          outputField,
          name,
          taxPeriod,
          dataFlowInfo,
          parsedFilters,
        },
      },
      error,
    };

    cache[cacheKey] = {
      data: result,
      timestamp: Date.now(),
    };

    return result;
  }
}
