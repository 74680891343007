import GC from '../../../../../SpreadSheets';
import { formulaHelpers } from '.';
import { formatCellValue } from '../../DataReference/dataReferenceHelper';
import { sourceDataFormulaName } from './formulas/sourceDataFormula';
import { gcExpressionTypes } from './spreadHelpers';

const keyRegexJson = /"([^"]+)"\s*:/g;
const valueRegexJson = /:\s*"([^"]+)"?/g;

/**
 * Obtains GC expression from formula passed
 * @param {*} sheet Sheet that contains {@link formula}
 * @param {*} formula Formula
 * @returns GC expression
 */
export const getGCExpression = (sheet, formula) =>
  GC.Spread.Sheets.CalcEngine.formulaToExpression(sheet, formula, 0, 0);

/**
 * Gets formula arguments or undefined if it can't find an expression with arguments
 * @param {*} sheet Sheet that contains {@link formula}
 * @param {*} formula Formula that might contains an expression with arguments
 * @returns Formula arguments or undefined if it can't find an expression with arguments
 */
export function getFormulaArgumentValues(sheet, formula) {
  const generalExpression = getGCExpression(sheet, formula);
  const sourceDataExpression = getSourceDataExpression(generalExpression);
  const expression = sourceDataExpression ? sourceDataExpression : generalExpression;

  return expression.arguments ? formulaHelpers.getArgumentValuesFromExpression(expression, sheet) : undefined;
}

/**
 * Searches for a source data expression contained in {@link expression} or undefined if it doesn't exist
 * @param {*} expression Expression that might contain a source data expression
 * @returns Source data expression contained in {@link expression} or undefined if it doesn't exist
 */
function getSourceDataExpression(expression) {
  let sourceDataExpression;
  if (expression) {
    const { type } = expression;

    switch (type) {
      case gcExpressionTypes.function:
        const name = expression.functionName;
        if (name === sourceDataFormulaName) {
          sourceDataExpression = expression;
        } else {
          const functionArguments = expression.arguments;
          for (let i = 0; i < functionArguments.length; i++) {
            const recursiveCallResult = getSourceDataExpression(functionArguments[i]);
            if (isSourceDataFunction(recursiveCallResult)) {
              sourceDataExpression = recursiveCallResult;
              break;
            }
          }
        }
        break;

      case gcExpressionTypes.operator:
        const expressionValues = Object.values(expression);
        for (let i = 0; i < expressionValues.length; i++) {
          const recursiveCallResult = getSourceDataExpression(expressionValues[i]);
          if (isSourceDataFunction(recursiveCallResult)) {
            sourceDataExpression = recursiveCallResult;
            break;
          }
        }
        break;

      case gcExpressionTypes.parentheses:
        sourceDataExpression = getSourceDataExpression(expression.value);
        break;

      default:
        break;
    }
  }

  return sourceDataExpression;
}

/**
 * Obtains the source data argument values if a source data function exist
 * @param {*} sheet Sheet that contains the {@link formula}
 * @param {*} formula Formula that might contains a source data expression
 * @returns Source data argument values if a source data function exist
 */
export function getSourceDataArgumentValues(sheet, formula) {
  let argumentValues;

  if (formula) {
    const generalExpression = getGCExpression(sheet, formula);
    const sourceDataExpression = getSourceDataExpression(generalExpression);

    if (sourceDataExpression && sourceDataExpression.arguments) {
      argumentValues = formulaHelpers.getArgumentValuesFromExpression(sourceDataExpression, sheet);
    } else if (isSourceDataFunction(generalExpression)) {
      argumentValues = generalExpression.arguments;
    }
  }

  return argumentValues;
}

/**
 * Obtains the source data arguments if a source data function exist
 * @param {*} sheet Sheet that contains the {@link formula}
 * @param {*} formula Formula that might contains a source data expression
 * @returns Source data arguments if a source data function exist
 */
export function getSourceDataArguments(sheet, formula) {
  let argumentValues;

  if (formula) {
    const generalExpression = getGCExpression(sheet, formula);
    const sourceDataExpression = getSourceDataExpression(generalExpression);

    if (sourceDataExpression && sourceDataExpression.arguments) {
      argumentValues = formulaHelpers.getArgumentsFromExpression(sourceDataExpression, sheet);
    } else if (isSourceDataFunction(generalExpression)) {
      argumentValues = generalExpression.arguments;
    }
  }

  return argumentValues;
}

export const isReferenceType = type => gcExpressionTypes.reference === type;

function isSourceDataFunction(expression) {
  if (
    typeof expression === 'object' &&
    expression.hasOwnProperty('type') &&
    expression.hasOwnProperty('functionName')
  ) {
    return expression.type === gcExpressionTypes.function && expression.functionName === sourceDataFormulaName;
  }
  return false;
}

export function sourceDataFiltersToFormulaValue(filters) {
  return `${JSON.stringify(filters).replace(keyRegexJson, "'$1':").replace(valueRegexJson, ": '$1'")}`;
}

export const dateToString = date =>
  date.toLocaleDateString('default', { year: 'numeric', month: '2-digit', day: '2-digit' });

export function getFiltersFromDynamicArguments(argumentList) {
  const filters = [];

  for (let i = 0; i < argumentList.length; i += 2) {
    const key = argumentList[i];
    const value = argumentList[i + 1];

    if (key && value !== undefined) {
      const stringValue = formatCellValue(value, true);
      filters.push({ field: key, value: stringValue ? stringValue : '' });
    }
  }

  return filters;
}

export function getParametersDescription() {
  const params = [];

  const outputParameter = {
    name: 'output',
    repeatable: false,
    optional: false,
  };

  const outputFieldParameter = {
    name: 'output_field',
    repeatable: false,
    optional: false,
  };

  const filtersParameter = {
    name: 'filter_field1, filter_value1',
    repeatable: true,
    optional: true,
  };

  params.push(outputParameter);
  params.push(outputFieldParameter);
  params.push(filtersParameter);

  return params;
}
