import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getWorkbookMetadata } from '../../editor/EditorPage/apis';
import { getComboboxData } from '../../editor/EditorPage/Spreadsheet/SideBar/SettingsPanel/apis';
import { SAVE_AS, RENAME } from './actions';
import { getNextValidWorkpaperName, saveAsWorkpaper, validateMetadata, saveAsTags } from './api';
import { useMetadata } from '../../editor/EditorPage/Spreadsheet/SideBar/SettingsPanel/useMetadata';
import { startJob } from '../jobs/apis';
import { WORKPAPER_COPY_JOB_TYPE } from '../jobs/jobTypes';
import { generateBatchId } from '../jobs/jobHelpers';
import EditorContext from '../../editor/EditorPage/EditorContext';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_CM_INTEGRATION } from '../../../constants/featureFlags';
import { useClient } from '../../../contexts/ClientContext';
import { isGlobalTemplateAccount } from '../auth';

export default function useWorkpaperActionModal({ workpaperId, handleFileMenuAction, fileMenuAction }) {
  const [isLoadingNextName, setIsLoadingNextName] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [taxPeriod, setTaxPeriod] = useState();
  const [name, setName] = useState();
  const [newId, setNewId] = useState();
  const [taxPeriodComboData, setTaxPeriodComboData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const { putMetadata } = useMetadata({ workpaperId });
  const { setWorkbookName, isGlobalTemplate, dataReferences } = useContext(EditorContext);
  const [showClientChangeModal, setShowClientChangeModal] = useState(false);
  const clientContext = useClient();
  const { clientId, setClientId } = clientContext || {};
  const [selectedClient, setSelectedClient] = useState(null);
  const [showCopySuccessMessage, setShowCopySuccessMessage] = useState(false);

  const isDifferentClient = selectedClient && selectedClient?.value !== clientId;
  const isCopyingToDifferentClient = isFeatureFlagEnabled(WKP_CM_INTEGRATION) && isDifferentClient;

  const getTitle = () => {
    switch (fileMenuAction) {
      case SAVE_AS:
        return 'Save as';
      case RENAME:
        return 'Rename Workpaper';
      default:
        break;
    }
  };

  const closeActionModal = () => {
    handleFileMenuAction(undefined);
  };

  const validateNameTaxPeriod = async () => {
    return await validateMetadata(workpaperId, name, taxPeriod, selectedClient?.value);
  };

  const handleClientChangeModalClose = () => {
    setShowClientChangeModal(false);
  };

  const handleClientChangeConfirmationProceed = async () => {
    setShowCopySuccessMessage(false);

    await setClientId(selectedClient?.value, { isInitialSelection: false });

    newId && setTimeout(() => history.push(`/editor/${newId}`));
    return;
  };

  const startSaveAsJob = async ({ workpaperId, name }) => {
    const saveAsBatchId = generateBatchId();
    const { jobId } = await startJob({
      entityId: workpaperId,
      batchId: saveAsBatchId,
      jobType: WORKPAPER_COPY_JOB_TYPE,
      payload: {
        fileName: name,
      },
    });
    return jobId;
  };

  const saveAs = async () => {
    const jobId = await startSaveAsJob({ workpaperId, name });

    const newId = await saveAsWorkpaper(workpaperId, jobId, name, taxPeriod, selectedClient?.value);
    isGlobalTemplateAccount() && (await saveAsTags(workpaperId, newId, 'Spreadsheet'));
    dataReferences.current = [];

    setNewId(newId);

    if (isCopyingToDifferentClient) {
      closeActionModal();
      setShowCopySuccessMessage(true);
    }
  };

  const rename = async () => {
    const response = await putMetadata({ id: workpaperId, name, taxPeriod });
    if (response.id) {
      setNewId(response.id);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    const isValid = await validateNameTaxPeriod();

    if (isValid) {
      try {
        if (fileMenuAction === SAVE_AS) {
          await saveAs();
        }
        if (fileMenuAction === RENAME) {
          await rename();
        }

        if (!isCopyingToDifferentClient) {
          setWorkbookName(name);
          closeActionModal();
        }
      } catch (error) {
        setErrorMessage('There was an error trying to save the data.');
      }
    } else {
      setErrorMessage('The combination of Name and Tax Period already exists.');
    }
    setIsSaving(false);

    if (!errorMessage && fileMenuAction === SAVE_AS && !isCopyingToDifferentClient && isGlobalTemplate && window)
      window.location.reload();
  };

  const handleName = name => {
    setErrorMessage(undefined);
    setName(name);
  };

  const handleClient = client => {
    setErrorMessage(undefined);
    setSelectedClient(client);
  };

  const handleTaxPeriod = taxPeriod => {
    setErrorMessage(undefined);
    setTaxPeriod(taxPeriod);
  };

  useEffect(() => {
    if (newId && !isCopyingToDifferentClient) {
      history.push(`/editor/${newId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newId]);

  useEffect(() => {
    const getNextValidName = async () => {
      setIsLoadingNextName(true);
      const nextName = await getNextValidWorkpaperName(workpaperId);
      const {
        metadata: { taxPeriod: wkpTaxPeriod, name: currentName },
      } = await getWorkbookMetadata({ id: workpaperId });
      const { taxPeriods } = await getComboboxData();
      setTaxPeriodComboData(taxPeriods.map(e => ({ value: e, label: e })));
      setTaxPeriod(wkpTaxPeriod);
      setIsLoadingNextName(false);
      switch (fileMenuAction) {
        case SAVE_AS:
          setName(nextName);
          break;
        case RENAME:
          setName(currentName);
          break;
        default:
          break;
      }
    };

    const cleanModalFields = () => {
      setSelectedClient(null);
      setErrorMessage(undefined);
      setShowCopySuccessMessage(false);
    };

    if (!!fileMenuAction) {
      cleanModalFields();
      getNextValidName();
    }
  }, [fileMenuAction, workpaperId]);

  return {
    isSaving,
    taxPeriod,
    name,
    newId,
    selectedClient,
    isDifferentClient,
    isLoadingNextName,
    history,
    title: getTitle(),
    showCopySuccessMessage,
    taxPeriodComboData,
    errorMessage,
    closeActionModal,
    handleName,
    handleTaxPeriod,
    handleSave,
    handleClient,
    handleClientChangeModalClose,
    handleClientChangeConfirmationProceed,
    setShowCopySuccessMessage,
    setShowClientChangeModal,
    isCopyingToDifferentClient,
    showClientChangeModal,
    currentClientId: clientId,
  };
}
