import React, { useState } from 'react';
import { BTButton, BTForm, BTIcon, BTTextarea } from '@btas/jasper';
import iconImage from '../elementType/icons/ai_sparkles.svg';
import './styles.scss';
import { generateAIExpression } from './api';

const ExpressionGeneratorModal = ({
  blockType,
  fields,
  prevPromptRef,
  handleFormulaChange,
  isAIGenerated,
  setShowDisclaimer,
  setShowExpressionGeneratorModal,
  setReadOnlyDisclaimer,
}) => {
  const [prompt, setPrompt] = useState(prevPromptRef.current);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const examples = blockType === 'formula' ? formulaExamples : filterExamples;
  const describeText = blockType === 'filter' ? 'filter criteria' : 'formula';
  const labelText = blockType === 'filter' ? 'filter' : 'formula';

  const handleReadOnly = () => {
    setReadOnlyDisclaimer(true);
    setShowDisclaimer(true);
  };
  const onCancelHandler = () => {
    setShowExpressionGeneratorModal(false);
  };
  const onPromptChangeHandler = e => {
    setPrompt(e.target.value);
    prevPromptRef.current = e.target.value;
  };
  const onGenerateHandler = async () => {
    setIsLoading(true);
    setError('');

    try {
      const req = await generateAIExpression(fields, blockType, prompt.trim());

      handleFormulaChange(req);
      isAIGenerated(true);
      setShowExpressionGeneratorModal(false);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="wkp-ai-gradient-container">
      <div className="wkp-ai-gradient"></div>
      <div className="wkp-expression-generator-heading-container">
        <img alt="AI Lab icon" src={iconImage} />
        <label>{`Bloomberg Tax Expression Generator`}</label>
        <a href onClick={handleReadOnly}>
          <BTIcon className="bbna-icon-apps" icon="info-circle-alt" />
        </a>
      </div>
      <BTForm.FormGroup>
        <BTTextarea
          hasError={error !== ''}
          placeholder={`Describe the ${describeText}...`}
          value={prevPromptRef.current}
          onChange={onPromptChangeHandler}
        ></BTTextarea>
        <div style={{ paddingTop: '10px' }}>
          {error && (
            <p className="text-error">
              {error} {` `}
              {error.startsWith('Either') && (
                <a
                  href="https://workpapers.bloombergtax.com/help/index.html?article=expressionSyntax"
                  rel="noreferrer"
                  style={{ fontWeight: 600 }}
                  target="_blank"
                >
                  View full list of supported functions expression syntax.
                </a>
              )}
            </p>
          )}
        </div>
        <p> Example prompts: </p>
        <ul className="wkp-ai-example-ul">
          {examples.map((exampleTxt, index) => {
            return <li key={`${blockType}-example-${index + 1}`}>{exampleTxt}</li>;
          })}
        </ul>
        <div className="wkp-ai-button-container">
          <BTButton
            btStyle="primary"
            className="wkp-inspector-preview-btn primary"
            disabled={isLoading}
            hasSpinner={isLoading}
            id={blockType === 'filter' ? 'generate-filter' : 'generate-formula'}
            onClick={onGenerateHandler}
          >
            {`Generate ${labelText}`}
          </BTButton>
          <BTButton className="wkp-inspector-preview-btn" onClick={onCancelHandler}>
            Cancel
          </BTButton>
        </div>
      </BTForm.FormGroup>
    </div>
  );
};

const formulaExamples = [
  'Take the lesser of amount and total.',
  'Multiply amount by exchange rate.',
  'Get the difference of amount and total.',
];

const filterExamples = [
  'Rows where Account Number is populated',
  'Rows where Placed in Service Date is after Acquisition Date',
  'Rows with non-zero purchase price',
  'Remove blank rows',
];

export default ExpressionGeneratorModal;
