import { nodeAPIServiceHostName } from '../../configs/params';
import CustomLogger from './Logger/CustomLogger';
import * as fetch from './fetch';

async function createNodeObject(payload) {
  const response = await fetch.post(`${nodeAPIServiceHostName}/node/objects`, { body: JSON.stringify(payload) });
  if (!response.ok) {
    throw response.data;
  }
  return response.json();
}

async function existedNode(resourceId) {
  const id = encodeURIComponent(resourceId);
  const response = await fetch.get(`${nodeAPIServiceHostName}/node/objects?resourceId=${id}`);
  if (!response.ok) {
    throw response.data;
  }
  return response.json();
}

async function updateNodeObject(payload) {
  const response = await fetch.put(`${nodeAPIServiceHostName}/node/objects`, { body: JSON.stringify(payload) });
  if (!response.ok) {
    throw response.data;
  }
  return response.json();
}

export async function handleNodeObject(
  resourceId,
  name,
  resourceUrl,
  taxPeriod,
  resourceType,
  filesData,
  canSendEvent,
  integrationType
) {
  const objectPayload = {
    resourceId,
    name,
    resourceUrl,
    resourceTaxPeriod: taxPeriod,
    resourceType,
    files: filesData,
    canSendEvent,
    integrationType,
  };

  try {
    const nodeExisted = await existedNode(resourceId);

    CustomLogger.pushLog(CustomLogger.operations.SEND_TO_TP, {
      workflow_id: resourceId,
      integration_type: integrationType,
      message: `${nodeExisted ? 'Updating' : 'Creating'} a node object with object payload ${objectPayload}`,
      response: JSON.stringify(nodeExisted),
    });

    const createResult = nodeExisted?.existed
      ? await updateNodeObject(objectPayload)
      : await createNodeObject(objectPayload);

    return createResult;
  } catch (error) {
    CustomLogger.pushLog(CustomLogger.operations.SEND_TO_TP, {
      workflow_id: resourceId,
      message: `Error while updating/creating a node object with ${resourceId}`,
      error: JSON.stringify(error),
    });
  }
}
