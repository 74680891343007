import React from 'react';
import WkpConnectionFilters from './WkpConnectionFilters';
import { BTForm, BTAlert, BTComboBox } from '@btas/jasper';
import './ConnectionForm/styles.scss';

export default function ConnectionForm({ dataFlowGroups, filters, formData, isReadOnly, updateParent }) {
  const { dataFlow, output, fieldErrors, formError } = formData;

  const selectedDataFlowGroup = !formData.selectedDataFlowGroup
    ? dataFlow
      ? dataFlowGroups.find(({ name }) => name === dataFlow.name)
      : formData.selectedDataFlowGroup
    : formData.selectedDataFlowGroup;

  const parseDataForCombobox = data =>
    data.reduce((prev, { name, id }) => [...prev, { label: name, value: id ? id : name }], []);

  // Parses form data for parent component
  const newDataGetters = {
    dataFlow: value => {
      const selectedDataFlowGroup = dataFlowGroups.find(({ name }) => name === value);

      const isDataFlowSingleTaxPeriod = selectedDataFlowGroup.taxPeriods.length === 1;
      const isDataFlowSingleOutput =
        isDataFlowSingleTaxPeriod && selectedDataFlowGroup.dataFlows[0].outputs.length === 1;

      return {
        ...formData,
        selectedDataFlowGroup,
        dataFlow: {
          ...selectedDataFlowGroup.dataFlows[0],
          taxPeriod: isDataFlowSingleTaxPeriod ? selectedDataFlowGroup.taxPeriods[0] : undefined,
        },
        output: isDataFlowSingleOutput ? selectedDataFlowGroup.dataFlows[0].outputs[0].id : undefined,
        outputField: undefined,
        selectedFilters: {},
      };
    },
    taxPeriod: value => {
      const taxPeriodDataFlow = selectedDataFlowGroup.dataFlows.find(({ taxPeriod }) => value === taxPeriod);
      const isTaxPeriodDataFlowSingleOutput = taxPeriodDataFlow.outputs.length === 1;

      return {
        ...formData,
        dataFlow: taxPeriodDataFlow,
        output: isTaxPeriodDataFlowSingleOutput ? taxPeriodDataFlow.outputs[0].id : undefined,
        outputField: undefined,
        selectedFilters: {},
      };
    },
    output: value => {
      return {
        ...formData,
        output: value,
        outputField: undefined,
        selectedFilters: {},
      };
    },
  };

  const optionGetters = {
    dataFlow: () => parseDataForCombobox(dataFlowGroups),
    taxPeriod: () =>
      selectedDataFlowGroup &&
      selectedDataFlowGroup.taxPeriods.map(taxPeriod => ({ label: taxPeriod, value: taxPeriod })),
    output: () => parseDataForCombobox(dataFlow.outputs),
  };

  const valueGetters = {
    dataFlow: () => (dataFlow ? parseDataForCombobox([dataFlow]) : null),
    taxPeriod: () => (dataFlow?.taxPeriod ? { label: dataFlow.taxPeriod, value: dataFlow.taxPeriod } : null),
    output: () => {
      const currentOutput = dataFlow?.outputs?.find(({ id }) => output === id);

      if (!currentOutput) {
        return null;
      }

      return {
        label: currentOutput.name,
        value: currentOutput.id,
      };
    },
  };

  const fieldHandlers = {
    dataFlow: event => {
      if (!event) updateParent({ fieldErrors: {}, formError: '' });
      else updateParent(newDataGetters.dataFlow(event.value));
    },
    taxPeriod: event => {
      if (!event)
        updateParent({
          ...formData,
          dataFlow: {
            ...selectedDataFlowGroup.dataFlows[0],
            taxPeriod: undefined,
          },
          output: undefined,
          outputField: undefined,
          selectedFilters: {},
        });
      else updateParent(newDataGetters.taxPeriod(event.value));
    },
    output: event => {
      updateParent(newDataGetters.output(event.value));
    },
  };
  return (
    <BTForm>
      <BTForm.FormGroup
        key="dataFlow"
        required
        data-testid="sdc-dataflow-field"
        htmlFor="dataFlow"
        label="Data Connect workflow"
      >
        <BTComboBox
          disabled={isReadOnly}
          id="dataFlow"
          isClearable={true}
          isSearchable={true}
          maxMenuHeight={100}
          options={optionGetters.dataFlow()}
          value={valueGetters.dataFlow()}
          onChange={e => fieldHandlers.dataFlow(e)}
        />
      </BTForm.FormGroup>
      {dataFlow && (
        <BTForm.FormGroup
          key="taxPeriod"
          required
          data-testid="sdc-tax-period-field"
          errorText={fieldErrors['Name']}
          hasError={fieldErrors['Name'] !== undefined}
          htmlFor="taxPeriod"
          label="Tax period"
        >
          <BTComboBox
            disabled={isReadOnly}
            id="taxPeriod"
            isClearable={true}
            maxMenuHeight={100}
            options={optionGetters.taxPeriod()}
            value={valueGetters.taxPeriod()}
            onChange={e => fieldHandlers.taxPeriod(e)}
          />
        </BTForm.FormGroup>
      )}
      {dataFlow && dataFlow.outputs?.length > 1 && (
        <BTForm.FormGroup required data-testid="sdc-output-field" htmlFor="output" label="Output">
          <BTComboBox
            disabled={isReadOnly}
            id="output"
            isClearable={true}
            maxMenuHeight={100}
            options={optionGetters.output()}
            value={valueGetters.output()}
            onChange={e => fieldHandlers.output(e)}
          />
        </BTForm.FormGroup>
      )}

      {dataFlow && filters && valueGetters.output() && (
        <WkpConnectionFilters
          filtersList={filters}
          formData={formData}
          readOnly={isReadOnly}
          updateParent={updateParent}
        />
      )}

      <BTAlert btStyle="danger" className="wkp-connection-form-error" visible={formError !== ''}>
        {formError}
      </BTAlert>
    </BTForm>
  );
}
