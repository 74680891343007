import { BTComboBox, BTForm, BTRadio } from '@btas/jasper';
import {
  getComboBoxData,
  getFormulaDataToReplace,
  isOverrideEnabled,
  setFormulaFieldToReplace,
  setNewColumn,
  setReplaceColumn,
} from './formulaOverride';
import { NEW_COLUMN, REPLACE_COLUMN } from './formulaOverride';
export const FormulaColumnOverride = ({
  canEditWorkflow,
  formulaOverride,
  overrideFields,
  updateData,
  newOutputFieldName,
}) => {
  const handleFieldChange = option => {
    const data = setFormulaFieldToReplace(option.value, formulaOverride);
    updateData(data);
  };

  const overrideEnabled = isOverrideEnabled(formulaOverride);
  const fieldToOverride = getFormulaDataToReplace(formulaOverride);
  return (
    <div>
      <BTForm.FormGroup label="Formula added as">
        <BTRadio
          inline
          checked={!overrideEnabled}
          disabled={!canEditWorkflow}
          label={NEW_COLUMN}
          name="formulaOutput"
          value="New"
          onChange={() => setNewColumn(formulaOverride, updateData)}
        />
        <BTRadio
          inline
          checked={overrideEnabled}
          disabled={!canEditWorkflow}
          label={REPLACE_COLUMN}
          name="formulaOutput"
          value="Replace"
          onChange={() => setReplaceColumn(updateData, formulaOverride, overrideFields, newOutputFieldName)}
        />
      </BTForm.FormGroup>

      {overrideEnabled && (
        <BTForm.FormGroup label="Select column to replace">
          <div className="wkp-fields-selector">
            <BTComboBox
              popOutMenu
              hasError={false}
              isSearchable={true}
              maxMenuHeight={100}
              options={getComboBoxData(overrideFields)}
              value={fieldToOverride}
              onChange={handleFieldChange}
            />
          </div>
        </BTForm.FormGroup>
      )}
    </div>
  );
};
