import { userDataAPIPath } from '../../../configs/params';
import { getTokenInfo } from '../../_shared/auth';
import * as fetch from '../../_shared/fetch';
import CustomLogger from '../../_shared/Logger/CustomLogger';

export async function getClients() {
  const res = await fetch.get(`${userDataAPIPath}/client`);

  if (res.status !== 200) {
    const { userId, companyId } = getTokenInfo();
    CustomLogger.pushLog(CustomLogger.operations.CLIENTS_MANAGEMENT.GET_CLIENTS, {
      message: `Failed to fetch data from user data API for user ${userId}`,
      company_id: companyId,
    });
    throw new Error('Error trying to get clients');
  }

  return await res.json();
}

export async function createUserToken(clientId) {
  //TODO HANDLE ERROR
  await fetch.post(`${userDataAPIPath}/client/token`, {
    body: JSON.stringify({ clientId }),
  });
}
