import { BTAlert, BTButton, BTModal } from '@btas/jasper';
import React, { useContext } from 'react';
import Spinner from '../Spinner';
import { SAVE_AS, RENAME } from './actions';
import SaveAs from './SaveAs';
import Rename from './Rename';
import useWorkpaperActionModal from './useWorkpaperActionModal';
import './styles.scss';
import { getFakeEvent, itemTypes, setPropValues } from '../EventTrackingContext/utils';
import { eventNames } from '../EventTrackingContext/constants';
import { EventTrackingContext } from '../EventTrackingContext';
import ClientChangeConfirmationModal from '../../_shared/Frame/ClientPickerMenu/ClientChangeConfirmationModal';

export default function WorkpaperActionModal({ workpaperId, fileMenuAction, handleFileMenuAction }) {
  const {
    name,
    taxPeriod,
    isSaving,
    isLoadingNextName,
    title,
    taxPeriodComboData,
    errorMessage,
    closeActionModal,
    handleSave,
    handleName,
    handleTaxPeriod,
    selectedClient,
    isDifferentClient,
    handleClient,
    currentClientId,
    showClientChangeModal,
    showCopySuccessMessage,
    setShowCopySuccessMessage,
    setShowClientChangeModal,
    handleClientChangeConfirmationProceed,
    handleClientChangeModalClose,
  } = useWorkpaperActionModal({
    workpaperId,
    handleFileMenuAction,
    fileMenuAction,
  });
  const { trackEvent } = useContext(EventTrackingContext);

  const saveDisable = isSaving || isLoadingNextName || !!errorMessage;

  const renderBody = () => {
    switch (fileMenuAction) {
      case SAVE_AS:
        return (
          <SaveAs
            currentClientId={currentClientId}
            errorMessage={errorMessage}
            handleClient={handleClient}
            handleName={handleName}
            handleTaxPeriod={handleTaxPeriod}
            isDifferentClient={isDifferentClient}
            name={name}
            selectedClient={selectedClient}
            taxPeriod={taxPeriod}
            taxPeriodComboData={taxPeriodComboData}
          />
        );
      case RENAME:
        return (
          <Rename
            errorMessage={errorMessage}
            handleName={handleName}
            handleTaxPeriod={handleTaxPeriod}
            name={name}
            taxPeriod={taxPeriod}
            taxPeriodComboData={taxPeriodComboData}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <BTModal
        id="workbook-action"
        show={!!fileMenuAction}
        size="modal-lg"
        title={title}
        onCloseClick={closeActionModal}
      >
        <BTModal.Body id="workbook-action-body">
          {isLoadingNextName || isSaving ? <Spinner /> : renderBody()}
        </BTModal.Body>
        <BTModal.Footer>
          <BTButton btStyle={isSaving ? 'primary' : 'default'} disabled={isSaving} onClick={closeActionModal}>
            Cancel
          </BTButton>
          <BTButton
            btStyle={saveDisable ? 'default' : 'primary'}
            disabled={saveDisable}
            hasSpinner={isSaving}
            onClick={e => {
              // e.target does not exist, use the fake event with the specified tag
              const fakeEvent = getFakeEvent(itemTypes.BUTTON, 'workpaper-save-as');

              // Track the event
              trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));

              // Handler
              handleSave(e);
            }}
          >
            {isDifferentClient ? 'Copy' : 'Save'}
          </BTButton>
        </BTModal.Footer>
      </BTModal>
      <ClientChangeConfirmationModal
        show={showClientChangeModal}
        onClose={handleClientChangeModalClose}
        onProceed={handleClientChangeConfirmationProceed}
      />
      <BTAlert
        appear
        dismissible
        fixed
        btStyle="success"
        visible={showCopySuccessMessage}
        onDismiss={() => setShowCopySuccessMessage(false)}
      >
        The workpaper{' '}
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}
          onClick={() => setShowClientChangeModal(true)}
        >
          '{name}'
        </span>{' '}
        was successfully copied to client '{selectedClient?.label}'.
      </BTAlert>
    </>
  );
}
