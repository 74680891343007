/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web-javascript'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 87
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/bindg/Global%20Product%20Workspace/implementation/web-javascript)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'productionbloomberglaw'|'developmentbloomberglaw'|'developmentbloombergtax'|'productionbloombergtax'|'developmentbloomberggovernment'|'productionbloomberggovernment'|'developmentnewsstandalone'|'productionnewsstandalone'|'developmenttaxsoftware'|'productiontaxsoftware'|'developmentcustomertools'|'productioncustomertools'|'developmentbloomberglawsoftware'|'productionbloomberglawsoftware'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} productionbloomberglaw
 * @property {string} developmentbloomberglaw
 * @property {string} developmentbloombergtax
 * @property {string} productionbloombergtax
 * @property {string} developmentbloomberggovernment
 * @property {string} productionbloomberggovernment
 * @property {string} developmentnewsstandalone
 * @property {string} productionnewsstandalone
 * @property {string} developmenttaxsoftware
 * @property {string} productiontaxsoftware
 * @property {string} developmentcustomertools
 * @property {string} productioncustomertools
 * @property {string} developmentbloomberglawsoftware
 * @property {string} productionbloomberglawsoftware
 */
export const ApiKey = {
  developmenttaxsoftware: '',
};

/**
 * @typedef {Object} AlertManagement
 * @param {'find'|'track'|'news'|'grouped'|'docket'|'bill citation reff'|'bna news'|'citation ref'|'deal maker'|'docket track'|'edgar'|'generic law'|'generic news'|'leg watch'|'opinion'|'patent'|'patent assignment'|'reg watch'|'tax'} [alertClass] Differentiates between a track, find, News, or grouped alert.
 * @param {string} alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
 * @param {boolean} [clientMatterExists] Is there anything listed in the "Client Matter" box?
 * @param {string} [displayFormat] Email Format "Detailed" or ""Tabular"  Alerts
 * @param {number} distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
 * @param {string} [frequency] How often should the alert/docket track be sent
 * @param {string} [keywords] Keywords used to generate the search, this could be zero or more items
 * @param {string[]} [originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
 * @param {boolean} searchTermsExist Did someone use a keyword
 * @param {string} senderId The ID of the original subscriber of the email.
 * @param {string} [ssid] Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
 */

/**
 * @typedef {Object} Annotations
 * @param {string} [annotationDetails] Specific details regarding the annontation, such as category or type.
 * @param {string[]} annotationStatus Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
 * @param {string[]} annotationType Array stores values to indicate if annotation action includes multiple types of annotationType   Possible values include:  * bulk  * shared  * private  * ...
 */

/**
 * @typedef {Object} AuthorProfiles
 * @param {string} [authorId] For News articles, this will be the BWRITE ID.  This is meant to be used uniquely identify a given author.
 * @param {string} [authorType] Determine if the author is an INDG Reporter or an External Contributor.
 * @param {string} [blpBioId] The ID assocaited with the author in BLP.
 * @param {string} [organization] Collect organization information about a specific author.
 */

/**
 * @typedef {Object} ChartsTables
 * @param {string} [chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
 * @param {number} [chartId] Numeric value of chart ID
 * @param {string[]} [chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
 * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [chartModifyAction] Granular action taken to modify chart
 * @param {string[]} [chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
 * @param {string} [chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
 * @param {string[]} [chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
 * @param {string} [chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
 * @param {string[]} [options] array of options
 * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [tableModifyAction] How was the table modified
 * @param {string[]} [tableOptions] Array of table fields
 * @param {number} [tableOptionsCount] Count of options selected to build chart
 */

/**
 * @typedef {Object} Chat
 * @param {string} [chatPrompt] The keywords/prompts that the user has used while interacting with the AI/Chatbot assistant
 * @param {string} [chatScope] The high level scope of the chat such as opinions and practical guidance.
 * @param {string} [chatSessionId] Unique identifier to differentiate between distinct chat sessions
 * @param {boolean} [responseGenerated] This property is used for when Chat Message is sent/viewed
 * @param {string} [searchServices] The type of search service that was called such as Personlized Suggestion, Recommended for you, BLAW Answers...
 */

/**
 * @typedef {Object} ClickInteraction
 * @param {string} [itemClicked] This should be the label of the specific item clicked
 * @param {string} [itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
 * @param {string} [itemLocation] Region on the page  where the click event occurs
 * @param {string} [itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
 */

/**
 * @typedef {Object} ContentExportOrShared
 * @param {number} [downloadSize] Stores the size of the item downloaded in KB
 * @param {string[]} [exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
 * @param {string} [method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
 */

/**
 * @typedef {Object} DocketEntryFilter
 * @param {string} entryDate Date selected for [Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Entry Filter
 * @param {string[]} filingType Filing Type for [Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Entry Filter
 * @param {string} [keywords] Keywords used to generate the search, this could be zero or more items
 */

/**
 * @typedef {Object} DocketSearch
 * @param {boolean} [docketIntent] When a docket number is recognized in the go bar... This is a boolean value.   The result would with the [docket intent box](https://www.bloomberglaw.com/product/blaw/search/results/c0c174f8dff6f509234c8e9c29d76e00?bc=W1siQmxvb21iZXJnIExhdyIsIi9wcm9kdWN0L2JsYXcvbm90aWZpY2F0aW9ucy9pdGVtcy9SRVNFQVJDSF9UUkFJTD9jb3VudD01MCZ0eXBlPVNFQVJDSCJdXQ--078704ad3141fd6e9876a60965ff7104e97c9377) showing on top of the search results.
 */

/**
 * @typedef {Object} DocketTrackProperties
 * @param {string} alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
 * @param {boolean} [clientMatterExists] Is there anything listed in the "Client Matter" box?
 * @param {boolean} descriptionExists Description Field in an Alert/Docket Track has been used.
 * @param {number} distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
 * @param {string} doNotCopy Alert Managment - Do not send a copy to the person setting up the alert.
 * @param {string} [frequency] How often should the alert/docket track be sent
 * @param {string} noActivity Alerts/Docket Track - Send email even when there is no activity
 * @param {string[]} [originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
 * @param {boolean} searchTermsExist Did someone use a keyword
 * @param {string} senderId The ID of the original subscriber of the email.
 * @param {boolean} tagsExist User generated tags added to an alert/docket track
 */

/**
 * @typedef {Object} Dockets
 * @param {string} [caseType] Property has no description in tracking plan.
 * @param {string} [court] court name, eg. United States District Court For The Southern District Of Texas
 * @param {string} [courtJurisdiction] state or federal
 * @param {string} [dateFiled] The date the docket entry was filed
 * @param {string} [docketKeyType] complaint, notice, summon
 * @param {string} [docketNumber] The Docket No...  ie... 2:16-cv-11082
 * @param {string} [docketType] Federal, State, and Pleading
 * @param {string} [docketViewer] regular, udv
 * @param {string} [natureOfSuit] Personal Injury - Other
 * @param {string} [pleadingId] EXT ID number / Entry number
 */

/**
 * @typedef {Object} Documents
 * @param {string} [contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
 * @param {string} [contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
 * @param {string} [documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
 * @param {string} [documentId] String that uniquely identifies each document.
 * @param {string} [documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
 * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
 * @param {*} [federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
 * @param {number} [nodeId] Property has no description in tracking plan.
 * @param {string} [publisher] The publisher for a content collection
 * @param {string} [secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
 */

/**
 * @typedef {Object} DocumentsLegislation
 * @param {string} [agency] stores the govt agency or private entity associated with a document.
 * @param {string} [agencyId] Identifying Key for agency or other govt/private entity
 * @param {string} [bill] Bill Number
 * @param {string} [congressSession] Federal Congress Number or State Session Name
 * @param {string} [stateName] Full state name (e.g. Rhode Island or Utah)
 */

/**
 * @typedef {Object} EntryRequest
 * @param {'success'|'failure'} entryRequestStatus When a user has successfully submitted a docket request
 */

/**
 * @typedef {Object} ErrorsAndNotices
 * @param {string} [noticeDetails] descriptor that provides context on the event/action the triggered a notice or error event to fire.  Helps differentiate between sources of friction that may be occuring on the same page/event
 */

/**
 * @typedef {Object} EventCounter
 * @param {number} [actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
 * @param {string} [actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
 * @param {string} [iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
 */

/**
 * @typedef {Object} Everywhere
 * @param {boolean} [aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
 * @param {string} appName The name of the Analytics app that's making the call
 * @param {string} [appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
 * @param {string} appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
 * @param {string} brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
 * @param {string} [channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
 * @param {string} [codeBase] Property has no description in tracking plan.
 * @param {string} [contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
 * @param {string} [displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
 * @param {string} domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
 * @param {string[]} [eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
 * @param {number} httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
 * @param {boolean} isLoggedIn Is the user currently logged in (Boolean)
 * @param {boolean} [itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
 * @param {string[]} [jurisdiction] This is used to capture the Jurisdiction values when available
 * @param {string} pageTitle Page Title
 * @param {string} pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
 * @param {string} [pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
 * @param {string} platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
 * @param {string} [previousPage] Property has no description in tracking plan.
 * @param {string} [product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
 * @param {number} [quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
 * @param {string} [sortedBy] Property has no description in tracking plan.
 * @param {string} url full url for the page
 */

/**
 * @typedef {Object} ExternalApps
 * @param {string} [externalAppHost] The specific host environment where the Office application is running (e.g., desktop, web, mobile).
 * @param {string} [externalAppLicenseType] The type of license associated with the Office application (e.g., free, enterprise, educational).
 * @param {string} [externalAppVersion] The version of the app being used, examples would be:      "Microsoft 365 Version 2401", "Office 2019 16.0.10371.20060"
 */

/**
 * @typedef {Object} FavoritedEmbeddedContent
 * @param {string} [contentTitle] This is the title or label for content that has been favorited
 * @param {string} [embeddedContentType] To be used to determine the type of content being displayed in embedded content items.
 */

/**
 * @typedef {Object} FeatureTour
 * @param {number} [tourDepth] Indicates the % completion of a tour. Should 100% when tourStatus = Completed otherwise should be a percentage between 0 and 1 if tourStatus = Exited
 * @param {string} tourName Describes the feature the tour is associated with. E.g. State Legislation Search Reinvent
 * @param {'Auto Start'|'User Start'} [tourStatus] Differentiates whether a tour was prompted automatically or via a user clicking some variation of 'tour'
 */

/**
 * @typedef {Object} FeedbackForms
 * @param {string} feedbackFormTitle identifying title for the feedback form. E.g. 'state leg 2.0' or 'custom bill compare'
 * @param {string[]} [options] array of options
 */

/**
 * @typedef {Object} FileUpload
 * @param {string} fileType To be used to specify the type of file, such as CSV, XLSX, DOCX, PDF, etc...  Should just be the extention without a period and all upper case 3-4 characters.  Should NOT be the filename.
 * @param {string[]} [options] array of options
 * @param {'Browse'|'Drag & Drop'} upLoadType To specify the type of Upload, did someone browse for a file or did someone drag and drop a file
 */

/**
 * @typedef {Object} Forms
 * @param {string} [formId] This captures the ID of the form
 * @param {string} [formNumber] This is capturing the form number when a form is viewed
 * @param {string} [formTitle] This is to capture the titile of the Form when a form is viewed
 * @param {string} [formYear] This is to capture the year value if/when available when a form is viewed
 * @param {string[]} [options] array of options
 */

/**
 * @typedef {Object} IndgNews
 * @param {string} [articleId] This is the BWRITE Article ID
 * @param {string} [articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
 * @param {string} [niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
 * @param {string} [source] the name of the publication that the article was published
 * @param {string[]} [subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
 * @param {string} [wire] the feed on which the article was published
 */

/**
 * @typedef {Object} JobRunSubmittedPropertyGroup
 * @param {string[]} [options] array of options
 */

/**
 * @typedef {Object} LoginProperties
 * @param {string} [loginClickLocation] The location on the page that the login action was taken.
 * @param {string} [loginResponse] Login Response from the login page
 * @param {string} loginType Is the login inline or modal
 * @param {string} rememberMe Remember Me on the login page
 */

/**
 * @typedef {Object} MfaProperties
 * @param {string} [mfaCountDown] This is the current value on the countdown clock
 * @param {string} mfaFailtureReason If there is a failure for the MFA submission, then it should be listed here.
 * @param {boolean} mfaInitialSetup Property has no description in tracking plan.
 * @param {string} [mfaMethod] Can only be email or sms (lowercase)
 * @param {boolean} mfaSuccess Whether the MFA was a success or failure
 */

/**
 * @typedef {Object} Navigation
 * @param {string} navGroup A high level indicator of which navigation section the clicked item was located in
 * @param {string} navItem The specific item that was clicked: Click Text on a navigational element, or the link that was clicked, or the name of the sub-nav item when used with Sub-Nav_Opened, article title
 * @param {string} [navLocation] Similar to the itemLocation property, this property is used to standardize information related to where on a page a user clicked a navigation link. This disaggregates the location from the group/nav type by explicitly indicating where ont he page the nav item is located (search bar, right rail, etc.).
 * @param {'Primary Nav'|'Sub Nav'|'Content Nav'|'Left Bar'|'Right Bar'|'Anthology'|'Text Link'|'Home Page Module'|'Article Sidebar'|'User Workflow'|'Internal Ad'|'Document Sidebar'|'Table'|'Banner'|'Flyout'|'Results List'|'Carousel'|'URL Shortener'|'Menu Item'} navType A categorization of the navigational elements a descriptive 'where' of the link was clicked or type of menu opened. If I open a sub-nav, it would be 'Sub-Nav', if I click on a link within a sub-nav it would also be 'Sub-Nav'. If I click on a link within or at the bottom of an article it might be Content Nav, or click on a category or tag group on the right hand side, 'Topics'.
 */

/**
 * @typedef {Object} NewsletterSignup
 * @param {'free'|'subscriber'} newsletterAccessType Denotes whether a newsletter is free or only available to subscribers
 * @param {string[]} [newslettersSelected] array of articles selected
 * @param {string[]} [options] array of options
 */

/**
 * @typedef {Object} OnboardingForm
 * @param {string} onboardingFormTitle Property has no description in tracking plan.
 * @param {string[]} [options] array of options
 */

/**
 * @typedef {Object} PlayProgress
 * @param {'25'|'50'|'75'|'100'} playDepth usually quartiles, the % of the audio or video that has been played
 */

/**
 * @typedef {Object} Podcasts
 * @param {string} [podcastEpisodeTitle] The title of a podcast eposide.
 * @param {'apple'|'google'|'stitcher'|'spotify'} [podcastPlatform] apple|google|stitcher|spotify
 * @param {string} [podcastSeriesId] ID for the Podcast Series
 * @param {string} [podcastSeriesTitle] The title of a podcast series.
 */

/**
 * @typedef {Object} PracticalGuidance
 * @param {string} [practicalGuidanceArea] Area metadata used within Practical Guidance documents in BLAW/BTAX.
 */

/**
 * @typedef {Object} RegistrationForm
 * @param {string} [licenseRequestedAmount] Number of Licenses Requested
 */

/**
 * @typedef {Object} ScrollProgress
 * @param {'25'|'50'|'75'|'100'} pageDepth usually quartiles, the % of the page that has been visible
 */

/**
 * @typedef {Object} SearchResults
 * @param {string} [keywords] Keywords used to generate the search, this could be zero or more items
 * @param {string} [resultContentKinds] Comma delimited list of content types included in the search results
 * @param {number} resultsCount count of search results
 * @param {string} [searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
 * @param {string[]} [searchArticleType] This is meant exclusively for INDG News articles
 * @param {string} [searchDateRange] This call be all dates, to the range of one or more dates
 * @param {string[]} [searchFilters] Array of all filter selections applied to the search results.
 * @param {string} [searchId] The ID for the current search
 * @param {string} [searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
 * @param {string} [searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
 * @param {number} [searchResultsPage] the search results page number where an action occurred
 * @param {number} [searchResultsVisible] Numeric indicator of how many search results are visible on a page.
 * @param {string} searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
 * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [searchSortBy] Sort by Date or Relevance
 * @param {boolean} searchTermsExist Did someone use a keyword
 * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
 * @param {string} searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
 */

/**
 * @typedef {Object} SearchResultsClick
 * @param {'preview'|'click through'|'typeahead'|'search suggestion'|'document export'} [clickThruType] used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
 * @param {string} [searchResultsBucketContentType] The label of the search results bucket, in BLAW/BTAX this can be the "Content Kind", in BGOV it can be "Legislation".      This is only applied to search buckets and should not be applied to single stream lists.
 * @param {number} [searchResultsBucketIndex] When Search Results on a page are delivered in one or more content sensitve buckets that contain at least one search result, but do not contain the entire set of results for that content.  Then this index will specify the index of "bucket" that the content was in.  For example if there are 5 content buckets on the page, but the link clicked was in the third bucket, then the index will be 3.  When this is set to (none) it should imply there are no content buckets on the page, this can happen on a page that is a single stream.
 * @param {number} [searchResultsIndex] the index of the result that was clicked
 */

/**
 * @typedef {Object} SearchSavedShared
 * @param {string} [keywords] Keywords used to generate the search, this could be zero or more items
 * @param {number} resultsCount count of search results
 * @param {string[]} [searchFilters] Array of all filter selections applied to the search results.
 * @param {string} [searchId] The ID for the current search
 * @param {string} [searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
 * @param {string} searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
 * @param {boolean} searchTermsExist Did someone use a keyword
 * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
 * @param {string} searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
 */

/**
 * @typedef {Object} SearchSubmittedPropertyGroup
 * @param {string} [keywords] Keywords used to generate the search, this could be zero or more items
 * @param {string[]} [previousFilters] array of the filters and keywords of the previous query. Used to help in analyzing and understanding the specific keyword or filter changes made when search action = refine.
 * @param {string[]} [previousKeywords] stores the keywords from the previous search query.    In instances where the functional 'keywords' may be different than the visible keywords (e.g. with BGOV inferred filters), store the functional keywords.
 * @param {string} [searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
 * @param {string[]} [searchFilters] Array of all filter selections applied to the search results.
 * @param {string} [searchId] The ID for the current search
 * @param {string} [searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
 * @param {string} searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
 * @param {boolean} [searchSuggestionAvailable] Indicates whether there were any autosuggesions available (whether or not they were selected).
 * @param {string[]} [searchSuggestionModified] array of modifications made to a search suggestion or AI supported search. Adds additional detail to 'search action = refined' that highlights the specific type of refinement.    sample values:  * keyword edited  * inferred filter added  * inferred filter removed
 * @param {boolean} searchTermsExist Did someone use a keyword
 * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
 * @param {string} searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
 */

/**
 * @typedef {Object} SidebarTab
 * @param {boolean} sidebarIsOpen Property has no description in tracking plan.
 * @param {string} sidebarItem Property has no description in tracking plan.
 */

/**
 * @typedef {Object} TocExportedOrShared
 * @param {string[]} [exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
 * @param {string} [method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
 */

/**
 * @typedef {Object} Tools
 * @param {string} [toolFeature] facet/aspect of a tool that was used
 * @param {string} [toolName] name of the tool
 * @param {string} [toolScope] Property has no description in tracking plan.
 */

/**
 * @typedef {Object} TrackAlerts
 * @param {string[]} [options] array of options
 */

/**
 * @typedef {Object} Upsell
 * @param {string} upsellContentName This should determine the name of the content being accessed.
 * @param {string} upsellContentType This should  determine what type of content is being accessed.  Sample values would be:  \* Document  \* Practice Area  \* Tool
 * @param {string} upsellContentUrl This should be the full URL of the content the user is trying to access.
 * @param {'Modal'|'Page'} upsellType This is a controlled list of the types of Upsells. Currently, it should either be Modal or Page.
 */

/**
 * @typedef {Object} UserWorkflowViewedPropertyGroup
 * @param {string} workFlowName Research Trail, Download Center, Alert Management etc
 */

/**
 * @typedef {Object} WorkflowViewedPropertyGroup
 * @param {number} [totalWorkflows] total number of workflows on a page (BTAX Software)
 */

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '87',
    branch: 'main',
    source: 'web-javascript',
    versionId: '6d9ed2cf-81f5-44f2-b45c-e8370d2015e6',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0',
    },
  },
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class Group {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.GROUP_IDENTIFY;
    this.event_properties = properties;
  }
}

export class AmplitudePageViewed {
  constructor(properties) {
    this.event_type = '[Amplitude] Page Viewed';
    this.event_properties = properties;
  }
}

export class AcademicRegistration {
  constructor(properties) {
    this.event_type = 'Academic Registration';
    this.event_properties = properties;
  }
}

export class AddedToWorkspace {
  constructor(properties) {
    this.event_type = 'Added to Workspace';
    this.event_properties = properties;
  }
}

export class AlertsInboxViewed {
  constructor(properties) {
    this.event_type = 'Alerts Inbox Viewed';
    this.event_properties = properties;
  }
}

export class AlertsManagementViewed {
  constructor(properties) {
    this.event_type = 'Alerts Management Viewed';
    this.event_properties = properties;
  }
}

export class AnnotationDeleted {
  constructor(properties) {
    this.event_type = 'Annotation Deleted';
    this.event_properties = properties;
  }
}

export class AnnotationsAdded {
  constructor(properties) {
    this.event_type = 'Annotations Added';
    this.event_properties = properties;
  }
}

export class AppPageViewed {
  constructor(properties) {
    this.event_type = 'App Page Viewed';
    this.event_properties = properties;
  }
}

export class ArticleExported {
  constructor(properties) {
    this.event_type = 'Article Exported';
    this.event_properties = properties;
  }
}

export class ArticleListenClosed {
  constructor(properties) {
    this.event_type = 'Article Listen Closed';
    this.event_properties = properties;
  }
}

export class ArticleListenOpened {
  constructor(properties) {
    this.event_type = 'Article Listen Opened';
    this.event_properties = properties;
  }
}

export class ArticleListenPaused {
  constructor(properties) {
    this.event_type = 'Article Listen Paused';
    this.event_properties = properties;
  }
}

export class ArticleListenPlayed {
  constructor(properties) {
    this.event_type = 'Article Listen Played';
    this.event_properties = properties;
  }
}

export class ArticleListenSkippedBack {
  constructor(properties) {
    this.event_type = 'Article Listen Skipped Back';
    this.event_properties = properties;
  }
}

export class ArticleListenSkippedForward {
  constructor(properties) {
    this.event_type = 'Article Listen Skipped Forward';
    this.event_properties = properties;
  }
}

export class ArticleSaved {
  constructor(properties) {
    this.event_type = 'Article Saved';
    this.event_properties = properties;
  }
}

export class ArticleShared {
  constructor(properties) {
    this.event_type = 'Article Shared';
    this.event_properties = properties;
  }
}

export class ArticleViewed {
  constructor(properties) {
    this.event_type = 'Article Viewed';
    this.event_properties = properties;
  }
}

export class BriefAnalyzerUsed {
  constructor(properties) {
    this.event_type = 'Brief Analyzer Used';
    this.event_properties = properties;
  }
}

export class BudgetToSpendUsed {
  constructor(properties) {
    this.event_type = 'Budget to Spend Used';
    this.event_properties = properties;
  }
}

export class CalendarEventViewed {
  constructor(properties) {
    this.event_type = 'Calendar Event Viewed';
    this.event_properties = properties;
  }
}

export class ChartBuilderViewed {
  constructor(properties) {
    this.event_type = 'Chart Builder Viewed';
    this.event_properties = properties;
  }
}

export class ChartDisplayModified {
  constructor(properties) {
    this.event_type = 'Chart Display Modified';
    this.event_properties = properties;
  }
}

export class ChartExported {
  constructor(properties) {
    this.event_type = 'Chart Exported';
    this.event_properties = properties;
  }
}

export class ChartFavorited {
  constructor(properties) {
    this.event_type = 'Chart Favorited';
    this.event_properties = properties;
  }
}

export class ChartHidden {
  constructor(properties) {
    this.event_type = 'Chart Hidden';
    this.event_properties = properties;
  }
}

export class ChartShown {
  constructor(properties) {
    this.event_type = 'Chart Shown';
    this.event_properties = properties;
  }
}

export class ChartViewed {
  constructor(properties) {
    this.event_type = 'Chart Viewed';
    this.event_properties = properties;
  }
}

export class ChatMessageSent {
  constructor(properties) {
    this.event_type = 'Chat Message Sent';
    this.event_properties = properties;
  }
}

export class ChatMessageViewed {
  constructor(properties) {
    this.event_type = 'Chat Message Viewed';
    this.event_properties = properties;
  }
}

export class ClientSelected {
  constructor(properties) {
    this.event_type = 'Client Selected';
    this.event_properties = properties;
  }
}

export class ConfirmationFormViewed {
  constructor(properties) {
    this.event_type = 'Confirmation Form Viewed';
    this.event_properties = properties;
  }
}

export class ContentExported {
  constructor(properties) {
    this.event_type = 'Content Exported';
    this.event_properties = properties;
  }
}

export class ContentFavorited {
  constructor(properties) {
    this.event_type = 'Content Favorited';
    this.event_properties = properties;
  }
}

export class ContentShared {
  constructor(properties) {
    this.event_type = 'Content Shared';
    this.event_properties = properties;
  }
}

export class ContractTaskPaneViewed {
  constructor(properties) {
    this.event_type = 'Contract Task Pane Viewed';
    this.event_properties = properties;
  }
}

export class DashboardViewed {
  constructor(properties) {
    this.event_type = 'Dashboard Viewed';
    this.event_properties = properties;
  }
}

export class DirectoryViewed {
  constructor(properties) {
    this.event_type = 'Directory Viewed';
    this.event_properties = properties;
  }
}

export class DocketEntriesFiltered {
  constructor(properties) {
    this.event_type = 'Docket Entries Filtered';
    this.event_properties = properties;
  }
}

export class DocketTrackAlertCreated {
  constructor(properties) {
    this.event_type = 'Docket Track Alert Created';
    this.event_properties = properties;
  }
}

export class DocketTrackDeleted {
  constructor(properties) {
    this.event_type = 'Docket Track Deleted';
    this.event_properties = properties;
  }
}

export class DocketTrackModalViewed {
  constructor(properties) {
    this.event_type = 'Docket Track Modal Viewed';
    this.event_properties = properties;
  }
}

export class DocketTrackModified {
  constructor(properties) {
    this.event_type = 'Docket Track Modified';
    this.event_properties = properties;
  }
}

export class DocketUpdateAuthorization {
  constructor(properties) {
    this.event_type = 'Docket Update Authorization';
    this.event_properties = properties;
  }
}

export class DocketUpdateSubmitted {
  constructor(properties) {
    this.event_type = 'Docket Update Submitted';
    this.event_properties = properties;
  }
}

export class DocumentCompared {
  constructor(properties) {
    this.event_type = 'Document Compared';
    this.event_properties = properties;
  }
}

export class DocumentExported {
  constructor(properties) {
    this.event_type = 'Document Exported';
    this.event_properties = properties;
  }
}

export class DocumentFavorited {
  constructor(properties) {
    this.event_type = 'Document Favorited';
    this.event_properties = properties;
  }
}

export class DocumentSaved {
  constructor(properties) {
    this.event_type = 'Document Saved';
    this.event_properties = properties;
  }
}

export class DocumentShared {
  constructor(properties) {
    this.event_type = 'Document Shared';
    this.event_properties = properties;
  }
}

export class DocumentViewed {
  constructor(properties) {
    this.event_type = 'Document Viewed';
    this.event_properties = properties;
  }
}

export class DraftAnalyzerUsed {
  constructor(properties) {
    this.event_type = 'Draft Analyzer Used';
    this.event_properties = properties;
  }
}

export class DrilldownClicked {
  constructor(properties) {
    this.event_type = 'Drilldown Clicked';
    this.event_properties = properties;
  }
}

export class EmbeddedContent {
  constructor(properties) {
    this.event_type = 'Embedded Content';
    this.event_properties = properties;
  }
}

export class ErrorViewed {
  constructor(properties) {
    this.event_type = 'Error Viewed';
    this.event_properties = properties;
  }
}

export class FeedbackFormSubmitted {
  constructor(properties) {
    this.event_type = 'Feedback Form Submitted';
    this.event_properties = properties;
  }
}

export class FeedbackFormViewed {
  constructor(properties) {
    this.event_type = 'Feedback Form Viewed';
    this.event_properties = properties;
  }
}

export class FileUploaded {
  constructor(properties) {
    this.event_type = 'File Uploaded';
    this.event_properties = properties;
  }
}

export class FindAlertCreated {
  constructor(properties) {
    this.event_type = 'Find Alert Created';
    this.event_properties = properties;
  }
}

export class FindAlertDeleted {
  constructor(properties) {
    this.event_type = 'Find Alert Deleted';
    this.event_properties = properties;
  }
}

export class FindAlertModified {
  constructor(properties) {
    this.event_type = 'Find Alert Modified';
    this.event_properties = properties;
  }
}

export class ForgotUsernamePassword {
  constructor(properties) {
    this.event_type = 'Forgot Username-Password';
    this.event_properties = properties;
  }
}

export class FormSubmitted {
  constructor(properties) {
    this.event_type = 'Form Submitted';
    this.event_properties = properties;
  }
}

export class FormViewed {
  constructor(properties) {
    this.event_type = 'Form Viewed';
    this.event_properties = properties;
  }
}

export class GroupedAlertCreated {
  constructor(properties) {
    this.event_type = 'Grouped Alert Created';
    this.event_properties = properties;
  }
}

export class HistoricalSpendViewed {
  constructor(properties) {
    this.event_type = 'Historical Spend Viewed';
    this.event_properties = properties;
  }
}

export class HomepageViewed {
  constructor(properties) {
    this.event_type = 'Homepage Viewed';
    this.event_properties = properties;
  }
}

export class InteractiveClick {
  constructor(properties) {
    this.event_type = 'Interactive Click';
    this.event_properties = properties;
  }
}

export class InteractiveStoryViewed {
  constructor(properties) {
    this.event_type = 'Interactive Story Viewed';
    this.event_properties = properties;
  }
}

export class JobRunFormViewed {
  constructor(properties) {
    this.event_type = 'Job Run Form Viewed';
    this.event_properties = properties;
  }
}

export class JobRunSubmitted {
  constructor(properties) {
    this.event_type = 'Job Run Submitted';
    this.event_properties = properties;
  }
}

export class LandingViewed {
  constructor(properties) {
    this.event_type = 'Landing Viewed';
    this.event_properties = properties;
  }
}

export class LearnMore {
  constructor(properties) {
    this.event_type = 'Learn More';
    this.event_properties = properties;
  }
}

export class LitigationAnalyticsUsed {
  constructor(properties) {
    this.event_type = 'Litigation Analytics Used';
    this.event_properties = properties;
  }
}

export class LoadMoreClicked {
  constructor(properties) {
    this.event_type = 'Load More Clicked';
    this.event_properties = properties;
  }
}

export class LoginSubmitted {
  constructor(properties) {
    this.event_type = 'Login Submitted';
    this.event_properties = properties;
  }
}

export class LoginView {
  constructor(properties) {
    this.event_type = 'Login View';
    this.event_properties = properties;
  }
}

export class MultiFactorAuthenticationSubmitted {
  constructor(properties) {
    this.event_type = 'Multi Factor Authentication Submitted';
    this.event_properties = properties;
  }
}

export class MultiFactorAuthenticationViewed {
  constructor(properties) {
    this.event_type = 'Multi Factor Authentication Viewed';
    this.event_properties = properties;
  }
}

export class NavClicked {
  constructor(properties) {
    this.event_type = 'Nav Clicked';
    this.event_properties = properties;
  }
}

export class NavClosed {
  constructor(properties) {
    this.event_type = 'Nav Closed';
    this.event_properties = properties;
  }
}

export class NavOpened {
  constructor(properties) {
    this.event_type = 'Nav Opened';
    this.event_properties = properties;
  }
}

export class NewsletterFormClosed {
  constructor(properties) {
    this.event_type = 'Newsletter Form Closed';
    this.event_properties = properties;
  }
}

export class NewsletterFormViewed {
  constructor(properties) {
    this.event_type = 'Newsletter Form Viewed';
    this.event_properties = properties;
  }
}

export class NewsletterSignupSubmitted {
  constructor(properties) {
    this.event_type = 'Newsletter Signup Submitted';
    this.event_properties = properties;
  }
}

export class NewsletterSignupViewed {
  constructor(properties) {
    this.event_type = 'Newsletter Signup Viewed';
    this.event_properties = properties;
  }
}

export class NewsletterSubscribed {
  constructor(properties) {
    this.event_type = 'Newsletter Subscribed';
    this.event_properties = properties;
  }
}

export class NewsletterViewed {
  constructor(properties) {
    this.event_type = 'Newsletter Viewed';
    this.event_properties = properties;
  }
}

export class NextResultNavigated {
  constructor(properties) {
    this.event_type = 'Next Result Navigated';
    this.event_properties = properties;
  }
}

export class NoticeViewed {
  constructor(properties) {
    this.event_type = 'Notice Viewed';
    this.event_properties = properties;
  }
}

export class OnboardingSubmitted {
  constructor(properties) {
    this.event_type = 'Onboarding Submitted';
    this.event_properties = properties;
  }
}

export class OnboardingViewed {
  constructor(properties) {
    this.event_type = 'Onboarding Viewed';
    this.event_properties = properties;
  }
}

export class OrganizationViewed {
  constructor(properties) {
    this.event_type = 'Organization Viewed';
    this.event_properties = properties;
  }
}

export class PageModified {
  constructor(properties) {
    this.event_type = 'Page Modified';
    this.event_properties = properties;
  }
}

export class PersonalizationModalViewed {
  constructor(properties) {
    this.event_type = 'Personalization Modal Viewed';
    this.event_properties = properties;
  }
}

export class PlayDepth {
  constructor(properties) {
    this.event_type = 'Play Depth';
    this.event_properties = properties;
  }
}

export class PleadingRequestAuthorization {
  constructor(properties) {
    this.event_type = 'Pleading Request Authorization';
    this.event_properties = properties;
  }
}

export class PleadingRequestSubmitted {
  constructor(properties) {
    this.event_type = 'Pleading Request Submitted';
    this.event_properties = properties;
  }
}

export class PodcastEpisodePlayed {
  constructor(properties) {
    this.event_type = 'Podcast Episode Played';
    this.event_properties = properties;
  }
}

export class PodcastSeriesLandingViewed {
  constructor(properties) {
    this.event_type = 'Podcast Series Landing Viewed';
    this.event_properties = properties;
  }
}

export class PodcastSeriesSubscribed {
  constructor(properties) {
    this.event_type = 'Podcast Series Subscribed';
    this.event_properties = properties;
  }
}

export class PodcastSeriesViewed {
  constructor(properties) {
    this.event_type = 'Podcast Series Viewed';
    this.event_properties = properties;
  }
}

export class PracticeToolUsed {
  constructor(properties) {
    this.event_type = 'Practice Tool Used';
    this.event_properties = properties;
  }
}

export class PreviousResultNavigated {
  constructor(properties) {
    this.event_type = 'Previous Result Navigated';
    this.event_properties = properties;
  }
}

export class ProfileViewed {
  constructor(properties) {
    this.event_type = 'Profile Viewed';
    this.event_properties = properties;
  }
}

export class ReadNow {
  constructor(properties) {
    this.event_type = 'Read Now';
    this.event_properties = properties;
  }
}

export class ReconciliationViewed {
  constructor(properties) {
    this.event_type = 'Reconciliation Viewed';
    this.event_properties = properties;
  }
}

export class RegistrationFormSubmitted {
  constructor(properties) {
    this.event_type = 'Registration Form Submitted';
    this.event_properties = properties;
  }
}

export class RegistrationFormViewed {
  constructor(properties) {
    this.event_type = 'Registration Form Viewed';
    this.event_properties = properties;
  }
}

export class RememberMe {
  constructor(properties) {
    this.event_type = 'Remember Me';
    this.event_properties = properties;
  }
}

export class ReportRequested {
  constructor(properties) {
    this.event_type = 'Report Requested';
    this.event_properties = properties;
  }
}

export class RequestDemo {
  constructor(properties) {
    this.event_type = 'Request Demo';
    this.event_properties = properties;
  }
}

export class ResultsDrilldownClicked {
  constructor(properties) {
    this.event_type = 'Results Drilldown Clicked';
    this.event_properties = properties;
  }
}

export class SavedForLater {
  constructor(properties) {
    this.event_type = 'Saved for Later';
    this.event_properties = properties;
  }
}

export class SavedSearchesViewed {
  constructor(properties) {
    this.event_type = 'Saved Searches Viewed';
    this.event_properties = properties;
  }
}

export class ScrollDepth {
  constructor(properties) {
    this.event_type = 'Scroll Depth';
    this.event_properties = properties;
  }
}

export class SearchExportCustomized {
  constructor(properties) {
    this.event_type = 'Search Export Customized';
    this.event_properties = properties;
  }
}

export class SearchFormClosed {
  constructor(properties) {
    this.event_type = 'Search Form Closed';
    this.event_properties = properties;
  }
}

export class SearchFormViewed {
  constructor(properties) {
    this.event_type = 'Search Form Viewed';
    this.event_properties = properties;
  }
}

export class SearchResultHidden {
  constructor(properties) {
    this.event_type = 'Search Result Hidden';
    this.event_properties = properties;
  }
}

export class SearchResultsClicked {
  constructor(properties) {
    this.event_type = 'Search Results Clicked';
    this.event_properties = properties;
  }
}

export class SearchResultsExported {
  constructor(properties) {
    this.event_type = 'Search Results Exported';
    this.event_properties = properties;
  }
}

export class SearchResultsNavigated {
  constructor(properties) {
    this.event_type = 'Search Results Navigated';
    this.event_properties = properties;
  }
}

export class SearchResultsSorted {
  constructor(properties) {
    this.event_type = 'Search Results Sorted';
    this.event_properties = properties;
  }
}

export class SearchResultsUnhidden {
  constructor(properties) {
    this.event_type = 'Search Results Unhidden';
    this.event_properties = properties;
  }
}

export class SearchResultsViewed {
  constructor(properties) {
    this.event_type = 'Search Results Viewed';
    this.event_properties = properties;
  }
}

export class SearchSaved {
  constructor(properties) {
    this.event_type = 'Search Saved';
    this.event_properties = properties;
  }
}

export class SearchShared {
  constructor(properties) {
    this.event_type = 'Search Shared';
    this.event_properties = properties;
  }
}

export class SearchSubmitted {
  constructor(properties) {
    this.event_type = 'Search Submitted';
    this.event_properties = properties;
  }
}

export class SelectionsSaved {
  constructor(properties) {
    this.event_type = 'Selections Saved';
    this.event_properties = properties;
  }
}

export class SetAsHome {
  constructor(properties) {
    this.event_type = 'Set as Home';
    this.event_properties = properties;
  }
}

export class SidebarTabOpen {
  constructor(properties) {
    this.event_type = 'Sidebar Tab Open';
    this.event_properties = properties;
  }
}

export class SubscriberAdded {
  constructor(properties) {
    this.event_type = 'Subscriber Added';
    this.event_properties = properties;
  }
}

export class TableResultsFormatted {
  constructor(properties) {
    this.event_type = 'Table Results Formatted';
    this.event_properties = properties;
  }
}

export class TagAdded {
  constructor(properties) {
    this.event_type = 'Tag Added';
    this.event_properties = properties;
  }
}

export class TagDeleted {
  constructor(properties) {
    this.event_type = 'Tag Deleted';
    this.event_properties = properties;
  }
}

export class TocExported {
  constructor(properties) {
    this.event_type = 'TOC Exported';
    this.event_properties = properties;
  }
}

export class TocShared {
  constructor(properties) {
    this.event_type = 'TOC Shared';
    this.event_properties = properties;
  }
}

export class TocViewed {
  constructor(properties) {
    this.event_type = 'TOC Viewed';
    this.event_properties = properties;
  }
}

export class TourEnded {
  constructor(properties) {
    this.event_type = 'Tour Ended';
    this.event_properties = properties;
  }
}

export class TourViewed {
  constructor(properties) {
    this.event_type = 'Tour Viewed';
    this.event_properties = properties;
  }
}

export class TrackAlertCreated {
  constructor(properties) {
    this.event_type = 'Track Alert Created';
    this.event_properties = properties;
  }
}

export class TrackAlertDeleted {
  constructor(properties) {
    this.event_type = 'Track Alert Deleted';
    this.event_properties = properties;
  }
}

export class TrackAlertModified {
  constructor(properties) {
    this.event_type = 'Track Alert Modified';
    this.event_properties = properties;
  }
}

export class UpsellViewed {
  constructor(properties) {
    this.event_type = 'Upsell Viewed';
    this.event_properties = properties;
  }
}

export class UserWorkflowExported {
  constructor(properties) {
    this.event_type = 'User Workflow Exported';
    this.event_properties = properties;
  }
}

export class UserWorkflowViewed {
  constructor(properties) {
    this.event_type = 'User Workflow Viewed';
    this.event_properties = properties;
  }
}

export class WorkflowViewed {
  constructor(properties) {
    this.event_type = 'Workflow Viewed';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {string} [properties.accountCreation] 1/1/22, 1/2/22
   * @param {string} [properties.accountType] * EMPL - EMPLOYEE  * LFSM - LAW FIRM - SMALL  * LFMD - LAW FIRM - MEDIUM  * SCRP - CORPORATION - STRATEGIC  * LSCH - LAW SCHOOL  * TNAC - TAX & ACCOUNTING - NOT STRATEGIC  * CORP - CORPORATION - NOT STRATEGIC  * STAC - TAX & ACCOUNTING - STRATEGIC  * LFVL - LAW FIRM - VERY LARGE  * LFLG - LAW FIRM - LARGE  * ASSN - ASSOCIATION - NOT STRATEGIC  * NOT_AVAILABLE - NOT AVAILABLE  * FGOV - FEDERAL GOVERNMENT  * UNIV - UNIVERSITY  * BSCH - BUSINESS SCHOOL  * Terminal - TERMINAL  * SASN - ASSOCIATION - STRATEGIC  * LS2 - LAW SCHOOL  * SGOV - STATE/LOCAL GOVERNMENT  * WLTH - ??  * SUBA - SUBSCRIPTION AGENCY  * SCRT - STATE COURTS  * TAX - STRATEGIC CORP - TAX  * LFS - SMALL LAW FIRMS  * SCP - STRATEGIC CORP  * CPA - TAX/ACCOUNTING FIRMS  * COR - CORPORATIONS NON STRATEGIC  * FG - ??  * LS3 - LAW SCHOOL  * SL3 - STATE COURTS CONSULTING
   * @param {string} [properties.alternateId] A user's ID given to them by a non-ECDS system
   * @param {string} [properties.authenticationMethod] Property has no description in tracking plan.
   * @param {string} [properties.authenticationSystem] The name of the authentication system, especially if the system is a non-ECDS authentication system.  This is intended to assist in determining how to lookup a users infomation based on their user_ID.
   * @param {string} [properties.bid] i.e., **BINDG Device Identifier**  This is a cookie that is set across all INDG domains, currently it's used for compliance and cross-product access.  Supported domains are:  * BloombergIndustry.com  * BloombergLaw.com  * BloombergTax.com  * BNA.com  BGOV.com will be added soon.
   * @param {*} [properties.blpFirmId] Bloomberg LP Firm ID
   * @param {string} [properties.blpUserId] UUID
   * @param {*} [properties.customerNumber] Need to verify, I believe the Customer Number  ***Should always exist if the user is logged in***
   * @param {string} [properties.dclid] Google campaign manager Click Identifier
   * @param {string} [properties.fbclid] Facebook Click Identifier from URL parameters
   * @param {string} [properties.firmType] Type of Firm the user has selected... This is currently self reported by the end-user.
   * @param {string} [properties.gbraid] Google Click Identifier for iOS device from Web to App
   * @param {string} [properties.gclid] Google Click Identifier from URL parameters
   * @param {*} [properties.indgUnitNumber] This is the Unit Number the user is assocaited with in ECDS, it is different than the Firm ID we get from BLP.
   * @param {string} [properties.indgUserId] This is the user ID the user gets from ECDS, it is different than the UUID.
   * @param {string} [properties.initial_dclid] Initial Google campaign manager Click Identifier
   * @param {string} [properties.initial_fbclid] Initial Facebook Click Identifier from URL parameters
   * @param {string} [properties.initial_gbraid] Initial Google Click Identifier for iOS device from Web to App
   * @param {string} [properties.initial_gclid] Initial Google Click Identifier from URL parameters
   * @param {string} [properties.initial_ko_click_id] Initial Kochava Click Identifier from URL parameters
   * @param {string} [properties.initial_li_fat_id] Initial Linkedin Click Identifier from URL parameters.  It is a member indirect identifier for Members for conversion tracking, retargeting, and analytics.
   * @param {string} [properties.initial_msclkid] Initial Microsoft Click Identifier
   * @param {string} [properties.initial_referrer] Property has no description in tracking plan.
   * @param {string} [properties.initial_referring_domain] Property has no description in tracking plan.
   * @param {string} [properties.initial_rtd_cid] Initial TikTok Click Identifier
   * @param {string} [properties.initial_ttclid] Initial TikTok Click Identifier
   * @param {string} [properties.initial_twclid] Initial Twitter Click Identifier from URL parameter
   * @param {string} [properties.initial_utm_campaign] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_content] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_id] An optional parameter for tracking unique IDs or transaction IDs associated with the link.
   * @param {string} [properties.initial_utm_medium] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_source] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_term] Property has no description in tracking plan.
   * @param {string} [properties.initial_wbraid] Initial Google Click Identifier for iOS device from App to Web
   * @param {string} [properties.internalUserFlag] Property has no description in tracking plan.
   * @param {string} [properties.jobType] Position within the firm the user has selected... This is currently self reported by the end-user.
   * @param {string} [properties.ko_click_id] Kochava Click Identifier from URL parameters
   * @param {string} [properties.lastLogin] first time, 1/1/22, 1/2/22
   * @param {string} [properties.msclkid] Microsoft Click Identifier
   * @param {string[]} [properties.practiceArea] The different practice areas the user is interested in.  Not necessarily what they are subscribed to.
   * @param {string[]} [properties.productEntitlement] What subscriptions does a user have.  Each user may have one or more entitlements and these entitlements can change.  We are adding these to help provide direction, but for detailed and more exact usage you should query someone on the product analytics team.      In the user tables, this is the "item" code and will look like:      BLAW## or BTAX## where ## is a 2 digit number.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {string} [properties.referring_domain] Property has no description in tracking plan.
   * @param {string} [properties.roleType] The role within the firm the user has selected... This is currently self reported by the end-user.
   * @param {'Trial'|'Firm'|'Anonymous'|'Free Trial'} [properties.subscriptionType] Trial, Firm, Anonymous, Free Trial - Where "Free Trial" are people who get a trial account via our webstore.
   * @param {string} [properties.ttclid] TikTok Click Identifier
   * @param {string} [properties.twclid] Twitter Click Identifier from URL parameter
   * @param {string} [properties.userAgent] Property has no description in tracking plan.
   * @param {string} [properties.userRole] librarian, attorney, etc
   * @param {string} [properties.utm_campaign] Property has no description in tracking plan.
   * @param {string} [properties.utm_content] Property has no description in tracking plan.
   * @param {string} [properties.utm_medium] Property has no description in tracking plan.
   * @param {string} [properties.utm_source] Property has no description in tracking plan.
   * @param {string} [properties.utm_term] Property has no description in tracking plan.
   * @param {string} [properties.wbraid] Google Click Identifier for iOS device from App to Web
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
   * Set Group for the current user
   *
   * @param {string} groupType The group type.
   * @param {string|string[]} groupName The group name.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  setGroup(groupType, groupName, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.setGroup(groupType, groupName, options);
  }

  /**
   * Identify a group and set or update that group's properties.
   *
   * @param {string} groupType The group type.
   * @param {string|string[]} groupName The group name.
   * @param {Object} [properties] The group's properties.
   * @param {*} [properties.[Amplitude] Group ID] Property has no description in tracking plan.
   * @param {*} [properties.[Amplitude] Group name] Property has no description in tracking plan.
   * @param {string} [properties.accountType] * EMPL - EMPLOYEE  * LFSM - LAW FIRM - SMALL  * LFMD - LAW FIRM - MEDIUM  * SCRP - CORPORATION - STRATEGIC  * LSCH - LAW SCHOOL  * TNAC - TAX & ACCOUNTING - NOT STRATEGIC  * CORP - CORPORATION - NOT STRATEGIC  * STAC - TAX & ACCOUNTING - STRATEGIC  * LFVL - LAW FIRM - VERY LARGE  * LFLG - LAW FIRM - LARGE  * ASSN - ASSOCIATION - NOT STRATEGIC  * NOT_AVAILABLE - NOT AVAILABLE  * FGOV - FEDERAL GOVERNMENT  * UNIV - UNIVERSITY  * BSCH - BUSINESS SCHOOL  * Terminal - TERMINAL  * SASN - ASSOCIATION - STRATEGIC  * LS2 - LAW SCHOOL  * SGOV - STATE/LOCAL GOVERNMENT  * WLTH - ??  * SUBA - SUBSCRIPTION AGENCY  * SCRT - STATE COURTS  * TAX - STRATEGIC CORP - TAX  * LFS - SMALL LAW FIRMS  * SCP - STRATEGIC CORP  * CPA - TAX/ACCOUNTING FIRMS  * COR - CORPORATIONS NON STRATEGIC  * FG - ??  * LS3 - LAW SCHOOL  * SL3 - STATE COURTS CONSULTING
   * @param {string} [properties.accountTypeName] By Default this should be set to "Anonymous" for users who aren't logged in.. For users who are logged in this will be set to their corresponding ATI/CTI value, except instead of using the acronym, it will be the full name... So "EMPL" will be "Employee".
   * @param {string} [properties.blpFirmId] Bloomberg LP Firm ID
   * @param {string} [properties.companyName] The physical name of the company - based off of Unit Number
   * @param {string} [properties.customerNumber] Need to verify, I believe the Customer Number  ***Should always exist if the user is logged in***
   * @param {string} [properties.indgUnitNumber] The INDG Firm ID  ***Should always exist if the user is logged in***
   * @param {EventOptions} [options] Options for this groupIdentify call.
   *
   * @return {PromiseResult}
   */
  groupIdentify(groupType, groupName, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude.groupIdentify(groupType, groupName, amplitudeIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.[Amplitude] Page Domain] Property has no description in tracking plan.
   * @param {string} [properties.[Amplitude] Page Location] Property has no description in tracking plan.
   * @param {string} [properties.[Amplitude] Page Path] Property has no description in tracking plan.
   * @param {string} [properties.[Amplitude] Page Title] Property has no description in tracking plan.
   * @param {string} [properties.[Amplitude] Page URL] Property has no description in tracking plan.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {string} [properties.referring_domain] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  amplitudePageViewed(properties, options) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * Academic Registration
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Academic%20Registration)
   *
   * [Login Page]()
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  academicRegistration(properties, options) {
    return this.track(new AcademicRegistration(properties), options);
  }

  /**
   * Added to Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Added%20to%20Workspace)
   *
   * User adds a search or a document to a workspace. Carries properties of the specific item (document, search, etc) added
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  addedToWorkspace(properties, options) {
    return this.track(new AddedToWorkspace(properties), options);
  }

  /**
   * Alerts Inbox Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Alerts%20Inbox%20Viewed)
   *
   * [Alerts Inbox](https://www.bloomberglaw.com/product/blaw/alerts/inbox)
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  alertsInboxViewed(properties, options) {
    return this.track(new AlertsInboxViewed(properties), options);
  }

  /**
   * Alerts Management Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Alerts%20Management%20Viewed)
   *
   * Alerts Management Viewed
   *
   * * [BLAW](https://www.bloomberglaw.com/product/blaw/alerts/management)
   *
   * * [BTAX]()
   *
   * * [BGOV]()
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  alertsManagementViewed(properties, options) {
    return this.track(new AlertsManagementViewed(properties), options);
  }

  /**
   * Annotation Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Annotation%20Deleted)
   *
   * This event tracks when a note is deleted within the platform
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} [properties.annotationDetails] Specific details regarding the annontation, such as category or type.
   * @param {string[]} properties.annotationStatus Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   * @param {string[]} properties.annotationType Array stores values to indicate if annotation action includes multiple types of annotationType   Possible values include:  * bulk  * shared  * private  * ...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  annotationDeleted(properties, options) {
    return this.track(new AnnotationDeleted(properties), options);
  }

  /**
   * Annotations Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Annotations%20Added)
   *
   * Event to track when users add annotations to data or documents within the platform.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} [properties.annotationDetails] Specific details regarding the annontation, such as category or type.
   * @param {string[]} properties.annotationStatus Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   * @param {string[]} properties.annotationType Array stores values to indicate if annotation action includes multiple types of annotationType   Possible values include:  * bulk  * shared  * private  * ...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {'preview'|'click through'|'typeahead'|'search suggestion'|'document export'} [properties.clickThruType] used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.searchResultsBucketContentType] The label of the search results bucket, in BLAW/BTAX this can be the "Content Kind", in BGOV it can be "Legislation".      This is only applied to search buckets and should not be applied to single stream lists.
   * @param {number} [properties.searchResultsBucketIndex] When Search Results on a page are delivered in one or more content sensitve buckets that contain at least one search result, but do not contain the entire set of results for that content.  Then this index will specify the index of "bucket" that the content was in.  For example if there are 5 content buckets on the page, but the link clicked was in the third bucket, then the index will be 3.  When this is set to (none) it should imply there are no content buckets on the page, this can happen on a page that is a single stream.
   * @param {number} [properties.searchResultsIndex] the index of the result that was clicked
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  annotationsAdded(properties, options) {
    return this.track(new AnnotationsAdded(properties), options);
  }

  /**
   * App Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/App%20Page%20Viewed)
   *
   * To be fired for the different application pages wihtin BTAX/BLAW Software
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  appPageViewed(properties, options) {
    return this.track(new AppPageViewed(properties), options);
  }

  /**
   * Article Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Exported)
   *
   * User exports an article, usually using download or print links.
   *
   * Owner: rcarmichael@vendor.bloombergindustry.com
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  articleExported(properties, options) {
    return this.track(new ArticleExported(properties), options);
  }

  /**
   * Article Listen Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Closed)
   *
   * Fire when the user clicks the "X" to close the listen UI.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  articleListenClosed(properties, options) {
    return this.track(new ArticleListenClosed(properties), options);
  }

  /**
   * Article Listen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Opened)
   *
   * User clicks on the Article Listen button either on the email or web page.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  articleListenOpened(properties, options) {
    return this.track(new ArticleListenOpened(properties), options);
  }

  /**
   * Article Listen Paused
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Paused)
   *
   * User presses the "pause" button in the audio player UI
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  articleListenPaused(properties, options) {
    return this.track(new ArticleListenPaused(properties), options);
  }

  /**
   * Article Listen Played
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Played)
   *
   * User presses the "play" button in the article listen UI.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  articleListenPlayed(properties, options) {
    return this.track(new ArticleListenPlayed(properties), options);
  }

  /**
   * Article Listen Skipped Back
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Skipped%20Back)
   *
   * Fire when the user skips backward in article listen UI.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  articleListenSkippedBack(properties, options) {
    return this.track(new ArticleListenSkippedBack(properties), options);
  }

  /**
   * Article Listen Skipped Forward
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Skipped%20Forward)
   *
   * Fire when the user skips forward in an article listen.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  articleListenSkippedForward(properties, options) {
    return this.track(new ArticleListenSkippedForward(properties), options);
  }

  /**
   * Article Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Saved)
   *
   * User saves an article.
   *
   * Owner: rcarmichael@vendor.bloombergindustry.com
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  articleSaved(properties, options) {
    return this.track(new ArticleSaved(properties), options);
  }

  /**
   * Article Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Shared)
   *
   * When a user shares an article via email or a social platform.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  articleShared(properties, options) {
    return this.track(new ArticleShared(properties), options);
  }

  /**
   * Article Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Viewed)
   *
   * Fire when the user views a bnews article (usually on page load).
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  articleViewed(properties, options) {
    return this.track(new ArticleViewed(properties), options);
  }

  /**
   * Brief Analyzer Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Brief%20Analyzer%20Used)
   *
   * Event: Brief Analyzer Used
   *
   * Description: This event tracks the usage of the Brief Analyzer feature on Bloomberg Law. It captures when users utilize the Brief Analyzer tool to analyze legal briefs and documents, providing insights into how often and in what context this feature is utilized
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  briefAnalyzerUsed(properties, options) {
    return this.track(new BriefAnalyzerUsed(properties), options);
  }

  /**
   * Budget to Spend Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Budget%20to%20Spend%20Used)
   *
   * fires when a user loads budget to spend in BGOV
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  budgetToSpendUsed(properties, options) {
    return this.track(new BudgetToSpendUsed(properties), options);
  }

  /**
   * Calendar Event Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Calendar%20Event%20Viewed)
   *
   * This event tracks when a user views a calendar event
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  calendarEventViewed(properties, options) {
    return this.track(new CalendarEventViewed(properties), options);
  }

  /**
   * Chart Builder Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Builder%20Viewed)
   *
   * Fire when users are shown the chart builder page to build a chart (includes edit or modify)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chartBuilderViewed(properties, options) {
    return this.track(new ChartBuilderViewed(properties), options);
  }

  /**
   * Chart Display Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Display%20Modified)
   *
   * Fire when users change display options such as column headers, answer length, highlight by timeframe, etc.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chartDisplayModified(properties, options) {
    return this.track(new ChartDisplayModified(properties), options);
  }

  /**
   * Chart Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Exported)
   *
   * Fire when users export chart (include current options in parameters)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chartExported(properties, options) {
    return this.track(new ChartExported(properties), options);
  }

  /**
   * Chart Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Favorited)
   *
   * Fire when users favorite chart options
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chartFavorited(properties, options) {
    return this.track(new ChartFavorited(properties), options);
  }

  /**
   * Chart Hidden
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Hidden)
   *
   * Fire when users toggle the show chart CTA to hide chart (e.g. historical spend)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chartHidden(properties, options) {
    return this.track(new ChartHidden(properties), options);
  }

  /**
   * Chart Shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Shown)
   *
   * Fire when users toggle the Show Chart CTA to show chart (Historical Spend)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chartShown(properties, options) {
    return this.track(new ChartShown(properties), options);
  }

  /**
   * Chart Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Viewed)
   *
   * Fire when users are shown a chart from chart builder whether new, saved, or modified.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chartViewed(properties, options) {
    return this.track(new ChartViewed(properties), options);
  }

  /**
   * Chat Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chat%20Message%20Sent)
   *
   * Event to track when a chat message is sent from any location within the platform
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chatPrompt] The keywords/prompts that the user has used while interacting with the AI/Chatbot assistant
   * @param {string} [properties.chatScope] The high level scope of the chat such as opinions and practical guidance.
   * @param {string} [properties.chatSessionId] Unique identifier to differentiate between distinct chat sessions
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {boolean} [properties.responseGenerated] This property is used for when Chat Message is sent/viewed
   * @param {string} [properties.searchServices] The type of search service that was called such as Personlized Suggestion, Recommended for you, BLAW Answers...
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chatMessageSent(properties, options) {
    return this.track(new ChatMessageSent(properties), options);
  }

  /**
   * Chat Message Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chat%20Message%20Viewed)
   *
   * Event to track when a chat message has been viewed by a user
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chatPrompt] The keywords/prompts that the user has used while interacting with the AI/Chatbot assistant
   * @param {string} [properties.chatScope] The high level scope of the chat such as opinions and practical guidance.
   * @param {string} [properties.chatSessionId] Unique identifier to differentiate between distinct chat sessions
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {boolean} [properties.responseGenerated] This property is used for when Chat Message is sent/viewed
   * @param {string} [properties.searchServices] The type of search service that was called such as Personlized Suggestion, Recommended for you, BLAW Answers...
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  chatMessageViewed(properties, options) {
    return this.track(new ChatMessageViewed(properties), options);
  }

  /**
   * Client Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Client%20Selected)
   *
   * When a user changes the company/client driving a given user experience. Example, client manager for workpapers or my lobbying profile for bgov, etc..
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  clientSelected(properties, options) {
    return this.track(new ClientSelected(properties), options);
  }

  /**
   * Confirmation Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Confirmation%20Form%20Viewed)
   *
   * This event tracks when a user views the confirmation form during the sales process
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  confirmationFormViewed(properties, options) {
    return this.track(new ConfirmationFormViewed(properties), options);
  }

  /**
   * Content Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Content%20Exported)
   *
   * This event tracks when users export generic content
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {string} [properties.formId] This captures the ID of the form
   * @param {string} [properties.formNumber] This is capturing the form number when a form is viewed
   * @param {string} [properties.formTitle] This is to capture the titile of the Form when a form is viewed
   * @param {string} [properties.formYear] This is to capture the year value if/when available when a form is viewed
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  contentExported(properties, options) {
    return this.track(new ContentExported(properties), options);
  }

  /**
   * Content Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Content%20Favorited)
   *
   * When a user "Favorites" non-document content, content modules or tools. 
   *
   * Example: News Channel Landing Pages on BLAW: https://www.bloomberglaw.com/bloomberglawnews/capital-markets
   *
   *
   * For documents - use Document Favorited
   *
   * For Charts - use Chart Favorited
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.contentTitle] This is the title or label for content that has been favorited
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string} [properties.embeddedContentType] To be used to determine the type of content being displayed in embedded content items.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  contentFavorited(properties, options) {
    return this.track(new ContentFavorited(properties), options);
  }

  /**
   * Content Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Content%20Shared)
   *
   * When a user shares content that is not an article, search page, document, or TOC.
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  contentShared(properties, options) {
    return this.track(new ContentShared(properties), options);
  }

  /**
   * Contract Task Pane Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Contract%20Task%20Pane%20Viewed)
   *
   * This event tracks when a user views the contract task pane, which displays relevant tasks and information related to a specific contract.... This is specific for the BLAW Contracts Tool
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  contractTaskPaneViewed(properties, options) {
    return this.track(new ContractTaskPaneViewed(properties), options);
  }

  /**
   * Dashboard Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Dashboard%20Viewed)
   *
   * This event tracks when a user views a dashboard
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  dashboardViewed(properties, options) {
    return this.track(new DashboardViewed(properties), options);
  }

  /**
   * Directory Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Directory%20Viewed)
   *
   * This event tracks when a user views a directory within the Bloomberg Government platform. It provides insights into user engagement with directories, helping to understand which directories are most frequently accessed and informing decisions on directory organization and content
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  directoryViewed(properties, options) {
    return this.track(new DirectoryViewed(properties), options);
  }

  /**
   * Docket Entries Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Entries%20Filtered)
   *
   * Have the docket entries been [filtered](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) on the Dockets page?
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string} properties.entryDate Date selected for [Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Entry Filter
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {string[]} properties.filingType Filing Type for [Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Entry Filter
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  docketEntriesFiltered(properties, options) {
    return this.track(new DocketEntriesFiltered(properties), options);
  }

  /**
   * Docket Track Alert Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Alert%20Created)
   *
   * A [Docket Track](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) has been created...
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {boolean} [properties.clientMatterExists] Is there anything listed in the "Client Matter" box?
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {boolean} properties.descriptionExists Description Field in an Alert/Docket Track has been used.
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {number} properties.distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string} properties.doNotCopy Alert Managment - Do not send a copy to the person setting up the alert.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {string} [properties.frequency] How often should the alert/docket track be sent
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {string} properties.noActivity Alerts/Docket Track - Send email even when there is no activity
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string[]} [properties.originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} properties.senderId The ID of the original subscriber of the email.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {boolean} properties.tagsExist User generated tags added to an alert/docket track
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  docketTrackAlertCreated(properties, options) {
    return this.track(new DocketTrackAlertCreated(properties), options);
  }

  /**
   * Docket Track Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Deleted)
   *
   * The Docket Track was deleted by the user.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  docketTrackDeleted(properties, options) {
    return this.track(new DocketTrackDeleted(properties), options);
  }

  /**
   * Docket Track Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Modal%20Viewed)
   *
   * Impressions of the Docket Track Modal, This can be viewed on [docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) or in [Custom Alerts](https://www.bloomberglaw.com/product/blaw/alerts/management)
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  docketTrackModalViewed(properties, options) {
    return this.track(new DocketTrackModalViewed(properties), options);
  }

  /**
   * Docket Track Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Modified)
   *
   * This is only if the docket track has been [modified](https://www.bloomberglaw.com/product/blaw/alerts/management)
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {boolean} [properties.clientMatterExists] Is there anything listed in the "Client Matter" box?
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {boolean} properties.descriptionExists Description Field in an Alert/Docket Track has been used.
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {number} properties.distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string} properties.doNotCopy Alert Managment - Do not send a copy to the person setting up the alert.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} [properties.frequency] How often should the alert/docket track be sent
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.noActivity Alerts/Docket Track - Send email even when there is no activity
   * @param {string[]} [properties.originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {string} properties.senderId The ID of the original subscriber of the email.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {boolean} properties.tagsExist User generated tags added to an alert/docket track
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  docketTrackModified(properties, options) {
    return this.track(new DocketTrackModified(properties), options);
  }

  /**
   * Docket Update Authorization
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Update%20Authorization)
   *
   * [Update Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Modal, when it has been viewed
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  docketUpdateAuthorization(properties, options) {
    return this.track(new DocketUpdateAuthorization(properties), options);
  }

  /**
   * Docket Update Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Update%20Submitted)
   *
   * Triggers when a user has successfully submitted a docket update from. the [Update Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Modal
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  docketUpdateSubmitted(properties, options) {
    return this.track(new DocketUpdateSubmitted(properties), options);
  }

  /**
   * Document Compared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Compared)
   *
   * This event tracks when a document is compared to another document, providing insights into user behavior and document usage patterns
   *
   *
   * NOTE:
   *
   * Document properties should be associated with the primary document being compared
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {'preview'|'click through'|'typeahead'|'search suggestion'|'document export'} [properties.clickThruType] used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} [properties.inclRecommended] Indicates if an event action includes a recommended document or other entity (recommended via ML model)
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.searchResultsBucketContentType] The label of the search results bucket, in BLAW/BTAX this can be the "Content Kind", in BGOV it can be "Legislation".      This is only applied to search buckets and should not be applied to single stream lists.
   * @param {number} [properties.searchResultsBucketIndex] When Search Results on a page are delivered in one or more content sensitve buckets that contain at least one search result, but do not contain the entire set of results for that content.  Then this index will specify the index of "bucket" that the content was in.  For example if there are 5 content buckets on the page, but the link clicked was in the third bucket, then the index will be 3.  When this is set to (none) it should imply there are no content buckets on the page, this can happen on a page that is a single stream.
   * @param {number} [properties.searchResultsIndex] the index of the result that was clicked
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  documentCompared(properties, options) {
    return this.track(new DocumentCompared(properties), options);
  }

  /**
   * Document Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Exported)
   *
   * Fire when users export a document
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {'preview'|'click through'|'typeahead'|'search suggestion'|'document export'} [properties.clickThruType] used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.searchResultsBucketContentType] The label of the search results bucket, in BLAW/BTAX this can be the "Content Kind", in BGOV it can be "Legislation".      This is only applied to search buckets and should not be applied to single stream lists.
   * @param {number} [properties.searchResultsBucketIndex] When Search Results on a page are delivered in one or more content sensitve buckets that contain at least one search result, but do not contain the entire set of results for that content.  Then this index will specify the index of "bucket" that the content was in.  For example if there are 5 content buckets on the page, but the link clicked was in the third bucket, then the index will be 3.  When this is set to (none) it should imply there are no content buckets on the page, this can happen on a page that is a single stream.
   * @param {number} [properties.searchResultsIndex] the index of the result that was clicked
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  documentExported(properties, options) {
    return this.track(new DocumentExported(properties), options);
  }

  /**
   * Document Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Favorited)
   *
   * When someone "Favorites" a document
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  documentFavorited(properties, options) {
    return this.track(new DocumentFavorited(properties), options);
  }

  /**
   * Document Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Saved)
   *
   * Fires when a user uses the "Saved for Later" functionality
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} [properties.formId] This captures the ID of the form
   * @param {string} [properties.formNumber] This is capturing the form number when a form is viewed
   * @param {string} [properties.formTitle] This is to capture the titile of the Form when a form is viewed
   * @param {string} [properties.formYear] This is to capture the year value if/when available when a form is viewed
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  documentSaved(properties, options) {
    return this.track(new DocumentSaved(properties), options);
  }

  /**
   * Document Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Shared)
   *
   * A document has been shared by email
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {'preview'|'click through'|'typeahead'|'search suggestion'|'document export'} [properties.clickThruType] used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.searchResultsBucketContentType] The label of the search results bucket, in BLAW/BTAX this can be the "Content Kind", in BGOV it can be "Legislation".      This is only applied to search buckets and should not be applied to single stream lists.
   * @param {number} [properties.searchResultsBucketIndex] When Search Results on a page are delivered in one or more content sensitve buckets that contain at least one search result, but do not contain the entire set of results for that content.  Then this index will specify the index of "bucket" that the content was in.  For example if there are 5 content buckets on the page, but the link clicked was in the third bucket, then the index will be 3.  When this is set to (none) it should imply there are no content buckets on the page, this can happen on a page that is a single stream.
   * @param {number} [properties.searchResultsIndex] the index of the result that was clicked
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  documentShared(properties, options) {
    return this.track(new DocumentShared(properties), options);
  }

  /**
   * Document Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Viewed)
   *
   * When a document has been viewed
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.practicalGuidanceArea] Area metadata used within Practical Guidance documents in BLAW/BTAX.
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  documentViewed(properties, options) {
    return this.track(new DocumentViewed(properties), options);
  }

  /**
   * Draft Analyzer Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Draft%20Analyzer%20Used)
   *
   * Event: Draft Analyzer Used
   *
   * Description: This event tracks the usage of the Draft Analyzer feature on Bloomberg Law. It captures when users utilize the Draft Analyzer tool to analyze and improve their legal drafts. The event provides insights into how often and in what context the Draft Analyzer is utilized, helping to understand user engagement and the value of this feature
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  draftAnalyzerUsed(properties, options) {
    return this.track(new DraftAnalyzerUsed(properties), options);
  }

  /**
   * Drilldown Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Drilldown%20Clicked)
   *
   * This event fires when a user interacts with the expand/collapse functionality of a table object. Examples include the results for BGOV's historical spending or federal funding flow, BTAX's ToC menus for documents, or analyses in the tax software product.
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  drilldownClicked(properties, options) {
    return this.track(new DrilldownClicked(properties), options);
  }

  /**
   * Embedded Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Embedded%20Content)
   *
   * Event to track when embedded content (i.e., BNA Picks) is viewed or interacted with on any platform or device
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.contentTitle] This is the title or label for content that has been favorited
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string} [properties.embeddedContentType] To be used to determine the type of content being displayed in embedded content items.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  embeddedContent(properties, options) {
    return this.track(new EmbeddedContent(properties), options);
  }

  /**
   * Error Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Error%20Viewed)
   *
   * User views a page that returns a server error (like a 404), be sure to update the httpStatusCode to match the error.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.noticeDetails] descriptor that provides context on the event/action the triggered a notice or error event to fire.  Helps differentiate between sources of friction that may be occuring on the same page/event
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  errorViewed(properties, options) {
    return this.track(new ErrorViewed(properties), options);
  }

  /**
   * Feedback Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Feedback%20Form%20Submitted)
   *
   * Fires when a user submits a feedback form
   *
   * Owner: Kyle Contrata
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {string} properties.feedbackFormTitle identifying title for the feedback form. E.g. 'state leg 2.0' or 'custom bill compare'
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  feedbackFormSubmitted(properties, options) {
    return this.track(new FeedbackFormSubmitted(properties), options);
  }

  /**
   * Feedback Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Feedback%20Form%20Viewed)
   *
   * Fires when a user views or is shown a feedback form.
   *
   * Owner: Kyle Contrata
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} properties.feedbackFormTitle identifying title for the feedback form. E.g. 'state leg 2.0' or 'custom bill compare'
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  feedbackFormViewed(properties, options) {
    return this.track(new FeedbackFormViewed(properties), options);
  }

  /**
   * File Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/File%20Uploaded)
   *
   * This event tracks when a file is uploaded to the system
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} properties.fileType To be used to specify the type of file, such as CSV, XLSX, DOCX, PDF, etc...  Should just be the extention without a period and all upper case 3-4 characters.  Should NOT be the filename.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {'Browse'|'Drag & Drop'} properties.upLoadType To specify the type of Upload, did someone browse for a file or did someone drag and drop a file
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  fileUploaded(properties, options) {
    return this.track(new FileUploaded(properties), options);
  }

  /**
   * Find Alert Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Created)
   *
   * This event tracks the creation of a new alert in the Alert Management system.  A Find Alert is associated with the user setting an alert based on a search they have performed.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {'find'|'track'|'news'|'grouped'|'docket'|'bill citation reff'|'bna news'|'citation ref'|'deal maker'|'docket track'|'edgar'|'generic law'|'generic news'|'leg watch'|'opinion'|'patent'|'patent assignment'|'reg watch'|'tax'} [properties.alertClass] Differentiates between a track, find, News, or grouped alert.
   * @param {string} properties.alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {boolean} [properties.clientMatterExists] Is there anything listed in the "Client Matter" box?
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayFormat] Email Format "Detailed" or ""Tabular"  Alerts
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {number} properties.distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} [properties.frequency] How often should the alert/docket track be sent
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string[]} [properties.originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {string} properties.senderId The ID of the original subscriber of the email.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.ssid] Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  findAlertCreated(properties, options) {
    return this.track(new FindAlertCreated(properties), options);
  }

  /**
   * Find Alert Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Deleted)
   *
   * The alert was deleted by the user
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {'find'|'track'|'news'|'grouped'|'docket'|'bill citation reff'|'bna news'|'citation ref'|'deal maker'|'docket track'|'edgar'|'generic law'|'generic news'|'leg watch'|'opinion'|'patent'|'patent assignment'|'reg watch'|'tax'} [properties.alertClass] Differentiates between a track, find, News, or grouped alert.
   * @param {string} properties.alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {boolean} [properties.clientMatterExists] Is there anything listed in the "Client Matter" box?
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayFormat] Email Format "Detailed" or ""Tabular"  Alerts
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {number} properties.distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} [properties.frequency] How often should the alert/docket track be sent
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string[]} [properties.originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {string} properties.senderId The ID of the original subscriber of the email.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.ssid] Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  findAlertDeleted(properties, options) {
    return this.track(new FindAlertDeleted(properties), options);
  }

  /**
   * Find Alert Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Modified)
   *
   * This would be fired when the "Save Search to Alert" is updated... While editing an Alert
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {'find'|'track'|'news'|'grouped'|'docket'|'bill citation reff'|'bna news'|'citation ref'|'deal maker'|'docket track'|'edgar'|'generic law'|'generic news'|'leg watch'|'opinion'|'patent'|'patent assignment'|'reg watch'|'tax'} [properties.alertClass] Differentiates between a track, find, News, or grouped alert.
   * @param {string} properties.alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {boolean} [properties.clientMatterExists] Is there anything listed in the "Client Matter" box?
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayFormat] Email Format "Detailed" or ""Tabular"  Alerts
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {number} properties.distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} [properties.frequency] How often should the alert/docket track be sent
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string[]} [properties.originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {string} properties.senderId The ID of the original subscriber of the email.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.ssid] Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  findAlertModified(properties, options) {
    return this.track(new FindAlertModified(properties), options);
  }

  /**
   * Forgot Username-Password
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Forgot%20Username-Password)
   *
   * Clicking on Forgot Username/Password on [login page]().
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  forgotUsernamePassword(properties, options) {
    return this.track(new ForgotUsernamePassword(properties), options);
  }

  /**
   * Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Form%20Submitted)
   *
   * This event tracks when a form is submitted by a user. It provides insights into user engagement and conversion rates for forms on our platform
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} [properties.formId] This captures the ID of the form
   * @param {string} [properties.formNumber] This is capturing the form number when a form is viewed
   * @param {string} [properties.formTitle] This is to capture the titile of the Form when a form is viewed
   * @param {string} [properties.formYear] This is to capture the year value if/when available when a form is viewed
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  formSubmitted(properties, options) {
    return this.track(new FormSubmitted(properties), options);
  }

  /**
   * Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Form%20Viewed)
   *
   * This event tracks when a user views a form. It provides insights into user engagement with forms on our platform
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {string} [properties.formId] This captures the ID of the form
   * @param {string} [properties.formNumber] This is capturing the form number when a form is viewed
   * @param {string} [properties.formTitle] This is to capture the titile of the Form when a form is viewed
   * @param {string} [properties.formYear] This is to capture the year value if/when available when a form is viewed
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  formViewed(properties, options) {
    return this.track(new FormViewed(properties), options);
  }

  /**
   * Grouped Alert Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Grouped%20Alert%20Created)
   *
   * This event tracks the creation of a grouped alert, which is a customized notification that combines multiple individual alerts into a single group
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {'find'|'track'|'news'|'grouped'|'docket'|'bill citation reff'|'bna news'|'citation ref'|'deal maker'|'docket track'|'edgar'|'generic law'|'generic news'|'leg watch'|'opinion'|'patent'|'patent assignment'|'reg watch'|'tax'} [properties.alertClass] Differentiates between a track, find, News, or grouped alert.
   * @param {string} properties.alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {boolean} [properties.clientMatterExists] Is there anything listed in the "Client Matter" box?
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayFormat] Email Format "Detailed" or ""Tabular"  Alerts
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {number} properties.distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} [properties.frequency] How often should the alert/docket track be sent
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string[]} [properties.originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {string} properties.senderId The ID of the original subscriber of the email.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.ssid] Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  groupedAlertCreated(properties, options) {
    return this.track(new GroupedAlertCreated(properties), options);
  }

  /**
   * Historical Spend Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Historical%20Spend%20Viewed)
   *
   * When user loads the Historical Spend Page
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  historicalSpendViewed(properties, options) {
    return this.track(new HistoricalSpendViewed(properties), options);
  }

  /**
   * Homepage Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Homepage%20Viewed)
   *
   * User views the homepage
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  homepageViewed(properties, options) {
    return this.track(new HomepageViewed(properties), options);
  }

  /**
   * Interactive Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Interactive%20Click)
   *
   * This should be used for any important interaction on a page or modal, that hasn't justified a specific event and doesn't cause a new page load (not a navigation). An example would be a click that shows a modal or modifies a table layout.
   *
   * The concept is if these items become valuable, we'll want to more specifically define them and track them.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string} [properties.externalAppHost] The specific host environment where the Office application is running (e.g., desktop, web, mobile).
   * @param {string} [properties.externalAppLicenseType] The type of license associated with the Office application (e.g., free, enterprise, educational).
   * @param {string} [properties.externalAppVersion] The version of the app being used, examples would be:      "Microsoft 365 Version 2401", "Office 2019 16.0.10371.20060"
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {string} [properties.formId] This captures the ID of the form
   * @param {string} [properties.formNumber] This is capturing the form number when a form is viewed
   * @param {string} [properties.formTitle] This is to capture the titile of the Form when a form is viewed
   * @param {string} [properties.formYear] This is to capture the year value if/when available when a form is viewed
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  interactiveClick(properties, options) {
    return this.track(new InteractiveClick(properties), options);
  }

  /**
   * Interactive Story Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Interactive%20Story%20Viewed)
   *
   * Fire when the user views an interactice story on bnews  (usually on page load).
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  interactiveStoryViewed(properties, options) {
    return this.track(new InteractiveStoryViewed(properties), options);
  }

  /**
   * Job Run Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Job%20Run%20Form%20Viewed)
   *
   * This event tracks when a user views the job run form in the BTAX Software Workflows
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  jobRunFormViewed(properties, options) {
    return this.track(new JobRunFormViewed(properties), options);
  }

  /**
   * Job Run Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Job%20Run%20Submitted)
   *
   * This event tracks the submission of job runs within the BTAX Software Workflows, providing insights into the frequency and usage patterns of this feature
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  jobRunSubmitted(properties, options) {
    return this.track(new JobRunSubmitted(properties), options);
  }

  /**
   * Landing Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Landing%20Viewed)
   *
   * News Landing Page (homepage or channel page) Viewed - Specific to INDG News from BWRITE.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  landingViewed(properties, options) {
    return this.track(new LandingViewed(properties), options);
  }

  /**
   * Learn More
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Learn%20More)
   *
   * Learn More from [Login Page]()
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  learnMore(properties, options) {
    return this.track(new LearnMore(properties), options);
  }

  /**
   * Litigation Analytics Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Litigation%20Analytics%20Used)
   *
   * This event tracks the usage of litigation analytics within the Bloomberg Government platform. It provides insights into how often and in what context users are utilizing the litigation analytics feature
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  litigationAnalyticsUsed(properties, options) {
    return this.track(new LitigationAnalyticsUsed(properties), options);
  }

  /**
   * Load More Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Load%20More%20Clicked)
   *
   * Load More on a search results page
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  loadMoreClicked(properties, options) {
    return this.track(new LoadMoreClicked(properties), options);
  }

  /**
   * Login Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Login%20Submitted)
   *
   * [Login Page](https://www.bloomberglaw.com/signin) Submitted
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.loginClickLocation] The location on the page that the login action was taken.
   * @param {string} [properties.loginResponse] Login Response from the login page
   * @param {string} properties.loginType Is the login inline or modal
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} properties.rememberMe Remember Me on the login page
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  loginSubmitted(properties, options) {
    return this.track(new LoginSubmitted(properties), options);
  }

  /**
   * Login View
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Login%20View)
   *
   * The [login page](https://www.bloomberglaw.com/signin) has been viewed
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.loginClickLocation] The location on the page that the login action was taken.
   * @param {string} [properties.loginResponse] Login Response from the login page
   * @param {string} properties.loginType Is the login inline or modal
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} properties.rememberMe Remember Me on the login page
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  loginView(properties, options) {
    return this.track(new LoginView(properties), options);
  }

  /**
   * Multi Factor Authentication Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Multi%20Factor%20Authentication%20Submitted)
   *
   * This event fires when a user submits their multi-factor authentication information during the login process.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.mfaCountDown] This is the current value on the countdown clock
   * @param {string} properties.mfaFailtureReason If there is a failure for the MFA submission, then it should be listed here.
   * @param {boolean} properties.mfaInitialSetup Property has no description in tracking plan.
   * @param {string} [properties.mfaMethod] Can only be email or sms (lowercase)
   * @param {boolean} properties.mfaSuccess Whether the MFA was a success or failure
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  multiFactorAuthenticationSubmitted(properties, options) {
    return this.track(new MultiFactorAuthenticationSubmitted(properties), options);
  }

  /**
   * Multi Factor Authentication Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Multi%20Factor%20Authentication%20Viewed)
   *
   * This event fires when users view the multi-factor authentication page on the login page.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.mfaCountDown] This is the current value on the countdown clock
   * @param {string} properties.mfaFailtureReason If there is a failure for the MFA submission, then it should be listed here.
   * @param {boolean} properties.mfaInitialSetup Property has no description in tracking plan.
   * @param {string} [properties.mfaMethod] Can only be email or sms (lowercase)
   * @param {boolean} properties.mfaSuccess Whether the MFA was a success or failure
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  multiFactorAuthenticationViewed(properties, options) {
    return this.track(new MultiFactorAuthenticationViewed(properties), options);
  }

  /**
   * Nav Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Clicked)
   *
   * Trigger when a user actually uses a navigation (clicks a link to a new page)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.navGroup A high level indicator of which navigation section the clicked item was located in
   * @param {string} properties.navItem The specific item that was clicked: Click Text on a navigational element, or the link that was clicked, or the name of the sub-nav item when used with Sub-Nav_Opened, article title
   * @param {string} [properties.navLocation] Similar to the itemLocation property, this property is used to standardize information related to where on a page a user clicked a navigation link. This disaggregates the location from the group/nav type by explicitly indicating where ont he page the nav item is located (search bar, right rail, etc.).
   * @param {'Primary Nav'|'Sub Nav'|'Content Nav'|'Left Bar'|'Right Bar'|'Anthology'|'Text Link'|'Home Page Module'|'Article Sidebar'|'User Workflow'|'Internal Ad'|'Document Sidebar'|'Table'|'Banner'|'Flyout'|'Results List'|'Carousel'|'URL Shortener'|'Menu Item'} properties.navType A categorization of the navigational elements a descriptive 'where' of the link was clicked or type of menu opened. If I open a sub-nav, it would be 'Sub-Nav', if I click on a link within a sub-nav it would also be 'Sub-Nav'. If I click on a link within or at the bottom of an article it might be Content Nav, or click on a category or tag group on the right hand side, 'Topics'.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  navClicked(properties, options) {
    return this.track(new NavClicked(properties), options);
  }

  /**
   * Nav Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Closed)
   *
   * Trigger when a user closes a navigational menu.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.navGroup A high level indicator of which navigation section the clicked item was located in
   * @param {string} properties.navItem The specific item that was clicked: Click Text on a navigational element, or the link that was clicked, or the name of the sub-nav item when used with Sub-Nav_Opened, article title
   * @param {string} [properties.navLocation] Similar to the itemLocation property, this property is used to standardize information related to where on a page a user clicked a navigation link. This disaggregates the location from the group/nav type by explicitly indicating where ont he page the nav item is located (search bar, right rail, etc.).
   * @param {'Primary Nav'|'Sub Nav'|'Content Nav'|'Left Bar'|'Right Bar'|'Anthology'|'Text Link'|'Home Page Module'|'Article Sidebar'|'User Workflow'|'Internal Ad'|'Document Sidebar'|'Table'|'Banner'|'Flyout'|'Results List'|'Carousel'|'URL Shortener'|'Menu Item'} properties.navType A categorization of the navigational elements a descriptive 'where' of the link was clicked or type of menu opened. If I open a sub-nav, it would be 'Sub-Nav', if I click on a link within a sub-nav it would also be 'Sub-Nav'. If I click on a link within or at the bottom of an article it might be Content Nav, or click on a category or tag group on the right hand side, 'Topics'.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  navClosed(properties, options) {
    return this.track(new NavClosed(properties), options);
  }

  /**
   * Nav Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Opened)
   *
   * Trigger when user opens a navigational menu of some sort, a hamburger, a drop down, etc.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.navGroup A high level indicator of which navigation section the clicked item was located in
   * @param {string} properties.navItem The specific item that was clicked: Click Text on a navigational element, or the link that was clicked, or the name of the sub-nav item when used with Sub-Nav_Opened, article title
   * @param {string} [properties.navLocation] Similar to the itemLocation property, this property is used to standardize information related to where on a page a user clicked a navigation link. This disaggregates the location from the group/nav type by explicitly indicating where ont he page the nav item is located (search bar, right rail, etc.).
   * @param {'Primary Nav'|'Sub Nav'|'Content Nav'|'Left Bar'|'Right Bar'|'Anthology'|'Text Link'|'Home Page Module'|'Article Sidebar'|'User Workflow'|'Internal Ad'|'Document Sidebar'|'Table'|'Banner'|'Flyout'|'Results List'|'Carousel'|'URL Shortener'|'Menu Item'} properties.navType A categorization of the navigational elements a descriptive 'where' of the link was clicked or type of menu opened. If I open a sub-nav, it would be 'Sub-Nav', if I click on a link within a sub-nav it would also be 'Sub-Nav'. If I click on a link within or at the bottom of an article it might be Content Nav, or click on a category or tag group on the right hand side, 'Topics'.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  navOpened(properties, options) {
    return this.track(new NavOpened(properties), options);
  }

  /**
   * Newsletter Form Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Form%20Closed)
   *
   * Newsletter Signup form is closed
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  newsletterFormClosed(properties, options) {
    return this.track(new NewsletterFormClosed(properties), options);
  }

  /**
   * Newsletter Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Form%20Viewed)
   *
   * Trigger when form is viewed to subscribe to newsletters.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  newsletterFormViewed(properties, options) {
    return this.track(new NewsletterFormViewed(properties), options);
  }

  /**
   * Newsletter Signup Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Signup%20Submitted)
   *
   * Submit sign up form for newsletter. This is the form where user enters contact info, not the form where they select which newsletter to subscribe to.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {'free'|'subscriber'} properties.newsletterAccessType Denotes whether a newsletter is free or only available to subscribers
   * @param {string[]} [properties.newslettersSelected] array of articles selected
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  newsletterSignupSubmitted(properties, options) {
    return this.track(new NewsletterSignupSubmitted(properties), options);
  }

  /**
   * Newsletter Signup Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Signup%20Viewed)
   *
   * Viewed the signup form for newsletters. This is the form where the user enters contact info (not the form where they select newsletter subscriptions).
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {'free'|'subscriber'} properties.newsletterAccessType Denotes whether a newsletter is free or only available to subscribers
   * @param {string[]} [properties.newslettersSelected] array of articles selected
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  newsletterSignupViewed(properties, options) {
    return this.track(new NewsletterSignupViewed(properties), options);
  }

  /**
   * Newsletter Subscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Subscribed)
   *
   * User has subscribed to a newsletter(s)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {'free'|'subscriber'} properties.newsletterAccessType Denotes whether a newsletter is free or only available to subscribers
   * @param {string[]} [properties.newslettersSelected] array of articles selected
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  newsletterSubscribed(properties, options) {
    return this.track(new NewsletterSubscribed(properties), options);
  }

  /**
   * Newsletter Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Viewed)
   *
   * This event tracks when a user views a newsletter - This is the "View Online" version of the newsletter for email.
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  newsletterViewed(properties, options) {
    return this.track(new NewsletterViewed(properties), options);
  }

  /**
   * Next Result Navigated
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Next%20Result%20Navigated)
   *
   * Click on the Next Result CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  nextResultNavigated(properties, options) {
    return this.track(new NextResultNavigated(properties), options);
  }

  /**
   * Notice Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Notice%20Viewed)
   *
   * Fires when notice for sanctioned countries is viewed
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.noticeDetails] descriptor that provides context on the event/action the triggered a notice or error event to fire.  Helps differentiate between sources of friction that may be occuring on the same page/event
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  noticeViewed(properties, options) {
    return this.track(new NoticeViewed(properties), options);
  }

  /**
   * Onboarding Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Onboarding%20Submitted)
   *
   * This event fires when a user submits their onboarding information for personalization purposes.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.onboardingFormTitle Property has no description in tracking plan.
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingSubmitted(properties, options) {
    return this.track(new OnboardingSubmitted(properties), options);
  }

  /**
   * Onboarding Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Onboarding%20Viewed)
   *
   * This event fires when a user views the onboarding process for personalized content. i.e., https://www.bloomberglaw.com/onboarding
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.onboardingFormTitle Property has no description in tracking plan.
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingViewed(properties, options) {
    return this.track(new OnboardingViewed(properties), options);
  }

  /**
   * Organization Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Organization%20Viewed)
   *
   * Fires when a user loads a page associated with an organizational entity (company, vendor, agency, court, etc.)
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  organizationViewed(properties, options) {
    return this.track(new OrganizationViewed(properties), options);
  }

  /**
   * Page Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Page%20Modified)
   *
   * Fire when a user takes an action to alter how a page is displayed or laid out. For example choosing between a generic or personalised version.
   *
   * Owner: rcarmichael@vendor.bloombergindustry.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  pageModified(properties, options) {
    return this.track(new PageModified(properties), options);
  }

  /**
   * Personalization Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Personalization%20Modal%20Viewed)
   *
   * User views a personalization modal
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  personalizationModalViewed(properties, options) {
    return this.track(new PersonalizationModalViewed(properties), options);
  }

  /**
   * Play Depth
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Play%20Depth)
   *
   * usually quartiles, the % of the audio or video that has been played
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {'25'|'50'|'75'|'100'} properties.playDepth usually quartiles, the % of the audio or video that has been played
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  playDepth(properties, options) {
    return this.track(new PlayDepth(properties), options);
  }

  /**
   * Pleading Request Authorization
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Pleading%20Request%20Authorization)
   *
   * A [docket entry/pleading](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) has been approved (Accept has been pressed)
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  pleadingRequestAuthorization(properties, options) {
    return this.track(new PleadingRequestAuthorization(properties), options);
  }

  /**
   * Pleading Request Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Pleading%20Request%20Submitted)
   *
   * When a user has accepted and submitted the [docket entry/pleading request](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2)...
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.caseType] Property has no description in tracking plan.
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.court] court name, eg. United States District Court For The Southern District Of Texas
   * @param {string} [properties.courtJurisdiction] state or federal
   * @param {string} [properties.dateFiled] The date the docket entry was filed
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.docketKeyType] complaint, notice, summon
   * @param {string} [properties.docketNumber] The Docket No...  ie... 2:16-cv-11082
   * @param {string} [properties.docketType] Federal, State, and Pleading
   * @param {string} [properties.docketViewer] regular, udv
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {'success'|'failure'} properties.entryRequestStatus When a user has successfully submitted a docket request
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.natureOfSuit] Personal Injury - Other
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.pleadingId] EXT ID number / Entry number
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  pleadingRequestSubmitted(properties, options) {
    return this.track(new PleadingRequestSubmitted(properties), options);
  }

  /**
   * Podcast Episode Played
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Episode%20Played)
   *
   * When the user clicks on the "Play" button for a podcast
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.podcastEpisodeTitle] The title of a podcast eposide.
   * @param {'apple'|'google'|'stitcher'|'spotify'} [properties.podcastPlatform] apple|google|stitcher|spotify
   * @param {string} [properties.podcastSeriesId] ID for the Podcast Series
   * @param {string} [properties.podcastSeriesTitle] The title of a podcast series.
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  podcastEpisodePlayed(properties, options) {
    return this.track(new PodcastEpisodePlayed(properties), options);
  }

  /**
   * Podcast Series Landing Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Landing%20Viewed)
   *
   * Podcast Series Landing View
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.podcastEpisodeTitle] The title of a podcast eposide.
   * @param {'apple'|'google'|'stitcher'|'spotify'} [properties.podcastPlatform] apple|google|stitcher|spotify
   * @param {string} [properties.podcastSeriesId] ID for the Podcast Series
   * @param {string} [properties.podcastSeriesTitle] The title of a podcast series.
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  podcastSeriesLandingViewed(properties, options) {
    return this.track(new PodcastSeriesLandingViewed(properties), options);
  }

  /**
   * Podcast Series Subscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Subscribed)
   *
   * This event tracks when a user subscribes to a specific podcast series
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.podcastEpisodeTitle] The title of a podcast eposide.
   * @param {'apple'|'google'|'stitcher'|'spotify'} [properties.podcastPlatform] apple|google|stitcher|spotify
   * @param {string} [properties.podcastSeriesId] ID for the Podcast Series
   * @param {string} [properties.podcastSeriesTitle] The title of a podcast series.
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  podcastSeriesSubscribed(properties, options) {
    return this.track(new PodcastSeriesSubscribed(properties), options);
  }

  /**
   * Podcast Series Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Viewed)
   *
   * Fire when a user loads a landing page for a specific podcast series.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.podcastEpisodeTitle] The title of a podcast eposide.
   * @param {'apple'|'google'|'stitcher'|'spotify'} [properties.podcastPlatform] apple|google|stitcher|spotify
   * @param {string} [properties.podcastSeriesId] ID for the Podcast Series
   * @param {string} [properties.podcastSeriesTitle] The title of a podcast series.
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  podcastSeriesViewed(properties, options) {
    return this.track(new PodcastSeriesViewed(properties), options);
  }

  /**
   * Practice Tool Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Practice%20Tool%20Used)
   *
   * Tracks the usage of the practice tool, providing insights into how often and in what ways users engage with this tool
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  practiceToolUsed(properties, options) {
    return this.track(new PracticeToolUsed(properties), options);
  }

  /**
   * Previous Result Navigated
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Previous%20Result%20Navigated)
   *
   * Click on the Previous Result CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  previousResultNavigated(properties, options) {
    return this.track(new PreviousResultNavigated(properties), options);
  }

  /**
   * Profile Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Profile%20Viewed)
   *
   * This event tracks when a person or profile is viewed
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.authorId] For News articles, this will be the BWRITE ID.  This is meant to be used uniquely identify a given author.
   * @param {string} [properties.authorType] Determine if the author is an INDG Reporter or an External Contributor.
   * @param {string} [properties.blpBioId] The ID assocaited with the author in BLP.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} [properties.organization] Collect organization information about a specific author.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  profileViewed(properties, options) {
    return this.track(new ProfileViewed(properties), options);
  }

  /**
   * Read Now
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Read%20Now)
   *
   * [Login Page](https://www.bloomberglaw.com/signin)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  readNow(properties, options) {
    return this.track(new ReadNow(properties), options);
  }

  /**
   * Reconciliation Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Reconciliation%20Viewed)
   *
   * This event tracks when a user views a reconciliation in the BTAX Software
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  reconciliationViewed(properties, options) {
    return this.track(new ReconciliationViewed(properties), options);
  }

  /**
   * Registration Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Registration%20Form%20Submitted)
   *
   * This event tracks when a user submits a registration form for sales purposes
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  registrationFormSubmitted(properties, options) {
    return this.track(new RegistrationFormSubmitted(properties), options);
  }

  /**
   * Registration Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Registration%20Form%20Viewed)
   *
   * This event tracks when a user views the registration form for sales purposes
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.licenseRequestedAmount] Number of Licenses Requested
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  registrationFormViewed(properties, options) {
    return this.track(new RegistrationFormViewed(properties), options);
  }

  /**
   * Remember Me
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Remember%20Me)
   *
   * Remember me button for the [login](https://www.bloomberglaw.com/signin)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  rememberMe(properties, options) {
    return this.track(new RememberMe(properties), options);
  }

  /**
   * Report Requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Report%20Requested)
   *
   * Fire when users successfully requested a report
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  reportRequested(properties, options) {
    return this.track(new ReportRequested(properties), options);
  }

  /**
   * Request Demo
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Request%20Demo)
   *
   * Request Demo from [Login Page]()
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  requestDemo(properties, options) {
    return this.track(new RequestDemo(properties), options);
  }

  /**
   * Results Drilldown Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Results%20Drilldown%20Clicked)
   *
   * Fire when users click an embedded link in the tool to drilldown on details
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  resultsDrilldownClicked(properties, options) {
    return this.track(new ResultsDrilldownClicked(properties), options);
  }

  /**
   * Saved for Later
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Saved%20for%20Later)
   *
   * Fires when a user saves content for later
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  savedForLater(properties, options) {
    return this.track(new SavedForLater(properties), options);
  }

  /**
   * Saved Searches Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Saved%20Searches%20Viewed)
   *
   * User views list of saved searches
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  savedSearchesViewed(properties, options) {
    return this.track(new SavedSearchesViewed(properties), options);
  }

  /**
   * Scroll Depth
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Scroll%20Depth)
   *
   * Fire when the user scrolls down any page at 25% increments.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {'25'|'50'|'75'|'100'} properties.pageDepth usually quartiles, the % of the page that has been visible
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  scrollDepth(properties, options) {
    return this.track(new ScrollDepth(properties), options);
  }

  /**
   * Search Export Customized
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Export%20Customized)
   *
   * User customizes their search export
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchExportCustomized(properties, options) {
    return this.track(new SearchExportCustomized(properties), options);
  }

  /**
   * Search Form Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Form%20Closed)
   *
   * When search forms or modals are closed without submitting.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchFormClosed(properties, options) {
    return this.track(new SearchFormClosed(properties), options);
  }

  /**
   * Search Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Form%20Viewed)
   *
   * Fire on any form or modal that serves as a search form or page devoted to initiating search (not search bars embedded in pages like the go bar) and include searchTool to name the specific search form.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchFormViewed(properties, options) {
    return this.track(new SearchFormViewed(properties), options);
  }

  /**
   * Search Result Hidden
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Result%20Hidden)
   *
   * User clicks to hide a search result - ideally includes document id
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchResultHidden(properties, options) {
    return this.track(new SearchResultHidden(properties), options);
  }

  /**
   * Search Results Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Clicked)
   *
   * User clicks through to a document from Search Results.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {'preview'|'click through'|'typeahead'|'search suggestion'|'document export'} [properties.clickThruType] used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {string} [properties.searchResultsBucketContentType] The label of the search results bucket, in BLAW/BTAX this can be the "Content Kind", in BGOV it can be "Legislation".      This is only applied to search buckets and should not be applied to single stream lists.
   * @param {number} [properties.searchResultsBucketIndex] When Search Results on a page are delivered in one or more content sensitve buckets that contain at least one search result, but do not contain the entire set of results for that content.  Then this index will specify the index of "bucket" that the content was in.  For example if there are 5 content buckets on the page, but the link clicked was in the third bucket, then the index will be 3.  When this is set to (none) it should imply there are no content buckets on the page, this can happen on a page that is a single stream.
   * @param {number} [properties.searchResultsIndex] the index of the result that was clicked
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  searchResultsClicked(properties, options) {
    return this.track(new SearchResultsClicked(properties), options);
  }

  /**
   * Search Results Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Exported)
   *
   * User exports/downloads Search Results or When the user tries to export content from a [dockets search result]()...
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchResultsExported(properties, options) {
    return this.track(new SearchResultsExported(properties), options);
  }

  /**
   * Search Results Navigated
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Navigated)
   *
   * Click on the Search Results CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchResultsNavigated(properties, options) {
    return this.track(new SearchResultsNavigated(properties), options);
  }

  /**
   * Search Results Sorted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Sorted)
   *
   * User sorts their search from the search results page
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchResultsSorted(properties, options) {
    return this.track(new SearchResultsSorted(properties), options);
  }

  /**
   * Search Results Unhidden
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Unhidden)
   *
   * User clicks to unhide a search result - ideally includes document id
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchResultsUnhidden(properties, options) {
    return this.track(new SearchResultsUnhidden(properties), options);
  }

  /**
   * Search Results Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Viewed)
   *
   * Any load of search results on a search results page.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {boolean} [properties.docketIntent] When a docket number is recognized in the go bar... This is a boolean value.   The result would with the [docket intent box](https://www.bloomberglaw.com/product/blaw/search/results/c0c174f8dff6f509234c8e9c29d76e00?bc=W1siQmxvb21iZXJnIExhdyIsIi9wcm9kdWN0L2JsYXcvbm90aWZpY2F0aW9ucy9pdGVtcy9SRVNFQVJDSF9UUkFJTD9jb3VudD01MCZ0eXBlPVNFQVJDSCJdXQ--078704ad3141fd6e9876a60965ff7104e97c9377) showing on top of the search results.
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchArticleType] This is meant exclusively for INDG News articles
   * @param {string} [properties.searchDateRange] This call be all dates, to the range of one or more dates
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {number} [properties.searchResultsPage] the search results page number where an action occurred
   * @param {number} [properties.searchResultsVisible] Numeric indicator of how many search results are visible on a page.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {'date'|'relevance'|'court'|'most cited'|'table of contents'} [properties.searchSortBy] Sort by Date or Relevance
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchResultsViewed(properties, options) {
    return this.track(new SearchResultsViewed(properties), options);
  }

  /**
   * Search Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Saved)
   *
   * User saves search
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {number} properties.resultsCount count of search results
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchSaved(properties, options) {
    return this.track(new SearchSaved(properties), options);
  }

  /**
   * Search Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Shared)
   *
   * User clicks 'Share' (or email icon) above search results and copies the share link provided, or submits email recipients for the share. (use method)
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.resultContentKinds] Comma delimited list of content types included in the search results
   * @param {number} properties.resultsCount count of search results
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} [properties.searchResponseType] name of search model used indicates whether results will be presented in 'buckets' or a list of documents
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchShared(properties, options) {
    return this.track(new SearchShared(properties), options);
  }

  /**
   * Search Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Submitted)
   *
   * Fire when user submits a keyword search or selects/modifies filters or otherwise takes an action to load new search results.
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {boolean} [properties.docketIntent] When a docket number is recognized in the go bar... This is a boolean value.   The result would with the [docket intent box](https://www.bloomberglaw.com/product/blaw/search/results/c0c174f8dff6f509234c8e9c29d76e00?bc=W1siQmxvb21iZXJnIExhdyIsIi9wcm9kdWN0L2JsYXcvbm90aWZpY2F0aW9ucy9pdGVtcy9SRVNFQVJDSF9UUkFJTD9jb3VudD01MCZ0eXBlPVNFQVJDSCJdXQ--078704ad3141fd6e9876a60965ff7104e97c9377) showing on top of the search results.
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string[]} [properties.previousFilters] array of the filters and keywords of the previous query. Used to help in analyzing and understanding the specific keyword or filter changes made when search action = refine.
   * @param {string[]} [properties.previousKeywords] stores the keywords from the previous search query.    In instances where the functional 'keywords' may be different than the visible keywords (e.g. with BGOV inferred filters), store the functional keywords.
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.searchAction] initial' search is the submission of a search (or arrival at search results via a link) via a search tool.   'refine' is the addition of filters, or selection of available refinements/filters, or clicking 'view all' in a bucketed search result set, once search results are shown (search id exists)   'edit' is initiated only in certain areas where a user can click to 'edit' or 'modify' their search using a search tool.
   * @param {string[]} [properties.searchFilters] Array of all filter selections applied to the search results.
   * @param {string} [properties.searchId] The ID for the current search
   * @param {string} [properties.searchModel] Version of the search model. Useful for monitoring performance of models underlying searches.
   * @param {string} properties.searchScope The selected 'scope' of the search - high level. Indicates what type of documents will be yielded in the search results.  example: dockets
   * @param {boolean} [properties.searchSuggestionAvailable] Indicates whether there were any autosuggesions available (whether or not they were selected).
   * @param {string[]} [properties.searchSuggestionModified] array of modifications made to a search suggestion or AI supported search. Adds additional detail to 'search action = refined' that highlights the specific type of refinement.    sample values:  * keyword edited  * inferred filter added  * inferred filter removed
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {'go bar'|'global'|'docket'|'filter'|'curated'|'direct link'|'navigation'|'news takeover'|'historical spend'|'state legislation'|'feature search'|'search all'|'search widget'|'terminal news'} properties.searchTool \*\*Indicates which search tool (which embedded search bar, modal, or other type of form, or what type of link directly to search results)was used to initiate, refine/filter, or edit the search.
   * @param {string} properties.searchType Indicates the method the user is using to initiate search or modify results (regardless of which tool they used to search), searching by keyword, selecting an autosuggest phrase or a document suggestion, using filters, or clicked 'view all' in a container of results. Sometimes the balue will be the same as searchTool, but the purpose is to distinguish different ways of searching using the same tool.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.toolFeature] facet/aspect of a tool that was used
   * @param {string} [properties.toolName] name of the tool
   * @param {string} [properties.toolScope] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  searchSubmitted(properties, options) {
    return this.track(new SearchSubmitted(properties), options);
  }

  /**
   * Selections Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Selections%20Saved)
   *
   * Fire when the user has saved 1 or more sets of selections. 
   *  Example: user selects a set of Jurisdictions in Chart Builder and then saves them for later use.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  selectionsSaved(properties, options) {
    return this.track(new SelectionsSaved(properties), options);
  }

  /**
   * Set as Home
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Set%20as%20Home)
   *
   * User clicks set as home to make a feature or section page their home page
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  setAsHome(properties, options) {
    return this.track(new SetAsHome(properties), options);
  }

  /**
   * Sidebar Tab Open
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Sidebar%20Tab%20Open)
   *
   * The sidebar tab within a document
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {boolean} properties.sidebarIsOpen Property has no description in tracking plan.
   * @param {string} properties.sidebarItem Property has no description in tracking plan.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  sidebarTabOpen(properties, options) {
    return this.track(new SidebarTabOpen(properties), options);
  }

  /**
   * Subscriber Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Subscriber%20Added)
   *
   * First login of a subscriber
   *
   * Owner: dylan.blass-svedvik@searchdiscovery.com
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  subscriberAdded(properties, options) {
    return this.track(new SubscriberAdded(properties), options);
  }

  /**
   * Table Results Formatted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Table%20Results%20Formatted)
   *
   * Fire when users use table field options to update pivot table results, such as adding, removing, reordering, resetting fields or applying table limits
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.chartGroup] The grouping of chart associated with the Chart Builder or Chart itself, note that not every chart will be part of a chart grouping.
   * @param {number} [properties.chartId] Numeric value of chart ID
   * @param {string[]} [properties.chartJurisdictions] List of selected jurisdictions when Chart Builder is submitted or Chart is exported
   * @param {'Filter Chart'|'Update Date Range'|'Update Chart Type'|'Add Chart Options'|'Remove Chart Options'|'Modify Column Header'|'Column Header - Topics'|'Column Header - Jurisdictions'|'Answer - short'|'Answer - summary'|'Answer - full'} [properties.chartModifyAction] Granular action taken to modify chart
   * @param {string[]} [properties.chartOptions] Array of all chart options to reflect current state of chart such as column headers, 'answer': short vs long answer 'show changes' :(date range)
   * @param {string} [properties.chartStatus] Indicates whether the chart being viewed was just created, just modified, or was previously saved.  Example: new, modified, saved
   * @param {string[]} [properties.chartTopics] List of selected topics when Chart Builder is submitted or Chart is exported
   * @param {string} [properties.chartType] The type of chart associated with the Chart Builder or Chart itself  Chart Builder example: pass-through entity, excise tax  Historical Spend example: Line
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {'Add Table Fields'|'Remove Table Fields'|'Reorder Table Fields'|'Reset Table Fields'|'Set Table Limits'} [properties.tableModifyAction] How was the table modified
   * @param {string[]} [properties.tableOptions] Array of table fields
   * @param {number} [properties.tableOptionsCount] Count of options selected to build chart
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  tableResultsFormatted(properties, options) {
    return this.track(new TableResultsFormatted(properties), options);
  }

  /**
   * Tag Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tag%20Added)
   *
   * This event tracks when a tag is added to a workspace
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} [properties.annotationDetails] Specific details regarding the annontation, such as category or type.
   * @param {string[]} properties.annotationStatus Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   * @param {string[]} properties.annotationType Array stores values to indicate if annotation action includes multiple types of annotationType   Possible values include:  * bulk  * shared  * private  * ...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {'preview'|'click through'|'typeahead'|'search suggestion'|'document export'} [properties.clickThruType] used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.searchResultsBucketContentType] The label of the search results bucket, in BLAW/BTAX this can be the "Content Kind", in BGOV it can be "Legislation".      This is only applied to search buckets and should not be applied to single stream lists.
   * @param {number} [properties.searchResultsBucketIndex] When Search Results on a page are delivered in one or more content sensitve buckets that contain at least one search result, but do not contain the entire set of results for that content.  Then this index will specify the index of "bucket" that the content was in.  For example if there are 5 content buckets on the page, but the link clicked was in the third bucket, then the index will be 3.  When this is set to (none) it should imply there are no content buckets on the page, this can happen on a page that is a single stream.
   * @param {number} [properties.searchResultsIndex] the index of the result that was clicked
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  tagAdded(properties, options) {
    return this.track(new TagAdded(properties), options);
  }

  /**
   * Tag Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tag%20Deleted)
   *
   * This event is triggered when a tag is deleted. It provides information on allowing for analysis of tag management and usage patterns
   *
   * Owner: Milad Amin
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} [properties.annotationDetails] Specific details regarding the annontation, such as category or type.
   * @param {string[]} properties.annotationStatus Array stores values to indicate of notes, tags, comments, etc. included in an annotation action are new or existing or both.
   * @param {string[]} properties.annotationType Array stores values to indicate if annotation action includes multiple types of annotationType   Possible values include:  * bulk  * shared  * private  * ...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.articleId] This is the BWRITE Article ID
   * @param {string} [properties.articleType] This should be the BWRITE Article Type  * Analysis  * Insight  * News  * etc...
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.niCodes] Stores niCode tags for BGOV news content. niCodes are used by BGOV/terminal to classify content (e.g. health, energy, etc.)
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.source] the name of the publication that the article was published
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string[]} [properties.subscriptionIds] The IDs for the different subscriptions contained in the email, especially if this is a "roll-up" type of email.   NI Codes for BGOV Content
   * @param {string} properties.url full url for the page
   * @param {string} [properties.wire] the feed on which the article was published
   * @param {EventOptions} [options] Options for this track call.
   */
  tagDeleted(properties, options) {
    return this.track(new TagDeleted(properties), options);
  }

  /**
   * TOC Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Exported)
   *
   * Fires when a user exports a Table of Contents
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  tocExported(properties, options) {
    return this.track(new TocExported(properties), options);
  }

  /**
   * TOC Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Shared)
   *
   * Fires when a user shares a Table of Contents
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  tocShared(properties, options) {
    return this.track(new TocShared(properties), options);
  }

  /**
   * TOC Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Viewed)
   *
   * Fires when a user views a Table of Contents
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  tocViewed(properties, options) {
    return this.track(new TocViewed(properties), options);
  }

  /**
   * Tour Ended
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tour%20Ended)
   *
   * This event signifies the completion of the product onboarding tour, indicating that a user has finished the guided tour designed to familiarize them with the product's features and functionality
   *
   * Owner: Kyle Contrata
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code
   * @param {number} [properties.tourDepth] Indicates the % completion of a tour. Should 100% when tourStatus = Completed otherwise should be a percentage between 0 and 1 if tourStatus = Exited
   * @param {string} properties.tourName Describes the feature the tour is associated with. E.g. State Legislation Search Reinvent
   * @param {'Auto Start'|'User Start'} [properties.tourStatus] Differentiates whether a tour was prompted automatically or via a user clicking some variation of 'tour'
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  tourEnded(properties, options) {
    return this.track(new TourEnded(properties), options);
  }

  /**
   * Tour Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tour%20Viewed)
   *
   * This event tracks when a user views a tour during the product onboarding process
   *
   * Owner: Kyle Contrata
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {number} [properties.tourDepth] Indicates the % completion of a tour. Should 100% when tourStatus = Completed otherwise should be a percentage between 0 and 1 if tourStatus = Exited
   * @param {string} properties.tourName Describes the feature the tour is associated with. E.g. State Legislation Search Reinvent
   * @param {'Auto Start'|'User Start'} [properties.tourStatus] Differentiates whether a tour was prompted automatically or via a user clicking some variation of 'tour'
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  tourViewed(properties, options) {
    return this.track(new TourViewed(properties), options);
  }

  /**
   * Track Alert Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Created)
   *
   * This event tracks the creation of a new alert in the Alert Management system.  A Track Alert tracks changes to a document, such as a Bill.  Note that Dockets have their own specific event called Docket Track Created.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {number} [properties.actionCount] Stores a numeric value that indicates the number of actions/events that occurred relative to a given action. For example, it can be used to store the number of filter interactions that took place between the start of a search and the eventual clickthru related to that search.
   * @param {string} [properties.actionDetail] describes the action(s) that are being tabulated via the actionCount property. For example, it could be 'filter interactions' if we are analyzing the number of filter interactions that occur between the start of a search and the eventual clickthru.
   * @param {string} [properties.agency] stores the govt agency or private entity associated with a document.
   * @param {string} [properties.agencyId] Identifying Key for agency or other govt/private entity
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {'find'|'track'|'news'|'grouped'|'docket'|'bill citation reff'|'bna news'|'citation ref'|'deal maker'|'docket track'|'edgar'|'generic law'|'generic news'|'leg watch'|'opinion'|'patent'|'patent assignment'|'reg watch'|'tax'} [properties.alertClass] Differentiates between a track, find, News, or grouped alert.
   * @param {string} properties.alertType The "type" of alert, "News"/"Docket"/"Legal"/"Docket"/etc...
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} [properties.bill] Bill Number
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {'preview'|'click through'|'typeahead'|'search suggestion'|'document export'} [properties.clickThruType] used to differentiate between a click on a result the populates a preview of the result or a full click through the pulls up the document's full details.
   * @param {boolean} [properties.clientMatterExists] Is there anything listed in the "Client Matter" box?
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.congressSession] Federal Congress Number or State Session Name
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayFormat] Email Format "Detailed" or ""Tabular"  Alerts
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {number} properties.distributedCount The number of recipients added to an email (including the sender)... This number could be 0.
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {string} [properties.frequency] How often should the alert/docket track be sent
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {string} [properties.itemClicked] This should be the label of the specific item clicked
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string} [properties.itemGroup] Similar to the navGroup property, this property is used to provide content-context to click actions. For example, if a subsection of a document contains interactive clicks (like to load more items in a list) and navigation events (like a link to another, related entity type) we store the subsection title.
   * @param {string} [properties.itemLocation] Region on the page  where the click event occurs
   * @param {string} [properties.itemType] This should be used to determine if it was:  * Advertisement  * Request Demo  * Informational  * Help  * Qualtrics  * etc...
   * @param {string} [properties.iterationId] unique identifier used in conjuction with actionCount and actionDetail. Used to connect a series of actions together for analysis.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.keywords] Keywords used to generate the search, this could be zero or more items
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string[]} [properties.options] array of options
   * @param {string[]} [properties.originalRecipientId] An ID for the user who recieved the email.  This should only be needed for BLAW/BTAX Alert.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.searchResultsBucketContentType] The label of the search results bucket, in BLAW/BTAX this can be the "Content Kind", in BGOV it can be "Legislation".      This is only applied to search buckets and should not be applied to single stream lists.
   * @param {number} [properties.searchResultsBucketIndex] When Search Results on a page are delivered in one or more content sensitve buckets that contain at least one search result, but do not contain the entire set of results for that content.  Then this index will specify the index of "bucket" that the content was in.  For example if there are 5 content buckets on the page, but the link clicked was in the third bucket, then the index will be 3.  When this is set to (none) it should imply there are no content buckets on the page, this can happen on a page that is a single stream.
   * @param {number} [properties.searchResultsIndex] the index of the result that was clicked
   * @param {boolean} properties.searchTermsExist Did someone use a keyword
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} properties.senderId The ID of the original subscriber of the email.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} [properties.ssid] Alert ID - Assuming this is available when the alert has been created, ideally we'd see this from alert creation to alert click through.
   * @param {string} [properties.stateName] Full state name (e.g. Rhode Island or Utah)
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  trackAlertCreated(properties, options) {
    return this.track(new TrackAlertCreated(properties), options);
  }

  /**
   * Track Alert Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Deleted)
   *
   * When a user deletes a tracked alert
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  trackAlertDeleted(properties, options) {
    return this.track(new TrackAlertDeleted(properties), options);
  }

  /**
   * Track Alert Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Modified)
   *
   * When a user modifies a track alert
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string[]} [properties.options] array of options
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  trackAlertModified(properties, options) {
    return this.track(new TrackAlertModified(properties), options);
  }

  /**
   * Upsell Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Upsell%20Viewed)
   *
   * This event fires when a user views an upsell offer (page or modal), regardless of where it occurs within the application. The Upsell page or Modal will be shown to users trying to click/access a part of the application that they do not have access to.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.upsellContentName This should determine the name of the content being accessed.
   * @param {string} properties.upsellContentType This should  determine what type of content is being accessed.  Sample values would be:  \* Document  \* Practice Area  \* Tool
   * @param {string} properties.upsellContentUrl This should be the full URL of the content the user is trying to access.
   * @param {'Modal'|'Page'} properties.upsellType This is a controlled list of the types of Upsells. Currently, it should either be Modal or Page.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  upsellViewed(properties, options) {
    return this.track(new UpsellViewed(properties), options);
  }

  /**
   * User Workflow Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/User%20Workflow%20Exported)
   *
   * This event fires when the user downloads/exports Workflow Content and clicks on Excel Export, Download, etc.
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentGroup] High level grouping of content types  * Legislation  * Opportunities  * Court Materials  * Articles  * etc.
   * @param {string} [properties.contentKind] The categorization of the type of document.  * Docket  * Task Order  * Solicitation  * etc.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.documentCollection] Represents name/title of the specific collection of documents that is assigned to a source tree node; may contain publisher info
   * @param {string} [properties.documentId] String that uniquely identifies each document.
   * @param {string} [properties.documentIdLong] Longer version of the BLAW documentId (which usually starts with an "X"), this one starts with a "Y"
   * @param {'search results'|'search auto suggest'|'navigation'|'direct link'|'User Workflow'|'First Draft'|'ai click'} [properties.documentViewSource] Document way finding method. (e.g. search results, search auto suggest, navigation, direct link)
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {number} [properties.downloadSize] Stores the size of the item downloaded in KB
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {string[]} [properties.exportOptions] Stores and array of selected column headers to be included in downloads (typically of CSVs/Excel files)
   * @param {*} [properties.federalCircuit] The Circuit Court   **Examples**:  * D.C. Circuit Courts  * U.S. Supreme Court  * Federal Circuit Courts  * First Circuit Courts  * Second Circuit Courts  * Third Circuit Courts  * Fourth Circuit Courts  * Fifth Circuit Courts  * Sixth Circuit Courts  * Seventh Circuit Courts  * Eighth Circuit Courts  * Ninth Circuit Courts  * Tenth Circuit Courts  * Eleventh Circuit Courts  * Other Federal Courts
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} [properties.method] Method is used across many event types to indicate usually a key option associated with that action such as the file type in the case of an export, or the choice of share link or email when sharing a document. Also used to identify the auth method for user logins.   Sample values: pdf, csv, share link, email, print
   * @param {number} [properties.nodeId] Property has no description in tracking plan.
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {string} [properties.publisher] The publisher for a content collection
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.secondaryDocumentId] Used to identify the secondary document ID for documents in a document compare event.
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  userWorkflowExported(properties, options) {
    return this.track(new UserWorkflowExported(properties), options);
  }

  /**
   * User Workflow Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/User%20Workflow%20Viewed)
   *
   * This event fires when visitors engage with User Workflow items such as Research Trai, Alert Management, and Download Center at the top of BLAW/BTAX and the top right corner of BGOV (after clicking the hamburger menu).
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {string} properties.url full url for the page
   * @param {string} properties.workFlowName Research Trail, Download Center, Alert Management etc
   * @param {EventOptions} [options] Options for this track call.
   */
  userWorkflowViewed(properties, options) {
    return this.track(new UserWorkflowViewed(properties), options);
  }

  /**
   * Workflow Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Workflow%20Viewed)
   *
   * This event tracks when a user views a workflow in the BTAX Software
   *
   * Owner: Philip Ramsey
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} [properties.aiEnabled] Indicates if a given action is related to an ai-powered tool or feature
   * @param {string} properties.appName The name of the Analytics app that's making the call
   * @param {string} [properties.appRole] The user's role in the app firing the event, since the interface may change based on this role, this is an event based role.      The user roles will be provided by ECDS in their payload, they should not be modified to be something other than ECDS provides.
   * @param {string} properties.appVersion The build version for the Analytics portion of the app... Should be a value that is incremental and can allow easy comparison. Will be used to slice data to show how changes in the analytics package affect overall reporting.
   * @param {string} properties.brand BLAW/BTAX/BGOVGA/BGOVGC/INDG
   * @param {string} [properties.channel] News Channel Code  * Daily Tax Report  * Crypto  * Business & Practice
   * @param {string} [properties.codeBase] Property has no description in tracking plan.
   * @param {string} [properties.contentLanguage] The two letter acronym for the display language of the content the user is working with, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} [properties.displayLanguage] The two letter acronym for the display language of the application being used, these can specify the region after the language...  examples are: "en-US", "en-GB", "fr-FR", "es-ES", etc...  https://confluence.bna.com/display/UAT/Language+Region+Lookup
   * @param {string} properties.domain current domain for the page  * bloomberglaw.com  * bloombergtax.com  * bloombergindustry.com  * bgov.com  * bna.com  for external links, the domain where the link is resolving, for example, links to the Washington Post would be 'washingtonpost.com'.
   * @param {string[]} [properties.eventDetails] this property should be used to collect any extra properties that will be used for either machine learning or extended information.  This is an array of strings and is optional.
   * @param {number} properties.httpStatusCode This is the status code that the server returns, examples:  * 200 - Normal  * 404 - Page Not Found  * 500 - Server Error
   * @param {boolean} properties.isLoggedIn Is the user currently logged in (Boolean)
   * @param {boolean} [properties.itemFavorited] This is to be used when visiting a page or item that a user has previously favorited.  This property is used to determine the ongoing usage of "favorited" content by the user.
   * @param {string[]} [properties.jurisdiction] This is used to capture the Jurisdiction values when available
   * @param {string} properties.pageTitle Page Title
   * @param {string} properties.pageType Page Type, Generally, Landing, Brand Landing, Article, etc...
   * @param {string} [properties.pageVersion] Used to denote a subtype or version of a page, specifically when the page, such as a homepage may be personalized or undergoing testing.  * Personalized Homepage
   * @param {string} properties.platform INDG Platform  BLAW/News/BTAX/BGOV/BTT/Marketing/Internal/ECDS/BTAX Software/ BTAX Research
   * @param {string} [properties.previousPage] Property has no description in tracking plan.
   * @param {string} [properties.product] Vertical Product Code   For BGOV - high level product section identifier (State Legislation, Congressional Directories, etc.)
   * @param {number} [properties.quantity] Used to indicate the number of entities associated with a bulk action (bulk tags, emails with more than one subscriber, etc.)   In the case of a single entity being associated, then the quantity should be 1
   * @param {string} [properties.sortedBy] Property has no description in tracking plan.
   * @param {number} [properties.totalWorkflows] total number of workflows on a page (BTAX Software)
   * @param {string} properties.url full url for the page
   * @param {EventOptions} [options] Options for this track call.
   */
  workflowViewed(properties, options) {
    return this.track(new WorkflowViewed(properties), options);
  }
}

export const ampli = new Ampli();
