import { getProductInfo } from './auth';

export function getStoredProductCodes() {
  const products = getProductInfo();
  if (!products) return null;

  return products;
}

export function getWKPProductCodes() {
  const products = getStoredProductCodes();

  if (!products) return null;

  let product;
  const productCodes = products['WKPP'];
  delete productCodes['packages'];

  for (const productCode in productCodes) {
    const editionCodes = productCodes[productCode];

    for (const editionCode in editionCodes) {
      product = {
        name: 'WKPP',
        productCode,
        editionCode,
      };
      break;
    }
    break;
  }

  if (!product) return null;

  return product;
}

export function getProductCodes() {
  const productCodes = getStoredProductCodes();

  if (!productCodes) return null;

  const products = Object.values(productCodes).flatMap(editionCodes =>
    Object.keys(editionCodes).filter(editionCode => editionCode !== 'packages')
  );

  return [...new Set(products)];
}
