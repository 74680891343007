import { BTForm } from '@btas/jasper';
import iconImage from '../elementType/icons/ai_sparkles_blue.svg';
import './styles.scss';

const AILabModal = ({ blockType, expressionGenerationHandler, isAIGenerated }) => {
  const textDescription = blockType === 'formula' ? 'formula' : 'filter criteria';
  return (
    <BTForm.FormGroup>
      <div>
        {isAIGenerated && (
          <p className="expression-generator-summary-disclaimer">
            The Bloomberg Tax AI Expression Generator is experimental in nature, and users are strongly advised to
            review the outputs to ensure the desired result has been achieved.
          </p>
        )}
      </div>
      <div className="wkp-ai-gradient-container">
        <div className="wkp-ai-gradient"></div>
        <div className="wkp-ai-modal-container">
          {!isAIGenerated && (
            <p>
              <b>{`Unsure how to write your ${textDescription}?`}</b> Describe it and we will automatically generate it
              for you.
            </p>
          )}
          {isAIGenerated && <p className="wkp-ai-describe-alt-text">Not what you were looking for?</p>}
          <img alt="AI Sparkles Icon" src={iconImage} />
          <p
            className="wkp-ai-describe-button"
            onClick={expressionGenerationHandler}
          >{`Describe ${isAIGenerated ? 'again' : textDescription}`}</p>
        </div>
      </div>
    </BTForm.FormGroup>
  );
};
export default AILabModal;
