export const fileExtensionToLowerCase = fileName => {
  if (!fileName) return '';

  const lastDotIndex = fileName.lastIndexOf('.');

  if (lastDotIndex === -1) return fileName;

  const baseName = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex + 1).toLowerCase();

  return `${baseName}.${extension}`;
};
