import { DEFAULT_HTTP_REQUEST_RETRIES } from '../../../../../configs/params';
import GC from '../../../../../SpreadSheets';
import CustomLogger from '../../../../_shared/Logger/CustomLogger';
export const momentDateFormats = [
  'MM-DD-YYYY',
  'M-D-YYYY',
  'MM-D-YYYY',
  'M-DD-YYYY',
  'MM/DD/YYYY',
  'M/D/YYYY',
  'MM/D/YYYY',
  'M/DD/YYYY',
  'YYYY-MM-DD',
  'YYYY-M-D',
  'YYYY-M-DD',
  'YYYY-MM-D',
  'YYYY/MM/DD',
  'YYYY/MM/D',
  'YYYY/M/DD',
  'YYYY/M/D',
  'ddd MMM DD YYYY HH:mm:ss',
];

export const operatorMap = {};
for (const [operatorString, operatorType] of Object.entries(GC.Spread.CalcEngine.preOperaterTypesMap)) {
  operatorMap[operatorType] = operatorString;
}

for (const [operatorString, operatorType] of Object.entries(GC.Spread.CalcEngine.operaterTypesMap)) {
  operatorMap[operatorType] = operatorString;
}

export async function httpRequestWithRetry(callback) {
  let attempt = 0;
  const retries = Number(DEFAULT_HTTP_REQUEST_RETRIES);
  while (attempt < retries) {
    try {
      const response = await callback();
      if ([400, 404, 409, 500].includes(response.status) || response.ok) return response;
      throw new Error(`Request failed with status: ${response.status}`);
    } catch (error) {
      attempt++;
      CustomLogger.pushLog(CustomLogger.operations.HTTP_REQUEST, {
        error: attempt < retries ? 'Retrying request Error:' : 'Max retries reached Error:' + error.message,
        attempt,
      });
      if (attempt >= retries) return null;
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
}
