import * as fetch from '../../../_shared/fetch';
import { generalErrorMessage } from '../../../_shared/messages';
import { dataFlowServiceHostName, spreadsheetServiceHostName } from '../../../../configs/params';

export async function getWorkbookMetadata(id) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/metadata/${id}`;
  const response = await fetch.get(url);
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function getMetadata() {
  const response = await fetch.get(`${spreadsheetServiceHostName}/spreadsheet/metadata`);
  return response.json();
}

export async function getDataFlowTaxPeriods() {
  const response = await fetch.get(`${dataFlowServiceHostName}/api/v1/data-flows/tax-periods`);
  return response.json();
}

export async function workpaperNameAndTaxPeriodExist(workpaperName, taxPeriod) {
  const encodedWorkpaperName = encodeURIComponent(workpaperName);
  const encodedTaxPeriod = encodeURIComponent(taxPeriod);
  const url = `${spreadsheetServiceHostName}/spreadsheet/workpapers/${encodedWorkpaperName}/tax-period-exist/${encodedTaxPeriod}`;
  const response = await fetch.get(url);
  return response.json();
}

export async function rollforwardWorkpaper(id, taxPeriod, jobId, currentTaxPeriod, userInfo) {
  try {
    const response = await fetch.post(`${spreadsheetServiceHostName}/spreadsheet/rollforward`, {
      body: JSON.stringify({
        workpaperId: id,
        userJobId: jobId,
        taxPeriod,
        currentTaxPeriod,
        userInfo,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw data.error;
    }
    return data;
  } catch (error) {
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: generalErrorMessage }];
    throw mappedError;
  }
}

export async function validateDataflowsExistence({ ids, taxPeriod }) {
  try {
    const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows/validate-existence`, {
      body: JSON.stringify({ ids, taxPeriod }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw data.error;
    }

    return data;
  } catch (error) {
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: generalErrorMessage }];
    throw mappedError;
  }
}
