const MessageType = {
  RenderCustomCell: 'Render Custom Cell',
  LoadWorkbookDataReferences: 'Load Workbook Data Reference',
  ReloadTabOnly: 'Reload Tab Only',
  CellPosition: 'Cell Position',
  CellTag: 'Cell Tag',
  CutPasteReference: 'Cut Paste Data Reference',
  CellValueChanged: 'Cell Value Changed',
  resolveDataLinks: 'Resolve Data Links',
};
export default MessageType;
