import { useRef, useState } from 'react';

function updateUndoStack(undoManager, historyTracker) {
  const undoStack = undoManager.getUndoStack();

  if (undoStack.length) {
    undoStack.forEach(stackItem => {
      const { cmd, sheetName, row, col, sheetArea, newValue } = stackItem;
      if (
        !historyTracker.current.some(
          record =>
            record.cmd === cmd &&
            record.sheetName === sheetName &&
            record.row === row &&
            record.col === col &&
            record.sheetArea === sheetArea &&
            record.newValue === newValue
        )
      ) {
        historyTracker.current.push(stackItem);
      }
    });
  }
}

export const CELL_REVIEW_ADDED = 'CELL_REVIEW_ADDED';
export const CELL_REVIEW_REMOVED = 'CELL_REVIEW_REMOVED';
export const CELL_REVIEW_COMMAND_DELETE = 'CELL_REVIEW_COMMAND_DELETE';
export const DATA_LINK_ADDED = 'DATA_LINK_ADDED';

function useHistoryTracker(cellReviewFunctionManager) {
  const [workpaperId, setWorkpaperId] = useState();
  const { deleteQueue, processDeleteQueue, enqueueCellReviews, processCellReviewQueue } =
    cellReviewFunctionManager.current;
  const historyTracker = useRef([]);
  const addToHistory = (action, spread) => {
    updateUndoStack(spread.undoManager(), historyTracker);
    historyTracker.current.push(action);
  };

  const defaultUndoSpreadJsAction = spread => {
    const undoManager = spread.undoManager();
    const undoStack = undoManager.getUndoStack();
    if (undoStack.length) {
      undoManager.undo();
    }
  };

  const applyUndoCallback = action => action.undo();
  const undoRemovedCellReviews = (spread, latestAction, isUndoDeleteCommandAction = false) => {
    const cellReviewsToAdd = latestAction.data.map(cellReview => ({
      ...cellReview,
      parameters: cellReview?.parameters ? JSON.parse(cellReview.parameters) : '{}',
    }));
    enqueueCellReviews(cellReviewsToAdd, isUndoDeleteCommandAction);
    processCellReviewQueue(spread, spread.getActiveSheet(), null);
  };

  const undoAction = spread => {
    updateUndoStack(spread.undoManager(), historyTracker);
    const latestAction = historyTracker.current.pop();
    if (!latestAction) {
      defaultUndoSpreadJsAction(spread);
      return;
    }
    switch (latestAction.cmd) {
      case CELL_REVIEW_ADDED:
        const idsToDelete = latestAction.data.map(({ id }) => id);
        deleteQueue.current = idsToDelete;
        processDeleteQueue(workpaperId, () => spread.getActiveSheet().repaint());
        break;
      case CELL_REVIEW_REMOVED:
        undoRemovedCellReviews(spread, latestAction);
        break;
      case CELL_REVIEW_COMMAND_DELETE:
        undoRemovedCellReviews(spread, latestAction, true);
        defaultUndoSpreadJsAction(spread);
        break;
      case DATA_LINK_ADDED:
        applyUndoCallback(latestAction);
        break;
      default:
        defaultUndoSpreadJsAction(spread);
        break;
    }
  };

  const setWorkpaperIdForHistoryTracker = workpaperId => setWorkpaperId(workpaperId);

  return { addToHistory, undoAction, historyTracker, setWorkpaperIdForHistoryTracker };
}

export default useHistoryTracker;
