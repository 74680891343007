import { getDataFlowByOutputId } from '../../SideBar/ConnectionPanel/apis';
import { isSourceDataFormulaMatch } from './../formulas';

export function createGoToSourceDataFlowCommand({ workpaperId, history }) {
  const goToSourceDataFlowCommand = {
    text: 'Go to Data Connect workflow',
    commandName: 'wkp.goToSourceDataFlow',
    visibleContext: '!CellLocked || !IsProtected',
    async execute(context, _, contextMenuArgs) {
      const spread = context.Spread;
      let activeCol, activeRow, sheet;

      if (contextMenuArgs) {
        activeCol = contextMenuArgs.activeCol;
        activeRow = contextMenuArgs.activeRow;
        sheet = spread.getSheetFromName(contextMenuArgs.sheetName);
      } else {
        sheet = spread.getActiveSheet();
        activeCol = sheet.getActiveColumnIndex();
        activeRow = sheet.getActiveRowIndex();
      }

      const formula = sheet.getFormula(activeRow, activeCol);

      if (!formula) {
        return false;
      }

      const match = isSourceDataFormulaMatch(formula);

      if (!match) {
        return false;
      }

      const { outputId } = match.groups;
      const dataFlow = await getDataFlowByOutputId(outputId);

      if (!dataFlow) {
        return false;
      }

      setTimeout(() => {
        history.push(
          `/data-flows/${dataFlow.dataFlowId}/editor?activeElement=${outputId}&sourceWorkpaper=${workpaperId}`
        );
      });

      return true;
    },
    iconClass: 'wkp-spread-go-to-source',
    workArea: 'viewport',
  };

  return {
    'wkp.goToSourceDataFlow': goToSourceDataFlowCommand,
  };
}
