import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Frame from '../_shared/Frame';
import Spinner from '../_shared/Spinner';
import { useDataFlow } from './DataFlowEditorPage/useDataFlow';
import DataFlowEditorPageContent from './DataFlowEditorPage/DataFlowEditorPageContent';
import './DataFlowEditorPage/styles.scss';
import { DataFlowJobsContextProvider } from './DataFlowJobsContext';
import {
  useLoadWorkflowStatusFromApi,
  useResetLockedState,
} from '../_shared/UserPermissionsContext/LockedWorkflows/LockedWorkflowHooks';

import { useTrackInteractiveClick } from '../_shared/EventTrackingContext';
import { useGetResourceClient } from '../../hooks/useGetResourceClient';

export default function DataFlowEditorPage() {
  const prevId = useRef(0);
  const { id } = useParams();
  const { isLoading: isResourceApiLoading } = useGetResourceClient('dataflow', id);
  const [dataFlow, { isLoading, isError }] = useDataFlow(id, isResourceApiLoading);
  const updateStatus = useLoadWorkflowStatusFromApi();
  const resetLockedState = useResetLockedState();
  const trackInteractiveClick = useTrackInteractiveClick();
  updateStatus(dataFlow?.status ? dataFlow.status : 'Not started', id, dataFlow?.lastModifiedDate);
  const [isDataFlowDirty, setIsDataFlowDirty] = useState(false);

  useEffect(() => {
    if (prevId.current !== id) {
      resetLockedState();
      prevId.current = id;
    }
  }, [id, resetLockedState]);

  if (isLoading || isResourceApiLoading) {
    return <Spinner />;
  }

  return (
    <Frame activeItem="data-flows" isDataFlowDirty={isDataFlowDirty}>
      <DataFlowJobsContextProvider>
        {dataFlow && (
          <DataFlowEditorPageContent
            dataFlow={dataFlow}
            setIsDataFlowDirty={setIsDataFlowDirty}
            trackInteractiveClick={trackInteractiveClick}
          />
        )}
        {isError && <div className="wkp-load-general-error">Failed to load data flow</div>}
      </DataFlowJobsContextProvider>
    </Frame>
  );
}
