import { BTButton } from '@btas/jasper';
import React from 'react';
import Frame from '../_shared/Frame';
import './ClientChange/styles.scss';
import { useClient } from '../../contexts/ClientContext';
import { createUserToken } from './ClientSelection/apis';
import { eventTrackTagNames } from '../_shared/EventTrackingContext/constants';

export default function ClientChangeConfirmationPage() {
  const queryParameters = new URLSearchParams(window.location.search);
  const client = queryParameters.get('client');
  const redirectUrl = queryParameters.get('redirectUrl');

  const { setClientId } = useClient();

  const handleClientChangeConfirmationProceedButton = async () => {
    await createUserToken(client);
    setClientId(client);
    window.location.replace(redirectUrl);
  };

  return (
    <Frame showAppBar={false}>
      <div className="client-change-container">
        <div>
          <h1>Change Client</h1>
          <div className="client-change-error-message-text">
            By changing clients, the session for the previously selected client will close. Any unsaved work will be
            lost. This change will
            <br />
            impact all Bloomberg Tax Workpapers browser tabs. Are you sure?
          </div>

          <div className="client-change-btn-container">
            <a href="/" style={{ marginRight: '10px' }}>
              <BTButton
                className="client-change-confirmation-cancel-btn"
                data-track-tag={eventTrackTagNames.interactive}
                id="client-change-confirmation-cancel-btn"
              >
                CANCEL
              </BTButton>
            </a>

            <BTButton
              btStyle="primary"
              className="client-change-confirmation-proceed-btn"
              data-track-tag={eventTrackTagNames.clientSelect}
              id="client-change-confirmation-proceed-btn"
              onClick={handleClientChangeConfirmationProceedButton}
            >
              PROCEED
            </BTButton>
          </div>
        </div>
      </div>
    </Frame>
  );
}
