import {
  AUTHENTICATOR_ENDPOINT,
  ecdsLogoutPageUrl,
  EnviromentUrl,
  globalAccountId,
  userDataAPIPath,
} from '../../configs/params';
import { WKP_CM_INTEGRATION } from '../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../utils/featureFlags';
import { remove } from './fetch';
import { getDataFromLocalStorage, removeDataFromLocalStorage, setDataToLocalStorage } from './storage';

const tokenInfo = 'token-info';
const userKey = 'btas-wkp-user';
const productInfo = 'btas-wkp-productInfo';
const userInfo = 'btas-wkp-user-info';
const aiSubscriptionInfo = 'wkp-ai-subscription';
const clientInfo = 'client-id';
const logoutSubscribers = [];

const localLogoutCleanup = () => {
  logoutSubscribers.forEach(subscriberCallback => subscriberCallback());
};

export const removeTokenInfo = () => {
  removeDataFromLocalStorage(tokenInfo);
};

export function logout() {
  const isCmEnabled = isFeatureFlagEnabled(WKP_CM_INTEGRATION);
  // app cleanup
  localLogoutCleanup();
  try {
    if (isCmEnabled) {
      remove(`${userDataAPIPath}/client/token`).then(response => {
        //remove clientId from local storage
        removeClientId();
      });
    }

    remove(`${AUTHENTICATOR_ENDPOINT}`).then(response => {
      if (!response.ok) {
        throw new Error('Logout failed');
      }

      removeTokenInfo();
      removeDataFromLocalStorage('tokenExpirationTime');

      window.location.replace(`${ecdsLogoutPageUrl}?url=${EnviromentUrl}`);
    });
  } catch (error) {
    if (isCmEnabled) {
      removeClientId();
    }
    removeTokenInfo();
    removeDataFromLocalStorage('tokenExpirationTime');
    removeAiSubscription();
    window.location.replace(`${ecdsLogoutPageUrl}?url=${EnviromentUrl}`);
  }
}

export function login({ user, token }) {
  sessionStorage.setItem(userKey, JSON.stringify(user));
}

export function getUser() {
  const tokenInfo = getTokenInfo();

  if (!tokenInfo) return;

  const {
    userId,
    firstName,
    companyId,
    companyName,
    companyNameOverride = null,
    subscriptions = null,
    preferences,
  } = tokenInfo;

  return {
    userId,
    firstName,
    companyId,
    companyName,
    companyNameOverride,
    subscriptions,
    preferences,
  };
}

export function getUserId() {
  const tokenInfo = getTokenInfo();

  if (!tokenInfo) return;

  const { userId } = tokenInfo;
  return userId;
}

export function getTokenInfo() {
  return JSON.parse(getDataFromLocalStorage(tokenInfo));
}

export function setTokenInfo(token) {
  setDataToLocalStorage(tokenInfo, JSON.stringify(token));
}

export function logoutSubscribe(callback) {
  const index = logoutSubscribers.push(callback) - 1;

  return {
    unsubscribe() {
      logoutSubscribers.splice(index, 1);
    },
  };
}

export function setProductInfo(info) {
  const { products } = info;
  sessionStorage.setItem(productInfo, JSON.stringify(products));
}

export function getProductInfo() {
  return JSON.parse(sessionStorage.getItem(productInfo));
}

export function removeProductInfo() {
  sessionStorage.removeItem(productInfo);
}

export function setAiSubscription(preferences) {
  if (!preferences) return false;
  let includeFound = false;
  let excludeFound = false;

  for (const preference of preferences) {
    if (preference.type === 'ai_labs_include' && preference.value === 'Y') {
      includeFound = true;
    } else if (preference.type === 'ai_labs_exclude' && preference.value === 'Y') {
      excludeFound = true;
    }
  }

  // Since XOR returns a binary value
  localStorage.setItem(aiSubscriptionInfo, Boolean(includeFound ^ excludeFound));

  // For the storage listener in nav
  window.dispatchEvent(new StorageEvent('storage'));
}

export function getAiSubscription() {
  return JSON.parse(localStorage.getItem(aiSubscriptionInfo) || false);
}

export function removeAiSubscription() {
  return localStorage.removeItem(aiSubscriptionInfo);
}

export function isGlobalTemplateAccount() {
  const { companyId } = getUser();
  return companyId === globalAccountId;
}

export function getUserInfo() {
  return JSON.parse(getDataFromLocalStorage(userInfo));
}

export function setUserInfo(data) {
  setDataToLocalStorage(userInfo, JSON.stringify(data));
}

export function removeUserInfo() {
  sessionStorage.removeItem(userInfo);
}

export function getClientId() {
  return getDataFromLocalStorage(clientInfo);
}

export function removeClientId() {
  if (getClientId()) {
    removeDataFromLocalStorage(clientInfo);
  }
}
