import { BTButton } from '@btas/jasper';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Frame from '../_shared/Frame';
import { getClients } from './ClientSelection/apis.js';
import { getUser } from '../../components/_shared/auth';
import { clientAccessManagerUrl } from '../../configs/params.js';
import { roles, hasUserRole } from '../../utils/userRoles';
import './NoClientAccessPage/styles.scss';
import { EventTrackingContext } from '../_shared/EventTrackingContext.jsx';
import { eventNames } from '../_shared/EventTrackingContext/constants.js';
import { getFakeEvent, itemTypes, setPropValues } from '../_shared/EventTrackingContext/utils.js';

export default function NoClientAccessPage() {
  const { trackEvent: trackingEvent } = useContext(EventTrackingContext);
  const history = useHistory();
  const { companyId } = getUser() || {};

  useEffect(() => {
    // Redirect user to default page if user has clients
    const validateUserHasNoClients = async () => {
      const clients = await getClients(companyId);
      if (clients?.length) {
        history.replace('/');
        return;
      } else {
        // track no client access event
        const fakeEvent = getFakeEvent(itemTypes.DIV, 'wkp-no-client-access-page');
        trackingEvent(
          eventNames.noticeViewed,
          setPropValues(fakeEvent, eventNames.noticeViewed, {
            noticeDetails: 'No client access granted page',
          })
        );
      }
    };
    validateUserHasNoClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCpocAdmin = hasUserRole(roles.CPOC_ADMIN);
  return (
    <Frame showAppBar={false}>
      <div className="no-client-access-container">
        <div>
          <h1>No client access granted</h1>
          {isCpocAdmin ? (
            <>
              <div className="clients-error-message-text">
                Either no clients exist, or you have not been added to any clients. As account administrator, you can
                grant <br />
                yourself access in <span>Client Access Manager</span>.
              </div>

              <div className="clients-btn-container">
                <BTButton
                  btStyle="primary"
                  className="client-access-manager-btn"
                  onClick={() => window.open(clientAccessManagerUrl, '_blank')}
                >
                  OPEN CLIENT ACCESS MANAGER
                </BTButton>
              </div>
            </>
          ) : (
            <div className="clients-error-message-text">
              You have not been added to any clients. Contact your account administrator to request access.
            </div>
          )}
        </div>
      </div>
    </Frame>
  );
}
