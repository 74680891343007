import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GC from '../../../SpreadSheets';
import MessageType from '../../_shared/PubSub/pubSubMessageType';
import usePubSub from '../EditorPage/PubSub/usePubSub';
import useResize from './Spreadsheet/_spreadsheets/useResize';
import { setDataToLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage } from '../../_shared/storage';
import { getLock, getWorkbookMetadata, getWorkbookState } from './apis';
import EditorContext from './EditorContext';
import { registerGlobalCustomFunctions } from './Spreadsheet/_spreadsheets';
import {
  overrideRibbonFilterCommand,
  setGoToSourceCommandsBehavior,
} from './Spreadsheet/_spreadsheets/commandsBehavior';
import WorkbookManager from '../../_shared/WorkbookManager';
import { CopyExecute } from './Spreadsheet/_spreadsheets/sheetHelpers';
import useLockWorkpaper from './useWorkpaper/useLockWorkpaper';
import { isEmptyObject } from './DataReference/dataReferenceHelper';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { DATA_LINKS } from '../../../constants/featureFlags';
import { decodeWorksheetUrl } from './worksheetUrlFormatter';
import { useKeyData } from './Spreadsheet/_spreadsheets/useKeyData';
import { getWorkflowStatus } from './Spreadsheet/SideBar/WorkflowPanel/Status/api';
import { cellFormulaCount, dataLinkAdditionalFormulaCheck, restoreCell } from './Spreadsheet/_spreadsheets/formulas';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import {
  applyTimestamp,
  getSheetOptions,
  preprocessCommand,
  skipCommand,
  spreadJSCommandsToAvoid,
  toJSON,
} from '../../../sjs-cmd-process';
import { UserPermissionsContext } from '../../_shared/UserPermissionsContext';
import { processPendingCommandsWithSJS } from './EditorContext/useCommandsQueue/apis';
import { getUserId } from '../../_shared/auth';
import useSyncCommands from './useWorkpaper/useSyncCommands';
import { useDataLink } from './useDataLink';
import { useEditorToggle } from './useEditorToggle';

export default function useWorkpaper({ id, versionId, navigator }) {
  const {
    spreadRef,
    isLocked,
    setIsLoading,
    setIsLoadingInBackground,
    setIsLocked,
    setIsSignOffPermission,
    setCommandsVisibleContext,
    addStatusBar,
    getStatusBarItem,
    statusBarAddedRef,
    enqueueCommands,
    enqueueCustomFormula,
    setWorkpaperId,
    setWorkpaperStatus,
    cleanUpDataReferences,
    loadWorkpaperDataReferences,
    trackDataReferenceCellPositions,
    cellValueChangedHandler,
    handleDataLinkCellDependencyChange,
    trackPastedReference,
    customFormulaReturnValue,
    syncDatareferences,
    customFormulaQueue,
    dataReferenceWorkpaperId,
    dataReferenceWorkpaperVersionId,
    lastCommandAppliedTime,
    commandsProcessingError,
  } = useContext(EditorContext);
  const versionIdRef = useRef(versionId);
  const [isWorkpaperLoaded, setIsWorkpaperLoaded] = useState(false);
  const [lockInfo, setLockInfo] = useState();
  const [loadWorkbookFailed, setLoadWorkbookFailed] = useState(false);
  const [loadWorkbookNotFound, setLoadWorkbookNotFound] = useState(false);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const { getAllKeyValues, createKeyValue } = useKeyData({ workpaperId: id });
  const workbookManager = WorkbookManager.getInstance();
  const keyValuePairs = useRef([]);

  const { publish } = usePubSub();
  const { setResized } = useResize({ id });
  const history = useHistory();
  const { userPermissions } = useContext(UserPermissionsContext);
  const { processDataLinkFromCommand, setupDataLinkSpreadListeners } = useDataLink({ workpaperId: id });
  const { disableEditor } = useEditorToggle();
  const isDataLinksEnabled = isFeatureFlagEnabled(DATA_LINKS);
  const verifyStaticVersion = async id => {
    const stateManager = await getWorkbookState(id);
    const isInProgress = stateManager?.state === 'inProgress';

    if (isInProgress) {
      window.location.reload();
    }
  };

  const { initLockRenew, lockCleanup, isLockInitialized } = useLockWorkpaper({
    id,
  });
  const { initSyncRenew, syncCleanup, isSyncInitialized } = useSyncCommands(id, commandsProcessingError);

  const storeWorkbookActiveSheetIndex = (newValue, userId, workpaperId, index) => {
    if (!newValue) return;
    setDataToLocalStorage(`${userId}-selectedSheetIndex-${workpaperId}`, index);
  };

  const setWorkbookActiveSheetIndex = (workbook, userId, workpaperId) => {
    const spread = spreadRef.current;
    const sheetsCount = Object.keys(workbook.sheets).length;
    const wkpOpenedPreviously = 'wkpOpenedPreviously';
    const wasOpenedBefore = keyValuePairs.current.find(pair => pair.key === wkpOpenedPreviously)?.value;
    if (wasOpenedBefore !== 'true' && spread.sheets.length) {
      const firstVisibleSheet = spread.sheets.find(s => s.visible() === GC.Spread.Sheets.SheetTabVisible.visible);
      const sheetIndex = spread.getSheetIndex(firstVisibleSheet.name());
      spread.setActiveSheetIndex(sheetIndex);
      firstVisibleSheet.setActiveCell(0, 0);
    } else {
      const index = getDataFromLocalStorage(`${userId}-selectedSheetIndex-${workpaperId}`);
      if (index && Number(index) < sheetsCount) {
        spread.setActiveSheetIndex(Number(index));
      } else {
        spread.setActiveSheetIndex(Number(sheetsCount - 1));
      }
    }
  };

  const displayCircularReferenceAlert = a => {
    const spread = spreadRef.current;
    const message =
      'There are one or more circular references where a formula refers to its own cell either directly or indirectly. This will cause them to calculate incorrectly. Try removing or changing these references, or moving the formulas to different cells.';
    if (a?.isCircularReference && !spread.options.iterativeCalculation) {
      GC.Spread.Sheets.Designer.showMessageBox(message, 'Warning', GC.Spread.Sheets.Designer.MessageBoxIcon.warning);
    } else if (a.cellRange) {
      const { row, col, rowCount, colCount } = a.cellRange;
      var circularReferences = spread.getCircularReference();
      if (circularReferences.length) {
        for (let r = 0; r < rowCount; r++) {
          for (let c = 0; c < colCount; c++) {
            const selectedRow = row + r;
            const selectedCol = col + c;
            const referenceExist = circularReferences?.some(
              x => x.row === selectedRow && x.col === selectedCol && x.sheetName === a.sheetName
            );
            if (referenceExist) {
              GC.Spread.Sheets.Designer.showMessageBox(
                message,
                'Warning',
                GC.Spread.Sheets.Designer.MessageBoxIcon.warning
              );
              break;
            }
          }
        }
      }
    }
  };

  const onWorkpaperLoaded = (readOnly, isGlobalTemplate) => {
    statusBarAddedRef.current = false;
    const ss = spreadRef.current;
    ss.options.allowDynamicArray = true;
    ss.options.allowCopyPasteExcelStyle = true;
    ss.options.allowAutoExtendFilterRange = true;
    ss.options.iterativeCalculation = false;
    ss.options.allowDragHeaderToMove = GC.Spread.Sheets.AllowDragHeaderToMove.both;
    publish({ message: MessageType.loadWorkpaperDataReferences, body: id, callback: loadWorkpaperDataReferences });
    syncDatareferences.trackDataReferencesAction.current = trackDataReferenceCellPositions;
    syncDatareferences.loadDataReferencesAction.current = loadWorkpaperDataReferences;
    if ((id && !versionId) || !readOnly) {
      const { Sheets: spreadNS } = GC.Spread;

      ss.bind(spreadNS.Events.ActiveSheetChanged, function () {
        setResized(spreadRef.current);
        ss.setActiveSheetIndex(ss.getActiveSheetIndex());
      });
      ss.bind(spreadNS.Events.ActiveSheetChanging, function (e, a) {
        if (a.newSheet) {
          const workpaperData = JSON.parse(getDataFromLocalStorage(id) || '{}');
          workpaperData['copyWorksheet'] = false;
          setDataToLocalStorage(id, JSON.stringify(workpaperData));
        }
      });

      ss.bind(GC.Spread.Sheets.Events.SheetChanged, (e, a) => {
        const isIgnoreInsert = getDataFromLocalStorage(`ignore-insert-sheet-${id}`);
        if (a.propertyName === 'insertSheet' && !isIgnoreInsert) {
          if (a.sheetIndex === ss.sheets.length - 1) {
            let sheet = ss.getSheetFromName(a.sheetName);
            let command = {
              cmd: 'insertSheet',
              sheetIndex: a.sheetIndex,
              sheetName: a.sheetName,
              data: getSheetOptions(sheet),
            };
            enqueueCommands([{ commandText: JSON.stringify(applyTimestamp(preprocessCommand(ss, command))) }]);
          }
        } else if (a.propertyName === 'isSelected') {
          storeWorkbookActiveSheetIndex(a.newValue, getUserId(), id, a.sheetIndex);
        }
      });

      ss.bind(GC.Spread.Sheets.Events.ValueChanged, function (e, a) {
        publish({ body: a, message: MessageType.CellValueChanged, callback: cellValueChangedHandler });
      });

      ss.bind(GC.Spread.Sheets.Events.ClipboardPasted, function (e, a) {
        publish({ body: a, message: MessageType.CutPasteReference, callback: trackPastedReference });
        displayCircularReferenceAlert(a);
      });

      ss.bind(GC.Spread.Sheets.Events.UserFormulaEntered, function (e, a) {
        displayCircularReferenceAlert(a);
      });

      ss.bind(GC.Spread.Sheets.Events.EnterCell, function (e, a) {
        decodeWorksheetUrl(a);
      });

      const pasteAction = ss.commandManager()['clipboardPaste'].execute;
      ss.commandManager()['clipboardPaste'].execute = function (context, options, actionType) {
        // the clipboardPaste command will be changed after execution.
        // we need to save the command before it is executed and send that to the server.

        const command = toJSON(GC, ss, options);
        const ret = pasteAction.call(this, context, options, actionType);
        if (ret !== false && !options.clipboardImage && !options.clipboardText) {
          applyTimestamp(options);
          command.fromSheet = options?.fromSheet?.name();
          processCommand(ss, command, actionType, options);
          options.timestamp = command.timestamp;
        }
        return ret;
      };

      const wkpOpenedPreviously = 'wkpOpenedPreviously';
      const wasOpenedBefore = keyValuePairs.current.find(pair => pair.key === wkpOpenedPreviously)?.value;
      if (wasOpenedBefore !== 'true') {
        createKeyValue({ workpaperId: id, keyData: { key: wkpOpenedPreviously, value: 'true', forceSave: true } });
      }

      const commandManager = ss.commandManager();

      commandManager.addListener('remoteCommands', arg => {
        let command = arg.command;
        if (command.cmd && command.cmd.length > 0) {
          if (
            skipCommand(ss, command) ||
            spreadJSCommandsToAvoid.includes(command?.cmd) ||
            (command.cmd === 'clipboardPaste' && !command.clipboardImage && !command.clipboardText)
          ) {
            return;
          }
          if (command.cmd === 'copySheet') {
            removeDataFromLocalStorage(`ignore-insert-sheet-${id}`);
          }
          if (
            command.cmd !== 'Designer.deleteSheet' &&
            command.cmd.indexOf('deleteSheet') >= 0 &&
            !command.deletedSheets
          ) {
            return;
          }
          if (command.cmd === 'editCell') {
            const { row, col } = command;
            const sheet = ss.getSheetFromName(command.sheetName);
            const formula = sheet.getFormula(row, col);
            if (formula) {
              const hasDatalinkCellAdditionalFormula = dataLinkAdditionalFormulaCheck(formula);
              if (cellFormulaCount(formula) || hasDatalinkCellAdditionalFormula) {
                restoreCell(ss, command);
                customFormulaQueue?.current?.pop();
                const message = hasDatalinkCellAdditionalFormula
                  ? 'Invalid formula'
                  : 'You cannot combine multiple data connections or tax variable functions inside a single cell.';
                return GC.Spread.Sheets.Designer.showMessageBox(
                  message,
                  'Warning',
                  GC.Spread.Sheets.Designer.MessageBoxIcon.warning
                );
              }
            }
          }

          if (command.cmd === 'Designer.sheetSettings') {
            delete command.options.sheetTabStateStylesAndTheme;
          }

          // server side measure text is not accurate, we change
          // the auto fit command to resize command.
          if (command.cmd === 'autoFitColumn') {
            let change = command['changes' + command.sheetName];
            for (let i = 0; i < command.columns.length; i++) {
              let col = command.columns[i];
              if (change && change[i] && change[i].length > 0) {
                col.size = change[i][2];
              } else {
                col.size = ss.getSheetFromName(command.sheetName).getColumnWidth(col.col);
              }
            }
          } else if (command.cmd === 'autoFitRow') {
            let change = command['changes' + command.sheetName];
            for (let i = 0; i < command.rows.length; i++) {
              let row = command.rows[i];
              if (change && change[i] && change[i].length > 0) {
                row.size = change[i][2];
              } else {
                row.size = ss.getSheetFromName(command.sheetName).getRowHeight(row.row);
              }
            }
          }
          const selectionSheet = ss.getSheetFromName(command.sheetName);
          if (!command.selections) command.selections = selectionSheet?.getSelections();
          publish({
            body: { command, sheet: command.sheet },
            message: MessageType.resolveDataLinks,
            callback: processDataLinkFromCommand,
          });
          setTimeout(() => {
            processCommand(ss, command, arg.actionType, arg);
          }, 100);
        }
      });
    }

    function validCustomFormulaCount(ss, command) {
      try {
        if (command.cmd === 'clipboardPaste') {
          if (command.pasteSpecialOptions.operationOptions !== GC.Spread.Sheets.PasteOperationOptions.none) {
            const sheet = ss.getSheetFromName(command.sheetName);
            for (let rangeIndex = 0; rangeIndex < command.pastedRanges.length; rangeIndex++) {
              const { row, col, rowCount, colCount } = command.pastedRanges[rangeIndex];
              for (let r = row; r < row + rowCount; r++) {
                for (let c = col; c < col + colCount; c++) {
                  const pastedFormula = sheet.getFormula(r, c);
                  if (pastedFormula) {
                    const hasDatalinkCellAdditionalFormula = dataLinkAdditionalFormulaCheck(pastedFormula);
                    if (cellFormulaCount(pastedFormula) || hasDatalinkCellAdditionalFormula) {
                      ss.undoManager().undo();
                      const message = hasDatalinkCellAdditionalFormula
                        ? 'Invalid formula'
                        : 'You cannot combine multiple data connections or tax variable functions inside a single cell.';
                      return message;
                    }
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.log('clipboardPaste validation failed for custom functions', error);
        return null;
      }
    }
    function processCommand(ss, command, actionType, arg) {
      try {
        const message = validCustomFormulaCount(ss, command);
        if (message) {
          GC.Spread.Sheets.Designer.showMessageBox(
            message,
            'Warning',
            GC.Spread.Sheets.Designer.MessageBoxIcon.warning
          );
        }
        command = preprocessCommand(ss, command);
        if (actionType !== 1) applyTimestamp(command);
        const commandToProcess = toJSON(GC, ss, command);
        if (actionType !== 1 && !commandToProcess.timestamp && command.timestamp)
          commandToProcess.timestamp = command.timestamp;
        if (actionType === 1) delete commandToProcess.hM;
        if (commandToProcess.cmd === 'clipboardPaste' && !commandToProcess.fromSheet) {
          commandToProcess.fromSheet = arg?.fromSheet?.name();
        }
        command = commandToProcess;
      } catch (ex) {
        console.log(ex);
      }
      command.actionType = actionType;
      enqueueCommands([
        {
          commandText: JSON.stringify(command),
        },
      ]);
    }

    if (!versionId && !readOnly && !isGlobalTemplate) {
      ss.options.saveChangesForSheet = true;
      CopyExecute(() => setConfirmModalVisible(true));
    }

    addStatusBar(ss);

    const overrideContextMenuItemsText = () => {
      const linkItem = ss.contextMenu.menuData.find(d => d.name === 'link');
      if (linkItem) {
        linkItem.text = 'Hyperlink...';
      }
    };

    const setCommandsBehavior = () => {
      const activeSheet = ss.getActiveSheet();
      setGoToSourceCommandsBehavior(activeSheet, setCommandsVisibleContext);
    };

    const workpaperData = JSON.parse(getDataFromLocalStorage(id) || '{}');
    workpaperData.creatingWorkpaper = false;
    setDataToLocalStorage(id, JSON.stringify(workpaperData));
    return () => {
      if (versionId || readOnly || isGlobalTemplate || !userPermissions.includes('edit_workpaper')) {
        disableEditor();
      }
      overrideContextMenuItemsText();
      setCommandsBehavior();
    };
  };

  const load = useCallback(async () => {
    const operationStartTime = Date.now();
    lastCommandAppliedTime.current = new Date().toISOString();
    setIsLoading(true);
    setWorkpaperId(id);
    dataReferenceWorkpaperId.current = id;
    dataReferenceWorkpaperVersionId.current = versionId;
    versionIdRef.current = versionId;
    syncDatareferences.trackDataReferencesAction.current = trackDataReferenceCellPositions;
    syncDatareferences.dataLinkCellDependencyChangeAction.current = handleDataLinkCellDependencyChange;
    try {
      workbookManager.resetStatusRetry();
      const { workbook, workbookLength } = await workbookManager.getWorkbookRequest(id, versionId);
      const [{ metadata, readOnly, isGlobalTemplate }, lockInfo, status] = await Promise.all([
        getWorkbookMetadata({ id, versionId }),
        getLock(id),
        getWorkflowStatus(id),
      ]);
      const signoff = readOnly ? false : userPermissions.includes('edit_workpaper_sign-offs');

      setWorkpaperStatus(status?.status);
      setIsLocked(!userPermissions.includes('edit_workpaper') || (readOnly && lockInfo?.userId !== null));
      setIsWorkpaperLoaded(true);
      setIsSignOffPermission(signoff);

      if (!versionId && !isLockInitialized()) {
        if (readOnly) {
          setLockInfo(lockInfo);
        } else {
          initLockRenew();
        }
      }

      if (!versionId && !isSyncInitialized()) {
        initSyncRenew(id);
      }

      const spread = spreadRef.current;
      overrideRibbonFilterCommand(spread);
      registerGlobalCustomFunctions(
        metadata.taxPeriod,
        id,
        enqueueCommands,
        spread,
        enqueueCustomFormula,
        customFormulaReturnValue
      );
      setIsLoadingInBackground(true);
      const workpaperName = metadata.name;
      spread.open(
        workbook,
        () => {
          onWorkpaperLoaded(readOnly, isGlobalTemplate)();
          setDataToLocalStorage(`${id}-metadata`, JSON.stringify({ workpaperName, taxPeriod: metadata.taxPeriod }));
          if (isDataLinksEnabled) setupDataLinkSpreadListeners(readOnly);
          setIsLoadingInBackground(false);
          setIsLoading(false);
          setWorkbookActiveSheetIndex(workbook, getUserId(), id);
          setResized(spreadRef.current);
          const loadingProgress = (100).toFixed(2);
          const loadingStatusItem = getStatusBarItem('loadingStatus');
          loadingStatusItem.updateText(`Loading: ${loadingProgress}%`);
          cleanUpDataReferences(id);
          CustomLogger &&
            CustomLogger.pushLog(CustomLogger.operations.OPEN, {
              workpaperId: id,
              workpaperName,
              fileSize: workbookLength.toString(),
              versionId,
              duration: (Date.now() - operationStartTime).toString(),
            });
        },
        error => {
          setLoadWorkbookFailed(true);
          CustomLogger &&
            CustomLogger.pushLog(CustomLogger.operations.OPEN, {
              error: JSON.stringify(error),
              workpaperId: id,
              workpaperName,
              fileSize: workbookLength.toString(),
              versionId,
              duration: (Date.now() - operationStartTime).toString(),
            });
          throw error;
        },
        { jsonStream: true }
      );
      history.replace();
    } catch (error) {
      if (error && !isEmptyObject(error)) {
        CustomLogger &&
          CustomLogger.pushLog(CustomLogger.operations.OPEN, {
            error: JSON.stringify(error),
            workpaperId: id,
            versionId,
            duration: (Date.now() - operationStartTime).toString(),
          });

        if (error?.details) {
          switch (error?.details[0]?.code) {
            case 'STATIC_VERSION_ERROR':
              setLoadWorkbookFailed(true);
              break;
            case 'UNAUTHORIZED_WORKBOOK_RESOURCE':
              setLoadWorkbookNotFound(true);
              break;
            default:
              navigator.toHome();
              break;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, versionId]);

  useEffect(() => {
    const setKeyValuePairs = async () => {
      keyValuePairs.current = await getAllKeyValues();
    };
    setKeyValuePairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWorkpaperLoaded) return;

    const processPendingCmds = async () => {
      await processPendingCommandsWithSJS(id);
    };

    const hanldeWorkpaperUnload = async () => {
      const versionId = versionIdRef.current;
      if (versionId || isLocked) return;

      await Promise.all([lockCleanup(), processPendingCmds(), syncCleanup()]);
      await verifyStaticVersion(id);
    };

    window.addEventListener('unload', hanldeWorkpaperUnload);

    return async () => {
      window.removeEventListener('unload', hanldeWorkpaperUnload);
      workbookManager.stopStatusRetry();

      await hanldeWorkpaperUnload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, lockCleanup, isWorkpaperLoaded, syncCleanup]);

  useEffect(() => {
    publish({ message: MessageType.LoadWorkbookDataReferences, body: id, callback: loadWorkpaperDataReferences });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (loadWorkbookNotFound) {
      history.push({ pathname: '/workpapers', state: { loadWorkbookNotFound } });
    }
  }, [loadWorkbookNotFound, history]);

  return {
    load,
    lockCleanup,
    setConfirmModalVisible,
    displayCircularReferenceAlert,
    lockInfo,
    loadWorkbookFailed,
    isConfirmModalVisible,
    spread: spreadRef.current,
  };
}
