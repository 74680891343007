import { DATA_LINK } from '../../../../../_shared/DataReference/ReferenceType';
import { extractFormulaValues, formatLinkValue } from '../../../datalinkHelper';
import { setValueOnServer } from '../../../useWorkpaper/processCommand';
import { datalinkFormulaRegex } from '../formulas';

export function executeRefreshDataLinks(spread, workpaperId, dataReferences, enqueueCommands) {
  console.log('Refreshing Data Link formulas...');
  const dataLinkReferences = dataReferences?.current?.filter(
    r => r.type === DATA_LINK && r.workpaperId === workpaperId
  );

  let commands = [];
  let partialWorkbooks = {};

  spread.suspendPaint();

  for (const r of dataLinkReferences) {
    const { taxPeriod, workpaperName, sheetName, a1Coordinate, row, column } =
      typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;
    const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';
    const sheet = spread.getSheetFromName(r.sheetName);
    const existingFormula = sheet.getFormula(r.row, r.column);
    const existingFormulaExtract = extractFormulaValues(existingFormula);
    const match = existingFormula?.match(datalinkFormulaRegex);
    if (!match) {
      continue;
    }

    const extractedReference = match[0];
    const updatedReference = `'${effectiveTaxPeriod}[${workpaperName}]${sheetName}'!${existingFormulaExtract?.a1Coordinate ?? a1Coordinate}`;
    const updatedFormula = existingFormula.replace(extractedReference, updatedReference);

    if (existingFormula === updatedFormula) {
      continue;
    }

    sheet.setFormula(r.row, r.column, `=${updatedFormula}`);

    const partialWorkbook = {
      [sheetName]: {
        [row]: {
          [column]: formatLinkValue(r.value),
        },
      },
    };

    const key = `${effectiveTaxPeriod}${workpaperName}`;
    if (!partialWorkbooks[key]) partialWorkbooks[key] = {};
    if (!partialWorkbooks[key][sheetName]) partialWorkbooks[key][sheetName] = {};
    if (!partialWorkbooks[key][sheetName][row]) partialWorkbooks[key][sheetName][row] = {};
    if (!partialWorkbooks[key][sheetName][row][column])
      partialWorkbooks[key][sheetName][row][column] = formatLinkValue(r.value);

    const command = setValueOnServer(sheet, r.row, r.column, `=${updatedFormula}`);
    command.partialWorkbook = partialWorkbook;
    command.workpaperName = workpaperName;
    command.taxPeriod = effectiveTaxPeriod;
    commands.push({ commandText: JSON.stringify(command) });
  }

  if (commands.length === 0) {
    console.log('No updates required. Skipping data link formula refresh.');
    spread.resumePaint();
    return false;
  }

  enqueueCommands(commands);

  // update values
  const references = spread.getExternalReferences(true);
  for (const { filePath: effectiveTaxPeriod, name } of references) {
    const key = `${effectiveTaxPeriod}${name}`;
    spread.updateExternalReference(name, partialWorkbooks[key], effectiveTaxPeriod, true);
  }

  spread.resumePaint();
  console.log('Data Links refreshed.');
  return true;
}
