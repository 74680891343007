import React, { useContext } from 'react';
import { BTAlert } from '@btas/jasper';
import UserPermissionsContext from '../_shared/UserPermissionsContext';
import { ecdsLogoutPageUrl, EnviromentUrl } from '../../configs/params';
import CustomLogger from '../_shared/Logger/CustomLogger';

const ErrorRetrievingRole = () => {
  const { userPermissions } = useContext(UserPermissionsContext);

  const isVisible = userPermissions.hasPermissionApiError();
  if (isVisible) {
    CustomLogger.pushLog(CustomLogger.operations.GETTING_ROLE, {
      error: 'Error getting role in client',
      message: 'Error getting role in client',
    });
  }

  return (
    <BTAlert
      appear
      dismissible
      fixed
      btStyle="warning"
      visible={isVisible}
      onDismiss={() => userPermissions.clearPermissionApiError()}
    >
      <p>
        An error occurred while retrieving your application role. The permissions associated with 'Reader' will be
        applied. If your application role is higher than 'Reader',
        <a href={`${ecdsLogoutPageUrl}?url=${EnviromentUrl}`}>log out</a> and try logging in again.
      </p>
    </BTAlert>
  );
};

export default ErrorRetrievingRole;
