export class ClientSyncService {
  constructor() {
    this.STORAGE_KEY = 'client-id';
  }

  getStoredClient() {
    return localStorage.getItem(this.STORAGE_KEY);
  }

  setClient(clientId) {
    if (!clientId) {
      throw new Error('ClientId is null');
    }

    localStorage.setItem(this.STORAGE_KEY, clientId);

    const savedClient = localStorage.getItem(this.STORAGE_KEY);
    if (savedClient !== clientId) {
      throw new Error('Failed to save client to localStorage');
    }
  }

  removeClient() {
    localStorage.removeItem(this.STORAGE_KEY);
  }

  subscribe(callback) {
    const handleStorageChange = event => {
      if (event.key === this.STORAGE_KEY) {
        callback(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }
}
