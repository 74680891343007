import * as fetch from '../../../../_shared/fetch';
import { getFormattedDate } from '../../../../_shared/dateFormatting';
import { dataFlowServiceHostName } from '../../../../../configs/params';

export async function getSourceFilesDetails({ dataFlowId }) {
  const data = await fetch
    .get(`${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-file-versions`)
    .then(res => res.json());

  return data.map(sourceFiles => {
    const { uploadTimestamp, lastModifiedUserFirstName } = sourceFiles;

    return {
      ...sourceFiles,
      importedDate: uploadTimestamp ? getFormattedDate(uploadTimestamp) : null,
      lastModifiedUser: lastModifiedUserFirstName ? `${lastModifiedUserFirstName}` : null,
    };
  });
}
