import CustomLogger from '../../_shared/Logger/CustomLogger';
import { getDataFromLocalStorage, getTokenExpirationTime, setDataToLocalStorage } from '../../_shared/storage';

export const AUTH_LOCK = 'auth-lock';
export const ONE_SECOND_MS = 1000;

export function isTokenExpired() {
  const tokenExpiration = getTokenExpirationTime();
  const tokenExpirationTime = parseInt(tokenExpiration);
  const currentTime = new Date().getTime();
  return currentTime >= tokenExpirationTime;
}

export function setMasterTab(tabId) {
  if (!getDataFromLocalStorage('masterTabId')) {
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
      message: `Setting masterTabId: ${tabId}`,
    });
    setDataToLocalStorage('masterTabId', tabId);
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `This is Master Tab now: ${tabId}` });
  }
}

export async function refreshToken(dataUpdate, refreshExpiredToken) {
  const data = await retryFunction(refreshExpiredToken, 3);
  dataUpdate(data);
  CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
    message: 'Automatic token refresh completed',
  });

  if (isTokenExpired()) {
    const message = 'Token has an invalid expiration date';
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
      message: message,
    });
  }
}

export const retryFunction = async (func, numRetries, funcParams) => {
  try {
    const result = await func(funcParams);
    return result;
  } catch (error) {
    numRetries--;

    if (numRetries === 0) {
      const message = `Failed to retrieve refresh token`;
      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
        messge: message,
      });
      throw new Error(message);
    }
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
      mesage: `Attempting to retry refresh token call, ${numRetries} left`,
    });
    return await retryFunction(func, numRetries, funcParams);
  }
};
