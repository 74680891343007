/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { BTModal, BTButton, BTGrid } from '@btas/jasper';
import { deleteWorkpapers, workpapersLockStatus } from './DeleteModal/apis';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { getWorkpapersUserCanDelete } from '../../../auth/permissions/CanDelete';
import UserPermissionsContext from '../../../_shared/UserPermissionsContext';
import { fetchDataLinks } from '../../../editor/EditorPage/Spreadsheet/WorkpaperSyncDataLinksModal/apis';
import Spinner from '../../../_shared/Spinner';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';
import { wijmoKey } from '../../../../configs/params';

export default function NewDeleteModal({
  selection,
  show,
  onClose,
  onDeleteProcessed,
  setHasDeleteWfError,
  setUndeletedWorkpapers,
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLinks, setDataLinks] = useState([]);
  const [dataLinksFinalStatus, setDataLinksFinalStatus] = useState([]);
  const [errorFetchingDataLinks, setErrorFetchingDataLinks] = useState(false);
  const { userPermissions } = useContext(UserPermissionsContext);
  const ids = selection.map(sel => sel.id);

  const defaultMessage = 'The selected workpapers will be moved to the recycle bin.';
  const dataLinksMessage =
    'By moving all the selected workpapers to the recycle bin, all outgoing links will be removed. Workpapers receiving values from the selected workpapers will have those links replaced with hardcoded values.';

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchDataLinks(ids);
        if (data === null) {
          setErrorFetchingDataLinks(true);
        } else if (data.length) {
          const dataLinksFinal = data.filter(dataLink => dataLink.workpaperStatus === 'Final');
          const dataLinks = data.filter(dataLink => dataLink.workpaperStatus !== 'Final');

          const formatDataLink = dataLink => ({
            ...dataLink,
            impactedLinks: dataLink.totalLinks,
            taxPeriod: dataLink.taxPeriod || '',
            workpaperName: dataLink.workpaperName,
          });
          setDataLinks(dataLinks.map(formatDataLink));
          setDataLinksFinalStatus(dataLinksFinal.map(formatDataLink));
        } else {
          setDataLinks([]);
          setDataLinksFinalStatus([]);
        }
      } catch (error) {
        console.error('Error fetching data links:', error);
        setErrorFetchingDataLinks(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (show) {
      fetchData();
    }
  }, [show, selection]);

  const handleDelete = async () => {
    const operationStartTime = Date.now();
    setIsDeleting(true);

    const workpapersUserCanDelete = getWorkpapersUserCanDelete(selection, userPermissions);

    if (workpapersUserCanDelete.size > 0) {
      const workpapersLock = await workpapersLockStatus(Array.from(workpapersUserCanDelete.keys()));

      if (workpapersLock && workpapersLock.length > 0) {
        await deleteWorkpapersLock(workpapersLock, operationStartTime);

        CustomLogger.pushLog(CustomLogger.operations.DELETE, {
          duration: (Date.now() - operationStartTime).toString(),
          ids: JSON.stringify(ids),
        });
      }
    }

    if (workpapersUserCanDelete.size !== selection.length) {
      const failedToDelete = selection.filter(workpaper => !workpapersUserCanDelete.has(workpaper.id));
      CustomLogger.pushLog(CustomLogger.operations.DELETE_WORKFLOW, {
        duration: (Date.now() - operationStartTime).toString(),
        ids: JSON.stringify(failedToDelete.map(workpaper => workpaper.id)),
      });
      userPermissions.setErrorData(failedToDelete.map(workpaper => workpaper.name));
      setHasDeleteWfError(failedToDelete.map(workpaper => workpaper.name));
    }

    onDeleteProcessed();
    setIsDeleting(false);
  };

  async function deleteWorkpapersLock(workpapersLock) {
    const workpaperIdsToDelete = workpapersLock.filter(x => !x.isLocked).map(x => x.id);
    const undeletedWorkpapers = workpapersLock.filter(x => x.isLocked);

    if (workpaperIdsToDelete && workpaperIdsToDelete.length > 0) {
      await deleteWorkpapers(workpaperIdsToDelete);
    }

    if (undeletedWorkpapers && undeletedWorkpapers.length > 0) {
      setUndeletedWorkpapers(undeletedWorkpapers);
    }
  }

  const Grid = ({ data }) =>
    data?.length && (
      <>
        <BTGrid allowResizing={1} data={data} wijmoKey={wijmoKey}>
          <BTGrid.Header
            key="workpaperName"
            align="left"
            binding="workpaperName"
            cellTemplate={context => (
              <>
                <a href={`/editor/${context.rowData.workpaperId}`} rel="noreferrer" target="_blank">
                  {context.rowData.workpaperName}
                </a>
              </>
            )}
            class="header-datalink-modal"
            width="*"
          >
            Name
          </BTGrid.Header>
          <BTGrid.Header key="taxPeriod" align="left" binding="taxPeriod" class="header-datalink-modal" width="*">
            Tax Period
          </BTGrid.Header>
          <BTGrid.Header
            key="impactedLinks"
            align="left"
            binding="impactedLinks"
            class="header-datalink-modal"
            width="*"
          >
            Impacted Links
          </BTGrid.Header>
        </BTGrid>
      </>
    );

  return (
    <BTModal btStyle="warning" id="delete-modal" show={show} title="Delete Workpapers" onCloseClick={onClose}>
      <BTModal.Body>
        {isLoading ? (
          <Spinner description="Scanning workpapers for Data Links..." />
        ) : errorFetchingDataLinks ? (
          <GeneralErrorMessage className="onDeleteModal-summary-alert" dismissible={false} />
        ) : (
          <div>
            <p>
              {dataLinks.length || dataLinksFinalStatus.length ? dataLinksMessage : defaultMessage}
              <br />
              {`Are you sure?`}
            </p>

            {dataLinks.length > 0 && <Grid data={dataLinks} />}
            {dataLinksFinalStatus.length > 0 && (
              <>
                <p>
                  The following linked workpapers will <strong>not</strong> be updated, since they are marked as
                  'Final':
                </p>
                <Grid data={dataLinksFinalStatus} />
              </>
            )}
          </div>
        )}
      </BTModal.Body>

      <BTModal.Footer>
        <BTButton btStyle={isDeleting ? 'primary' : 'default'} disabled={isDeleting} onClick={onClose}>
          Cancel
        </BTButton>

        {!errorFetchingDataLinks && (
          <BTButton btStyle="primary" disabled={isDeleting} hasSpinner={isDeleting} onClick={handleDelete}>
            Delete
          </BTButton>
        )}
      </BTModal.Footer>
    </BTModal>
  );
}
