import { useEffect, useRef } from 'react';
import { WORKPAPER_EXPORT_JOB_TYPE } from '../../_shared/jobs/jobTypes';
import { getJobsStatus, startJob, updateJob } from '../../_shared/jobs/apis';
import { exportWorkpapers } from './apis';
import { WORKPAPER_JOB_STATUS_COMPLETED, WORKPAPER_JOB_STATUS_IN_PROGRESS } from '../../_shared/jobs/jobStatus';
import useJobsOverlay from '../common/JobsOverlayWindow/useJobsOverlay';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { getWorkbookState, workbookStates } from '../../editor/EditorPage/apis';

export default function useExportFile(loadJobs, processes, cleanGridSelection) {
  const prevProcessRef = useRef();
  const { bindOnJobCompleted } = useJobsOverlay();

  const exportFile = async ({ id: workpaperId, name: fileName }) => {
    const { jobId } = await startJob({
      entityId: workpaperId,
      jobType: WORKPAPER_EXPORT_JOB_TYPE,
      payload: {
        fileName,
      },
    });
    bindOnJobCompleted(
      jobId,
      function (startTime) {
        CustomLogger.pushLog(CustomLogger.operations.EXPORT, {
          duration: (Date.now() - startTime).toString(),
          workpaperId,
          fileName,
        });
      },
      Date.now()
    );

    await loadJobs();
    await waitUntilExcelIsDone(workpaperId, jobId);
    await updateJob(jobId, WORKPAPER_JOB_STATUS_COMPLETED, workpaperId);
  };

  const exportFiles = async selection => {
    if (0 < selection.length) {
      CustomLogger.pushLog(CustomLogger.operations.EXPORT, {
        message: `Exporting ${selection.length} Workpapers in parallel`,
      });
    }

    selection.forEach(exportFile);
    cleanGridSelection();
  };

  useEffect(() => {
    if (processes && processes.length > 0 && prevProcessRef.current && prevProcessRef.current.length > 0) {
      if (processes.length === prevProcessRef.current.length) {
        const jobsChanged = findStatusChangedValue(
          prevProcessRef.current,
          processes,
          WORKPAPER_JOB_STATUS_IN_PROGRESS,
          WORKPAPER_JOB_STATUS_COMPLETED
        );
        if (jobsChanged && jobsChanged?.length) {
          jobsChanged.forEach(x => {
            if (x.jobType === WORKPAPER_EXPORT_JOB_TYPE) {
              downloadWorkpaper(x.entityId, x.jobId);
            }
          });
        }
      }
    }
    prevProcessRef.current = processes;
  }, [processes]);

  function findStatusChangedValue(prevProcesses, newProcesses, inProgress, completed) {
    const readyProcesses = prevProcesses.filter(
      (obj1, i) =>
        obj1.entityId === newProcesses[i].entityId &&
        obj1['status'] === inProgress &&
        newProcesses[i]['status'] === completed
    );
    if (readyProcesses) {
      return readyProcesses;
    }

    return null;
  }

  function fetchExcelRunningJobs(workpaperId) {
    return getJobsStatus({
      entityIds: [workpaperId],
      jobTypes: [WORKPAPER_EXPORT_JOB_TYPE],
      jobStatus: [WORKPAPER_JOB_STATUS_IN_PROGRESS],
    });
  }

  async function waitUntilExcelIsDone(workpaperId, ignoreJobId) {
    let { state } = await getWorkbookState(workpaperId);
    let retries = 600;

    while (state === workbookStates.inProgress) {
      if (--retries < 0) {
        throw new Error('Excel version generation failed. Reload the page');
      }
      await new Promise(r => setTimeout(r, 1000));
      state = (await getWorkbookState(workpaperId)).state;
    }

    let runningJobs = await fetchExcelRunningJobs(workpaperId);
    runningJobs = runningJobs.filter(({ jobId }) => ignoreJobId !== jobId);

    while (runningJobs.length > 0) {
      if (--retries < 0) {
        throw new Error('Excel version generation failed. Reload the page');
      }

      await new Promise(r => setTimeout(r, 1000));
      runningJobs = await fetchExcelRunningJobs(workpaperId);
      runningJobs = runningJobs.filter(({ jobId }) => ignoreJobId !== jobId);
    }
  }

  async function downloadWorkpaper(id, jobId) {
    const presignedUrl = await exportWorkpapers({ id, jobId });

    const response = await fetch(presignedUrl);
    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    const urlObj = new URL(presignedUrl);
    const encodedFilename = urlObj.pathname.split('/').pop();
    const decodedFilename = decodeURIComponent(encodedFilename);

    // Regex that matches '-Shifted' followed by an optional '-' and one or more digits.
    // This pattern identifies the timezone offset suffix (e.g., '-Shifted180' or '-Shifted-300')
    const timezoneOffsetRegex = /-Shifted-?\d+/;

    const modifiedFilename = decodedFilename.replace(timezoneOffsetRegex, '');

    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = modifiedFilename;
    document.body.appendChild(link);

    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
      window?.URL?.revokeObjectURL(objectUrl);
    }, 0);
  }

  return { exportFiles };
}
