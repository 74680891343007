import GC from '../../../../SpreadSheets';
import {
  SOURCE_DATA_CONNECTION,
  INT_TAX_RATE,
  STATE_TAX_RATE,
  STATE_APPORTION,
  DATA_LINK,
  CELL_REVIEW,
} from '../../../_shared/DataReference/ReferenceType';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { formatCustomFormulaReturnValue, loadingString } from '../Spreadsheet/_spreadsheets/formulas';
import { momentDateFormats } from '../Spreadsheet/_spreadsheets/utils';
const moment = require('moment');

export function isEmptyObject(obj) {
  try {
    if (typeof obj === 'string') {
      obj = JSON.parse(obj);
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  } catch {
    return false;
  }
}

export async function generateOutputRequest(customFormulaQueue) {
  const sdcOutputRequests = generateSdcOutputRequest(customFormulaQueue);
  const intTaxRateOutputRequests = generateIntTaxRateOutputRequests(customFormulaQueue);
  const stateTaxRateOutputRequests = generateStateTaxRateOutputRequests(customFormulaQueue);
  const stateApportionOutputRequests = generateStateApportionOutputRequests(customFormulaQueue);
  const dataLinkOutputRequests = generateDataLinkOutputRequests(customFormulaQueue);

  const outPutRequest = [
    ...sdcOutputRequests,
    ...intTaxRateOutputRequests,
    ...stateTaxRateOutputRequests,
    ...stateApportionOutputRequests,
    ...dataLinkOutputRequests,
  ];

  return outPutRequest;
}

function generateSdcOutputRequest(customFormulaQueue) {
  const sourceConnections = customFormulaQueue.current.filter(({ type }) => type === SOURCE_DATA_CONNECTION);
  let sourceConnectionRequest = [];

  if (sourceConnections.length) {
    sourceConnectionRequest = sourceConnections.map(
      ({
        id,
        row,
        column,
        sheetName,
        output: outputId,
        outputField: fieldToSum,
        filters: criteria,
        type,
        processId,
      }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id, processId }),
        outputId,
        fieldToSum,
        criteria,
      })
    );
  }

  return sourceConnectionRequest;
}

function generateIntTaxRateOutputRequests(customFormulaQueue) {
  const intTaxRateConnections = customFormulaQueue.current.filter(({ type }) => type === INT_TAX_RATE);
  let intTaxRateRequest = [];

  if (intTaxRateConnections.length) {
    intTaxRateRequest = intTaxRateConnections.map(
      ({ id, row, column, sheetName, country, periodStartDate, taxableIncome, type, processId }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id, processId }),
        country,
        periodStartDate,
        taxableIncome,
      })
    );
  }

  return intTaxRateRequest;
}

function generateStateTaxRateOutputRequests(customFormulaQueue) {
  const stateTaxRateConnections = customFormulaQueue.current.filter(({ type }) => type === STATE_TAX_RATE);
  let stateTaxRateRequest = [];

  if (stateTaxRateConnections.length) {
    stateTaxRateRequest = stateTaxRateConnections.map(
      ({ id, row, column, sheetName, jurisdiction, periodStartDate, taxableIncome, type, processId }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id, processId }),
        jurisdiction,
        ...{ startDate: periodStartDate },
        ...{ income: taxableIncome },
      })
    );
  }

  return stateTaxRateRequest;
}

function generateStateApportionOutputRequests(customFormulaQueue) {
  const stateApportionConnections = customFormulaQueue.current.filter(({ type }) => type === STATE_APPORTION);
  let stateApportionRequest = [];

  if (stateApportionConnections.length) {
    stateApportionRequest = stateApportionConnections.map(
      ({ id, row, column, sheetName, state, date, factor, type, processId }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id, processId }),
        state,
        date,
        type: factor,
      })
    );
  }

  return stateApportionRequest;
}

function generateDataLinkOutputRequests(customFormulaQueue) {
  const dataLinkConnections = customFormulaQueue.current.filter(({ type }) => type === DATA_LINK);
  let dataLinkRequest = [];

  if (dataLinkConnections.length) {
    dataLinkRequest = dataLinkConnections.map(({ id, row, column, type, name, sheetName, parameters, processId }) => ({
      key: JSON.stringify({ row, column, name, sheetName, type, id, processId }),
      parameters,
    }));
  }

  return dataLinkRequest;
}

export function customFormulaMatchReturnValue(dataReferences, dataReferenceValues, targetParameter) {
  let formulaValue = undefined;
  try {
    if (!dataReferences?.current?.length && dataReferenceValues?.current?.length) {
      return undefined;
    }
    const type = targetParameter.type;
    if (type === SOURCE_DATA_CONNECTION) {
      formulaValue = dataReferences.current.find(x => {
        if (!x.parameters) return undefined;
        const parameters = JSON.parse(x.parameters);
        const { OutputId, OutputField, Filters } = parameters;
        const targetFilter = JSON.stringify(targetParameter.filters);
        return (
          targetParameter.output === OutputId &&
          targetParameter.outputField?.toString()?.toLowerCase() === OutputField?.toString()?.toLowerCase() &&
          targetFilter === Filters
        );
      });
    }

    if (type === INT_TAX_RATE) {
      formulaValue = dataReferences.current.find(x => {
        if (!x.parameters) return undefined;

        const parameters = JSON.parse(x.parameters);
        const { Country, PeriodStartDate, TaxableIncome } = parameters;
        return (
          Country?.toString()?.toLowerCase() === targetParameter.country?.toString()?.toLowerCase() &&
          formatCellValue(PeriodStartDate) === formatCellValue(targetParameter.periodStartDate) &&
          TaxableIncome === targetParameter.taxableIncome
        );
      });
    }

    if (type === STATE_TAX_RATE) {
      formulaValue = dataReferences.current.find(x => {
        if (!x.parameters) return undefined;
        const parameters = JSON.parse(x.parameters);
        const { Jurisdiction, PeriodStartDate, TaxableIncome } = parameters;
        return (
          Jurisdiction?.toString()?.toLowerCase() === targetParameter.jurisdiction?.toString()?.toLowerCase() &&
          formatCellValue(PeriodStartDate) === formatCellValue(targetParameter.periodStartDate) &&
          TaxableIncome === targetParameter.taxableIncome
        );
      });
    }

    if (type === STATE_APPORTION) {
      formulaValue = dataReferences.current.find(x => {
        if (!x.parameters) return undefined;
        const parameters = JSON.parse(x.parameters);
        const { State, Date, Type } = parameters;
        return (
          State?.toString()?.toLowerCase() === targetParameter.state?.toString()?.toLowerCase() &&
          formatCellValue(Date.toString()) === formatCellValue(targetParameter.date.toString()) &&
          Type === targetParameter.factor
        );
      });
    }
    if (type === DATA_LINK) {
      formulaValue = dataReferences.current.find(x => {
        if (!x.parameters) return undefined;
        const parameters = JSON.parse(x.parameters);
        const { workpaperName, sheetName, row, column, taxPeriod } = parameters;
        return (
          workpaperName?.toString()?.toLowerCase() === targetParameter.workpaperName?.toString()?.toLowerCase() &&
          sheetName?.toString()?.toLowerCase() === targetParameter.sheetName?.toString()?.toLowerCase() &&
          row?.toString()?.toLowerCase() === targetParameter.row?.toString()?.toLowerCase() &&
          column?.toString()?.toLowerCase() === targetParameter.column?.toString()?.toLowerCase() &&
          taxPeriod?.toString()?.toLowerCase() === targetParameter.taxPeriod?.toString()?.toLowerCase()
        );
      });
    }
  } catch (error) {
    console.log('Formula return value error:', error);
    return '';
  }

  const resolveValue = dataReferenceValues.current.find(x => {
    const { row, column, sheetName, type } = JSON.parse(x.key);
    return (
      row === targetParameter.row &&
      column === targetParameter.column &&
      sheetName === targetParameter.sheetName &&
      type === targetParameter.type
    );
  })?.value;
  return formulaValue?.value || resolveValue;
}

export async function generateRequestPayload(customFormulaQueue, dataReferenceValues) {
  return customFormulaQueue.current.map(({ id, row, column, parameters, type, sheetName, value }) => {
    const resolvedValue = dataReferenceValues?.current.find(x => {
      if (x.key) {
        const key = JSON.parse(x.key);
        return row === key.row && column === key.column && sheetName === key.sheetName && type === key.type;
      }
      return null;
    });
    const referenceValue = resolvedValue?.value || value;
    return {
      referenceId: id,
      row,
      column,
      parameters: JSON.stringify(parameters),
      referenceType: type,
      sheetName,
      oldValue: referenceValue,
      newValue: referenceValue,
      extraData: resolvedValue?.extraData,
    };
  });
}

export function createInMemoryDataReferences(
  activeSheet,
  dataReferences,
  customFormulaQueue,
  isUndoDeleteCommandAction = false
) {
  if (isUndoDeleteCommandAction) {
    return;
  }
  const referencesToCreate = [];
  customFormulaQueue.current.forEach(
    ({ id, row, column, parameters, type, sheetName, workpaperId, value, extraData }) => {
      const isCellReview = type === CELL_REVIEW;
      const referenceIndex = dataReferences.current.findIndex(
        x =>
          x.row === row &&
          x.column === column &&
          x.type === type &&
          x.sheetName === sheetName &&
          x.parameters &&
          (!isCellReview || JSON.parse(x?.parameters)?.ReviewerUserId === parameters.ReviewerUserId)
      );
      const formulaParameters = JSON.stringify(parameters);
      if (referenceIndex !== -1) {
        const { reference } = dataReferences.current[referenceIndex];
        if (reference) {
          let formattedCellValue = value;
          if (isCellReview) {
            const cellValue = activeSheet?.getValue(reference.row, reference.column);

            if (
              cellValue === GC.Spread.CalcEngine.Errors.NotAvailable ||
              cellValue.GC.Spread.CalcEngine.Errors.NotAvailable.toString()
            ) {
              formattedCellValue = '';
            }
          }
          dataReferences.current[referenceIndex].value = formattedCellValue;
          dataReferences.current[referenceIndex].extraData = extraData;
          dataReferences.current[referenceIndex].parameters = formulaParameters;
        }
      } else {
        referencesToCreate.push({
          id,
          row,
          column,
          parameters: formulaParameters,
          type,
          sheetName,
          value,
          workpaperId,
        });
      }
    }
  );
  dataReferences.current = [...dataReferences.current, ...referencesToCreate];
}

export async function updateInMemoryDataReferences(dataReferences, customFormulaQueue, dataReferenceValues) {
  const referencesToCreate = [];
  const referenceMap = new Map(
    dataReferences.current.map(ref => [`${ref.row}-${ref.column}-${ref.sheetName}-${ref.type}`, ref])
  );
  const queueMap = new Map(
    customFormulaQueue.current.map(ref => [`${ref.row}-${ref.column}-${ref.sheetName}-${ref.type}`, ref])
  );

  dataReferenceValues.current.forEach(({ key, value, extraData }) => {
    const { row, column, sheetName, type } = JSON.parse(key);
    const refKey = `${row}-${column}-${sheetName}-${type}`;

    if (queueMap.has(refKey)) {
      const queueRef = queueMap.get(refKey);
      queueRef.value = value;

      if (referenceMap.has(refKey)) {
        const reference = referenceMap.get(refKey);
        reference.value = value || '';
        reference.parameters = JSON.stringify(queueRef.parameters);
        reference.type = queueRef.type;
        reference.extraData = extraData;
      } else {
        referencesToCreate.push({
          id: queueRef.id,
          row,
          column,
          parameters: JSON.stringify(queueRef.parameters),
          type: queueRef.type,
          sheetName,
          value: value || '',
          extraData,
        });
      }
    }
  });

  dataReferences.current.push(...referencesToCreate);
}

export async function updateCustomFormulaQueue(sourceQueue, dataReferenceValues) {
  if (!sourceQueue.current.length || !dataReferenceValues.current.length) return;
  const values = [...dataReferenceValues.current];
  const processIdsToRemove = new Set(values.map(item => JSON.parse(item.key).processId));
  dataReferenceValues.current = dataReferenceValues.current = dataReferenceValues.current.filter(
    item => !processIdsToRemove.has(JSON.parse(item.key).processId)
  );
  sourceQueue.current = sourceQueue.current.filter(item => !processIdsToRemove.has(item.processId));
}

export async function renderResolvedValues(spreadRef, dataReferenceValues) {
  const activeSheet = spreadRef.current.getActiveSheet();
  const activeSheetName = activeSheet.name();
  const data = { defaultValue: loadingString };
  let hasMismatch = false;
  for (const resolvedValue of dataReferenceValues.current) {
    if (!resolvedValue.key) continue;
    const { sheetName, row, column, type } = JSON.parse(resolvedValue.key);
    const sheet = sheetName === activeSheetName ? activeSheet : spreadRef.current.getSheetFromName(sheetName);
    if (!sheet) {
      console.warn(`Sheet ${sheetName} does not exist for reference`, resolvedValue);
      continue;
    }
    const currentValue = sheet.getValue(row, column);
    if (
      formatCustomFormulaReturnValue(currentValue, data, sheet, row, column, type) !==
      formatCustomFormulaReturnValue(resolvedValue.value, data, sheet, row, column, type)
    ) {
      hasMismatch = true;
      break;
    }
  }

  if (!hasMismatch) return;
  activeSheet.suspendPaint();
  activeSheet.suspendCalcService();
  for (const resolvedValue of dataReferenceValues.current) {
    if (!resolvedValue.key) continue;
    const { sheetName, row, column } = JSON.parse(resolvedValue.key);
    const sheet = sheetName === activeSheetName ? activeSheet : spreadRef.current.getSheetFromName(sheetName);
    if (!sheet) continue;
    resolvedValue.previousValue = sheet.getValue(row, column);
    sheet.recalcRange(row, column, 1, 1);
  }

  activeSheet.resumeCalcService(false);
  activeSheet.resumePaint();
}

function isValidDate(dateString) {
  for (let index = 0; index < momentDateFormats.length; index++) {
    const dateFormat = momentDateFormats[index];
    const parsedDate = moment(dateString, dateFormat.toString(), true);
    if (parsedDate.isValid()) {
      return true;
    }
  }
  return false;
}

function timeZoneInstance(dateString) {
  const charactersAfterTime = dateString.toString().match(/(\d{2}:\d{2}:\d{2})\s(.*)/);

  return charactersAfterTime ? charactersAfterTime[2] : undefined;
}

export function formatCellValue(value, isApiTrigger = false) {
  try {
    const isPercentage = /^\d+(\.\d+)?%$/.test(value);

    if ((typeof value === 'string' || value instanceof String || value instanceof Date) && !isPercentage) {
      if (!isNaN(Date.parse(value))) {
        const timeZone = timeZoneInstance(value);
        const dateWithoutTimeZone = (timeZone ? value.toString().replace(timeZone, '') : value).trim();
        const isValidDateValue = isValidDate(dateWithoutTimeZone);
        if (isValidDateValue) {
          return isApiTrigger
            ? moment(new Date(dateWithoutTimeZone).toLocaleDateString()).format('MM/DD/YYYY')
            : new Date(dateWithoutTimeZone).toLocaleDateString();
        }
      }
    }
  } catch (error) {
    CustomLogger &&
      CustomLogger.pushLog(CustomLogger.operations.FORMULA_RECALCULATION, {
        error: JSON.stringify(error),
        value: JSON.stringify(value),
      });
  }

  return value;
}
