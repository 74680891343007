import { FORMULA_COLOR } from '../../shared/colors';
import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/formula_block_icon.svg';
import hintImage from '../icons/formula_hint_img.svg';
import FormulaElementInspector from '../../FormulaElementInspector';
import { FORMULA } from '../types/shared/typesConstants';
import {
  getOriginalName,
  getOriginalNameFromInternal,
  getInternalNameFromOriginal,
  generateFormulaWithInternalName,
  generateFormulaWithOriginalName,
} from '../../../shared/utils/FieldHashUtils';
import { validateFormula } from '../../FormulaElementInspector/api';
import { TEXT, NUMERIC, DATE } from '../../shared/fieldTypes';
import {
  createDefaultFormulaOverride,
  FORMULA_OVERRIDE_KEY,
  isOverrideEnabled,
  updateOutputFieldTypeForOverride,
} from '../../FormulaElementInspector/formulaOverride';
export class FormulaElementType extends TransformationElementType {
  static TYPE = FORMULA;
  updatedFormulaType = {};

  static HELP_TEXT = `The formula block allows you to append a column to your data and define a formula for that column. The formula supplied will be applied to each row in the data set.<img src=${hintImage} alt="Formula hint" />`;

  constructor() {
    super(FormulaElementType.TYPE, 'Formula', FORMULA_COLOR, iconImage, FormulaElementType.HELP_TEXT);
  }

  get initialData() {
    return {
      name: this.label,
      type: this.type,
      outputFieldName: '',
      outputFieldType: null,
      formula: '',
      formulaIsValid: false,
      AIGenerated: false,
      validationFromElementType: { block: '', validationRan: false },
      [FORMULA_OVERRIDE_KEY]: createDefaultFormulaOverride(),
    };
  }

  get maxCount() {
    return -1;
  }

  get inspectorComponent() {
    return FormulaElementInspector;
  }

  applySourceElements(elementData, sourceElements) {
    let fields = elementData.fields || [];
    const fieldsReferenceInFormula = fields.filter(field => elementData.formula.includes(field.name));
    let validationFromElementType = elementData.validationFromElementType?.validationRan;

    if (sourceElements.in) {
      const newElementData = super.applySourceElements(elementData, sourceElements);
      fields = newElementData.fields;
    }
    let outputFieldType = elementData.outputFieldType;

    if (validationFromElementType && elementData.name === elementData.validationFromElementType?.block) {
      // Will only run when field type is updated from upstream block
      validationFromElementType = false;
      outputFieldType = this.updatedFormulaType[elementData.name] ?? elementData.outputFieldType;
      delete this.updatedFormulaType[elementData.name];
    }

    const formula = generateFormulaWithOriginalName(elementData.formula || '', elementData.fields || []);
    const outputFieldName = getOriginalNameFromInternal(elementData.outputFieldName || '', elementData.fields || []);

    if (fieldsReferenceInFormula?.length > 0) {
      const updatedFields = fieldsReferenceInFormula.filter(function (field) {
        const hasFieldName = fields.find(f => f.name === field.name);
        return hasFieldName ? field.type !== hasFieldName.type : false;
      });

      if (updatedFields.length > 0) {
        //When promise is complete, will update formula outputType for next render
        validationFromElementType = true;
        this.validateFormulaType(formula, fields).then(value => {
          this.updatedFormulaType[elementData.name] = value?.isValid ? value?.resultType : elementData.outputFieldType;
        });
      }
    }

    if (
      elementData.outputFieldName &&
      elementData.outputFieldType &&
      !fields?.find(f => getOriginalName(f) === outputFieldName)
    ) {
      const formulaField = (elementData.fields || [])?.find(f => getOriginalName(f) === outputFieldName);

      if (formulaField && formulaField?.name === elementData?.outputField && formulaField?.type === outputFieldType) {
        fields.push(formulaField);
      } else {
        fields.push({
          name: outputFieldName,
          type: outputFieldType,
        });
      }
    }

    updateOutputFieldTypeForOverride(fields, elementData && elementData[FORMULA_OVERRIDE_KEY], outputFieldType);

    return {
      ...elementData,
      fields: fields,
      outputFieldType: outputFieldType,
      formula,
      outputFieldName,
      AIGenerated: elementData?.AIGenerated,
      validationFromElementType: { block: elementData.name, validationRan: validationFromElementType },
      [FORMULA_OVERRIDE_KEY]: elementData[FORMULA_OVERRIDE_KEY],
    };
  }

  generateReadableFormula(normal, fields) {
    return generateFormulaWithOriginalName(normal, fields);
  }

  getPreviewColumns(elementData) {
    if (!elementData?.fields) {
      return [];
    }

    let previewColumns = [...elementData.fields];
    const overrideEnabled = isOverrideEnabled(elementData);

    let outputField = previewColumns?.find(f => {
      return f.name === elementData?.outputFieldName;
    });

    if (overrideEnabled) {
      previewColumns.pop();
    }

    if (elementData.outputFieldHash) {
      outputField.original_name = outputField.name;
      outputField.name = elementData.outputFieldHash;
    }
    return previewColumns;
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      outputFieldName: getInternalNameFromOriginal(elementData.outputFieldName, elementData.fields),
      outputFieldType: elementData.outputFieldType,
      formula: generateFormulaWithInternalName(elementData.formula, elementData.fields),
      AIGenerated: elementData?.AIGenerated,
      [FORMULA_OVERRIDE_KEY]: elementData[FORMULA_OVERRIDE_KEY],
    };
  }

  validateFormulaType(formula, fields) {
    const runFormulaValidation = new Promise((resolve, reject) => {
      const data = validateFormula(formula, fields, [TEXT, NUMERIC, DATE]);
      resolve(data);
    });
    return runFormulaValidation;
  }
}
