import * as wkpFetch from '../../../_shared/fetch';
import { workpapersSpreadsheetModelHostName } from '../../../../configs/params';
import { httpRequestWithRetry } from '../Spreadsheet/_spreadsheets/utils';

async function fetchFilteredData({ basePath, workpaperId, sheetName = '', filters = new Map() }) {
  let urlFilter = '';
  for (const [k, v] of filters) {
    urlFilter += `${k}=${v}&`;
  }
  urlFilter = urlFilter.slice(0, -1);

  const url = `${workpapersSpreadsheetModelHostName}/${basePath}/${workpaperId}${sheetName && '/' + sheetName}${urlFilter && '?' + urlFilter}`;
  const response = await httpRequestWithRetry(() => wkpFetch.get(url));

  if (!response.ok) {
    return null;
  }
  return await response.json();
}

export async function getWorkpaperDataReferences({ workpaperId, sheetName = '', filters = new Map() }) {
  return fetchFilteredData({
    basePath: 'datareferences',
    workpaperId,
    sheetName,
    filters,
  });
}

export async function getWorkpaperOutSyncDataReferences({ workpaperId, sheetName = '', filters = new Map() }) {
  return fetchFilteredData({
    basePath: 'datareferences/outSync',
    workpaperId,
    sheetName,
    filters,
  });
}

export async function createWorkpaperDataReferences(workpaperId, metadata) {
  if (metadata?.length) {
    const body = { details: metadata };
    body.workpaperId = workpaperId;

    const response = await httpRequestWithRetry(() =>
      wkpFetch.post(`${workpapersSpreadsheetModelHostName}/datareferences/create/${workpaperId}`, {
        body: JSON.stringify(body),
      })
    );
    if (!response || !response.ok) {
      return null;
    }
    try {
      return await response.json();
    } catch {
      return null;
    }
  }
}

export async function resolveFormulaValues(workpaperId, outputRequest) {
  const requestBody = JSON.stringify(outputRequest);
  const response = await httpRequestWithRetry(() =>
    wkpFetch.post(`${workpapersSpreadsheetModelHostName}/datareferences/resolve/${workpaperId}`, {
      body: requestBody,
    })
  );

  window.dataReferenceResolve && window.dataReferenceResolve.resolve();

  return response;
}

export async function updateReferences(workpaperId, references) {
  const body = JSON.stringify({
    workpaperId,
    details: references.map(({ row, column, id: referenceId, parameters, value, sheetName, extraData }) => ({
      row,
      column,
      referenceId,
      newValue: value,
      sheetName,
      parameters: typeof parameters === 'string' ? parameters : JSON.stringify(parameters),
      extraData,
    })),
  });
  return await httpRequestWithRetry(() =>
    wkpFetch.put(`${workpapersSpreadsheetModelHostName}/datareferences/${workpaperId}`, {
      body,
    })
  );
}

export async function markDataReferencesAsReviewed(workpaperId, dataReferences) {
  const body = JSON.stringify({ dataReferences });
  const url = `${workpapersSpreadsheetModelHostName}/datareferences/mark-as-reviewed/${workpaperId}`;

  return await wkpFetch.put(url, { body });
}

export async function deleteReferences(workpaperId, referenceIds) {
  const body = JSON.stringify({
    workpaperId: workpaperId,
    referenceIds,
  });
  const response = await httpRequestWithRetry(() =>
    wkpFetch.remove(`${workpapersSpreadsheetModelHostName}/datareferences/${workpaperId}`, {
      body,
    })
  );
  if (!response.ok) {
    return null;
  }
  return response;
}

export async function getAssociatedDataTransformations(workpaperId, taxPeriod, currTaxPeriod) {
  const response = await wkpFetch.get(
    `${workpapersSpreadsheetModelHostName}/datareferences/${workpaperId}/tax-period/${taxPeriod}/current-tax-period/${currTaxPeriod}`
  );
  if (!response.ok) {
    return null;
  }
  return await response.json();
}

export async function getDataReferencesByWorkpaperData(metadata) {
  const body = JSON.stringify(metadata);
  const response = await httpRequestWithRetry(() =>
    wkpFetch.post(`${workpapersSpreadsheetModelHostName}/datareferences/get-by-workpaper-details`, {
      body,
    })
  );
  if (!response.ok) {
    return null;
  }
  return await response.json();
}
