import { useEffect } from 'react';

export default function useReferenceScheduler(processFormulaQueue, processCallback, processingInterval) {
  const executeIfDataPresent = async () => {
    if (processFormulaQueue.current !== null) {
      await processCallback();
    }
  };

  useEffect(() => {
    const timerId = setTimeout(executeIfDataPresent, processingInterval);
    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processFormulaQueue.current]);
}
