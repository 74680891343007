import { useContext, useEffect, useRef } from 'react';
import EditorContext from './EditorContext';
import { useMetadata } from './Spreadsheet/SideBar/SettingsPanel/useMetadata';
import GC from '../../../SpreadSheets';
import { useEditorToggle } from './useEditorToggle';
import {
  createWorkpaperDataReferences,
  deleteReferences,
  getDataReferencesByWorkpaperData,
  getWorkpaperDataReferences,
} from './DataReference/apis';
import { DATA_LINK } from '../../_shared/DataReference/ReferenceType';
import { v4 as uuidv4 } from 'uuid';
import { DATA_LINK_ADDED } from './HistoryTracker/useHistoryTracker';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { DATA_LINKS } from '../../../constants/featureFlags';
import { setValueOnServer } from './useWorkpaper/processCommand';
import { BroadcastChannels, EventTypes } from './useBroadcast/constants';
import { applyTimestamp } from '../../../sjs-cmd-process';
import { generateFormulaFromDatabase } from './Spreadsheet/_spreadsheets/formulas';
import useDataLinkSync from './useDataLinkSync';
import { getDataFromLocalStorage } from '../../_shared/storage';
import {
  extractFormulaValues,
  formatLinkValue,
  filterDataLinkReferences,
  groupWorkbooks,
  isDataLinkFormula,
  toA1Notation,
} from './datalinkHelper';
import { executeRefreshDataLinks } from './Spreadsheet/_spreadsheets/commands/refreshDataLinks';

export function useDataLink({ workpaperId }) {
  const targetWorkbookStorage = 'linking.target';
  const sourceWorkbookStorage = 'linking.source';
  const endDataLinkCommandName = 'endDataLink';
  const sourceSelectionColor = '#AB63DB';
  const defaultSelectionColor = '#0073FF';
  const storageListener = useRef(null);
  const unsubscribeSourceUpdateListener = useRef(null);
  const unsubscribeTargetCreatedListener = useRef(null);
  const isDataLinksEnabled = isFeatureFlagEnabled(DATA_LINKS);

  const {
    spreadRef,
    setDataLinkStatus,
    addToHistory,
    enqueueCommands,
    dataReferences,
    enqueueDataLinks,
    loadWorkpaperDataReferences,
    createChannel,
    sendMessage,
    subscribeToChannel,
  } = useContext(EditorContext);

  const { getMetadata } = useMetadata({ workpaperId });
  const { disableEditor, enableEditor } = useEditorToggle();
  const { startOutSyncDataLinksCheck, stopOutSyncDataLinksCheck } = useDataLinkSync({ workpaperId });

  async function getRequestParameters(name, row, column, sheetName, params, sourceWorkpaperId = null) {
    if (!params) return;
    const {
      name: sourceWorkpaperName,
      sheetName: sourceSheetName,
      a1Coordinate,
      row: sourceRow,
      column: sourceColumn,
    } = params;
    const sourceTaxPeriod = params.taxPeriod ? params.taxPeriod : '';

    const sourceParameters = {
      ...(sourceTaxPeriod && { taxPeriod: sourceTaxPeriod }),
      ...(sourceWorkpaperId && { workpaperId: sourceWorkpaperId }),
      workpaperName: sourceWorkpaperName,
      sheetName: sourceSheetName,
      a1Coordinate,
      row: sourceRow,
      column: sourceColumn,
    };

    const requestParameters = {
      row,
      column,
      type: DATA_LINK,
      sheetName,
      name,
      id: uuidv4(),
      workpaperId,
      parameters: sourceParameters,
    };

    return requestParameters;
  }

  async function findDataReference({ sheetName, row, col }) {
    return dataReferences.current.find(
      ref =>
        ref.type === DATA_LINK &&
        ref.sheetName === sheetName &&
        ref.row === row &&
        ref.column === col &&
        ref.workpaperId === workpaperId
    );
  }

  async function cleanDataLinks() {
    try {
      const activeSheet = spreadRef?.current?.getActiveSheet();
      if (!activeSheet) return;
      const getEffectiveReferenceType = ref => ref.referenceType ?? ref.type;

      const dataLinkReferences = dataReferences.current.filter(
        ref =>
          getEffectiveReferenceType(ref) === DATA_LINK &&
          ref.workpaperId === workpaperId &&
          ref.sheetName === activeSheet.name()
      );

      if (!dataLinkReferences?.length) return;
      const referencesToDelete = dataLinkReferences
        .filter(({ row, column }) => !isDataLinkFormula(activeSheet.getFormula(row, column)))
        .map(({ id }) => id);

      if (referencesToDelete?.length) {
        const deleteSet = new Set(referencesToDelete);
        dataReferences.current = dataReferences.current.filter(ref => !deleteSet.has(ref.id));

        await deleteReferences(workpaperId, referencesToDelete);
      }
    } catch (error) {
      console.error('Error cleaning data links:', error);
    }
  }

  async function handleFinishDataLink() {
    const sheet = spreadRef.current.getActiveSheet();
    const [{ row, col: column }] = sheet.getSelections();
    const sheetName = sheet.name();
    const value = sheet.getValue(row, column);
    const { taxPeriod, name } = await getMetadata();

    localStorage.setItem(
      sourceWorkbookStorage,
      JSON.stringify({
        workpaperId,
        taxPeriod,
        name,
        sheetName,
        row,
        column,
        value,
        isFinal: true,
      })
    );
  }

  function sourceWorkbookHandler(linkStorageState) {
    if (linkStorageState && linkStorageState.workpaperId !== workpaperId) {
      setDataLinkStatus({ active: true, targetWorkpaperName: linkStorageState.workpaperName });
      disableEditor();
      spreadRef.current.commandManager().setShortcutKey('commitInputNavigationDown', false);
      spreadRef.current.commandManager().setShortcutKey(endDataLinkCommandName, GC.Spread.Commands.Key.enter);
      spreadRef.current.getActiveSheet().options.selectionBorderColor = sourceSelectionColor;
    } else {
      setDataLinkStatus({ active: false, targetWorkpaperName: null });
      enableEditor();
      spreadRef.current.commandManager().setShortcutKey('commitInputNavigationDown', GC.Spread.Commands.Key.enter);
      spreadRef.current.commandManager().setShortcutKey(endDataLinkCommandName, false);
      spreadRef.current.getActiveSheet().options.selectionBorderColor = defaultSelectionColor;
      loadWorkpaperDataReferences(workpaperId, true);
    }
  }

  async function targetWorkbookHandler(linkStorageState, event) {
    if (linkStorageState && linkStorageState.workpaperId === workpaperId) {
      const {
        workpaperId: sourceId,
        taxPeriod,
        name,
        sheetName,
        row: sourceRow,
        column: sourceColumn,
        value: rawValue,
        isFinal,
      } = JSON.parse(event.newValue);
      const value = rawValue === null || rawValue === undefined ? '' : rawValue;
      const { row: targetRow, column: targetColumn, previousValue, isNegative } = linkStorageState;
      const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';
      const a1Coordinate = toA1Notation(sourceRow, sourceColumn);
      const sign = isNegative ? '-' : '';

      const ss = spreadRef.current;
      const sheet = ss.getActiveSheet();

      const referenceId = uuidv4();
      const dataLinkReference = {
        workpaperId,
        row: targetRow,
        column: targetColumn,
        referenceId,
        id: referenceId,
        sheetName: sheet.name(),
        referenceType: DATA_LINK,
        type: DATA_LINK,
        oldValue: value,
        newValue: value,
        value,
        processId: uuidv4(),
        parameters: JSON.stringify({
          workpaperId: sourceId,
          taxPeriod,
          workpaperName: name,
          sheetName,
          a1Coordinate,
          row: sourceRow,
          column: sourceColumn,
        }),
      };
      const command = {
        cmd: 'editCell',
        sheetName: sheet.name(),
        row: targetRow,
        col: targetColumn,
        applyResult: 0,
        activeRowIndex: targetRow,
        activeColIndex: targetColumn,
        actionType: 0,
        workpaperName: name,
        taxPeriod: effectiveTaxPeriod,
      };
      const formulas = await processCustomFormulas([dataLinkReference]);
      const matchingFormula = formulas?.find(({ key }) => {
        const [sheetName, row, column] = key.split('-');
        return (
          sheetName === dataLinkReference.sheetName &&
          Number(row) === dataLinkReference.row &&
          Number(column) === dataLinkReference.column
        );
      });

      if (matchingFormula) {
        if (matchingFormula.formula) {
          if (!isFinal) return;
          command.newValue = matchingFormula.formula;
          ss.commandManager().execute(command);
          if (isDataLinkFormula(matchingFormula.formula)) {
            addToHistory(
              {
                cmd: DATA_LINK_ADDED,
                undo: () => {
                  ss.undoManager().undo();
                  sheet.setValue(targetRow, targetColumn, previousValue);
                },
              },
              ss
            );
          }
        }
        sheet.endEdit(true);
        return;
      } else {
        if (!isFinal) return;

        const partialWorkbook = {
          [sheetName]: {
            [sourceRow]: {
              [sourceColumn]: formatLinkValue(value),
            },
          },
        };
        const formula = `${sign}'${effectiveTaxPeriod}[${name}]${sheetName}'!${a1Coordinate}`;
        command.newValue = `=${formula}`;
        command.partialWorkbook = partialWorkbook;
        command.ignoreValueResolve = true;
        ss.commandManager().execute(command);
        addToHistory(
          {
            cmd: DATA_LINK_ADDED,
            undo: () => {
              ss.undoManager().undo();
              sheet.setValue(targetRow, targetColumn, previousValue);
            },
          },
          ss
        );
        ss.updateExternalReference(name, partialWorkbook, effectiveTaxPeriod, true);
        const filteredDataLinkReferences = filterDataLinkReferences([dataLinkReference], formulas);
        if (filteredDataLinkReferences) {
          createWorkpaperDataReferences(workpaperId, filteredDataLinkReferences);
          if (filterDataLinkReferences.length) dataReferences.current.push(filteredDataLinkReferences[0]);
          sendMessage(BroadcastChannels.TargetCreatedReference, {
            type: EventTypes.DataLinkCreated,
            details: filteredDataLinkReferences,
          });
        }
        sheet.endEdit(true);
      }
      cleanDataLinks();
    }
  }

  function setupSourcesUpdateChannelListener() {
    function listener(event) {
      const updatedReferences = event.data.details;
      if (updatedReferences?.length) {
        const filteredReferences = updatedReferences.filter(ref => ref.workpaperId === workpaperId);
        dataReferences.current = dataReferences.current.map(
          ref => filteredReferences.find(r => r.id === ref.id) ?? ref
        );
      }
    }
    const unsubscribeListener = subscribeToChannel(
      BroadcastChannels.SourceUpdateReferences,
      EventTypes.DataLinkSourceUpdated,
      listener
    );
    return unsubscribeListener;
  }

  function setupTargetCreatedChannelListener() {
    function listener(event) {
      const createdReferences = event.data.details;
      for (const createdReference of createdReferences) {
        const params = JSON.parse(createdReference.parameters);
        if (params.workpaperId === workpaperId) {
          dataReferences.current = [...dataReferences.current, createdReference];
          startOutSyncDataLinksCheck();
        }
      }
    }
    const unsubscribeListener = subscribeToChannel(
      BroadcastChannels.TargetCreatedReference,
      EventTypes.DataLinkCreated,
      listener
    );
    return unsubscribeListener;
  }

  function setupStorageListener() {
    if (!window) return;
    function listener(event) {
      if (event.storageArea === localStorage) {
        const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
        switch (event.key) {
          case targetWorkbookStorage: {
            sourceWorkbookHandler(linking);
            break;
          }
          case sourceWorkbookStorage: {
            targetWorkbookHandler(linking, event);
            break;
          }
          default:
            break;
        }
      }
    }
    window.addEventListener('storage', listener);
    return listener;
  }

  function sourceWorkbookSpreadListeners(readOnly) {
    async function setDataLinkSource(row, column, sheetName, value) {
      const { taxPeriod, name } = await getMetadata();

      localStorage.setItem(
        sourceWorkbookStorage,
        JSON.stringify({
          workpaperId,
          taxPeriod,
          name,
          sheetName,
          row,
          column,
          value,
        })
      );
    }
    const ss = spreadRef.current;
    if (!ss | readOnly) return;
    ss.bind(GC.Spread.Sheets.Events.SelectionChanged, function (e, { sheet, newSelections, sheetName }) {
      const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
      if (linking && workpaperId !== linking.workpaperId) {
        const [{ row, col: column, colCount, rowCount }] = newSelections;
        if (colCount > 1 || rowCount > 1) {
          GC.Spread.Sheets.Designer.showMessageBox(
            'Invalid formula',
            'Warning',
            GC.Spread.Sheets.Designer.MessageBoxIcon.warning
          );
        }
        const value = sheet.getValue(row, column);
        setDataLinkSource(row, column, sheetName, value);
      }
    });

    ss.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, function (e, { newSheet, oldSheet }) {
      const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
      if (linking && workpaperId !== linking.workpaperId) {
        oldSheet.options.selectionBorderColor = defaultSelectionColor;
        newSheet.options.selectionBorderColor = sourceSelectionColor;
        const [{ row, col }] = newSheet.getSelections();
        const value = newSheet.getValue(row, col);
        const cellData = JSON.parse(localStorage.getItem(sourceWorkbookStorage));
        cellData.sheetName = newSheet.name();
        cellData.row = row;
        cellData.column = col;
        cellData.value = value;
        localStorage.setItem(sourceWorkbookStorage, JSON.stringify(cellData));
      }
    });
  }

  function targetWorkbookSpreadListener(readOnly) {
    async function setDataLinkTarget(row, column, previousValue, isNegative) {
      const { name } = await getMetadata();

      localStorage.setItem(
        targetWorkbookStorage,
        JSON.stringify({
          workpaperId,
          workpaperName: name,
          row,
          column,
          previousValue,
          isNegative,
        })
      );
    }
    const ss = spreadRef.current;

    if (!ss || readOnly) return;
    ss.bind(GC.Spread.Sheets.Events.EditChange, function (e, { sheet, row, col, editingText }) {
      if (/^=[-+]*$/.test(editingText)) {
        const isNegativeFlag = (editingText.match(/-/g) || []).length % 2 !== 0;
        setDataLinkTarget(row, col, sheet.getValue(row, col), isNegativeFlag);
      } else {
        localStorage.removeItem(targetWorkbookStorage);
        localStorage.removeItem(sourceWorkbookStorage);
      }
    });

    ss.bind(GC.Spread.Sheets.Events.EditEnded, function (e, a) {
      localStorage.removeItem(targetWorkbookStorage);
      localStorage.removeItem(sourceWorkbookStorage);
    });

    async function formulaEventHandler(e, args) {
      if (isDataLinksEnabled) {
        if (e.type === 'CellClick') {
          const getEffectiveReferenceType = ref => ref.referenceType ?? ref.type;

          const dataLinkReferences = dataReferences.current.filter(
            ref => getEffectiveReferenceType(ref) === DATA_LINK && ref.workpaperId === workpaperId
          );

          if (!dataLinkReferences?.length) return;

          await refreshDataLinkFormulas(true);
          await cleanDataLinks();
        }
      }
    }

    ss.bind(GC.Spread.Sheets.Events.CellClick, formulaEventHandler);
  }

  async function processDataLinkFromCommand({ command, sheet }) {
    if (isDataLinksEnabled) {
      const createDataLinkReferences = [];
      sheet = sheet ?? spreadRef?.current?.getSheetFromName(command.sheetName);
      const selections = command.selections;
      if (selections?.length && !command.ignoreValueResolve) {
        const { workpaperName } = JSON.parse(getDataFromLocalStorage(`${workpaperId}-metadata`));
        for (let i = 0; i < selections.length; i++) {
          const { row, col, rowCount, colCount } = selections[i];
          for (let r = 0; r < rowCount; r++) {
            for (let c = 0; c < colCount; c++) {
              const selectedRow = row + r;
              const selectedCol = col + c;
              const cellFormula = sheet?.getFormula(selectedRow, selectedCol);
              if (isDataLinkFormula(cellFormula)) {
                const formulaParams = extractFormulaValues(cellFormula);
                if (formulaParams) {
                  let parameters;
                  const reference = findDataReference({
                    sheetName: command.sheetName,
                    row,
                    col,
                  });
                  if (reference) {
                    parameters =
                      typeof reference.parameters === 'string'
                        ? JSON.parse(reference.parameters)
                        : reference.parameters;
                  }
                  const request = await getRequestParameters(
                    workpaperName,
                    selectedRow,
                    selectedCol,
                    command.sheetName,
                    formulaParams,
                    parameters?.workpaperId
                  );
                  request['processId'] = uuidv4();
                  createDataLinkReferences.push(request);
                }
              }
            }
          }
        }
      }

      if (createDataLinkReferences.length) {
        const formulas = await processCustomFormulas(createDataLinkReferences);
        const filteredDataLinkReferences = filterDataLinkReferences(createDataLinkReferences, formulas);
        if (filteredDataLinkReferences.length) {
          await loadWorkpaperDataReferences(workpaperId);
          await enqueueDataLinks(filteredDataLinkReferences);
          await processDataLinks(
            filteredDataLinkReferences,
            sheet,
            false,
            command.cmd === 'editCell' && isDataLinkFormula(command.newValue)
          );
        }
      }
      await cleanDataLinks();
    }
  }

  async function processCustomFormulas(createDataLinkReferences) {
    if (!createDataLinkReferences?.length) return [];
    const metadata = createDataLinkReferences.map(({ row, column, sheetName, parameters }) => {
      const formulaParameters = typeof parameters === 'string' ? JSON.parse(parameters) : parameters;
      return {
        key: `${sheetName}-${row}-${column}`,
        row: formulaParameters.row,
        column: formulaParameters.column,
        sheetName: formulaParameters.sheetName,
        workpaperName: formulaParameters.workpaperName,
        taxPeriod: formulaParameters.taxPeriod,
      };
    });

    const referencesFormulas = await getDataReferencesByWorkpaperData(metadata);
    if (!referencesFormulas?.length) return [];

    const formulas = referencesFormulas.map(reference => ({
      ...reference,
      formula: generateFormulaFromDatabase(reference),
    }));

    const commands = [];
    formulas.forEach(({ key, formula }) => {
      const [sheetName, row, column] = key.split('-');
      const sheet = spreadRef?.current?.getSheetFromName(sheetName);
      if (!sheet) return;

      sheet.setFormula(Number(row), Number(column), formula);
      const command = setValueOnServer(sheet, Number(row), Number(column), formula);
      commands.push({ commandText: JSON.stringify(applyTimestamp(command)) });
    });

    if (commands.length) enqueueCommands(commands);

    return formulas;
  }

  async function processDataLinks(dataLinkReferences, sheet, avoidResolveFormula = false, overrideMetadata = false) {
    const commands = [];
    const referencesToUpdate = [];
    const groupedWorkbooks = new Map();
    const chunkSize = 10;
    for (let i = 0; i < dataLinkReferences.length; i += chunkSize) {
      const chunk = dataLinkReferences.slice(i, i + chunkSize);
      await Promise.all(
        chunk.map(async dataLink => {
          const reference = await findDataReference({
            sheetName: dataLink.sheetName,
            row: dataLink.row,
            col: dataLink.column,
          });

          if (reference) {
            const parameters =
              typeof reference.parameters === 'string' ? JSON.parse(reference.parameters) : reference.parameters;
            let { workpaperId: sourceWorkpaperId, workpaperName, taxPeriod = '', sheetName, row, column } = parameters;
            const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';
            const { workpaperName: targetWorkpaperName } = JSON.parse(
              getDataFromLocalStorage(`${workpaperId}-metadata`)
            );
            const formula = `=${sheet.getFormula(reference.row, reference.column)}`;

            if (isDataLinkFormula(sheet.getFormula(reference.row, reference.column))) {
              const updatedFormulaExtract = extractFormulaValues(sheet.getFormula(reference.row, reference.column));
              const request = await getRequestParameters(
                targetWorkpaperName,
                reference.row,
                reference.column,
                reference.sheetName,
                updatedFormulaExtract,
                sourceWorkpaperId
              );

              reference.parameters = request.parameters;
              reference.oldValue = reference.value;
              reference.newValue = reference.value;
              reference.referenceId = reference.id;
              reference.referenceType = reference.type;
              delete reference.id;
              delete reference.type;
              referencesToUpdate.push(reference);

              if (!avoidResolveFormula) {
                const partialWorkbook = {
                  [sheetName]: {
                    [row]: {
                      [column]: formatLinkValue(reference.value),
                    },
                  },
                };

                groupWorkbooks(
                  groupedWorkbooks,
                  workpaperName,
                  sheetName,
                  row,
                  column,
                  reference,
                  partialWorkbook,
                  effectiveTaxPeriod
                );

                const command = setValueOnServer(sheet, reference.row, reference.column, formula);
                command.partialWorkbook = partialWorkbook;
                command.workpaperName = workpaperName;
                command.taxPeriod = effectiveTaxPeriod;
                command.isUndoable = false;

                commands.push({ commandText: JSON.stringify(applyTimestamp(command)) });
              }
            }
          }
        })
      );
    }

    updateWorkbooks(groupedWorkbooks);
    if (commands.length) {
      enqueueCommands(commands);
    }
    if (referencesToUpdate.length) {
      await createWorkpaperDataReferences(referencesToUpdate[0].workpaperId, referencesToUpdate);
    }
  }

  function updateWorkbooks(groupedWorkbooks) {
    groupedWorkbooks?.forEach((value, key) => {
      const { partialWorkbooks, effectiveTaxPeriod } = value;
      spreadRef.current.updateExternalReference(key, partialWorkbooks, effectiveTaxPeriod, true);
    });
  }

  function setupDataLinkSpreadListeners(readOnly) {
    if (readOnly) {
      removeListeners();
    } else {
      setUpListeners();
    }
    targetWorkbookSpreadListener(readOnly);
    sourceWorkbookSpreadListeners(readOnly);

    spreadRef.current.commandManager().register(endDataLinkCommandName, { execute: handleFinishDataLink });
  }

  function setUpListeners() {
    storageListener.current = setupStorageListener();
    unsubscribeSourceUpdateListener.current = setupSourcesUpdateChannelListener();
    unsubscribeTargetCreatedListener.current = setupTargetCreatedChannelListener();
    const dataLinkTypeReferences = dataReferences.current.filter(({ type }) => type === DATA_LINK);
    if (dataLinkTypeReferences.length) {
      const outGoingDataLinks = dataLinkTypeReferences.filter(dataLink => dataLink.workpaperId !== workpaperId);
      const dataLinks = dataLinkTypeReferences.filter(dataLink => dataLink.workpaperId === workpaperId);

      if (outGoingDataLinks.length) {
        startOutSyncDataLinksCheck();
      }

      if (dataLinks.length) {
        refreshDataLinkFormulas();
      }
    }
  }

  async function refreshDataLinkFormulas(fetchLatest = false) {
    if (fetchLatest) {
      const updatedDataLinkReferences = await getWorkpaperDataReferences({
        workpaperId,
        filters: new Map([
          ['includeDataLinksSources', false],
          ['referenceType', DATA_LINK],
        ]),
      });

      const updatedReferences = [
        // Update existing references
        ...dataReferences.current.map(
          ref =>
            updatedDataLinkReferences.find(
              r =>
                r.row === ref.row && r.column === ref.column && r.sheetName === ref.sheetName && ref.type === DATA_LINK
            ) ?? ref
        ),

        // Add new references that don't exist
        ...updatedDataLinkReferences.filter(
          r =>
            !dataReferences.current.some(
              ref =>
                ref.row === r.row && ref.column === r.column && ref.sheetName === r.sheetName && ref.type === DATA_LINK
            )
        ),
      ];

      dataReferences.current = updatedReferences;

      executeRefreshDataLinks(spreadRef.current, workpaperId, { current: updatedReferences }, enqueueCommands);
    } else {
      executeRefreshDataLinks(spreadRef.current, workpaperId, dataReferences, enqueueCommands);
    }
  }

  function removeListeners() {
    if (storageListener.current) window.removeEventListener('storage', storageListener.current);
    storageListener.current = null;
    unsubscribeSourceUpdateListener.current?.();
    unsubscribeSourceUpdateListener.current = null;
    unsubscribeTargetCreatedListener.current?.();
    unsubscribeTargetCreatedListener.current = null;
    setDataLinkStatus({ active: false, targetWorkpaperName: null });
  }

  useEffect(() => {
    if (window?.BroadcastChannel) {
      createChannel(BroadcastChannels.SourceUpdateReferences);
      createChannel(BroadcastChannels.TargetCreatedReference);
    }

    return () => {
      try {
        if (isDataLinksEnabled) {
          removeListeners();
          stopOutSyncDataLinksCheck();
        }
      } catch (error) {}
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { processDataLinkFromCommand, setupDataLinkSpreadListeners };
}
