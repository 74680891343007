import * as wkpFetch from '../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../configs/params';
import { generalErrorMessage } from '../../_shared/messages';
import { commandsStatusEnum, processPendingCommandsWithSJS } from './EditorContext/useCommandsQueue/apis';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { PreSignedUrlFileTypes } from '../../../constants/preSignedUrlFileTypes';
import { httpRequestWithRetry } from './Spreadsheet/_spreadsheets/utils';

export const WORKPAPER_NOT_FOUND_ERROR = 'WORKPAPER_NOT_FOUND_ERROR';

export const workbookStates = {
  ready: 'Ready',
  open: 'Open',
  failed: 'Failed',
  inProgress: 'InProgress',
};

let staticGenerationError;

async function validateWorkbookState(workpaperId) {
  const { state } = await getWorkbookState(workpaperId);
  return {
    done: state === workbookStates.ready || state === workbookStates.open,
    failed: state === workbookStates.failed,
  };
}

async function checkWorkpaperStateAndFetchPresignedUrl(id, statusRetryRef) {
  const { state } = await getWorkbookState(id);

  if (state === workbookStates.inProgress) {
    await processPendingCommandsWithSJS(id);
    await retryFetchStaticVersion(statusRetryRef, id);
  } else if (state === workbookStates.failed) {
    await retryFetchStaticVersion(statusRetryRef, id);
  }

  return await getWorkbookModel(id);
}

export async function getWorkbook({ id, versionId, statusRetryRef }) {
  if (versionId) {
    try {
      return await fetchPresignedUrl(await getWorkbookModel(id, versionId));
    } catch (error) {
      throwStaticVersionError(error);
    }
  } else {
    try {
      const presignedUrl = await checkWorkpaperStateAndFetchPresignedUrl(id, statusRetryRef);
      return await fetchPresignedUrl(presignedUrl);
    } catch (error) {
      throwStaticVersionError(error);
    }
  }
}

const throwStaticVersionError = error => {
  const newError = new Error();
  newError.details = [
    {
      code: 'STATIC_VERSION_ERROR',
      message: generalErrorMessage,
      error: error.message,
      errorDetail: staticGenerationError,
    },
  ];
  throw newError;
};

async function getWorkbookStatus(id) {
  return validateWorkbookState(id);
}
export async function retryFetchStaticVersion(statusRetryRef, id) {
  let retries = 240;
  let status = await getWorkbookStatus(id);
  while (!status.done && --retries >= 0) {
    if (retries === 0) {
      staticGenerationError = 'Maximun retries';
      throw new Error('Static version generation failed. Reload the page');
    }

    if (statusRetryRef && !statusRetryRef.current) {
      staticGenerationError = 'Status request';
      throw new Error('Status request aborted');
    }

    await new Promise(r => setTimeout(r, 1000));
    status = await getWorkbookStatus(id);
    if (status.failed) {
      staticGenerationError = 'static generation failure';
      throw new Error('Static version generation has failed');
    }
  }
}

async function getWorkbookModel(id, versionId) {
  let url = `${spreadsheetServiceHostName}/spreadsheet/files/${id}`;

  if (versionId) {
    url += `/version/${versionId}`;
  }
  const fetchOperation = async () => {
    return await wkpFetch.get(url);
  };

  return fetchAndRetryAsyncOperation(fetchOperation, url);
}

async function fetchPresignedUrl(presignedUrl) {
  const fetchOperation = async () => {
    return await fetch(presignedUrl);
  };
  return fetchAndRetryAsyncOperation(fetchOperation, presignedUrl, true);
}

export async function getWorkbookMetadata({ id, versionId }) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/metadata/${id}${versionId ? '/' + versionId : ''}`;
  const metadataResponse = await httpRequestWithRetry(() => wkpFetch.get(url));
  const res = metadataResponse.clone();

  if (!res.ok) {
    const errorText = await res.text();
    if (errorText) {
      throw Error(errorText);
    }
  } else {
    const { name, taxPeriod, readOnly, isGlobalTemplate } = await res.json();
    return {
      metadata: {
        name,
        taxPeriod,
      },
      readOnly,
      isGlobalTemplate,
    };
  }
}

export async function getLock(workpaperId) {
  const response = await httpRequestWithRetry(() =>
    wkpFetch.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`)
  );
  if (response.ok) {
    try {
      return await response.json();
    } catch (error) {}
  } else if (response.status === 404) {
    const error = new Error(`Failed to get lock. Workpaper with ID ${workpaperId} not found (404)`);

    error.name = WORKPAPER_NOT_FOUND_ERROR;
    throw error;
  } else {
  }
}

export async function setLock(workpaperId) {
  const response = await httpRequestWithRetry(() =>
    wkpFetch.post(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`)
  );
  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data;
}

export async function deleteLock(workpaperId) {
  const response = await httpRequestWithRetry(() =>
    wkpFetch.remove(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`)
  );
  const data = await response.json();

  if (!response.ok) {
    if (response.status === 404) {
      return;
    }
    throw data.error;
  }

  return data;
}

export async function getWorkbookState(id) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/workpaper/${id}/state`;
  return fetchAndRetryAsyncOperation(() => wkpFetch.get(url), url);
}

export async function getWorkbookNeedRefresh(id) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/workpaper/${id}/need-refresh`;
  const response = await wkpFetch.get(url);
  if (response.ok) {
    try {
      return await response.json();
    } catch (error) {}
  } else if (response.status === 404) {
    const error = new Error(`Failed to get NeedRefresh flag. Workpaper with ID ${id} not found (404)`);
    error.name = WORKPAPER_NOT_FOUND_ERROR;
    throw error;
  } else {
  }
}

export async function updatedWorkbookNeedRefresh(id, needRefresh = false) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/workpaper/${id}/need-refresh`;
  try {
    return await wkpFetch.put(url, {
      body: JSON.stringify({ needRefresh }),
    });
  } catch (error) {
    throw error;
  }
}

export async function getCommandsStatus(workpaperId) {
  const response = await httpRequestWithRetry(() =>
    wkpFetch.get(
      `${spreadsheetServiceHostName}/spreadsheet/commands/${workpaperId}/status-count/${commandsStatusEnum.Fail}`
    )
  );

  if (response.ok) {
    try {
      return await response.json();
    } catch (error) {}
  } else if (response.status === 404) {
    const error = new Error(`Failed to get sync. Workpaper with ID ${workpaperId} not found (404)`);
    error.name = WORKPAPER_NOT_FOUND_ERROR;
    throw error;
  } else {
  }
}

export async function getSourcePresignedUrl(workpaperId) {
  const filenameUrlEncoded = encodeURIComponent(workpaperId);
  const presignedUrlResult = await httpRequestWithRetry(() =>
    wkpFetch.get(
      `${spreadsheetServiceHostName}/spreadsheet/files/presigned-url/${filenameUrlEncoded}.json?fileType=${PreSignedUrlFileTypes.StaticVersion}`
    )
  );
  const presignedUrlData = await presignedUrlResult.json();
  if (!presignedUrlResult.ok) {
    throw presignedUrlData.error;
  }
  return presignedUrlData;
}

export async function worbookJsonSync(workpaperId, jobId, key) {
  try {
    return await httpRequestWithRetry(() =>
      wkpFetch.put(`${spreadsheetServiceHostName}/spreadsheet/files/sync/${workpaperId}`, {
        body: JSON.stringify({ workpaperId, jobId, key, syncCommands: true }),
      })
    );
  } catch (error) {
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: generalErrorMessage }];
    throw mappedError;
  }
}
async function fetchAndRetryAsyncOperation(operation, url, isPresignData = false, maxRetries = 3, retryDelay = 1000) {
  let attempts = 0;
  let responseBody;
  let responseHeaders;
  while (attempts < maxRetries) {
    try {
      const operationResponse = await operation();
      const response = operationResponse.clone();
      responseBody = response.text();
      responseHeaders = Array.from(operationResponse.headers.entries());

      if (!response.ok) {
        const errorText = await response.text();
        if (errorText) {
          throw Error(errorText);
        }
      } else {
        const data = await operationResponse.json();
        if (isPresignData) {
          return {
            workbook: data,
            workbookLength: parseInt(operationResponse.headers.get('content-length')),
          };
        } else if (data.presignedUrl) {
          return data.presignedUrl;
        } else {
          return data;
        }
      }
    } catch (error) {
      if (attempts >= maxRetries - 1) {
        throw error;
      } else {
        attempts++;
        CustomLogger &&
          CustomLogger.pushLog(CustomLogger.operations.RETRY_OPEN, {
            url,
            responseBody,
            responseHeaders,
            error: JSON.stringify(error),
          });
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      }
    }
  }
}
