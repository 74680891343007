import * as fetch from '../../../_shared/fetch';
import { uploadSourceFile } from '../../shared/apis';
import { dataFlowServiceHostName } from '../../../../configs/params';
import { getTempWorkingFileLocation, getWorkbookInfo } from '../FileDialog/apis';
import { getUser } from '../../../_shared/auth';
import { WKP_INGEST_REWRITE } from '../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { startJob } from '../../../_shared/jobs/apis';
import { DATAFLOW_FILE_METADATA_JOB_TYPE } from '../../../_shared/jobs/jobTypes';
import { INTEGRATION_REGEX, INTEGRATIONS_TYPE, SFTP_REGEX } from './constants';

export async function uploadPendingInputFile(dataFlowId, inputId, sampleFile) {
  const uploadResult = await uploadSourceFile(sampleFile);

  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${inputId}/pending`;

  const res = await fetch.post(reqUri, { body: JSON.stringify(uploadResult) });

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getDataFlowSourceFileVersion(dataFlowId, sourceFileVersionId) {
  const response = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-file-versions/${sourceFileVersionId}`
  );
  return response.json();
}

export async function validateSourceFileFields(dataFlowId, newFields, inputId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${inputId}/validate`;
  const res = await fetch.post(reqUri, { body: JSON.stringify({ newFields }) });

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function removeSourceFileData(sourceFileId, dataFlowId, inputId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${sourceFileId}/inputs/${inputId}/delete`;
  const res = await fetch.remove(reqUri);

  return res.json();
}

export async function removeSourceFile(sourceFileId, sourceFileVersionId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/${sourceFileId}/versions/${sourceFileVersionId}/delete`;
  const res = await fetch.remove(reqUri);

  return res.json();
}

export async function validatePermanentDelete(dataFlowId, inputId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/inputs/${inputId}/validate-permanent-delete`;
  const res = await fetch.get(reqUri);

  return res.json();
}

export async function removeSourceFilePermanently(deleteInfos, systemCode) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/${systemCode}/permanent-delete`;
  const res = await fetch.remove(reqUri, { body: JSON.stringify(deleteInfos) });

  return res.json();
}

export async function getFAworkFlowNames() {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/FA/workflow-names`;
  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getFAFocusPeriods(workFlowId, workFlowName) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/FA/focus-periods/${workFlowId}/${workFlowName}`;

  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getFASourceFileInfo(workFlowId, workFlowName, focusPeriod) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/FA/source-file/${workFlowId}/${workFlowName}/${focusPeriod}`;

  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function isValidFAUser() {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/FA/validate-access`;

  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function removeOriginUploadS3File(dataFlowId, sourceFileInfo) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/location`;
  const res = await fetch.remove(reqUri, { body: JSON.stringify(sourceFileInfo) });

  return res.json();
}

async function getFileSheet(sourceFile, companyId, clientId = null) {
  const { name, path, bucket } = sourceFile;
  let uploadResult = {
    bucket,
    path: `${clientId ? `${companyId}/${clientId}${path}` : `${companyId}${path}`}`,
    name: name,
  };
  const uploadedWorkbookInfo = await getWorkbookInfo(uploadResult);
  return uploadedWorkbookInfo;
}

export async function getFile(sourceFile, id, clientId = null) {
  const { companyId } = getUser();
  const { type, name, path, bucket } = sourceFile;
  let sheetNames = '';
  let processingPath = '';
  const isCsvFile = type.toLowerCase() === 'csv';

  if (isFeatureFlagEnabled(WKP_INGEST_REWRITE)) {
    // download the file so we can send the path to sheet names endpoint
    // create job for polling
    let { jobId } = await startJob({
      entityId: id,
      jobType: DATAFLOW_FILE_METADATA_JOB_TYPE,
      batchId: id,
      payload: {},
    });

    const integrationType = SFTP_REGEX.test(bucket)
      ? INTEGRATIONS_TYPE.SFTP
      : INTEGRATION_REGEX.test(bucket)
        ? INTEGRATIONS_TYPE.FIXED_ASSETS
        : null;

    const location = clientId ? `${companyId}/${clientId}${path}` : `${companyId}${path}`;
    var result = await getTempWorkingFileLocation(jobId, { path: location, fileName: name }, id, integrationType);
    processingPath = result.processingPath;

    if (result?.ErrorTitle) {
      throw new Error('Error obtaining file from SFTP');
    }
    if (!isCsvFile) {
      sheetNames = await getWorkbookInfo({ fileName: name, path: processingPath }, id);
    }
  } else {
    if (!isCsvFile) {
      sheetNames = await getFileSheet(sourceFile, companyId, clientId);
      if (sheetNames?.error) {
        throw new Error(sheetNames.error.code);
      }
    }
  }

  return {
    uploadFile: {
      uploadFiles: [
        {
          name,
          fileLocation: {
            id,
            fileKey: `${clientId ? `${companyId}/${clientId}${path}` : `${companyId}${path}`}`,
            bucket,
          },
          processingPath,
        },
      ],
    },
    file: {
      label: name,
      value: name,
    },
    sheetData: !isCsvFile
      ? sheetNames.sheetNames.map(sheet => {
          return { value: sheet.name, label: sheet.name };
        })
      : [{ value: '', label: '' }],
  };
}
