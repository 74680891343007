import { getOriginalName } from '../../shared/utils/FieldHashUtils';

export const FIELDS = 'fields';
export const FORMULA_OVERRIDE_ENABLED = 'overrideEnabled';
export const FORMULA_OVERRIDE_FIELD = 'overrideFieldName';
export const FORMULA_OVERRIDE_KEY = 'formulaBlockOverride';

export const OVERRIDE_INPUT_FIELD_NAME = 'overrideInputField';
export const OVERRIDE_INPUT_FIELD_TYPE = 'lastOverrideUpstreamType';

export const NEW_COLUMN = 'New column';
export const REPLACE_COLUMN = 'Replacement for existing column';

export const OUTPUT_FIELD_NAME = 'outputFieldName';

export function createDefaultFormulaOverride() {
  return {
    [FORMULA_OVERRIDE_ENABLED]: false,
  };
}

export function setNewColumn(formulaOverride, updateData) {
  const data = disableFormulaOverride(formulaOverride);
  updateData({
    [FORMULA_OVERRIDE_KEY]: data,
  });
}

export function setReplaceColumn(updateData, formulaOverride, overrideFields, newOutputFieldName) {
  const data = enableFormulaOverride(formulaOverride);
  const updatedFields = overrideFields ? [...overrideFields].filter(field => field.name !== newOutputFieldName) : [];

  updateData({
    [OUTPUT_FIELD_NAME]: '',
    [FIELDS]: updatedFields,
    [FORMULA_OVERRIDE_KEY]: data,
  });
}

export function isOverrideEnabledElementData(elementData) {
  return elementData && isOverrideEnabled(elementData[FORMULA_OVERRIDE_ENABLED]);
}
export function isOverrideEnabled(formulaOverride) {
  return formulaOverride && formulaOverride[FORMULA_OVERRIDE_ENABLED];
}

function enableFormulaOverride(formulaOverride) {
  const update = Object.assign({}, formulaOverride);
  update[FORMULA_OVERRIDE_ENABLED] = true;
  return update;
}

function disableFormulaOverride(formulaOverride) {
  return {
    [FORMULA_OVERRIDE_FIELD]: formulaOverride[FORMULA_OVERRIDE_FIELD],
    [FORMULA_OVERRIDE_ENABLED]: false,
  };
}

export function getFormulaFieldToReplace(formulaOverride) {
  if (formulaOverride && formulaOverride[FORMULA_OVERRIDE_ENABLED]) {
    return formulaOverride[FORMULA_OVERRIDE_FIELD];
  }
  return null;
}

export function getFormulaDataToReplace(formulaOverride) {
  const formulaOverrideFieldName = getFormulaFieldToReplace(formulaOverride);
  const data = { label: formulaOverrideFieldName, value: formulaOverrideFieldName };
  return data;
}

export function setFormulaFieldToReplace(fieldToReplace, formulaOverride) {
  return {
    [OUTPUT_FIELD_NAME]: '',
    [FORMULA_OVERRIDE_KEY]: {
      ...formulaOverride,
      [FORMULA_OVERRIDE_FIELD]: fieldToReplace,
      [FORMULA_OVERRIDE_ENABLED]: true,
    },
  };
}

export function getComboBoxData(overrideFields) {
  return overrideFields.map(field => {
    return {
      label: field.name,
      value: field.name,
    };
  });
}

export function updateOutputFieldTypeForOverride(fields, overrideData, outputFieldType) {
  if (!overrideData) {
    return;
  }

  fields?.forEach(function (field) {
    const fieldName = getOriginalName(field);
    if (fieldName === overrideData[OVERRIDE_INPUT_FIELD_NAME]) {
      field.type = overrideData[OVERRIDE_INPUT_FIELD_TYPE];
    }
  });

  fields?.forEach(function (field) {
    const fieldName = getOriginalName(field);
    if (fieldName === overrideData[FORMULA_OVERRIDE_FIELD]) {
      overrideData[OVERRIDE_INPUT_FIELD_NAME] = fieldName;
      overrideData[OVERRIDE_INPUT_FIELD_TYPE] = field.type;
      field.type = outputFieldType;
    }
  });
}
