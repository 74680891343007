import * as fetch from '../fetch';
import { userDataAPIPath } from '../../../configs/params';
import CustomLogger from '../Logger/CustomLogger';
import { WKP_USER_PERMISSIONS } from '../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { redirectToLogin } from '../jwtAuth';

export const getReaderPermissions = function () {
  return {
    apiError: true,
    role: 'reader',
    permissions: ['edit_workpaper_sign-offs', 'download_workflow_outputs'],
  };
};

const permissionErrorMessage = `Failed to retrieve permissions`;
export async function getUserPermissions() {
  if (!isFeatureFlagEnabled(WKP_USER_PERMISSIONS)) {
    return {
      apiError: false,
    };
  }

  try {
    let res = await fetch.get(`${userDataAPIPath}/permissions`);

    if (res.status === 404) {
      CustomLogger.pushLog(CustomLogger.operations.RETRIEVE_USER_PERMISSIONS, permissionErrorMessage);
      return getReaderPermissions();
    } else if (res.status === 401) {
      redirectToLogin();
    }
    return await res.json();
  } catch (error) {
    CustomLogger.pushLog(CustomLogger.operations.RETRIEVE_USER_PERMISSIONS, permissionErrorMessage);
    redirectToLogin();
    return getReaderPermissions();
  }
}
