import React, { useEffect, useRef } from 'react';
import {
  configureWorkbookForFilePreview,
  configureSheetForFilePreview,
  importSpreadFromExcel,
  importSpreadFromCsv,
} from './utils';
import GC from '../DataFlowOutputPreview/SpreadView/SpreadSheets';

export function FilePreview({ id, isVisible, file, sheetName, maxExcelSize = 50_000_000, isCsv }) {
  const workbook = useRef();

  useEffect(() => {
    if (isVisible && file) {
      if (!isCsv) {
        if (file.size < maxExcelSize) {
          workbook.current = new GC.Spread.Sheets.Workbook(document.getElementById(id));

          importSpreadFromExcel(workbook.current, file).then(() => {
            configureWorkbookForFilePreview(workbook.current);

            for (let i = 0; i < (workbook.current?.getSheetCount() || 0); i++) {
              configureSheetForFilePreview(workbook.current.getSheet(i));
            }
          });
        }
      } else {
        workbook.current = new GC.Spread.Sheets.Workbook(document.getElementById(id));

        importSpreadFromCsv(workbook.current, file).then(() => {
          configureWorkbookForFilePreview(workbook.current);
          configureSheetForFilePreview(workbook.current.getActiveSheet());
        });
      }
    }
  }, [id, isVisible, file, isCsv, maxExcelSize]);

  useEffect(() => {
    if (isCsv === false && workbook.current && sheetName) {
      workbook.current.setActiveSheet(sheetName);
    }
  }, [isCsv, sheetName]);

  if (!isVisible || !file) {
    return null;
  }

  if (file.size > maxExcelSize) {
    return (
      <div
        style={{
          flexGrow: 3,
          marginLeft: '10px',
          overflow: 'hidden',
          background: '#F0F0F0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p style={{ color: 'grey' }}>The selected file is to large to preview.</p>
      </div>
    );
  }

  return (
    <div style={{ flexGrow: 1, marginLeft: '10px', overflow: 'hidden', border: '1px solid grey' }}>
      <div id={id} style={{ height: '100%' }} />
    </div>
  );
}
