import React from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import { eventTrackTagNames } from '../../../_shared/EventTrackingContext/constants';

export default function WorkpaperNeedRefreshModal({ show }) {
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <>
      <style>
        {`
          .bbui-dialog-title-close-icon {
            display: none;
          }
        `}
      </style>
      <BTModal className="wkp-refresh-modal" show={show} title="Incoming Linked Values Updated">
        <BTModal.Body>
          <p>One ore more incoming linked values were updated. Refresh the workpaper to update to the latest values.</p>
        </BTModal.Body>
        <BTModal.Footer>
          <BTButton
            btStyle="primary"
            data-track-tag={eventTrackTagNames.interactive}
            id="wkp-refresh-btn"
            onClick={handleRefresh}
          >
            Refresh
          </BTButton>
        </BTModal.Footer>
      </BTModal>
    </>
  );
}
