import { BTButton, BTComboBox, BTForm } from '@btas/jasper';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Frame from '../_shared/Frame';
import GeneralErrorMessage from '../_shared/GeneralErrorMessage';
import Spinner from '../_shared/Spinner';
import { clientAccessManagerUrl } from '../../configs/params.js';
import { roles, hasUserRole } from '../../utils/userRoles';
import { isFeatureFlagEnabled } from '../../utils/featureFlags';
import { WKP_LANDING_ON_DATAFLOW } from '../../constants/featureFlags';
import './ClientSelection/styles.scss';
import { useClient } from '../../contexts/ClientContext.js';
import { useGetClients } from '../../hooks/useGetClients.jsx';
import { useCheckClientAvailability } from '../../hooks/useCheckClientAvailability';
import CustomLogger from '../_shared/Logger/CustomLogger.js';
import { getTokenInfo } from '../_shared/auth.js';
import { eventNames, eventTrackTagNames } from '../_shared/EventTrackingContext/constants.js';
import { setPropValues } from '../_shared/EventTrackingContext/utils.js';
import { EventTrackingContext } from '../_shared/EventTrackingContext.jsx';

export default function ClientSelection() {
  const { trackEvent } = useContext(EventTrackingContext);
  const { userId, companyId } = getTokenInfo();
  const { clients, isLoading, error: clientsError } = useGetClients();
  const history = useHistory();
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const { clientId, setClientId } = useClient();

  const defaultPath = isFeatureFlagEnabled(WKP_LANDING_ON_DATAFLOW) ? '/data-flows' : '/workpapers';

  const isCpocAdmin = hasUserRole(roles.CPOC_ADMIN);

  const getQueryParameters = url => {
    return new URLSearchParams(url);
  };

  const queryParams = getQueryParameters(window.location.search);
  const errorFromResourceApi = queryParams.get('error');
  const hasApplicationError = clientsError || errorFromResourceApi;

  const getRedirectParameter = () => {
    const queryParameters = getQueryParameters(window.location.search);
    const returnUrls = queryParameters.get('redirectUrl');
    let returnUrl;
    if (returnUrls) {
      const containsReturnUrl = returnUrls.includes('?');
      const splittedUrls = containsReturnUrl ? returnUrls.split('?') : [returnUrls];
      const cleanReturnUrl = splittedUrls.length > 1 ? splittedUrls[splittedUrls.length - 1] : splittedUrls[0];
      returnUrl = cleanReturnUrl.includes('redirectUrl=') ? cleanReturnUrl.replace('redirectUrl=', '') : cleanReturnUrl;
    }
    return returnUrl;
  };

  useEffect(() => {
    async function handleClientsData() {
      if (!clients.length) {
        history.replace('/no-client-access');
        return;
      }

      if (clients?.length === 1) {
        const clientInfo = clients[0];
        await setClientId(clientInfo?.id, { isInitialSelection: true });

        CustomLogger.pushLog(CustomLogger.operations.CLIENTS_MANAGEMENT.CLIENT_SELECTED, {
          message: `User ${userId} logged into client ${clientInfo?.id}`,
          company_id: companyId,
        });

        goToRedirectUrl();
        return;
      }

      const clientOptions = clients.map(c => ({ label: c.name, value: c.name }));
      setClientOptions(clientOptions);
      setSelectedClient(clientOptions[0]);
    }

    async function checkExistingClient() {
      if (clientId) {
        CustomLogger.pushLog(CustomLogger.operations.CLIENTS_MANAGEMENT.CLIENT_SELECTED, {
          message: `User ${userId} logged into client ${clientId}`,
          company_id: companyId,
        });
        goToRedirectUrl();
      }
    }

    if (!isLoading && !clientsError && clients) {
      handleClientsData();
    }

    checkExistingClient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, isLoading, clientsError]);

  const goToRedirectUrl = () => {
    const redirectUrl = getRedirectParameter();
    let target = redirectUrl ?? defaultPath;
    // Convert target to a URL object
    const url = new URL(target, window.location.origin);

    // Extract the relative path and query string
    const relativePath = url.pathname + url.search;
    history.replace(relativePath);
  };

  const onClientChange = c => {
    if (c && c.value) {
      setSelectedClient(c);
    }
  };

  const onClickOpenClient = async () => {
    try {
      const clientInfo = clients.find(c => c.name === selectedClient.value);
      if (!clientInfo) {
        console.error('Selected client not found in clients list');
        return;
      }

      await setClientId(clientInfo?.id, { isInitialSelection: true });

      CustomLogger.pushLog(CustomLogger.operations.CLIENTS_MANAGEMENT.CLIENT_SELECTED, {
        message: `User ${userId} logged into client ${clientInfo?.id}`,
        company_id: companyId,
      });

      goToRedirectUrl();
    } catch (error) {
      console.error('Error setting clientId', error);
    }
  };

  const handleClientNotFound = async () => {
    await setClientId(null);
  };

  useCheckClientAvailability(clients, handleClientNotFound);

  return (
    <Frame showAppBar={false}>
      <div className="client-selection-container">
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <h1>Welcome to Bloomberg Tax Workpapers</h1>
            <div className="select-client-box">
              <h2>Select a client to begin</h2>
              <div className="client-box-elements">
                <BTForm.FormGroup label="Client">
                  <BTComboBox
                    id="Client"
                    name="Client"
                    options={clientOptions}
                    value={selectedClient}
                    onChange={onClientChange}
                  ></BTComboBox>
                  <BTButton
                    btStyle="primary"
                    className="select-client-btn"
                    data-track-tag={eventTrackTagNames.clientSelect}
                    id="select-client-btn"
                    onClick={onClickOpenClient}
                  >
                    OPEN CLIENT
                  </BTButton>
                </BTForm.FormGroup>
              </div>
              {isCpocAdmin ? (
                <div className="helpText">
                  Don't see the client you are looking for?
                  <a
                    className="item-menu-apps"
                    href={clientAccessManagerUrl}
                    rel="noreferrer"
                    role="menuitem"
                    target="_blank"
                    onClick={event => {
                      trackEvent(eventNames.navigation, setPropValues(event, eventNames.navigation, null));
                    }}
                  >
                    <span className="icon-menu-apps"> Open Client Access Manager </span>
                  </a>
                  to add or edit clients.
                </div>
              ) : (
                <div className="helpText">
                  Don't see the client you are looking for? Contact your account administrator to add clients or to
                  request client access.
                </div>
              )}
            </div>
          </>
        )}
        <GeneralErrorMessage dismissible={true} visible={hasApplicationError} />
      </div>
    </Frame>
  );
}
