/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef, useContext } from 'react';
import { BTIcon } from '@btas/jasper';
import { useHistory } from 'react-router-dom';

import { useUserProfile } from '../../../../hooks/useUserProfile';
import { clientAccessManagerUrl } from '../../../../configs/params';
import './styles.scss';
import { useClient } from '../../../../contexts/ClientContext';
import ClientChangeConfirmationModal from './ClientChangeConfirmationModal';
import { useGetClients } from '../../../../hooks/useGetClients';
import { useCheckClientAvailability } from '../../../../hooks/useCheckClientAvailability';
import CustomLogger from '../../Logger/CustomLogger';
import { getTokenInfo } from '../../auth';
import { eventNames } from '../../EventTrackingContext/constants';
import { setPropValues } from '../../EventTrackingContext/utils';
import { EventTrackingContext } from '../../EventTrackingContext';

const ClientPickerMenu = () => {
  const { trackEvent } = useContext(EventTrackingContext);
  const history = useHistory();
  const { clientId, setClientId } = useClient();
  const { clients, error } = useGetClients();
  const handleClientNotFound = async () => {
    await setClientId(null);
    history.replace('/client-selection');
  };

  const prevClientIdRef = useRef(clientId);
  const [showClientChangeConfirmationModal, setShowClientChangeConfirmationModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState('');
  const { isAdmin } = useUserProfile();
  const userIsAdmin = isAdmin();

  const selectedClient = clients.find(c => c.id === clientId);

  const handleClientClick = client => {
    setShowClientChangeConfirmationModal(true);
    setSelectedClientId(client.id);
  };

  const handleClientChangeConfirmationModalClose = () => {
    setShowClientChangeConfirmationModal(false);
  };

  const handleClientChangeConfirmationModalProceed = async () => {
    const { userId, companyId } = getTokenInfo();

    setShowClientChangeConfirmationModal(false);
    const previousClientId = prevClientIdRef.current;
    prevClientIdRef.current = selectedClientId;

    await setClientId(selectedClientId, { isInitialSelection: false });

    CustomLogger.pushLog(CustomLogger.operations.CLIENTS_MANAGEMENT.CLIENT_SELECTED, {
      message: `User ${userId} switched from client ${previousClientId} to client ${selectedClientId}`,
      company_id: companyId,
    });

    history.replace('/');
    return;
  };

  useEffect(() => {
    if (error) {
      history.replace('/get-clients-error');
    }
  }, [error, history]);
  useCheckClientAvailability(clients, handleClientNotFound);
  useEffect(() => {
    // Redirecting to client change page when client change in other tab
    if (clientId && prevClientIdRef.current && prevClientIdRef.current !== clientId) {
      history.replace('/client-change');
      return;
    }

    prevClientIdRef.current = clientId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  if (!clients.length) {
    return null;
  }

  return (
    <li className="dropdown clients-dropdown">
      <a
        className="dropdown-toggle client-picker-header"
        data-testid="client-picker"
        data-toggle="dropdown"
        href="#"
        id="client-picker"
        title="ACME"
        type="button"
      >
        <div>
          <img alt="Client Picker" src="/assets/locked_folder.svg" />
          <span data-testid="selected-client-name">{selectedClient?.name}</span>
          <img alt="arrow down icon" className="caret-down" src="/assets/caret_down.svg" />
        </div>
      </a>
      <ul aria-labelledby="client-picker" className="dropdown-menu dropdown-menu-clients" role="menu">
        <div className="clients-container">
          <li aria-level="2" className="dropdown-header clients-header" role="heading">
            SELECT CLIENT
          </li>
          {clients.map((client, index) => (
            <a
              key={client.id}
              className={`client-item app-link ${selectedClient?.id === client.id ? 'active disabled' : ''}`}
              rel="noreferrer"
              role="menuitem"
              onClick={() => handleClientClick(client)}
            >
              <span className="name-menu-apps" onClick={() => handleClientClick(client)}>
                {client.name}
              </span>
            </a>
          ))}
        </div>
        {userIsAdmin && (
          <>
            <div className="clients-divider"></div>
            <a
              className="clients-manager-link"
              href={clientAccessManagerUrl}
              rel="noreferrer"
              target="_blank"
              onClick={event => {
                trackEvent(eventNames.navigation, setPropValues(event, eventNames.navigation, null));
              }}
            >
              <div className="clients-manager">
                <BTIcon icon="gear" />
                <span>Manage Clients...</span>
              </div>
            </a>
          </>
        )}
      </ul>
      <ClientChangeConfirmationModal
        show={showClientChangeConfirmationModal}
        onClose={handleClientChangeConfirmationModalClose}
        onProceed={handleClientChangeConfirmationModalProceed}
      />
    </li>
  );
};

export default ClientPickerMenu;
