import { CELL_REVIEW } from '../../../_shared/DataReference/ReferenceType';
import { formatCellValue } from './dataReferenceHelper';

/**
 * This hook handles cell tracking update logic
 */
export function useCellTrackerUpdate({
  dataReferences,
  workpaperIdRef,
  cellPositionUpdateQueue,
  processCellPositionUpdateQueue,
  lastCellReviewActionTimestamp,
}) {
  function updateSheetReferencesFromCells(sheet, sheetNames) {
    if (!sheetNames?.length) return;

    const valueReferences = [];

    sheetNames.forEach(sheetName => {
      const currentSheet = sheet?.getParent()?.getSheetFromName(sheetName);
      if (!currentSheet) return;

      const referenceDependentCells = currentSheet.getDirtyCells();
      if (!referenceDependentCells?.length) return;
      const cellReviewMap = new Map();

      dataReferences?.current
        ?.filter(r => r.sheetName === sheetName && r.type === CELL_REVIEW)
        .forEach(ref => {
          const key = `${ref.row}-${ref.column}`;
          cellReviewMap.set(key, ref);
        });
      referenceDependentCells.forEach(({ row, col }) => {
        const key = `${row}-${col}`;
        if (cellReviewMap.has(key)) {
          const reference = cellReviewMap.get(key);
          const cellValue = formatCellValue(currentSheet.getCell(row, col).text());

          if (reference.value !== cellValue && !valueReferences.some(v => v.id === reference.id)) {
            valueReferences.push({ ...reference, value: cellValue });
          }
        }
      });
    });

    if (valueReferences.length) {
      updateDataReferences(valueReferences);
    }
  }

  function updateReferencesFromCells(sheetReferences, row, col) {
    const cellReferences = sheetReferences.filter(r => {
      return r.row === row && r.column === col;
    });

    if (cellReferences?.length) {
      const notCellReviewReferences = cellReferences.filter(x => x.type !== CELL_REVIEW);

      if (notCellReviewReferences?.length) {
        return notCellReviewReferences.map(x => x.id);
      }
      return null;
    }
  }

  function updateDataReferenceCellPositions(positionReferences, type, filter, operation, row, column, count) {
    const referencesPositionsToUpdate = [];

    positionReferences.forEach(reference => {
      let filterPosition;
      let position;
      let isWithinSelection = false;
      let isWithinRange = false;

      if (filter === 'row') {
        filterPosition = reference.row;
        position = row;
        isWithinRange = reference.column >= column;
      } else if (filter === 'column') {
        filterPosition = reference.column;
        position = column;
        isWithinRange = reference.row >= row;
      }

      if (operation === 'equal') {
        isWithinSelection = filterPosition === position;
      } else if (operation === 'greater-equal') {
        isWithinSelection = filterPosition >= position;
      }

      if (type === 'row' && isWithinSelection === true && isWithinRange === true) {
        const updatedRowCount = reference.row + count;
        if (updatedRowCount >= 0) {
          return referencesPositionsToUpdate.push({ ...reference, row: updatedRowCount });
        }
      } else if (type === 'column' && isWithinSelection === true && isWithinRange === true) {
        const updatedColumnCount = reference.column + count;
        if (updatedColumnCount >= 0) {
          return referencesPositionsToUpdate.push({ ...reference, column: updatedColumnCount });
        }
      } else if (type === 'rowcolumn') {
        return referencesPositionsToUpdate.push({
          ...reference,
          column: reference.column + count.columnCount,
          row: reference.row + count.rowCount,
        });
      }
    });

    return referencesPositionsToUpdate;
  }

  function updateDataReferences(referencesToUpdate) {
    cellPositionUpdateQueue.current = [...cellPositionUpdateQueue.current, ...referencesToUpdate];
    processCellPositionUpdateQueue();

    if (referencesToUpdate && referencesToUpdate.length > 0) {
      const updatedInMemoryReference = [];
      dataReferences.current.forEach(reference => {
        const updatedReference = referencesToUpdate.find(x => x.id === reference.id);
        if (updatedReference) {
          const isDataLinkSource = updatedReference.workpaperId !== workpaperIdRef.current;
          const hasChanges =
            updatedReference.row !== reference.row ||
            updatedReference.column !== reference.column ||
            updatedReference.value !== reference.value;
          const isCellReview = updatedReference.type === CELL_REVIEW;

          if (hasChanges || isDataLinkSource) {
            updatedInMemoryReference.push(updatedReference);
          }
          if (isCellReview) {
            lastCellReviewActionTimestamp.current = Date.now();
          }
        } else {
          updatedInMemoryReference.push(reference);
        }
      });
      dataReferences.current = updatedInMemoryReference;
    }
  }

  return {
    updateSheetReferencesFromCells,
    updateReferencesFromCells,
    updateDataReferenceCellPositions,
    updateDataReferences,
  };
}
