import React, { useState, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { BTAlert, BTButton, BTForm, BTInput, BTModal, BTCheckbox, BTIcon, BTComboBox } from '@btas/jasper';
import useCopyDataFlow from './SaveAsModal/useCopyDataFlow';
import TaxPeriodInput from '../../../_shared/TaxPeriodInput';
import './SaveAsModal/styles.scss';

import { useTrackInteractiveClick } from '../../../_shared/EventTrackingContext';
import { itemTypes } from '../../../_shared/EventTrackingContext/utils';
import { useGetClients } from '../../../../hooks/useGetClients';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { WKP_CM_INTEGRATION } from '../../../../constants/featureFlags';
import { getTokenInfo } from '../../../_shared/auth';
import { useHistory } from 'react-router-dom';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import ClientChangeConfirmationModal from '../../../_shared/Frame/ClientPickerMenu/ClientChangeConfirmationModal';
import { useClient } from '../../../../contexts/ClientContext';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';

export const getRenamedValue = name => {
  // Check if name has appended number
  const appendedPosition = name.lastIndexOf('(');

  if (appendedPosition === 0 || appendedPosition === -1) {
    return `${name} (1)`;
  }

  const appendedValue = name.substring(appendedPosition);

  const numberFromValue = appendedValue.match(/\d+/);

  if (!numberFromValue) {
    return `${name} (1)`;
  }
  const substringName = name.substring(0, appendedPosition);

  return `${substringName}(${parseInt(appendedValue.match(/\d+/)[0]) + 1})`;
};

const SaveAsModal = ({ initialData, dataFlow, showWarning, onClose }) => {
  const { dataFlowActions } = useContext(DataFlowEditorContext);
  const [{ isLoading, error, isSuccess, generalError }, { copyDataFlow, reset }] = useCopyDataFlow();
  const [name, setName] = useState(`${getRenamedValue(initialData.name)}`);
  const [taxPeriod, setTaxPeriod] = useState(initialData.taxPeriod);
  const [includeSourceFiles, setIncludeSourceFiles] = useState(false);
  const [taxPeriodChanged, setTaxPeriodChanged] = useState(false);
  const [showCopySuccessMessage, setShowCopySuccessMessage] = useState(false);
  const [showClientChangeModal, setShowClientChangeModal] = useState(false);
  const clientContext = useClient();
  const { clientId, setClientId } = clientContext || {};
  const { clients, isLoading: clientsLoading, error: clientsError } = useGetClients();
  const [selectedClient, setSelectedClient] = useState(null);
  const isDifferentClient = selectedClient && selectedClient.value !== clientId;

  useEffect(() => {
    if (!clientsLoading && clients.length > 0) {
      const defaultClient = clients.find(client => client.id === clientId);
      if (defaultClient && (!selectedClient || clientId !== defaultClient.id)) {
        setSelectedClient({ label: defaultClient.name, value: defaultClient.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsLoading, clients, clientId]);

  const history = useHistory();

  useEffect(() => {
    if (isSuccess) {
      dataFlowActions.setSaveStateDirty(false);
      if (isFeatureFlagEnabled(WKP_CM_INTEGRATION) && isDifferentClient) {
        setShowCopySuccessMessage(true);
      } else {
        setTimeout(() => history.push(`/data-flows/${isSuccess.newDataFlowId}/editor`));
      }

      CustomLogger.pushLog(CustomLogger.operations.SAVE_AS, {
        workflow_id: dataFlow.id,
        worflow_name: name,
        target_client_id: selectedClient?.value,
        message: `Data flow was 'saved as' successfully`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onChange = useCallback(e => setName(e.target.value), [setName]);
  const trackEvent = useTrackInteractiveClick();

  const onSaveClicked = useCallback(() => {
    trackEvent('Form Submitted - Save As Modal', itemTypes.BUTTON);
    copyDataFlow(dataFlow, name, taxPeriod, includeSourceFiles, isDifferentClient ? selectedClient?.value : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, dataFlow, copyDataFlow, taxPeriod, includeSourceFiles, selectedClient, trackEvent]);

  const onTaxPeriodChanged = useCallback(tp => {
    if (tp !== taxPeriod) {
      setName(initialData.name);
      setTaxPeriodChanged(true);
    } else {
      setName(`${getRenamedValue(initialData.name)}`);
      setTaxPeriodChanged(false);
    }
    setTaxPeriod(tp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClientChangeModalClose = () => {
    setShowCopySuccessMessage(false);
  };

  const handleClientChangeConfirmationProceed = async () => {
    const { userId, companyId } = getTokenInfo();
    setShowCopySuccessMessage(false);
    setShowClientChangeModal(false);
    setClientId(selectedClient?.value);
    CustomLogger.pushLog(CustomLogger.operations.CLIENTS_MANAGEMENT.CLIENT_SELECTED, {
      message: `User ${userId} switched from client ${clientId} to client ${selectedClient.value} after copying data flow between clients`,
      company_id: companyId,
    });
    await setClientId(selectedClient?.value, { isInitialSelection: false });
    setTimeout(() => history.push(`/data-flows/${isSuccess.newDataFlowId}/editor`));
    return;
  };

  return (
    <>
      {!showCopySuccessMessage ? (
        <>
          <BTModal show={true} size="modal-lg" title="Save as" onCloseClick={onClose}>
            <BTModal.Body className="saveAs">
              {showWarning && (
                <React.Fragment>
                  <div>
                    The changes you’ve made would invalidate one or more source data connections in your workpapers. To
                    keep your changes, you'll need to save the data flow with a new name.
                  </div>
                  <br />
                </React.Fragment>
              )}
              <BTForm>
                {isFeatureFlagEnabled(WKP_CM_INTEGRATION) && (
                  <BTForm.FormGroup
                    required
                    aria-label="clientForm"
                    errorText={clientsError ? 'Failed to load clients' : undefined}
                    hasError={!!clientsError}
                    label="Client"
                  >
                    <BTComboBox
                      canCreateNew={false}
                      id="client"
                      isClearable={true}
                      isLoading={clientsLoading}
                      isSearchable={true}
                      maxMenuHeight={100}
                      noResultsMessage="No clients found."
                      options={clients.map(client => ({ label: client.name, value: client.id }))}
                      value={selectedClient}
                      onChange={option => setSelectedClient(option)}
                    />
                  </BTForm.FormGroup>
                )}
                <TaxPeriodInput
                  dataFlowId={dataFlow.id}
                  errorText={error?.taxPeriod}
                  value={taxPeriod}
                  onChange={onTaxPeriodChanged}
                />
                <BTForm.FormGroup
                  required
                  aria-label="nameForm"
                  errorText={error?.name}
                  hasError={!!error?.name}
                  label="Name"
                >
                  <BTInput maxLength="100" value={name} onChange={onChange} />
                </BTForm.FormGroup>
                <br />
                {!isDifferentClient && (
                  <BTCheckbox
                    checked={includeSourceFiles}
                    id="includeSourceFiles"
                    label="Include source file(s)"
                    onChange={ev => setIncludeSourceFiles(ev.target.checked)}
                  />
                )}
              </BTForm>
              {taxPeriodChanged && (
                <React.Fragment>
                  <br />
                  <div className="notification">
                    <p>
                      <BTIcon icon="info-circle"></BTIcon>If you are performing a rollforward, it is recommended to keep
                      the same name as the original Data Connect workflow. Keeping the same name will enable workpaper
                      data connections to rollforward to the new period.
                    </p>
                  </div>
                </React.Fragment>
              )}
              {isDifferentClient && (
                <React.Fragment>
                  <br />
                  <div
                    className="notification"
                    style={{
                      backgroundColor: '#FFD700',
                      fontSize: '16px',
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px 16px',
                      color: 'black',
                    }}
                  >
                    <BTIcon icon="exclamation-triangle" style={{ marginRight: '8px', color: 'black' }} />
                    <span>
                      When copying a Data Connect workflow to a different client, source files will not be included.
                    </span>
                  </div>
                </React.Fragment>
              )}
            </BTModal.Body>
            <BTModal.Footer>
              <BTButton onClick={onClose}>Cancel</BTButton>
              <BTButton btStyle="primary" disabled={isLoading} hasSpinner={isLoading} onClick={onSaveClicked}>
                {isDifferentClient ? 'Copy' : 'Save'}
              </BTButton>
            </BTModal.Footer>
            <BTAlert appear dismissible fixed btStyle="danger" visible={!!error} onDismiss={reset}>
              {error || ''}
            </BTAlert>
          </BTModal>
        </>
      ) : (
        <>
          <BTAlert appear dismissible fixed btStyle="success" visible={showCopySuccessMessage} onDismiss={onClose}>
            The data connect workflow{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}
              onClick={() => setShowClientChangeModal(true)}
            >
              '{name}'
            </span>{' '}
            has been successfully copied to client '{selectedClient?.label}'.
          </BTAlert>
          {showClientChangeModal && (
            <ClientChangeConfirmationModal
              show={showClientChangeModal}
              onClose={handleClientChangeModalClose}
              onProceed={handleClientChangeConfirmationProceed}
            />
          )}
        </>
      )}
      <GeneralErrorMessage dismissible={true} visible={generalError} onDismiss={() => reset} />
    </>
  );
};

SaveAsModal.propTypes = {
  initialData: PropTypes.object,
  dataFlow: PropTypes.object,
  onClose: PropTypes.func,
  showWarning: PropTypes.bool,
};

SaveAsModal.defaultProps = {
  showWarning: false,
};

export default SaveAsModal;
