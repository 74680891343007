import qs from 'qs';
import * as api from '../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../configs/params';
import { fileExtensionToLowerCase } from '../../../utils/fileExtensionToLowerCase';

export async function getDataFlowSourceFiles(dataFlowId, inputId) {
  const response = await api.get(`${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${inputId}`);
  return response.json();
}

export async function getPresignedUrl(presignedUrlQuery) {
  const response = await api.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/source-files/presigned-url?${presignedUrlQuery}`
  );
  if (!response.ok) {
    const errorResponse = await response.json();
    throw errorResponse.error;
  }

  return response.json();
}

export async function uploadFileToBucket(url, sourceFile) {
  const fileWithLowercaseExt = new File([sourceFile], fileExtensionToLowerCase(sourceFile.name));

  const response = await fetch(url, {
    method: 'PUT',
    body: fileWithLowercaseExt,
  });

  if (!response.ok) {
    throw new Error('Failed to upload source file. Please check your internet connection and try again.');
  }
}

export async function uploadSourceFile(sourceFile) {
  const presignedUrlQuery = qs.stringify({ fileName: sourceFile.name });
  const presignedUrlResult = await api.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/source-files/presigned-url?${presignedUrlQuery}`
  );
  const presignedUrlData = await presignedUrlResult.json();
  if (!presignedUrlResult.ok) {
    throw presignedUrlData.error;
  }

  const { url, key, bucket } = presignedUrlData;
  const uploadResult = await fetch(url, {
    method: 'PUT',
    body: sourceFile,
  });
  if (!uploadResult.ok) {
    throw new Error('Failed to upload source file. Please check your internet connection and try again.');
  }

  return { name: sourceFile.name, path: key, bucket };
}

export async function fetchRetry(cbFunc, delay) {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        resolve(await cbFunc);
      } catch (err) {
        reject(err);
      }
    }, delay);
  });
}
