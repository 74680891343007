import { BTButton } from '@btas/jasper';
import React, { useContext, useEffect } from 'react';
import Frame from '../_shared/Frame';
import './ClientChange/styles.scss';
import { getFakeEvent, itemTypes, setPropValues } from '../_shared/EventTrackingContext/utils';
import { eventNames } from '../_shared/EventTrackingContext/constants';
import { EventTrackingContext } from '../_shared/EventTrackingContext';

export default function UnauthorizedClientAccessPage() {
  const { trackEvent: trackingEvent } = useContext(EventTrackingContext);
  useEffect(() => {
    const fakeEvent = getFakeEvent(itemTypes.DIV, 'wkp-no-client-access-page');
    trackingEvent(
      eventNames.noticeViewed,
      setPropValues(fakeEvent, eventNames.noticeViewed, {
        noticeDetails: 'Unauthorized client access page',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Frame showAppBar={false}>
      <div className="client-change-container">
        <div>
          <h1>No client access granted</h1>
          <div className="client-change-error-message-text">
            You do not have access to this client. Contact your account administrator to request access.
          </div>

          <div className="client-change-btn-container">
            <a href="/">
              <BTButton btStyle="primary" className="client-change-homepage-btn">
                RETURN TO WORKPAPERS HOME
              </BTButton>
            </a>
          </div>
        </div>
      </div>
    </Frame>
  );
}
