import React, { useEffect, useState } from 'react';
import { BTModal, BTButton, BTGrid } from '@btas/jasper';
import Spinner from '../../../_shared/Spinner';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';
import { fetchDataLinks, syncDependentDatalinks } from './WorkpaperSyncDataLinksModal/apis';
import { wijmoKey } from '../../../../configs/params';

const Grid = ({ data }) =>
  data.length && (
    <>
      <BTGrid allowResizing={1} data={data} wijmoKey={wijmoKey}>
        <BTGrid.Header
          key="workpaperName"
          align="left"
          binding="workpaperName"
          cellTemplate={context => (
            <>
              <a href={`/editor/${context.rowData.workpaperId}`} rel="noreferrer" target="_blank">
                {context.rowData.workpaperName}
              </a>
            </>
          )}
          class="header-datalink-modal"
          width="*"
        >
          Name
        </BTGrid.Header>
        <BTGrid.Header key="taxPeriod" align="left" binding="taxPeriod" class="header-datalink-modal" width="*">
          Tax Period
        </BTGrid.Header>
        <BTGrid.Header key="impactedLinks" align="left" binding="impactedLinks" class="header-datalink-modal" width="*">
          Impacted Links
        </BTGrid.Header>
      </BTGrid>
    </>
  );

const WorkpaperSyncDataLinksModal = ({ workpaperId, onClose, onSyncDataLinks }) => {
  const [dataLinks, setDataLinks] = useState([]);
  const [dataLinksFinalStatus, setDataLinksFinalStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorSyncDataLinks, setErrorSyncDataLinks] = useState(false);

  useEffect(
    () => {
      async function fetchData() {
        setIsLoading(true);
        const data = await fetchDataLinks([workpaperId]);
        setIsLoading(false);
        if (data === null) {
          setErrorSyncDataLinks(true);
        }
        const dataLinksFinal = data.filter(dataLink => dataLink.workpaperStatus === 'Final');
        const dataLinks = data.filter(dataLink => dataLink.workpaperStatus !== 'Final');

        const formatDataLink = dataLink => ({
          ...dataLink,
          impactedLinks: `${dataLink.outSyncLinks}/${dataLink.totalLinks}`,
          taxPeriod: dataLink.taxPeriod || '',
          workpaperName: dataLink.workpaperName,
        });
        setDataLinks(dataLinks.map(formatDataLink));
        setDataLinksFinalStatus(dataLinksFinal.map(formatDataLink));
      }
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSyncClick = async () => {
    setIsLoading(true);
    setErrorSyncDataLinks(false);

    try {
      await syncDependentDatalinks(workpaperId);
      setIsLoading(false);
      onSyncDataLinks();
    } catch {
      setIsLoading(false);
      setErrorSyncDataLinks(true);
    }
  };

  return (
    <BTModal
      className="dataLinks-sync-modal"
      show={true}
      size="modal-lg"
      title="Synchronize Changes"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <div className="body-modal-height">
          {isLoading ? (
            <Spinner description="Scanning workpaper for Data Links..." />
          ) : errorSyncDataLinks ? (
            <GeneralErrorMessage className="onSyncDataLinks-summary-alert" dismissible={false} />
          ) : (
            <>
              {dataLinks.length > 0 && (
                <>
                  <p>By synchronizing changes, the following linked workpapers will be updated. Are you sure?</p>
                  <Grid data={dataLinks} />
                </>
              )}
              {dataLinksFinalStatus.length > 0 && (
                <>
                  <p>
                    The following linked workpapers will <strong>not</strong> be updated, since they are marked as
                    'Final':
                  </p>
                  <Grid data={dataLinksFinalStatus} />
                </>
              )}
            </>
          )}
        </div>
      </BTModal.Body>

      <BTModal.Footer>
        <BTButton id="wkp-datalinks-sync-cancel-btn" onClick={onClose}>
          CANCEL
        </BTButton>

        {!errorSyncDataLinks && !isLoading && (
          <>
            <BTButton btStyle="primary" hasSpinner={false} id="wkp-datalink-sync-btn" onClick={handleSyncClick}>
              SYNCHRONIZE
            </BTButton>
          </>
        )}
      </BTModal.Footer>
    </BTModal>
  );
};

export default WorkpaperSyncDataLinksModal;
