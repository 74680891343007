import { useEffect, useReducer, useState } from 'react';
import usePubSub from '../PubSub/usePubSub';
import MessageType from '../../../_shared/PubSub/pubSubMessageType';
import { v4 as uuidv4 } from 'uuid';
import { saveCommands } from './useCommandsQueue/apis';
import { getDataFromLocalStorage } from '../../../_shared/storage';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { DEFAULT_COMMANDS_SAVING_TIMEOUT } from '../../../../configs/params';
import { httpRequestWithRetry } from '../Spreadsheet/_spreadsheets/utils';

const ACTIONS = {
  ADD_COMMAND: 'addCommand',
  START_PROCESS: 'startProcessQueue',
  END_PROCESS: 'endProcessQueue',
  ERROR_PROCESS: 'errorProcessQueue',
};

const initialState = {
  commandsQueue: [],
  isCommandsSaving: false,
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.ADD_COMMAND:
      return { ...state, commandsQueue: [...state.commandsQueue, ...action.payload] };
    case ACTIONS.START_PROCESS:
      return { ...state, isCommandsSaving: true };
    case ACTIONS.END_PROCESS:
      return {
        ...state,
        isCommandsSaving: false,
        commandsQueue: state.commandsQueue.filter(c => !action.payload.some(p => c.commandId === p.commandId)),
      };
    case ACTIONS.ERROR_PROCESS:
      return { ...state, isCommandsSaving: false };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export default function useCommandsQueue({
  spreadRef,
  dataReferenceWorkpaperId,
  syncDatareferences,
  lastCommandAppliedTime,
  commandsProcessingError,
  setShowWorkpaperSyncCommandsModal,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [workpaperId, setWorkpaperId] = useState();
  const { publish } = usePubSub();

  const syncDatareferencesPositionsData = async commands => {
    const trackingcommands = [...commands];
    syncDatareferences.dataLinkCellDependencyChangeAction.current(trackingcommands);

    trackingcommands.forEach(commandRequest => {
      const command = JSON.parse(commandRequest.commandText);
      publish({
        body: { command, sheet: spreadRef.current.getSheetFromName(command.sheetName) },
        message: MessageType.CellPosition,
        callback: syncDatareferences?.trackDataReferencesAction.current,
      });
    });
  };

  const sendCommands = async commands => {
    if (!commands.length || state.isCommandsSaving || commandsProcessingError.current) return;

    dispatch({ type: ACTIONS.START_PROCESS });
    const operationStartTime = Date.now();
    syncDatareferencesPositionsData(commands);

    try {
      const sheetName = spreadRef.current?.getActiveSheet()?.name();
      commands.forEach(command => {
        delete command.sheetNames;
      });
      const response = await httpRequestWithRetry(() =>
        saveCommands(dataReferenceWorkpaperId.current, sheetName, commands, lastCommandAppliedTime)
      );

      if (response) {
        if (response.ok) {
          dispatch({ type: ACTIONS.END_PROCESS, payload: commands });

          if (commands.some(x => x.commandText.includes('{"cmd":"renameSheet"'))) {
            publish({
              body: workpaperId,
              message: MessageType.CellPosition,
              callback: syncDatareferences?.loadDataReferencesAction.current,
            });
          }
        } else if ([404, 409].includes(response.status)) {
          window.location.reload();
        } else {
          commandsProcessingError.current = true;
          state.isCommandsSaving = false;
        }
      } else {
        commandsProcessingError.current = true;
        state.isCommandsSaving = false;
      }

      CustomLogger.pushLog(CustomLogger.operations.PROCESS_COMMAND, {
        workpaperId: dataReferenceWorkpaperId.current,
        commandsCount: commands.length.toString(),
        commandIds: commands.map(c => c.commandId).join(', '),
        commandsDetails: commands.map(c => JSON.parse(c.commandText).cmd).join(', '),
        duration: (Date.now() - operationStartTime).toString(),
        status: response?.status.toString(),
      });
    } catch (error) {
      dispatch({ type: ACTIONS.ERROR_PROCESS });
      CustomLogger.pushLog(CustomLogger.operations.PROCESS_COMMAND, {
        workpaperId: dataReferenceWorkpaperId.current,
        commandsCount: commands.length.toString(),
        commandIds: commands.map(c => c.commandId).join(', '),
        commandsDetails: commands.map(c => JSON.parse(c.commandText).cmd).join(', '),
        duration: (Date.now() - operationStartTime).toString(),
        error: error.message,
      });
    }
  };

  useEffect(() => {
    if (state.commandsQueue.length > 0) {
      sendCommands([...state.commandsQueue]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.commandsQueue]);

  useEffect(() => {
    if (state.isCommandsSaving) {
      const timeoutId = setTimeout(() => {
        commandsProcessingError.current = true;
        state.isCommandsSaving = false;
        setShowWorkpaperSyncCommandsModal(true);
        CustomLogger.pushLog(CustomLogger.operations.PROCESS_COMMAND, {
          error: 'Command processing took longer than expected',
          workpaperId: dataReferenceWorkpaperId.current,
        });
      }, DEFAULT_COMMANDS_SAVING_TIMEOUT);

      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isCommandsSaving]);

  const enqueueCommands = commands => {
    const updatedCommands = commands.map(command => ({
      ...command,
      commandId: uuidv4(),
      creationDate: new Date().toISOString(),
      sessionId: getDataFromLocalStorage(`sessionId-${dataReferenceWorkpaperId.current}`),
    }));
    dispatch({ type: ACTIONS.ADD_COMMAND, payload: updatedCommands });
  };

  return {
    isCommandsSaving: state.isCommandsSaving,
    commandsQueue: state.commandsQueue,
    enqueueCommands,
    setWorkpaperId,
  };
}
