import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { JasperThemeProvider } from '@btas/jasper';
import AuthRoute from './App/AuthRoute';
import EditorPageRoute from './App/EditorPageRoute';
import GeneralErrorBoundary from './App/GeneralErrorBoundary';
import AuthLoginPage from './components/auth/AuthLoginPage';
import LoginPage from './components/auth/LoginPage';
import HomePage from './components/home/HomePage';
import EditorRedirect from './components/editor/EditorRedirect';
import DataFlowsPage from './components/data-flows/DataFlowsPage';
import DataFlowEditorPage from './components/data-flows/DataFlowEditorPage';
import Spinner from './components/_shared/Spinner';
import useWorkpaperApp from './utils/useWorkpaperApp';
import { useAuth } from './components/auth/hooks/useAuth';
import { isFeatureFlagEnabled } from './utils/featureFlags';
import { WKP_LANDING_ON_DATAFLOW, WKP_CM_INTEGRATION } from './constants/featureFlags';
import { usePendo } from './utils/usePendo';
import { useMaintenanceMode } from './components/global-components/Hooks/useMaintenanceMode';
import EditorPage from './components/editor/EditorPage';
import { displayApplicationBrowserConsoleLogs } from './components/_shared/consoleLogger';
import RecycleBin from './components/home/RecycleBin';
import ClientSelection from './components/home/ClientSelection';
import { UserPermissionsProvider } from './components/_shared/UserPermissionsContext';
import { EventTrackingProvider } from './components/_shared/EventTrackingContext';
import NoClientAccessPage from './components/home/NoClientAccessPage';
import UnauthorizedClientAccessPage from './components/home/UnAuthorizedClientAccessPage';
import { ClientProviderProxy } from './contexts/ClientContext';
import ClientChange from './components/home/ClientChange';
import GetClientsErrorPage from './components/home/GetClientsErrorPage';
import ClientChangeConfirmationPage from './components/home/ClientChangeConfirmationPage';
import { getTokenInfo } from './components/_shared/auth';

function App() {
  displayApplicationBrowserConsoleLogs();
  useMaintenanceMode();
  const location = useLocation();
  const { user } = useAuth();
  const { isFeatureFlagsLoaded } = useWorkpaperApp();
  usePendo();

  if (location.pathname === '/login') {
    const tokenInfo = getTokenInfo();
    if (!tokenInfo) {
      /**
       * Enable login path only when user not logged in
       */
      return (
        <Switch>
          <Route exact component={LoginPage} path="/login" />
        </Switch>
      );
    } else {
      /**
       * Redirect user to homepage if user already logged in
       */
      window.location.href = document.baseURI;
    }
  }

  return (
    <JasperThemeProvider>
      <GeneralErrorBoundary>
        {!user || !isFeatureFlagsLoaded ? (
          <Spinner />
        ) : (
          <EventTrackingProvider>
            <UserPermissionsProvider>
              <ClientProviderProxy>
                <Switch>
                  <Route exact component={AuthLoginPage} path="/auth/login" />
                  <AuthRoute exact component={HomePage} path="/workpapers" />
                  <AuthRoute exact component={RecycleBin} path="/workpapers/recycle-bin" />
                  {isFeatureFlagEnabled(WKP_CM_INTEGRATION) && (
                    <AuthRoute exact component={ClientSelection} path="/client-selection" />
                  )}
                  {isFeatureFlagEnabled(WKP_CM_INTEGRATION) && (
                    <AuthRoute exact component={NoClientAccessPage} path="/no-client-access" />
                  )}
                  {isFeatureFlagEnabled(WKP_CM_INTEGRATION) && (
                    <AuthRoute exact component={UnauthorizedClientAccessPage} path="/unauthorized-client-access" />
                  )}
                  {isFeatureFlagEnabled(WKP_CM_INTEGRATION) && (
                    <AuthRoute exact component={ClientChange} path="/client-change" />
                  )}
                  {isFeatureFlagEnabled(WKP_CM_INTEGRATION) && (
                    <AuthRoute exact component={GetClientsErrorPage} path="/get-clients-error" />
                  )}
                  {isFeatureFlagEnabled(WKP_CM_INTEGRATION) && (
                    <AuthRoute exact component={ClientChangeConfirmationPage} path="/client-change-confirmation" />
                  )}
                  <EditorPageRoute exact component={EditorRedirect} path="/editor" />
                  <EditorPageRoute exact component={EditorPage} path="/editor/:id" />
                  <EditorPageRoute exact component={EditorPage} path="/editor/:id/history/:versionId" />
                  <AuthRoute exact component={DataFlowsPage} path="/data-flows" />
                  <AuthRoute
                    exact
                    path="/data-flows/import"
                    render={props => <DataFlowsPage {...props} showImportDialog />}
                  />
                  <AuthRoute component={DataFlowEditorPage} path="/data-flows/:id/editor" />
                  <Redirect
                    to={
                      isFeatureFlagEnabled(WKP_CM_INTEGRATION)
                        ? '/client-selection'
                        : isFeatureFlagEnabled(WKP_LANDING_ON_DATAFLOW)
                          ? '/data-flows'
                          : '/workpapers'
                    }
                  />
                </Switch>
              </ClientProviderProxy>
            </UserPermissionsProvider>
          </EventTrackingProvider>
        )}
      </GeneralErrorBoundary>
    </JasperThemeProvider>
  );
}

export default App;
