import { useQuery } from '@tanstack/react-query';
import { searchGlobalTemplate } from './apis';

export const useSearch = searchText => {
  return useQuery(['search template', searchText], () => searchGlobalTemplate(searchText), {
    enabled: true,
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 50 * 1000,
  });
};
