import { useState, useCallback, useMemo } from 'react';

import * as apis from './apis';
import { transformToConfiguration } from '../../useSaveDataFlow/transformToConfiguration';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';
import { WKP_INPUT_FILE_IMPORT } from '../../../../../constants/featureFlags';
import { InputElementType } from '../../elementType/types/InputElementType';
import CustomLogger from '../../../../_shared/Logger/CustomLogger';

export default function useCopyDataFlow() {
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [generalError, setGeneralError] = useState(false);

  const copyDataFlow = useCallback(
    async (dataFlow, name, taxPeriod, includeSourceFiles, selectedClientId = undefined) => {
      setError(null);
      setLoading(true);
      try {
        if (!includeSourceFiles && isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
          for (const key in dataFlow.elements) {
            if (dataFlow.elements[key]?.elementType?.type === InputElementType.TYPE) {
              if (dataFlow.elements[key]?.elementData?.uploadedFile)
                delete dataFlow.elements[key]?.elementData?.uploadedFile;
              if (dataFlow.elements[key]?.elementData?.import) delete dataFlow.elements[key]?.elementData?.import;
              if (dataFlow.elements[key]?.elementData?.hasSourceFileUploaded)
                delete dataFlow.elements[key]?.elementData?.hasSourceFileUploaded;
            }
          }
        }

        const workflowConfiguration = undefined;

        const response = await apis.copyDataFlow(
          transformToConfiguration(dataFlow.elements, dataFlow.links, workflowConfiguration, dataFlow.notes || {}),
          name,
          taxPeriod,
          includeSourceFiles,
          dataFlow.id,
          selectedClientId
        );

        if (response.error) {
          const validationDetails = response.error?.details.reduce(
            (acc, err) => ({ ...acc, [err.target]: err.message }),
            {}
          );
          setError(validationDetails || response.error.message);
          CustomLogger.pushLog(CustomLogger.operations.SAVE_AS, {
            workflow_id: dataFlow.id,
            worflow_name: name,
            error: JSON.stringify(validationDetails || response.error.message),
            message: `Something went wrong with the response from the create new data flow endpoint when saving as.`,
          });
        } else {
          setIsSuccess({
            newDataFlowId: response.id,
          });
        }
      } catch (e) {
        CustomLogger.pushLog(CustomLogger.operations.SAVE_AS, {
          workflow_id: dataFlow.id,
          worflow_name: name,
          error: JSON.stringify(e.message),
          message: `Something happened while trying to copy the data flow when saving as.`,
        });
        setGeneralError(true);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const reset = useCallback(() => {
    setError(false);
    setIsSuccess(null);
    setGeneralError(false);
  }, [setError]);

  const state = useMemo(
    () => ({ error, isLoading, isSuccess, generalError }),
    [error, isLoading, isSuccess, generalError]
  );

  const actions = useMemo(
    () => ({
      copyDataFlow,
      reset,
      setGeneralError,
    }),
    [copyDataFlow, reset, setGeneralError]
  );

  return [state, actions];
}
