import GC from '../../../../../../SpreadSheets';
import { STATE_TAX_RATE } from '../../../../../_shared/DataReference/ReferenceType';
import { enqueueFormula } from '../formulas';

export const stateTaxRateFormulaName = 'STATETAXRATE';

const name = stateTaxRateFormulaName;

const parameters = [
  {
    name: '"jurisdiction"',
    repeatable: false,
    optional: false,
  },
  {
    name: '"period_start_date"',
    repeatable: false,
    optional: false,
  },
  {
    name: 'taxable_income',
    repeatable: false,
    optional: true,
  },
];

const description =
  'Retrieves state tax rates from the tax law database. Taxable income parameter is optional. For states with graduated income tax, if no taxable income is entered then the tax rate for the highest income bracket is returned; if taxable income is entered, then the effective rate is computed.';

const evaluationFunction = (args, data) => {
  const [context, jurisdiction, periodStartDate] = args;

  if (jurisdiction && periodStartDate) {
    const formulaType = STATE_TAX_RATE;

    return enqueueFormula(data, context, formulaType, args);
  }
  return GC.Spread.CalcEngine.Errors.NotAvailable;
};

export const stateTaxRateFormula = (gcSyncFunction, data) =>
  new gcSyncFunction({
    name,
    minArgs: 2,
    maxArgs: 3,
    descriptionData: {
      name,
      description,
      parameters,
    },
    evaluationFunction,
    data,
  });
